import { FormControl } from '@material-ui/core';
import clsx from 'clsx';
import Select from '@material-ui/core/Select';
import PT from 'prop-types';
import { unitType as unitTypePropType } from '../../../types';
import useStyles from '../../data-drawer/edit-form/data-drawer-edit-form-styles';

const SelectUnit = ({ onUnitSelection, defaultOrSelectedUnit, unitTypes }) => {
  const classes = useStyles();

  if (
    defaultOrSelectedUnit === null ||
    Object.keys(defaultOrSelectedUnit).length === 0 ||
    defaultOrSelectedUnit.name === null ||
    defaultOrSelectedUnit.name === ''
  ) {
    return <div data-testid="no-unit" />;
  }
  return (
    <FormControl
      variant="outlined"
      className={clsx([classes.valueInput])}
      required
      name="unit"
    >
      <Select
        inputProps={{ 'data-testid': 'select-unit-drop-down' }}
        native
        onChange={e => onUnitSelection(e)}
      >
        <option
          selected="selected"
          value={JSON.stringify({
            id: defaultOrSelectedUnit.id,
            name: defaultOrSelectedUnit.name,
          })}
        >
          {defaultOrSelectedUnit.name}
        </option>
        {unitTypes?.unit_systems?.map(system => (
          <optgroup key={system.short_name} label={system.short_name}>
            {system.units
              ?.filter(unit => {
                return unit.id !== defaultOrSelectedUnit.id;
              })
              .map(unit => {
                const unitDetails = { id: unit.id, name: unit.name };
                return (
                  <option key={unit.name} value={JSON.stringify(unitDetails)}>
                    {unit.name}
                  </option>
                );
              })}
          </optgroup>
        ))}
      </Select>
    </FormControl>
  );
};

SelectUnit.propTypes = {
  defaultOrSelectedUnit: unitTypePropType.isRequired,
  unitTypes: unitTypePropType.isRequired,
  onUnitSelection: PT.func.isRequired,
};
export default SelectUnit;
