import PT from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import Star from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import handleFavourites from './hooks';
import { StyledIconButton } from '../../buttons';

function FavouritesButton({ loading, project }) {
  const { t } = useTranslation(['common']);
  const [cookies, setCookie, removeCookie] = useCookies([
    'ddbFavoriteProjects',
  ]);
  const { addFavorite, removeFavorite, favorite } = handleFavourites(
    project,
    cookies,
    setCookie,
    removeCookie
  );

  const icon = favorite ? (
    <Star data-testid="favourite" />
  ) : (
    <StarBorderIcon data-testid="notFavourite" />
  );

  return (
    <StyledIconButton
      title={t('common:favourite')}
      data-testid="favouritesButtons"
      data-cy={!loading && 'favourites-button'}
      onClick={favorite ? removeFavorite : addFavorite}
      aria-label={favorite ? 'Favourite' : 'Not favourite'}
      icon={
        loading ? (
          <Skeleton width={20} height={20} data-testid="loading" />
        ) : (
          icon
        )
      }
    />
  );
}

FavouritesButton.propTypes = {
  project: PT.shape({
    jobNameShort: PT.string,
    number: PT.string,
    projectId: PT.string,
  }),
  loading: PT.bool.isRequired,
};

FavouritesButton.defaultProps = {
  project: {
    jobNameShort: '',
    number: null,
    projectId: null,
  },
};

export default FavouritesButton;
