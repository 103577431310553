import { useState } from 'react';

export default () => {
  const [open, setOpen] = useState(true);

  const handleChangeOpen = () => {
    setOpen(!open);
  };

  return { handleChangeOpen, open };
};
