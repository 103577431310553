import { useState, useEffect, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import qs from 'qs';
import { isEmpty } from 'lodash';
import ParameterWizardAddParameters from '../parameter-wizard-add-parameters';
import { parametersApi, handleApiError } from '../../../../../../api';
import ParameterWizardSummary from '../parameter-wizard-summary';
import { ParametersContext } from '../../../../../../context/parameters-context';

export default (open, handleToggleOpen) => {
  const { t } = useTranslation(['common', 'parameters']);
  const { projectId } = useParams();
  const { search } = useLocation();
  const { asset_id: assetId, project_parameter: projectParameter } = {
    ...qs.parse(search, { ignoreQueryPrefix: true }),
  };

  const { getParameters } = useContext(ParametersContext);

  const [selectedAsset, setSelectedAsset] = useState({});
  const [warningOpen, setWarningOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [steps] = useState([
    {
      label: t('parameters:defineParametersToAdd'),
      optional: false,
      completed: false,
    },
    { label: t('summary'), optional: false, completed: false },
  ]);
  const [parameters, setParameters] = useState([]);
  const [assetHierarchy, setAssetHierarchy] = useState([]);
  const [otherAssetsOfTheSameType, setOtherAssetsOfTheSameType] = useState([]);
  const [showNext, setShowNext] = useState(false);
  const [requiredDataType, setRequiredDataType] = useState({});
  const [inputDataType, setInputDataType] = useState({});
  const [errorValue, setErrorValue] = useState({});
  const [additionalAssetsLoaded, setAdditionalAssetsLoaded] = useState(false);
  const [startingIndexOfParantAssets, setStartingIndexOfParantAssets] =
    useState(0);

  useEffect(() => {
    let didCancel = false;

    const getSelectedAsset = async () => {
      const { assets: data } = await parametersApi('getAssets', {
        asset_id: assetId,
      }).catch(err => handleApiError(err, []));
      const asset = data?.[0] || {
        id: null,
        name: null,
        asset_type: { id: null },
      };
      if (!didCancel)
        setSelectedAsset({
          id: asset.id,
          name: asset.name,
          asset_type_id: asset.asset_type.id,
        });
    };
    if (assetId) getSelectedAsset();
    else if (projectParameter) setSelectedAsset({ id: projectId });

    return () => {
      didCancel = true;
    };
  }, [assetId, projectParameter, projectId]);

  useEffect(() => {
    setOtherAssetsOfTheSameType([]);
  }, [selectedAsset]);

  useEffect(() => {
    let didCancel = false;

    // The following methods help to assign this new parameter to other assets of the same asset type. The data populates the "Parent Assets" checkboxes

    // Get the Hierarchy for this asset
    const getHierarchyForThisAsset = async () => {
      if (projectParameter) {
        setAssetHierarchy([]);
      } else {
        const query = {
          asset_ids: [selectedAsset.id],
        };
        if (selectedAsset.id) {
          const response = await parametersApi(
            'getAssetHierarchy',
            query
          ).catch(err => handleApiError(err, []));
          if (!didCancel && response) {
            const { hierarchies: data } = response;
            setAssetHierarchy(data[0]);
          }
        }
      }
    };

    // Get the hierarchy of the other assets of the same type - seems like an unnecessary extra call but I think is needed because of the API structure
    const getHierarchyForOtherAssetsOfTheSameType = async assets => {
      // I think this does need to be converted to JSON for now - possible API bug
      setAdditionalAssetsLoaded(false);
      const query = {
        asset_ids: JSON.stringify(
          assets
            ?.slice(
              startingIndexOfParantAssets,
              startingIndexOfParantAssets + 50
            )
            .map(item => item.id)
        ),
      };
      let data = null;
      if (!isEmpty(JSON.parse(query.asset_ids))) {
        const { hierarchies } = await parametersApi(
          'getAssetHierarchy',
          query
        ).catch(err => handleApiError(err, []));
        data = hierarchies;
      }

      const otherAssets = assets
        ?.slice(startingIndexOfParantAssets, startingIndexOfParantAssets + 50)
        .map((asset, i) => ({
          ...asset,
          hierarchy: data ? data[i] : [],
          show: true,
        }));

      let parentAssets = [];

      if (
        otherAssetsOfTheSameType[0]?.asset_type.id ===
        selectedAsset.asset_type_id
      ) {
        // remove any duplicates
        const otherAssetIds = otherAssetsOfTheSameType.map(asset => asset.id);
        parentAssets = [...otherAssetsOfTheSameType];
        otherAssets?.forEach(asset => {
          if (!otherAssetIds.includes(asset.id)) {
            parentAssets.push(asset);
          }
        });
      } else {
        parentAssets = otherAssets;
      }

      if (!didCancel) {
        // console.log(parentAssets)
        setOtherAssetsOfTheSameType(parentAssets);
      }
    };

    // Get other assets of the same type
    const getOtherAssetsOfTheSameType = async typeId => {
      const query = {
        asset_type_id: [typeId],
        project_id: [projectId],
        page_limit: 1000,
        asset_id: selectedAsset.id // sdp - only target same asset
      };
      const { assets: data } = await parametersApi('getAssets', query).catch(
        err => handleApiError(err, [])
      );
      // attach a checked boolean to every asset, with the current selectedAsset set to true;
      const assetsWithCurrentAssetChecked = data?.map(asset => ({
        ...asset,
        checked: asset.id === selectedAsset.id,
      }));

      if (assetsWithCurrentAssetChecked.length)
        await getHierarchyForOtherAssetsOfTheSameType(
          assetsWithCurrentAssetChecked
        );
    };

    if (open) {
      if (!projectParameter && projectId) {
        getOtherAssetsOfTheSameType(selectedAsset.asset_type_id);
      } else {
        setAdditionalAssetsLoaded(true);
      }

      if (selectedAsset?.id) getHierarchyForThisAsset();
    }

    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    projectId,
    projectParameter,
    selectedAsset,
    startingIndexOfParantAssets,
    open,
  ]);

  useEffect(() => {
    // if all parameters are valid ()
    setShowNext(
      parameters.length > 0 &&
        parameters.filter(parameter => parameter.revision.valid.isValid)
          .length === parameters.length
    );
  }, [parameters]);

  useEffect(() => {
    if (otherAssetsOfTheSameType.length > 0) setAdditionalAssetsLoaded(true);
  }, [otherAssetsOfTheSameType]);

  const handleUpdateParameters = cb => {
    setParameters(cb);
  };

  const handleAddingMoreParentAssets = () => {
    setStartingIndexOfParantAssets(prev => prev + 50);
  };

  const addNewParameter = () => {
    setParameters(currParameters => {
      const newParameter = {
        id: uuidv4(),
        additionalAssets: otherAssetsOfTheSameType
          ? JSON.parse(JSON.stringify(otherAssetsOfTheSameType)) // clone existing additionalAssets for the new parameter
          : null,
        parameter_type: {
          id: '',
          name: 'New Parameter',
        },
        revision: {
          status: 'unanswered',
          source_id: '',
          valid: { isValid: false, state: 'empty' },
          values: [
            {
              value: 'Enter value',
              unit: {
                name: '',
                id: '',
              },
            },
          ],
        },
      };
      return [...currParameters, newParameter];
    });
  };

  const stepContent = [
    <ParameterWizardAddParameters
      key="ParameterWizardAddParameters"
      parameters={parameters}
      addNewParameter={addNewParameter}
      setParameters={handleUpdateParameters}
      selectedAsset={selectedAsset}
      assetHierarchy={assetHierarchy}
      additionalAssets={otherAssetsOfTheSameType}
      requiredDataType={requiredDataType}
      setRequiredDataType={setRequiredDataType}
      inputDataType={inputDataType}
      setInputDataType={setInputDataType}
      errorValue={errorValue}
      setErrorValue={setErrorValue}
      additionalAssetsLoaded={additionalAssetsLoaded}
      handleAddingMoreParentAssets={handleAddingMoreParentAssets}
    />,
    <ParameterWizardSummary
      key="ParameterWizardSummary"
      parameters={parameters}
      disabled={!showNext}
    />,
  ];

  const toggleWarning = () => {
    // toggle the warning modal on and off
    setWarningOpen(!warningOpen);
  };

  const handleClose = () => {
    // close down the modals if the user chooses to exit
    setShowNext(false);
    setWarningOpen(false);
    setParameters([]);
    setActiveStep(0);
    handleToggleOpen();
  };

  const handleNext = () => {
    // step through to the next content element
    if (activeStep + 1 <= stepContent.length) setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    // step back to the previous content element
    if (activeStep - 1 >= 0) setActiveStep(activeStep - 1);
  };

  const processProjectParameters = () => {
    return parameters.map(parameter => {
      // const disciplineIds =
      //   parameter.discipline_ids?.map(discipline => discipline.id) || [];
      // const categoryIds = parameter.tag_ids?.map(category => category.id) || [];

      const params = {
        parameter_id: parameter.id,
        parameter_type_id: parameter.parameter_type.id,
        project_id: projectId,
        // discipline_id: disciplineIds,
        // category_id: categoryIds,
      };
      // if (parameter.revision.status !== 'unanswered') {
      //   params.revision = {
      //     source_id: parameter.revision.source_id,
      //     values: parameter.revision.values.map(item => ({
      //       value: item.value,
      //       unit_id: item.unit.id,
      //     })),
      //   };
      // }
      return params;
    });
  };

  const processParameters = () => {
    let params = [];
    // console.log(parameters)
    for (let i = 0; i < parameters.length; i++) {
      const parameter = parameters[i];

      // get the parentIds, we'll be creating a parameter out of each later
      // sdp - is always the same asset selected

      const parentIds = parameter.additionalAssets
        .filter(additionalAsset => additionalAsset.checked)
        .map(additionalAsset => additionalAsset.id);

      const parameterTypeId = parameter.parameter_type.id;
      // const postRevision = {
      //   source_id: parameter.revision.source_id,
      //   values: parameter.revision.values.map(item => ({
      //     value: item.value,
      //     unit_id: item.unit.id,
      //   })),
      // };

      // const disciplineIds =
      //   parameter.discipline_ids?.map(discipline => discipline.id) || [];
      // const categoryIds = parameter.tag_ids?.map(category => category.id) || [];

      // create a new parameter object for each parent
      const parameterSingleParent = parentIds.map(parentId => {
        const singlePostParameterObject = {
          // parameter_id: uuidv4(), // new id don't bother to reuse old one
          parameter_type_id: parameterTypeId,
          parent_ids: [parentId], // we will be restricting each parameter to a single asset parent for now
          project_id: projectId,
          // discipline_id: disciplineIds,
          // category_id: categoryIds,
        };
        // if (parameter.revision.status !== 'unanswered') {
        //   singlePostParameterObject.revision = postRevision;
        // }

        return singlePostParameterObject;
      });

      params = params.concat(parameterSingleParent);
    }

    return params;
  };

  /**
   * Wizard has been set up to add a single parameter to multiple parents
   * Data model can not yet support this cleanly, so for now we will be creating separate parameters,
   * one for each asset parent.
   *
   * when user clicks finish, create a new set of properly formatted parameters to send to the API
   */
  const handleFinish = async () => {
    const postParameters = async body => {
      return parametersApi('postParameters', {
        body,
      }).catch(err => handleApiError(err, []));
    };
    setShowNext(false);
    // console.log(projectParameter)
    // const newParameters = projectParameter
    //   ? processProjectParameters()
    //   : processParameters();
    const newParameters = processParameters();
    // console.log(newParameters)
    await postParameters({
      parameters: newParameters,
    });
        
    await getParameters({startIndex: 0})

    handleClose();
  };

  return {
    steps,
    stepContent,
    activeStep,
    warningOpen,
    toggleWarning,
    handleClose,
    handleNext,
    handleBack,
    showNext,
    handleFinish,
    handleAddingMoreParentAssets,
  };
};
