import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  main: {
    color: theme.palette.type !== 'dark' && theme.palette.primary.main,
    height: '65vh',
  },
  content: {
    height: '100%',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(16),
      paddingBottom: theme.spacing(10),
    },
  },
  title: {
    letterSpacing: '.7rem',
    fontWeight: theme.typography.fontWeightLight,
    [theme.breakpoints.only('xs')]: {
      fontSize: 24,
    },
  },
  button: {
    marginTop: theme.spacing(4),
  },
}));
