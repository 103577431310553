import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  modal: {
    backgroundColor: theme.palette.background.paper,
    width: 550,
    height: 675,
    maxHeight: 675,
    float: 'right',
    marginRight: '16%',
    marginTop: '7.5%',
    borderRadius: 5,
    boxShadow:
      'box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);',
    position: 'relative',
  },
  title: {
    marginLeft: '5%',
  },
  content: {
    marginLeft: '5%',
    marginRight: '5%',
  },
  buttons: {
    minWidth: '100%',
    position: 'absolute',
    bottom: 10,
  },
  cancelButton: {
    float: 'right',
    marginLeft: '5%',
  },
  submitButton: {
    float: 'right',
    marginRight: '5%',
    color: theme.palette.primary.main,
  },
  contentSpacing: {
    marginTop: 10,
  },
}));
