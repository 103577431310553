import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import {
  parametersApi,
  commentsApi,
  qaApi,
  handleApiError,
} from '../../../api';
import { camelCaseKeys, formatDate } from '../../../utils';
import { ParametersContext } from '../../../context/parameters-context';

export default setParameterEdited => {
  const { searchParams, updateParametersList } = useContext(ParametersContext);
  const { i18n } = useTranslation();
  const { state } = useLocation();

  const initialData = state ? state.parameter : {};

  const [parameter, setParameter] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [editMode, setEditMode] = useState(false);
  const [updateValues, setUpdateValues] = useState({
    source_id: null,
    values: [
      {
        id: null,
        value: null,
      },
    ],
  });
  const [defaultUnits, setDefaultUnits] = useState({});
  const [unitTypes, setUnitTypes] = useState({});
  const [openAddEntriesWizard, setOpenAddEntriesWizard] = useState(false);
  const [selectedSource, setSelectedSource] = useState({ title: null });
  const parameterId = searchParams.get('parameter_id');

  const toggleOpenAddEntriesWizard = () => {
    setOpenAddEntriesWizard(!openAddEntriesWizard);
  };

  useEffect(() => {
    let didCancel = false;
    const source = axios.CancelToken.source();

    const getParameter = async () => {
      setError(undefined);
      try {
        const apiResponse = await parametersApi(
          'getParameter',
          {
            parameter_id: parameterId,
          },
          source.token
        );
        if (!didCancel && apiResponse) {
          const { parameter: response } = apiResponse;
          setParameter(camelCaseKeys(response));
        }
      } catch (err) {
        setError(err.response.data);
      }
    };
    if (state?.parameter) {
      setParameter(state.parameter);
      window.history.replaceState({}, '');
    } else if (parameterId) getParameter();

    return () => {
      didCancel = true;
      source.cancel();
    };
  }, [parameterId, state]);

  useEffect(() => {
    let didCancel = false;

    const getUnits = async () => {
      const { parameter_type: parameterType } = await parametersApi(
        'getParameterType',
        {
          parameter_type_id: parameter.parameterType.id,
        }
      ).catch(err => handleApiError(err, []));
      const defaultUnit = parameterType?.default_unit;
      if (!didCancel) setDefaultUnits(defaultUnit);
    };
    if (parameter?.parameterType?.id) getUnits();

    return () => {
      didCancel = true;
    };
  }, [parameter]);

  useEffect(() => {
    let didCancel = false;
    const getUnitTypes = async () => {
      const { unit_type: parameterUnitType } = await parametersApi(
        'getUnitType',
        {
          unit_type_id: defaultUnits.unit_type_id,
        }
      ).catch(err => handleApiError(err, []));
      if (!didCancel) setUnitTypes(parameterUnitType);
    };

    if (defaultUnits?.unit_type_id) {
      getUnitTypes();
    }
    return () => {
      didCancel = true;
    };
  }, [defaultUnits]);

  useEffect(() => {
    if (
      updateValues.source_id ||
      updateValues.values.some(element => element.value)
    ) {
      setParameterEdited(true);
    } else {
      setParameterEdited(false);
    }
  }, [updateValues, setParameterEdited]);

  useEffect(() => {
    let didCancel = false;

    const values = {
      source_id: null,
      values: [
        {
          id:
            parameter?.revision?.values?.[0]?.unit?.id ||
            defaultUnits?.id ||
            defaultUnits,
          value: null,
        },
      ],
    };

    if (!didCancel) {
      setUpdateValues(values);
    }

    return () => {
      didCancel = true;
    };
  }, [defaultUnits, editMode, parameter]);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const sendUpdatedValues = () => {
    const updateRevision = async () => {
      setEditMode(false);
      setLoading(true);
      const { revision } = await parametersApi('postRevision', {
        parameter_id: parameterId,
        body: {
          source_id: updateValues.source_id,
          values: [
            {
              unit_id: updateValues.values[0].id,
              value: updateValues.values[0].value,
            },
          ],
        },
      }).catch(err => handleApiError(err, []));
      setParameter({ ...parameter, revision });
      updateParametersList({ ...parameter, revision }, parameterId);
      setLoading(false);
    };
    updateRevision();
  };

  const updateStatus = newStatus => {
    let didCancel = false;
    const patchStatus = async () => {
      const response = await parametersApi('patchRevision', {
        parameter_id: parameterId,
        body: { status: newStatus },
      }).catch(err => handleApiError(err, []));
      if (!didCancel && response) {
        const { revision } = response;
        setParameter({
          ...parameter,
          revision,
        });
        updateParametersList(
          {
            ...parameter,
            revision,
          },
          parameterId
        );
      }
    };
    patchStatus();

    return () => {
      didCancel = true;
    };
  };

  const [openModal, setOpenModal] = useState(false);
  const [rejectedComment, setRejectedComment] = useState('');
  const handleChangeRejectedComment = event => {
    setRejectedComment(event.target.value);
  };

  const handleModalSubmit = () => {
    const comment = document.getElementById('modalComment').value;
    const newUpdateStatus = async () => {
      const response = await parametersApi('patchRevision', {
        parameter_id: parameterId,
        body: { comment, status: 'rejected' },
      }).catch(err => handleApiError(err, []));

      if (response) {
        const { revision: assets } = response;
        setParameter({
          ...parameter,
          revision: assets,
        });
        updateParametersList(
          {
            ...parameter,
            revision: assets,
          },
          parameterId
        );
      }
    };

    setOpenModal(false);
    newUpdateStatus();
  };

  const [rejectedAlert, setRejectedAlert] = useState({
    name: '',
    date: '',
    comment: '',
    status: '',
  });

  useEffect(() => {
    let didCancel = false;

    const getRejectedAlert = async () => {
      let comment;

      if (parameter?.revision?.comment) {
        const commentId = parameter?.revision?.comment;
        const response = await commentsApi('getCommentById', {
          comment_id: commentId,
          Version: 0,
        }).catch(handleApiError);
        if (response) {
          const { comment: newComment } = response;
          comment = newComment;
        }
      }
      if (parameter?.revision?.id) {
        const { records: status } = await qaApi('getRecords', {
          reference_id: parameter?.revision?.id,
          sort_by: 'created_at',
          order: 'desc',
        }).catch(handleApiError);

        if (status.length > 0) {
          const newAlert = {
            name: status[0].created_by.staff_name,
            date:
              status.length > 0
                ? formatDate(status[0]?.created_at, undefined, i18n.language)
                : '',
            comment: comment?.content,
            status: status[0].type.name,
          };
          if (!didCancel) setRejectedAlert(newAlert);
        }
      }
    };
    getRejectedAlert();

    return () => {
      didCancel = true;
    };
  }, [parameter.revision, i18n.language]);

  return {
    error,
    parameter,
    loading,
    editMode,
    toggleEditMode,
    updateValues,
    setUpdateValues,
    sendUpdatedValues,
    updateStatus,
    defaultUnits,
    openModal,
    setOpenModal,
    handleModalSubmit,
    rejectedAlert,
    openAddEntriesWizard,
    setOpenAddEntriesWizard,
    toggleOpenAddEntriesWizard,
    handleChangeRejectedComment,
    rejectedComment,
    unitTypes,
    selectedSource,
    setSelectedSource,
  };
};
