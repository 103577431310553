import PT from 'prop-types';
import CardHeader from '@material-ui/core/CardHeader';
import Skeleton from '@material-ui/lab/Skeleton';

function CustomCardHeader({
  renderAvatar,
  renderTitle,
  titleTypographyProps = {},
  subheaderTypographyProps = {},
  renderAction,
  renderSubheader,
  isLoading,
}) {
  return (
    <CardHeader
      data-testid="card-header"
      avatar={
        isLoading ? (
          <Skeleton
            variant="circle"
            width={40}
            height={40}
            data-testid="loading"
          />
        ) : (
          renderAvatar
        )
      }
      title={isLoading ? <Skeleton data-testid="loading" /> : renderTitle}
      titleTypographyProps={titleTypographyProps}
      data-cy="parameterHeader"
      subheader={
        isLoading ? <Skeleton data-testid="loading" /> : renderSubheader
      }
      subheaderTypographyProps={subheaderTypographyProps}
      action={renderAction}
    />
  );
}

CustomCardHeader.propTypes = {
  renderAvatar: PT.node,
  renderTitle: PT.string,
  titleTypographyProps: PT.shape({}),
  subheaderTypographyProps: PT.shape({}),
  renderAction: PT.node.isRequired,
  renderSubheader: PT.shape({}),
  isLoading: PT.bool.isRequired,
};

CustomCardHeader.defaultProps = {
  renderTitle: '',
  renderAvatar: {},
  titleTypographyProps: {},
  subheaderTypographyProps: {},
  renderSubheader: {},
};

export default CustomCardHeader;
