import PT from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import useHooks from './hooks';
import useStyles from './wizard-styles';
import Warning from '../warning';

function Wizard(props) {
  const {
    open,
    activeStep,
    steps,
    stepContent,
    handleBack,
    handleAccept: handleAcceptRoot,
    primaryButtonProps: { label: primaryButtonLabel, ...primaryButtonRest },
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { handleCancel, warningOpen, handleAccept, handleWarningClose } =
    useHooks(handleAcceptRoot);

  return (
    <Dialog
      classes={{ container: classes.container, paper: classes.paper }}
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={handleCancel}
      data-testid="newProjectWizard"
    >
      <DialogTitle>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          className={classes.stepper}
        >
          {steps.map(({ label, optional, completed }) => {
            return (
              <Step key={label} completed={completed}>
                <StepLabel
                  className={classes.stepLabel}
                  optional={
                    optional ? (
                      <Typography
                        variant="caption"
                        className={classes.optionLabel}
                      >
                        {t('optional')}
                      </Typography>
                    ) : null
                  }
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.content}>
        {stepContent[activeStep]}
      </DialogContent>
      <Divider />
      <DialogActions className={classes.actions}>
        <Button
          onClick={handleCancel}
          className={clsx([classes.secondary, classes.leftAlign])}
        >
          {t('buttons.cancel')}
        </Button>
        <Button
          disabled={activeStep === 0 || primaryButtonRest.disabled}
          onClick={handleBack}
          className={classes.secondary}
        >
          {t('buttons.back')}
        </Button>
        <Button className={classes.primary} {...primaryButtonRest}>
          {primaryButtonLabel}
        </Button>
      </DialogActions>
      <Warning
        open={warningOpen}
        handleAccept={handleAccept}
        handleClose={handleWarningClose}
        title={t('wizard.warning.title')}
        body={t('wizard.warning.body')}
        secondaryButtonText={t('buttons.cancel')}
        primaryButtonText={t('buttons.close')}
      />
    </Dialog>
  );
}

Wizard.propTypes = {
  open: PT.bool.isRequired,
  activeStep: PT.number.isRequired,
  steps: PT.arrayOf(
    PT.shape({
      label: PT.string.isRequired,
      optional: PT.bool,
      completed: PT.bool,
    }).isRequired
  ).isRequired,
  stepContent: PT.arrayOf(PT.node).isRequired,
  handleBack: PT.func.isRequired,
  handleAccept: PT.func.isRequired,
  primaryButtonProps: PT.shape({
    label: PT.string.isRequired,
  }).isRequired,
};

export default Wizard;
