import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(() => ({
  grow: {
    flexGrow: 1,
  },
  widerMargin: {
    margin: '8px',
  },
}));
