// todo: remove in refactor
/* eslint-disable no-confusing-arrow */
import PT from 'prop-types';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import useStyles from './select-source-styles';
import useSelectSource from './hooks';
import AddSource from './add-source';

const SelectSource = ({
  setUpdateValues,
  updateValues,
  errors,
  selectedSource,
  setSelectedSource,
}) => {
  const filter = createFilterOptions();
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    allSources,
    setAllSources,
    sourceError,
    setSourceError,
    open,
    toggleOpen,
    setDialogValue,
    dialogValue,
  } = useSelectSource();

  return (
    <>
      <Autocomplete
        className={clsx([classes.grow, classes.widerMargin])}
        onChange={(_, newValue) => {
          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen();
              setDialogValue(vals => ({
                ...vals,
                title: newValue,
              }));
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen();
            setDialogValue(items => ({
              ...items,
              title: newValue.inputValue,
            }));
          } else {
            setUpdateValues({
              ...updateValues,
              source_id: newValue?.id || null,
            });
            setSelectedSource(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              title: t('addInputValue', { inputValue: params.inputValue }),
            });
          }

          return filtered;
        }}
        selectOnFocus
        options={allSources}
        getOptionLabel={option => {
          return option.reference
            ? `${option.reference} - ${option.title}`
            : option.title || '';
        }}
        value={selectedSource}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            error={sourceError || errors}
            onBlur={({ target }) => setSourceError(!target.value)}
            required
            helperText={
              sourceError
                ? t('selectSource.sourceError.noValue')
                : t('requiredError')
            }
            label={t('selectSource.label')}
            variant="outlined"
          />
        )}
      />
      <AddSource
        open={open}
        dialogValue={dialogValue}
        setDialogValue={setDialogValue}
        toggleOpen={toggleOpen}
        setAllSources={setAllSources}
        setUpdateValues={setUpdateValues}
        setSelectedSource={setSelectedSource}
      />
    </>
  );
};

SelectSource.propTypes = {
  setUpdateValues: PT.func.isRequired,
  updateValues: PT.oneOfType([PT.array, PT.object]).isRequired,
  errors: PT.bool.isRequired,
  selectedSource: PT.shape(PT.object).isRequired,
  setSelectedSource: PT.func.isRequired,
};

export default SelectSource;
