import commonConfig from './common';

export default {
  url: {
    ...commonConfig.url,
    api: {
      base: 'https://dev.ddb.arup.com',
    },
  },
  featureFlags: {
    isLanguageCheckEnabled: true,
    isMultipleEntriesEnabled: false,
    isRoleBasedPermissionEnabled: false,
    isProjectSearchEnabled: false,
  },
  env: 'development',
};
