/* eslint-disable no-unused-vars */
import { useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import Content from './content';
import DataCardHeader from './header';
import EditForm from './edit-form';
import AddEntriesWizard from './multiple-entries/add-entries-wizard';
import useStyles from './data-drawer-styles';
import useParameter from './hooks';
import Alert from '../alerts';

function DataDrawer() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [parameterEdited, setParameterEdited] = useState(false);
  const [displayWarning, setDisplayWarning] = useState(false);
  const [warningAccept, setWarningAccept] = useState(() => () => {});

  const checkForWarning = (callback, args) => {
    if (parameterEdited) {
      setDisplayWarning(true);
      setWarningAccept(() => () => {
        setDisplayWarning(false);
        callback(...args);
      });
    } else {
      setDisplayWarning(false);
      callback(...args);
    }
  };

  const {
    error,
    parameter,
    loading,
    editMode,
    toggleEditMode,
    updateValues,
    setUpdateValues,
    sendUpdatedValues,
    updateStatus,
    defaultUnits,
    openModal,
    setOpenModal,
    rejectedAlert,
    handleModalSubmit,
    openAddEntriesWizard,
    setOpenAddEntriesWizard,
    toggleOpenAddEntriesWizard,
    rejectedComment,
    handleChangeRejectedComment,
    unitTypes,
    selectedSource,
    setSelectedSource,
  } = useParameter(setParameterEdited);

  const modalBody = (
    <div className={classes.modal}>
      <h3 className={classes.title}>{t('addComment')}</h3>
      <TextField
        id="modalComment"
        label="Comment"
        variant="outlined"
        multiline
        required
        value={rejectedComment}
        onChange={handleChangeRejectedComment}
        className={classes.textfield}
      />
      <div>
        <Button
          onClick={() => setOpenModal(false)}
          className={classes.cancelButton}
        >
          {t('buttons.cancel')}
        </Button>
        <Button
          onClick={() => handleModalSubmit()}
          className={classes.submitButton}
          disabled={isEmpty(rejectedComment)}
        >
          {t('buttons.submit')}
        </Button>
      </div>
    </div>
  );

  const renderContent = () => {
    if (loading)
      return (
        <Skeleton
          className={classes.skeletonBody}
          variant="rect"
          height="50vh"
          width="90%"
        />
      );
    if (editMode)
      return (
        <EditForm
          parameter={parameter}
          updateValues={updateValues}
          setUpdateValues={setUpdateValues}
          defaultUnits={defaultUnits}
          unitTypes={unitTypes}
          sendUpdatedValues={sendUpdatedValues}
          toggleEditMode={(...args) => checkForWarning(toggleEditMode, args)}
          setSelectedSource={setSelectedSource}
          selectedSource={selectedSource}
        />
      );
    return (
      <>
        {rejectedAlert.status === 'rejected' &&
          parameter.revision?.status !== undefined && (
            <Alert
              title={
                <Typography variant="h6">
                  {t('parameters:rejectedReason')}: {rejectedAlert.comment}
                </Typography>
              }
              text={
                <>
                  <Typography variant="subtitle2">
                    {t('parameters:rejectedBy')}: {rejectedAlert.name}
                  </Typography>
                  <Typography variant="subtitle2">
                    {t('parameters:rejectedOn')}: {rejectedAlert.date}
                  </Typography>
                </>
              }
            />
          )}
        <Content
          parameter={parameter}
          defaultUnits={defaultUnits}
          editMode={editMode}
          updateValues={updateValues}
          checkForWarning={checkForWarning}
          toggleEditMode={(...args) => checkForWarning(toggleEditMode, args)}
          setUpdateValues={setUpdateValues}
          sendUpdatedValues={sendUpdatedValues}
          setOpenModal={setOpenModal}
          setOpen={setOpenAddEntriesWizard}
          toggleOpenAddEntriesWizard={toggleOpenAddEntriesWizard}
        />
      </>
    );
  };

  return error ? (
    <div className={classes.alert}>
      <Alert title={error.msg} text={error.details} />
    </div>
  ) : (
    <>
      <DataCardHeader
        loading={loading}
        parameter={parameter}
        editMode={editMode}
        toggleEditMode={(...args) => checkForWarning(toggleEditMode, args)}
        updateStatus={updateStatus}
        setOpenModal={setOpenModal}
      />
      <Divider />
      <CardContent>
        {renderContent()}
        {openAddEntriesWizard && (
          <AddEntriesWizard
            open={openAddEntriesWizard}
            setOpen={setOpenAddEntriesWizard}
            setUpdateValues={setUpdateValues}
            sendUpdatedValues={sendUpdatedValues}
            defaultUnits={defaultUnits}
            parameterDataType={parameter?.parameterType?.dataType}
          />
        )}
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {modalBody}
        </Modal>
      </CardContent>
    </>
  );
}

export default DataDrawer;
