import { useState, useEffect } from 'react';
import { handleApiError, referenceDataApi } from '../../../../../api';

export default (setOpen, defaultUnits, parameterDataType) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [inputUnit, setInputUnit] = useState('');
  const [titleError, setTitleError] = useState(false);
  const [referenceError, setReferenceError] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const [valueError, setValueError] = useState(false);

  const [dialogValue, setDialogValue] = useState({
    source_type_id: '',
    title: '',
    reference: '',
    reference_id: '',
    reference_table: '',
    reference_url: '',
    value: '',
    units: '',
    locationInSource: '',
    comment: '',
    // todo: on post should separate out, to be kept as single date in state
    date_day: new Date().getDate().toString(),
    date_month: new Date().getMonth().toString(),
    date_year: new Date().getFullYear().toString(),
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleAccept = () => {
    handleClose();
  };

  useEffect(() => {
    let didCancel = false;

    const getSources = async () => {
      const { source_types: sourceTypes } = await referenceDataApi(
        'getSourceTypes',
        {
          visible_only: true,
        }
      ).catch(handleApiError);
      if (!didCancel) setOptions(sourceTypes);

      if (dialogValue.source_type_id.length > 0) {
        setInputValue(
          sourceTypes.find(
            sourceType => sourceType.id === dialogValue.source_type_id
          ).name
        );
      }
    };

    getSources();

    return () => {
      didCancel = true;
    };
  }, [dialogValue.source_type_id, dialogValue.source_type_id.length]);

  const unitArray = Array.isArray(defaultUnits) ? defaultUnits : [defaultUnits];

  const updateDialogValue = ({ target: { value } }, field) => {
    setDialogValue({ ...dialogValue, [field]: value });
  };

  const updatePublicationDate = date => {
    setDialogValue({
      ...dialogValue,
      date_day: String(new Date(date).getDate()),
      date_month: String(new Date(date).getMonth()),
      date_year: String(new Date(date).getFullYear()),
    });
  };

  const updateTitle = event => {
    updateDialogValue(event, 'title');
  };

  const validateTitle = () => {
    setTitleError(dialogValue.title && dialogValue.title.length > 255);
  };

  const updateReference = event => {
    updateDialogValue(event, 'reference');
  };

  const validateReference = () => {
    setReferenceError(
      dialogValue.reference && dialogValue.reference.length > 255
    );
  };

  const updateUrl = event => {
    updateDialogValue(event, 'url');
  };

  const validateUrl = () => {
    setUrlError(dialogValue.url && dialogValue.url.length > 1000);
  };

  const updateValue = event => {
    updateDialogValue(event, 'value');
  };

  const validateValue = () => {
    setValueError(
      !dialogValue.value ||
        (parameterDataType === 'number' &&
          !Number.isFinite(dialogValue.value)) ||
        (parameterDataType === 'integer' &&
          !Number.isInteger(Number(dialogValue.value))) ||
        (parameterDataType === 'float' &&
          Number.isInteger(Number(dialogValue.value)))
    );
  };

  const updateUnit = event => {
    updateDialogValue(event, 'unit');
  };

  const validateUnit = () => {
    setTitleError(dialogValue.unit && dialogValue.unit.length > 255);
  };

  const updateLocation = event => {
    updateDialogValue(event, 'locationInSource');
  };

  const validateLocation = () => {
    setReferenceError(
      dialogValue.locationInSource && dialogValue.locationInSource.length > 255
    );
  };

  const updateComment = event => {
    updateDialogValue(event, 'comment');
  };

  const validateComment = () => {
    setUrlError(dialogValue.comment && dialogValue.comment.length > 1000);
  };

  return {
    updateFunctions: {
      updatePublicationDate,
      updateTitle,
      updateReference,
      updateUrl,
      updateValue,
      updateUnit,
      updateLocation,
      updateComment,
    },
    errors: {
      titleError,
      referenceError,
      urlError,
      valueError,
    },
    validateFunctions: {
      validateTitle,
      validateReference,
      validateUrl,
      validateValue,
      validateUnit,
      validateLocation,
      validateComment,
    },
    handleAccept,
    handleClose,
    unitArray,
    inputUnit,
    setInputUnit,
    dialogValue,
    setDialogValue,
    inputValue,
    setInputValue,
    options,
  };
};
