import {
  shape,
  string,
  objectOf,
  bool,
  number,
  arrayOf,
  array,
  node,
  oneOfType,
  oneOf,
  any,
  object,
  func,
} from 'prop-types';

export const assetTypePropType = shape({
  name: string,
  id: string,
});

export const unitType = shape({
  id: oneOfType([string, number]),
  created_at: string,
  deleted_at: string,
  updated_at: string,
  name: string,
});

export const parameterType = shape({
  id: oneOfType([string, number]),
  parameterType: shape({
    id: oneOfType([string, number]),
    name: string.isRequired,
    dataType: string,
    created_at: string,
    updated_at: string,
    deleted_at: string,
  }),
  revision: shape({
    id: oneOfType([string, number]),
    status: oneOf([
      'unanswered',
      'answered',
      'checked',
      'approved',
      'rejected',
    ]),
    source_id: oneOfType([string, number]),
    valid: shape({ isValid: bool, state: string }),
    values: arrayOf(
      shape({
        id: oneOfType([string, number]),
        // eslint-disable-next-line react/forbid-prop-types
        value: any,
        unit: unitType,
      })
    ),
    created_at: string,
    created_by: shape({
      staff_id: number,
      staff_name: string,
      company_centre_arup_unit: string,
      email: string,
      grade_level: number,
      location_name: string,
      my_people_page_url: string,
    }),
  }),
  parents: arrayOf(
    shape({
      id: oneOfType([string, number]),
      project_id: string,
      children: arrayOf(oneOfType([string, number])),
      name: string.isRequired,
      assetType: shape({
        id: oneOfType([string, number]),
        parent_id: oneOfType([string, number]),
        name: string.isRequired,
        created_at: string,
        updated_at: string,
        deleted_at: string,
        asset_sub_type: bool,
      }),
    })
  ),
});

export const dictionaryOfStringType = objectOf(string);

export const dictionaryOfBooleanType = objectOf(bool);

export const projectType = shape({
  centre_code: string,
  confidential: bool,
  job_name_short: string,
  job_number: string,
  number,
  organisation_name: string,
  project_director_email: string,
  project_director_name: string,
  project_id: string,
  project_manager_email: string,
  project_manager_name: string,
  project_url: string,
  scope_of_works: string,
  created_at: string,
  updated_at: string,
  deleted_at: string,
});

export const parameterListFiltersType = shape({
  categories: arrayOf(),
  disciplines: arrayOf(),
  parameter: arrayOf(parameterType),
  qa_status: arrayOf(),
  source_type_id: arrayOf(),
  sources: arrayOf(),
  units: arrayOf(),
});

export const filterOptionType = shape({
  name: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: array, // This can be many things, too complicated to put here simply
});

export const checkBoxType = shape({
  name: string.isRequired,
  isSelected: bool, // This can be many things, too complicated to put here simply
});

export const menuType = shape({
  label: string.isRequired,
  value: string,
  icon: node,
  onClick: func,
});

export const sortType = shape({
  sortBy: string.isRequired,
  order: string.isRequired,
});

// Needs further investigation
export const filterType = object;

export const assetTypeType = shape({
  id: string.isRequired,
  parent: string,
});

export const parameterTypeType = shape({
  id: string.isRequired,
});

export const sourceType = shape({
  id: string,
  source: string,
  valueLocation: string,
  directoryURL: string,
  published: string,
  comment: string,
});

export const tagTypeType = shape({
  id: string.isRequired,
  name: string.isRequired,
});

export const children = oneOfType([arrayOf(node), node]).isRequired;

export const tagType = shape({
  id: string.isRequired,
  name: string.isRequired,
  global_tag: bool,
  loadingTag: bool,
  // eslint-disable-next-line react/forbid-prop-types
  icon: any,
});

export const entryType = shape({
  id: oneOfType([string, number]).isRequired,
  relevance: arrayOf(string).isRequired,
  source: sourceType,
  entryValue: string,
  type: string,
  status: string,
  sourceOpen: bool,
  popoverOpen: bool,
  relevanceOpen: bool,
  editEntryOpen: bool,
});

export const userType = shape({ avatar: string, role: arrayOf(string) });
