import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import useStyles from './parameter-wizard-summary-styles';
import StatusCircle from '../../../../../../components/status-circle';
import { parameterType } from '../../../../../../types';

function ParameterWizardSummary(props) {
  const { parameters, disabled } = props;

  const classes = useStyles();
  const { t } = useTranslation(['parameters']);

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Typography variant="h5" component="h2" gutterBottom>
          {t('parameters:addParamConfirmation')}
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          {t('parameters:addParamToProjectConfirmation')}
        </Typography>
        {disabled ? (
          <div className={classes.circular}>
            <CircularProgress size={40} />
          </div>
        ) : (
          <div className={clsx(classes.verticalScroll, classes.list)}>
            <Table
              width="100%"
              className={classes.table}
              aria-label="Parameter summary table"
            >
              <TableBody>
                {parameters.map(parameter => (
                  <TableRow key={parameter.id} className={classes.tr}>
                    <TableCell className={classes.td}>
                      <StatusCircle
                        status={parameter.revision.status || 'unanswered'}
                        size={40}
                      />
                    </TableCell>
                    <TableCell className={classes.td}>
                      <Typography variant="h5" component="h6">
                        {parameter.parameter_type.name}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.td}>
                      <Typography variant="h5" component="h6">
                        {parameter.revision.values[0].value === 'Enter value'
                          ? ''
                          : parameter.revision.values[0].value}
                        {parameter.revision.values[0].unit.name}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.td}>
                      <Typography variant="h5" component="h6">
                        <ul className={classes.assetList}>
                          {parameter.additionalAssets
                            .filter(additionalAsset => additionalAsset.checked)
                            .map(additionalAsset => (
                              <li
                                key={additionalAsset.id}
                                className={classes.asset}
                              >
                                <Breadcrumbs
                                  maxItems={2}
                                  aria-label="breadcrumb"
                                >
                                  {additionalAsset.hierarchy.map(item => (
                                    <Typography
                                      className={classes.breadcrumbHeader}
                                      key={item.id}
                                    >
                                      {item.name}
                                    </Typography>
                                  ))}
                                  <Typography
                                    className={classes.breadcrumbHeader}
                                  >
                                    {additionalAsset.name}
                                  </Typography>
                                </Breadcrumbs>
                              </li>
                            ))}
                        </ul>
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </CardContent>
    </Card>
  );
}

ParameterWizardSummary.propTypes = {
  parameters: PT.arrayOf(parameterType).isRequired,
  disabled: PT.bool.isRequired,
};

export default ParameterWizardSummary;
