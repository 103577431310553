import PT from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import AddParameterList from './add-parameter-list';
import AddParameterEdit from './add-parameter-edit';
import Warning from '../../../../../components/dialogs/warning';
import {
  assetTypePropType,
  parameterType,
  dictionaryOfStringType,
  dictionaryOfBooleanType,
} from '../../../../../types';
import useStyles from './add-parameter-step-styles';
import useHooks from './hooks';

function AddParameterStep({
  parameters,
  setParameters,
  selectedAsset,
  assetHierarchy,
  additionalAssets,
  requiredDataType,
  setRequiredDataType,
  inputDataType,
  setInputDataType,
  errorValue,
  setErrorValue,
  additionalAssetsLoaded,
  handleAddingMoreParentAssets,
}) {
  const classes = useStyles();
  const { t } = useTranslation(['common', 'parameters']);

  const {
    unitTypes,
    selectedParameter,
    parameterTypes,
    sources,
    unit,
    switchParameter,
    handleDelete,
    updateDisciplineTags,
    updateTags,
    updateParameterType,
    updateValue,
    updateValues,
    setUpdateValues,
    updateUnit,
    typesLoading,
    sourcesLoading,
    unitsLoading,
    requiredError,
    setRequiredError,
    displayWarning,
    warningClose,
    warningAccept,
    updateParentAssets,
    setSelectedSource,
    selectedSource,
  } = useHooks(
    parameters,
    setParameters,
    selectedAsset,
    additionalAssets,
    setErrorValue
  );

  return (
    <Grid container className={classes.root}>
      <Grid item xs={4}>
        <AddParameterList
          selectedParameter={selectedParameter}
          parameters={parameters}
          switchParameter={switchParameter}
        />
      </Grid>
      <Grid item xs={8} className={classes.editor}>
        <AddParameterEdit
          unitTypes={unitTypes}
          selectedParameter={selectedParameter}
          additionalAssets={additionalAssets}
          deleteParameter={handleDelete}
          parameterTypes={parameterTypes}
          sources={sources}
          unit={unit}
          updateDisciplineTags={updateDisciplineTags}
          updateTags={updateTags}
          updateParameterType={updateParameterType}
          setUpdateValues={setUpdateValues}
          updateValues={updateValues}
          updateValue={updateValue}
          updateUnit={updateUnit}
          typesLoading={typesLoading}
          sourcesLoading={sourcesLoading}
          unitsLoading={unitsLoading}
          requiredError={requiredError}
          setRequiredError={setRequiredError}
          updateParentAssets={updateParentAssets}
          requiredDataType={requiredDataType}
          setRequiredDataType={setRequiredDataType}
          inputDataType={inputDataType}
          setInputDataType={setInputDataType}
          errorValue={errorValue}
          setErrorValue={setErrorValue}
          handleAddingMoreParentAssets={handleAddingMoreParentAssets}
          additionalAssetsLoaded={additionalAssetsLoaded}
          assetHierarchy={assetHierarchy}
          selectedAsset={selectedAsset}
          selectedSource={selectedSource}
          setSelectedSource={setSelectedSource}
        />
      </Grid>
      <Warning
        open={displayWarning}
        handleAccept={warningAccept}
        handleClose={warningClose}
        title={t('parameters:removeParamConfirm')}
        body={t('parameters:removeParamInfo')}
        secondaryButtonText={t('buttons.cancel')}
        primaryButtonText={t('buttons.delete')}
      />
    </Grid>
  );
}

AddParameterStep.propTypes = {
  parameters: PT.arrayOf(parameterType).isRequired,
  setParameters: PT.func.isRequired,
  selectedAsset: assetTypePropType,
  additionalAssets: PT.arrayOf(assetTypePropType),
  requiredDataType: dictionaryOfStringType.isRequired,
  setRequiredDataType: PT.func.isRequired,
  inputDataType: dictionaryOfStringType.isRequired,
  setInputDataType: PT.func.isRequired,
  errorValue: dictionaryOfBooleanType.isRequired,
  setErrorValue: PT.func.isRequired,
  additionalAssetsLoaded: PT.bool.isRequired,
  handleAddingMoreParentAssets: PT.func.isRequired,
  assetHierarchy: PT.arrayOf(assetTypePropType).isRequired,
};

AddParameterStep.defaultProps = {
  selectedAsset: {},
  additionalAssets: [],
};

export default AddParameterStep;
