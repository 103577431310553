import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  box: {
    width: '100%',
  },
  disabled: { color: theme.palette.text.disabled },
  toggleMainColor: ({ open }) => ({
    color: open ? theme.palette.primary.main : theme.palette.text.primary,
  }),
  toggleSecondaryColor: ({ open }) => ({
    color: open ? theme.palette.primary.main : theme.palette.text.secondary,
  }),
  noPadding: {
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    color: theme.palette.text.secondary,
  },
  status: {
    width: '5%',
  },
  topBorder: {
    borderBottom: 'none',
    paddingLeft: 0,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

export default useStyles;
