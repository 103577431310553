import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  fullHeight: { height: '100%' },
  avatar: {
    height: 80,
    width: 80,
    padding: 0,
    margin: '6.5px 0',
    position: 'relative',
  },
  title: {
    paddingLeft: theme.spacing(3),
  },
  grow: {
    flexGrow: 1,
  },
  widerMargin: {
    margin: '8px',
  },
  content: {
    padding: theme.spacing(1),
  },
  action: {
    marginTop: '12px',
  },
  checkboxHolder: {
    border: '1px solid lightgray',
    borderRadius: 5,
    padding: '5px 10px',
    maxHeight: 250,
    overflow: 'auto',
    margin: '5px 10px',
  },
  menuItem: {
    width: '100%',
  },
  dotsIcon: {
    width: '75px',
  },
}));
