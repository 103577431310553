import startCase from './start-case';

export default str => {
  return str
    ? str
        .split(' - ')
        .map(word => {
          return startCase(word, false); // Use false as 2nd parameter to avoid removing ampersands and other symbols
        })
        .join(' - ')
    : str;
};
