import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  icon: {
    padding: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(1),
  },
}));
