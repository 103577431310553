/* eslint-disable no-console */
import { createContext, useMemo } from 'react';
import PT from 'prop-types';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import { isUndefined } from 'lodash';
import baseTheme from '../theme';
import { localeThemeMap, LANGUAGES } from '../i18n/languages';

export const ThemeContext = createContext();

export const Theme = ({ children, i18n }) => {
  const [cookies, setCookie] = useCookies(['paletteType']);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  if (isUndefined(cookies?.paletteType))
    setCookie('paletteType', prefersDarkMode ? 'dark' : 'light', {
      path: '/',
      maxAge: 31536000,
    });

  const theme = useMemo(
    () =>
      baseTheme(
        cookies.paletteType === 'dark' ? 'dark' : 'light',
        localeThemeMap[i18n.language]
      ),
    [cookies.paletteType, i18n.language]
  );

  const handleThemeChange = () =>
    setCookie(
      'paletteType',
      cookies.paletteType === 'dark' ? 'light' : 'dark',
      {
        path: '/',
        maxAge: 31536000,
      }
    );

  return (
    <ThemeContext.Provider
      value={{
        handleThemeChange,
        paletteType: cookies.paletteType !== 'dark',
      }}
    >
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export const ThemeProvider = withTranslation()(Theme);

Theme.propTypes = {
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]).isRequired,
  i18n: PT.shape({ language: PT.string }),
};

Theme.defaultProps = {
  i18n: { language: LANGUAGES[0] },
};
