// todo: update and remove in refactor
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import PT from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import QuestionAnswerSharpIcon from '@material-ui/icons/QuestionAnswerSharp';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import useEditForm from './hooks';
import DataDrawerGrid from '../grid';
import useStyles from './data-drawer-edit-form-styles';
import SelectSource from '../../source/select-source';
import SelectUnit from '../../units/select-units';
import { parameterType, unitType } from '../../../types';
import { DatePicker } from '../../pickers';

function EditForm({
  defaultUnits,
  updateValues,
  setUpdateValues,
  sendUpdatedValues,
  toggleEditMode,
  parameter,
  unitTypes,
  selectedSource,
  setSelectedSource,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    valueError,
    setValueError,
    onDateChange,
    onBooleanChange,
    onInputValueChange,
    onUnitSelection,
    errorMessage,
  } = useEditForm(updateValues, setUpdateValues, parameter);

  const renderForm = () => {
    if (parameter.parameterType.dataType === 'date')
      return (
        <DatePicker
          className={clsx([classes.valueInput])}
          disableToolbar
          label="DD/MM/YYYY"
          error={valueError}
          value={updateValues.values[0]?.value || null}
          helperText={
            valueError
              ? t('dataDrawer.editForm.fullDateError')
              : t('requiredError')
          }
          onChange={target => onDateChange(target)}
        />
      );
    if (parameter.parameterType.dataType === 'boolean')
      return (
        <FormControl variant="outlined" className={clsx([classes.valueInput])}>
          <InputLabel id="boolean">{t('dataDrawer.editForm.value')}</InputLabel>
          <Select
            labelId="boolean"
            label="value"
            value={updateValues.values[0].value || ''}
            autoWidth
            onChange={({ target }) => onBooleanChange(target)}
          >
            <MenuItem value="true">{t('true')}</MenuItem>
            <MenuItem value="false">{t('false')}</MenuItem>
          </Select>
        </FormControl>
      );
    return (
      <TextField
        autoFocus
        className={clsx([classes.valueInput])}
        required
        variant="outlined"
        label={t('dataDrawer.editForm.value')}
        helperText={valueError ? errorMessage : t('requiredError')}
        value={updateValues.values?.[0]?.value || ''}
        error={valueError}
        onBlur={({ target }) => !target.value && setValueError(true)}
        onChange={({ target }) => onInputValueChange(target)}
      />
    );
  };

  return (
    <Grid
      item
      className={clsx([classes.grow, classes.widerMargin, classes.content])}
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
    >
      <DataDrawerGrid
        icon={<QuestionAnswerSharpIcon />}
        caption={
          <Typography variant="caption" color="textSecondary">
            {t('dataDrawer.editForm.value')}
          </Typography>
        }
        content={
          <Grid item container direction="row" justify="space-between">
            {renderForm()}
            <SelectUnit
              onUnitSelection={onUnitSelection}
              defaultOrSelectedUnit={
                parameter.revision?.values[0]?.unit || defaultUnits
              }
              unitTypes={unitTypes}
            />
          </Grid>
        }
      />
      <DataDrawerGrid
        icon={<LibraryBooksIcon />}
        caption={
          <Typography variant="caption" color="textSecondary">
            {t('source')}
          </Typography>
        }
        content={
          <Grid item container direction="column">
            <SelectSource
              setUpdateValues={setUpdateValues}
              updateValues={updateValues}
              setSelectedSource={setSelectedSource}
              selectedSource={selectedSource}
            />
          </Grid>
        }
      />
      <Grid item container direction="row" spacing={2}>
        <Grid item>
          <Button onClick={toggleEditMode}>{t('buttons.cancel')}</Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            disabled={
              valueError ||
              !updateValues.source_id ||
              !updateValues.values?.[0]?.value
            }
            onClick={sendUpdatedValues}
          >
            {t('buttons.save')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

EditForm.propTypes = {
  updateValues: parameterType.isRequired,
  defaultUnits: unitType.isRequired,
  setUpdateValues: PT.func.isRequired,
  toggleEditMode: PT.func.isRequired,
  sendUpdatedValues: PT.func.isRequired,
  parameter: parameterType.isRequired,
  unitTypes: unitType.isRequired,
  selectedSource: PT.object.isRequired,
  setSelectedSource: PT.func.isRequired,
};

export default EditForm;
