const red = {
  50: '#FFE0E0',
  100: '#FFD0D0',
  200: '#FFB6B6',
  300: '#FF9494',
  400: '#FF6A6A',
  500: '#EF4F4F',
  600: '#F40808',
  700: '#CC0A0A',
  800: '#A60606',
  900: '#850101',
  // 100: '#530000'
};
export default red;
