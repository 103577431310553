/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import axios from 'axios';
import qs from 'qs';
import {
  qaApi,
  referenceDataApi,
  parameterMetadataApi,
  parametersApi,
  environmentContextApi,
  handleApiError,
} from '../../../../../api';
import {
  formatValue,
  formatCurrentFilters,
  updateFiltersFromSearch,
} from '../helper';

export default (handleFilterActive, searchParams, setSearchParams) => {
  const parsedSearch = qs.parse(searchParams.toString());

  const [loading, setLoading] = useState(true);
  const [availableOptions, setAvailableOptions] = useState();

  const [parameterTypesLoading, setParameterTypesLoading] = useState(true);
  const [parameterTypesOptions, setParameterTypesOptions] = useState();

  const [filters, setFilters] = useState({
    sort_by: { sortBy: parsedSearch.sort_by },
    qa_status: formatValue(parsedSearch.qa_status, 'name'),
    parameter_type_id: formatValue(parsedSearch.parameter_type_id, 'id'),
    source_type_id: formatValue(parsedSearch.source_type_id, 'id'),
    category_id: formatValue(parsedSearch.category_id, 'id'),
    unit_id: formatValue(parsedSearch.unit_id, 'id'),
    discipline_id: formatValue(parsedSearch.discipline_id, 'id'),
    report_id: formatValue(parsedSearch.report_id, 'id'),
  });

  const handleOnChange = (value, key) =>
    setFilters({ ...filters, [key]: value });

  const sortByOptions = [
    { label: 'name', sortBy: 'parameter_type_name' },
    { label: 'qaStatus', sortBy: 'qa_status' },
    { label: 'parameterList.createdAt', sortBy: 'created_at' },
    { label: 'parameterList.revisedAt', sortBy: 'revised_at' },
  ];

  const filtersMap = [
    {
      loading,
      id: 'qaStatus',
      key: 'qa_status',
      options: availableOptions?.qaTypes || [],
      getOptionSelected: (opt, val) => opt.name === val.name,
    },
    {
      loading: parameterTypesLoading,
      id: 'parameterTypes',
      key: 'parameter_type_id',
      options: parameterTypesOptions?.parameterTypes || [],
      getOptionSelected: (opt, val) => opt.id === val.id,
    },
    {
      loading,
      id: 'disciplines',
      key: 'discipline_id',
      options: availableOptions?.disciplines || [],
      getOptionSelected: (opt, val) => opt.id === val.id,
    },
    {
      loading,
      id: 'categories',
      key: 'category_id',
      options: availableOptions?.categories || [],
      getOptionSelected: (opt, val) => opt.id === val.id,
    },
    {
      loading,
      id: 'reports',
      key: 'report_id',
      options: availableOptions?.reports || [],
      getOptionSelected: (opt, val) => opt.id === val.id,
    },
    {
      loading,
      id: 'sourceType',
      key: 'source_type_id',
      options: availableOptions?.sourceTypes || [],
      getOptionSelected: (opt, val) => opt.id === val.id,
    },
    {
      loading,
      id: 'units',
      key: 'unit_id',
      options: availableOptions?.units || [],
      getOptionSelected: (opt, val) => opt.id === val.id,
    },
  ];

  useEffect(() => {
    const source = axios.CancelToken.source();
    let didCancel = false;

    const getParameterTypes = async () => {
      try {
        const response = await parametersApi(
          'getAllParameterTypes',
          {},
          source.token
        ).catch(handleApiError);

        if (!didCancel && response) {
          const { parameter_types: parameterTypes } = response;
          setParameterTypesOptions({ parameterTypes });

          if (parsedSearch?.parameter_type_id) {
            const parameterTypeIds = formatValue(
              parsedSearch.parameter_type_id,
              'id'
            );
            setFilters(prevFilters => ({
              ...prevFilters,
              parameter_type_id: parameterTypes.filter(item =>
                // eslint-disable-next-line max-nested-callbacks
                parameterTypeIds.some(type => item.id === type.id)
              ),
            }));
          }
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
      setParameterTypesLoading(false);
    };
    getParameterTypes();

    return () => {
      source.cancel();
      didCancel = true;
    };
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let didCancel = false;

    const getOptions = async () => {
      try {
        const response = await Promise.all([
          parameterMetadataApi(
            'getTags',
            { tag_type_id: '17bd1c6a-a14c-43ef-a981-192b539af5da' },
            source.token
          ).catch(handleApiError),
          qaApi(
            'getTypes',
            { flow_id: '564e698d-3e37-4fcc-9d8b-d69978b17652' },
            source.token
          ).catch(handleApiError),
          referenceDataApi('getSourceTypes', {}, source.token).catch(
            handleApiError
          ),
          parameterMetadataApi(
            'getTags',
            { tag_type_id: 'f18d3070-dae5-4386-996e-9c70d796e558' },
            source.token
          ).catch(handleApiError),
          parametersApi('getAllUnits', {}, source.token).catch(handleApiError),
          environmentContextApi('getDisciplines', {}, source.token).catch(
            handleApiError
          ),
        ]);

        if (!didCancel && response) {
          const [
            { tags: reports },
            { types: qaTypes },
            { source_types: sourceTypes },
            { tags: categories },
            { units },
            { disciplines: disciplinesResponse },
          ] = response;
          const disciplines = disciplinesResponse.map(
            ({ discipline_id: id, discipline_name: name }) => ({
              id,
              name,
            })
          );
          setAvailableOptions(prevOptions => ({
            ...prevOptions,
            reports,
            qaTypes,
            sourceTypes,
            categories,
            units,
            disciplines,
          }));

          const {
            parameter_type_id: parameterTypeId,
            qa_status: qaStatus,
            sort_by: sortBy,
            order,
            ...rest
          } = parsedSearch;

          const updatedFilters = updateFiltersFromSearch(rest, {
            report_id: reports,
            source_type_id: sourceTypes,
            category_id: categories,
            unit_id: units,
            discipline_id: disciplines,
          });

          setFilters(prevFilters => ({
            ...prevFilters,
            ...updatedFilters,
          }));
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
      setLoading(false);
    };

    getOptions();

    return () => {
      source.cancel();
      didCancel = true;
    };
  }, []);

  const applyFilters = () => {
    const availableFilters = formatCurrentFilters(filters);
    setSearchParams({ ...parsedSearch, ...availableFilters });
    handleFilterActive();
  };

  return { filters, sortByOptions, applyFilters, filtersMap, handleOnChange };
};
