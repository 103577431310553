const green = {
  50: '#E2FFD9',
  100: '#D0FFC5',
  200: '#BAFFB9',
  300: '#87FB80',
  400: '#27EB56',
  500: '#2FC359',
  600: '#28AF73',
  700: '#24994B',
  800: '#11822D',
  900: '#056937',
  // 100: '#005023'
};

export default green;
