import PT from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import AssetWizardSummaryList from '../asset-wizard-summary-list';
import useStyles from './asset-wizard-summary-styles';
import { assetTypeType } from '../../../../types';

function AssetWizardSummary(props) {
  const { theAssets, theAssetTypes, disabled } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card variant="outlined" className={classes.root}>
      <CardContent className={classes.content}>
        <Typography variant="h5" component="h2" gutterBottom>
          {t('addAssets')}
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          {t('confirmAssets')}
        </Typography>
        {disabled ? (
          <div className={classes.circular}>
            <CircularProgress size={40} />
          </div>
        ) : (
          <AssetWizardSummaryList
            theAssets={theAssets}
            theAssetTypes={theAssetTypes}
          />
        )}
      </CardContent>
    </Card>
  );
}

AssetWizardSummary.propTypes = {
  theAssets: PT.arrayOf(
    PT.shape({ name: PT.string.isRequired, asset_id: PT.string })
  ).isRequired,
  theAssetTypes: PT.arrayOf(assetTypeType).isRequired,
  disabled: PT.bool.isRequired,
};

export default AssetWizardSummary;
