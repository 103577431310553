import { isValid, formatDistance, differenceInDays } from 'date-fns';
import formatDate from './format-date';
import { localeDateMap, LANGUAGES } from '../i18n/languages';

export default (rawDate, limit = 6, locale = LANGUAGES[0]) => {
  const from = new Date();
  const date = new Date(rawDate);
  if (isValid(date)) {
    if (differenceInDays(from, date) > limit)
      return formatDate(rawDate, undefined, locale);
    return formatDistance(date, from, {
      locale: localeDateMap[locale],
    });
  }
  return '-';
};
