/* eslint-disable no-confusing-arrow */
import { makeStyles, darken } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import GavelIcon from '@material-ui/icons/Gavel';
import PublicIcon from '@material-ui/icons/Public';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CategoryIcon from '@material-ui/icons/Category';
import RecycleVariant from 'mdi-material-ui/RecycleVariant';
import Telescope from 'mdi-material-ui/Telescope';
import Crane from 'mdi-material-ui/Crane';

export const tagTypeLookup = {
  scope: {
    backgroundColor: 'rgba(254, 255, 222, 1)',
    color: 'rgba(106, 115, 1, 1)',
    icon: <Telescope />,
  },
  workstage: {
    backgroundColor: 'rgba(215, 215, 215, 1)',
    color: 'rgba(0, 0, 0, 1)',
    icon: <Crane />,
  },
  process: {
    backgroundColor: 'rgba(255, 228, 204, 1)',
    color: 'rgba(168, 72, 2, 1)',
    icon: <RecycleVariant />,
  },
  client: {
    backgroundColor: 'rgba(205, 219, 255, 1)',
    color: 'rgba(10, 40, 120, 1)',
    icon: <PersonIcon />,
  },
  code: {
    backgroundColor: 'rgba(236, 213, 254, 1)',
    color: 'rgba(98, 12, 166, 1)',
    icon: <GavelIcon />,
  },
  sector: {
    backgroundColor: 'rgba(255, 225, 225, 1)',
    color: 'rgba(200, 14, 14, 1)',
    icon: <AccountBalanceIcon />,
  },
  skill: {
    backgroundColor: 'rgba(255, 229, 249, 1)',
    color: 'rgba(181, 5, 142, 1)',
    icon: <CategoryIcon />,
  },
  location: {
    backgroundColor: 'rgba(235, 255, 234, 1)',
    color: 'rgba(64, 109, 60, 1)',
    icon: <PublicIcon />,
  },
};

export default makeStyles(theme => {
  const color = props => {
    let tagColor;
    if (tagTypeLookup[props.tagType]?.color)
      tagColor = tagTypeLookup[props.tagType]?.color;
    else if (theme.palette.type === 'dark')
      tagColor = theme.palette.text.primary;
    else tagColor = theme.palette.primary.main;
    return tagColor;
  };

  const backgroundColor = props => {
    let tagBackgroundColor;
    if (props.clickable)
      tagBackgroundColor = tagTypeLookup[props.tagType]?.backgroundColor;
    else if (theme.palette.type === 'dark')
      tagBackgroundColor = darken(theme.palette.text.primary, 0.25);
    else tagBackgroundColor = theme.palette.background.default;
    return tagBackgroundColor;
  };

  return {
    baseChip: {
      border: `1px solid`,
      margin: theme.spacing(0.5),
      paddingLeft: theme.spacing(0.5),
      backgroundColor,
      color,
      width: props => {
        return props.holder && '130px';
      },
      justifyContent: 'left',
    },
    icon: {
      color,
    },
    deleteIcon: {
      color: theme.palette.common.black,
    },
    label: { paddingRight: props => !props.label && theme.spacing(0.25) },
  };
});
