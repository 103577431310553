import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default (updateValues, setUpdateValues, parameter) => {
  const { t } = useTranslation();
  const [valueError, setValueError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setErrorMessage('');
  }, [parameter]);

  const onUnitSelection = e => {
    const unitDetails = JSON.parse(e.target.value);
    const newUnit = { ...updateValues };
    newUnit.values[0].id = unitDetails.id || null;

    setUpdateValues(newUnit);
  };
  const onDateChange = target => {
    setValueError(!Date.parse(target));
    const newValues = { ...updateValues };
    newValues.values[0].value = target || null;
    setUpdateValues(newValues);
  };

  const onBooleanChange = target => {
    const newValues = { ...updateValues };
    newValues.values[0].value = target.value || null;
    setUpdateValues(newValues);
  };

  const onInputValueChange = ({ value }) => {
    setValueError(false);
    setErrorMessage(null);
    const { dataType } = parameter.parameterType;

    if (
      (dataType === 'integer' || dataType === 'number') &&
      !Number.isFinite(+value)
    ) {
      setValueError(true);
      setErrorMessage(
        dataType === 'integer'
          ? t('dataDrawer.editForm.wholeNumberValueError')
          : t('dataDrawer.editForm.decimalValueError')
      );
    }

    if (dataType === 'string' && typeof value !== 'string') {
      setValueError(true);
      setErrorMessage(t('dataDrawer.editForm.textValueError'));
    }

    const newValues = { ...updateValues };
    newValues.values[0].value = value || null;
    setUpdateValues(newValues);
  };

  return {
    valueError,
    setValueError,
    onDateChange,
    onBooleanChange,
    onInputValueChange,
    errorMessage,
    onUnitSelection,
  };
};
