import PT from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Warning from '../../../../../components/dialogs/warning';
import useHooks from './hooks';
import useStyles from './parameter-wizard-styles';

function ParameterWizard({ open, handleToggleOpen }) {
  const classes = useStyles();
  const { t } = useTranslation(['common', 'parameters']);
  const {
    steps,
    stepContent,
    activeStep,
    warningOpen,
    toggleWarning,
    handleClose,
    handleNext,
    handleBack,
    showNext,
    handleFinish,
  } = useHooks(open, handleToggleOpen);

  return (
    <Dialog
      classes={{ container: classes.container, paper: classes.paper }}
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={toggleWarning}
      data-testid="add-parameter-wizard"
    >
      <DialogTitle>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map(({ label, optional, completed }) => {
            return (
              <Step key={label} completed={completed}>
                <StepLabel
                  className={classes.stepLabel}
                  optional={
                    optional ? (
                      <Typography
                        variant="caption"
                        className={classes.optionLabel}
                      >
                        {t('common:optional')}
                      </Typography>
                    ) : null
                  }
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </DialogTitle>
      <Divider />
      {stepContent[activeStep]}
      <DialogActions className={classes.actions}>
        <Button
          onClick={toggleWarning}
          className={clsx([classes.secondary, classes.leftAlign])}
        >
          {t('common:buttons.cancel')}
        </Button>

        {activeStep === steps.length - 1 ? (
          <div>
            <Button
              disabled={!showNext}
              className={classes.secondary}
              onClick={handleBack}
            >
              {t('common:buttons.back')}
            </Button>
            <Button
              disabled={!showNext}
              className={classes.primary}
              onClick={handleFinish}
            >
              {t('common:buttons.finish')}
            </Button>
          </div>
        ) : (
          <div>
            <Button
              disabled={!showNext}
              className={classes.primary}
              onClick={handleNext}
            >
              {t('common:buttons.next')}
            </Button>
          </div>
        )}
      </DialogActions>
      <Warning
        open={warningOpen}
        handleAccept={handleClose}
        handleClose={toggleWarning}
        title={t('parameters:closeWizardConfirm')}
        body={t('parameters:exitWizardConfirm')}
        secondaryButtonText={t('common:buttons.cancel')}
        primaryButtonText={t('common:buttons.close')}
      />
    </Dialog>
  );
}

ParameterWizard.propTypes = {
  open: PT.bool.isRequired,
  handleToggleOpen: PT.func.isRequired,
};

export default ParameterWizard;
