/* eslint-disable no-nested-ternary */
import { Suspense, useContext, useState } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import ListIcon from '@material-ui/icons/List';
import EmptyState from '../../../components/empty-state';
import useStyles from './parameter-list-styles';
import Header from './header';
import ParameterListFilters from './filters';
import InfiniteList from '../../../components/infinite-list';
import Alert from '../../../components/alerts';
import { ParameterCard } from '../../../components/cards';
import { ParametersContext } from '../../../context/parameters-context';
import formatParameterInfo from './helper';

function ParameterList() {
  const {
    parameters,
    loading,
    hasMoreParameters,
    getParameters,
    pageLimit,
    error,
    searchParams,
    setSearchParams,
  } = useContext(ParametersContext);

  const classes = useStyles();
  const { i18n } = useTranslation([], { useSuspense: false });
  const [isFilterActive, setIsFilterActive] = useState(false);

  const handleFilterActive = () => {
    setIsFilterActive(!isFilterActive);
  };

  return (
    <div className={classes.root}>
      <Suspense fallback={null}>
        <Header
          handleFilterActive={handleFilterActive}
          isFilterActive={isFilterActive}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      </Suspense>
      {isFilterActive ? (
        <Suspense fallback={null}>
          <ParameterListFilters
            handleFilterActive={handleFilterActive}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        </Suspense>
      ) : !loading && isEmpty(parameters) ? (
        <Suspense fallback={null}>
          {error ? (
            <div className={classes.alert}>
              <Alert title={error.msg} text={error.details} />
            </div>
          ) : (
            <EmptyState
              Icon={ListIcon}
              title="parameters:noParams"
              subtitle="parameters:subtitle"
            />
          )}
        </Suspense>
      ) : (
        <InfiniteList
          loadMoreRows={getParameters}
          hasNextPage={hasMoreParameters}
          rowHeight={70}
          list={parameters}
          loading={loading}
          loaderNumber={pageLimit}
          loader={<ParameterCard loading />}
        >
          {parameters.map(parameter => {
            const updatedParameter = formatParameterInfo(parameter, i18n);
            return (
              <ParameterCard
                key={parameter.id}
                loading={loading}
                parameter={updatedParameter}
                handleClick={() =>
                  setSearchParams(
                    {
                      ...qs.parse(searchParams.toString()),
                      parameter_id: parameter.id,
                    },
                    { state: { parameter } }
                  )
                }
                selected={searchParams.get('parameter_id') === parameter.id}
              />
            );
          })}
        </InfiniteList>
      )}
    </div>
  );
}

export default ParameterList;
