// todo: update and remove in refactor
/* eslint-disable react/forbid-prop-types */
import PT from 'prop-types';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import AddIcon from '@material-ui/icons/Add';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import InputAdornment from '@material-ui/core/InputAdornment';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Header from '../header';
import useStyles from './column-styles';
import useHooks from './hooks';
import { assetTypeType, assetTypePropType } from '../../../../../types';
import { StyledIconButton } from '../../../../../components/buttons';

function Column(props) {
  const { t } = useTranslation(['parameters']);
  const { column, theAssetTypes, openAssetTypes, toggleAssetType, addAsset } =
    props;
  const classes = useStyles();
  const {
    reOrderedAssetTypes,
    assetParentId,
    toggleTheInputBox,
    updateInputValue,
    selectedAsset,
    setSelectedAsset,
    newAssetError,
    setNewAssetError,
  } = useHooks(column, theAssetTypes, openAssetTypes);

  return (
    <div className={classes.column}>
      <Divider />
      <Header label={column.title} />
      <Divider />
      <List className={classes.list}>
        {reOrderedAssetTypes.map((assetType, i) => {
          const currentAssetNames = assetType.assets.map(asset =>
            asset.name.toLowerCase()
          );
          return (
            <div key={assetType.id}>
              <ListItem
                key={assetType.id}
                button
                disabled={assetType.assets.length === 0}
                onClick={() => toggleAssetType(assetParentId, assetType.id)}
              >
                <ListItemIcon className={classes.iconWidth}>
                  {assetType.open ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                </ListItemIcon>
                <ListItemText
                  className={clsx([classes.capitalise, classes.bold])}
                  disableTypography
                >
                  {`${assetType.name} (${assetType.assets.length})`}
                </ListItemText>
                <ListItemSecondaryAction>
                  {/* <StyledIconButton
                    onClick={() => {
                      toggleTheInputBox(i);
                    }}
                    className={classes.addIcon}
                    aria-label="Add new asset button"
                    icon={<AddIcon />}
                  /> */}
                </ListItemSecondaryAction>
              </ListItem>

              <Collapse in={assetType?.showAddAssetInput}>
                <List component="div" disablePadding>
                  <ListItem
                    key={t('parameters:addNewAssetType', {
                      assetType: assetType.id,
                    })}
                    className={classes.editableRoot}
                  >
                    {assetType.asset_sub_types?.length > 0 ? (
                      <TextField
                        id="asset-sub-type"
                        select
                        className={classes.subAssetType}
                        label="Asset Sub Types"
                        value={selectedAsset.name || ''}
                        onChange={e => {
                          setSelectedAsset(e.target.value);
                          updateInputValue(i, e.target.value.name);
                          addAsset(
                            e.target.value.name,
                            assetType,
                            column.parent
                          );
                        }}
                      >
                        {assetType.asset_sub_types.map(subType => (
                          <MenuItem key={subType.id} value={subType}>
                            {subType.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      <TextField
                        autoFocus
                        label={t('parameters:addAsset')}
                        dense="true"
                        error={newAssetError}
                        helperText={
                          newAssetError && t('parameters:existingAsset')
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <StyledIconButton
                                disabled={
                                  !assetType.addAssetInputText || newAssetError
                                }
                                size="small"
                                onClick={() => {
                                  addAsset(
                                    assetType.addAssetInputText,
                                    assetType,
                                    column.parent
                                  );
                                }}
                                aria-label="Add new asset"
                                icon={<AddCircleIcon fontSize="small" />}
                              />
                            </InputAdornment>
                          ),
                        }}
                        value={assetType.addAssetInputText || ''}
                        onChange={e => {
                          updateInputValue(i, e.target.value);
                          setNewAssetError(
                            currentAssetNames.includes(
                              e.target.value.toLowerCase()
                            )
                          );
                        }}
                        onKeyPress={e => {
                          // if enter is pressed
                          if (e.charCode === 13 && !newAssetError) {
                            addAsset(
                              assetType.addAssetInputText,
                              assetType,
                              column.parent
                            );
                          }
                        }}
                      />
                    )}
                  </ListItem>
                </List>
              </Collapse>

              <Collapse in={assetType?.open}>
                <List component="div" disablePadding>
                  {assetType?.assets?.map(asset => {
                    return (
                      <ListItem
                        button
                        className={classes.nested}
                        key={asset.id}
                        selected={asset.selected}
                        onClick={asset.onClick}
                      >
                        <ListItemText
                          className={clsx({
                            [classes.capitalise]: true,
                            [classes.newItem]: asset?.new,
                          })}
                          disableTypography
                        >
                          {asset.name}
                        </ListItemText>
                      </ListItem>
                    );
                  })}
                </List>
              </Collapse>
            </div>
          );
        })}
      </List>
    </div>
  );
}

Column.propTypes = {
  column: assetTypePropType.isRequired,
  theAssetTypes: PT.arrayOf(assetTypeType).isRequired,
  toggleAssetType: PT.func.isRequired,
  addAsset: PT.func.isRequired,
  openAssetTypes: PT.arrayOf(assetTypeType).isRequired,
};

export default Column;
