import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  root: {
    width: '100%',
    height: 70,
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '40px 1fr',
    columnGap: theme.spacing(1),
    // gridTemplateRows: 'repeat(2, 1fr)',
    // gridTemplateAreas: "'avatar row1' 'avatar row2'",
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  row: {
    display: 'grid',

    // gridTemplateColumns: 'repeat(2, minmax(0px, auto))',
  },
  row1: {
    gridArea: 'row1',
    gridTemplateAreas: "'parameterName value'",
  },
  row2: {
    gridArea: 'row2',
    gridTemplateAreas: "'asset nameDate'",
  },
  avatar: {
    // gridArea: 'avatar',
    display: 'grid',
    placeItems: 'end',
  },
  parameterName: {
    // gridArea: 'parameterName',
    marginBottom: 'auto',
    width: '100%',
  },
  value: { 
    // gridArea: 'value', 
    marginBottom: 'auto' 
  },
  asset: {
    gridArea: 'asset',
    marginTop: 'auto',
    width: '65%',
  },
  nameDate: {
    gridArea: 'nameDate',
    marginTop: 'auto',
  },
  parameterNameTypography: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: '1.75rem',
    letterSpacing: '0.031em',
  },
  typography: {
    fontWeight: theme.typography.fontWeightLight,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: '1.75rem',
    letterSpacing: '0.031em',
    color: theme.palette.textSecondary,
  },
  redWarning: {
    color: theme.palette.error.main,
  },
  alignRight: { textAlign: 'right' },
}));
