import PT from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import useStyles from './filter-styles';
import {
  BasicDropdown,
  ListBoxComponent,
} from '../../../../components/pickers';
import handleFilterAndSort from './hooks';
import { renderCheckboxOption } from './helper';

function ParameterListFilters({
  handleFilterActive,
  searchParams,
  setSearchParams,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { sortByOptions, filters, applyFilters, filtersMap, handleOnChange } =
    handleFilterAndSort(handleFilterActive, searchParams, setSearchParams);

  return (
    <div className={classes.root} data-testid="parameterListFilters">
      <BasicDropdown
        data-testid="sortDropdown"
        label={t('sortBy')}
        options={sortByOptions}
        disableClearable
        value={filters.sort_by}
        getOptionLabel={option => {
          const [sortOption] = sortByOptions.filter(
            item => item.sortBy === option.sortBy
          );
          return t(sortOption?.label);
        }}
        getOptionSelected={(opt, val) => opt.sortBy === val.sortBy}
        onChange={(event, value) => handleOnChange(value, 'sort_by')}
        size="small"
      />
      <Divider className={classes.divider} />
      <Typography className={classes.title} variant="body2">
        {t('filterBy')}
      </Typography>
      {filtersMap
        // TODO: remove filter once migrated to V1.0.0 of the parameter service
        .filter(({ id }) => id !== 'parameterTypes')
        .map(({ id, label, key, ...rest }) => (
          <BasicDropdown
            className={classes.filter}
            key={id}
            disableListWrap
            ListboxComponent={ListBoxComponent}
            data-testid="filtersDropdown"
            value={filters[key]}
            getOptionLabel={option => option.name}
            label={t(id)}
            size="small"
            multiple
            onChange={(event, value) => handleOnChange(value, key)}
            renderOption={renderCheckboxOption}
            renderTags={(value, getTagProps) =>
              value.map(({ name }, index) => (
                <Chip
                  data-testid="chipTags"
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  avatar={
                    name ? null : (
                      <CircularProgress
                        data-testid="loadingCircle"
                        size={20}
                        thickness={6}
                      />
                    )
                  }
                  label={name || ''}
                  {...getTagProps({ index })}
                />
              ))
            }
            {...rest}
          />
        ))}
      <Button
        className={classes.applyButton}
        onClick={applyFilters}
        color="primary"
        variant="contained"
        size="small"
        disableElevation
      >
        {t('buttons.apply')}
      </Button>
    </div>
  );
}

ParameterListFilters.propTypes = {
  handleFilterActive: PT.func.isRequired,
  setSearchParams: PT.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  searchParams: PT.any.isRequired,
};

export default ParameterListFilters;
