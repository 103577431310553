import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  button: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)', // Transparent
    },
    ...theme.typography.body1,
    textTransform: 'none',
    textAlign: 'left',
    padding: 0,
  },
  toggleMainColor: ({ open }) => ({
    color: open ? theme.palette.primary.main : theme.palette.text.primary,
  }),
  toggleSecondaryColor: ({ open }) => ({
    color: open ? theme.palette.primary.main : theme.palette.text.secondary,
  }),
}));

export default useStyles;
