import PT from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import BottomNavigation from '../../../bottom-navigation';
import ExpandButton from '../expand-button';
import useStyles from './bottom-nav-styles';
import { StyledIconButton } from '../../../buttons';

export default function BottomNav({
  open,
  bottomNavigation,
  toggleDrawerOpen,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <div className={classes.bottomNav}>
      {open ? (
        <>
          {!isEmpty(bottomNavigation) && (
            <BottomNavigation navigation={bottomNavigation} />
          )}
          <ExpandButton open={open} toggleDrawerOpen={toggleDrawerOpen} />
        </>
      ) : (
        <List
          dense
          className={classes.smallNavList}
          data-testid="listNavigation"
        >
          {bottomNavigation.map(
            ({ icon, id, label, path, value, userAccess }) =>
              userAccess && (
                <ListItem
                  key={id}
                  className={classes.smallNav}
                  onClick={() => navigate(path)}
                  selected={value}
                >
                  <StyledIconButton
                    title={t(label)}
                    icon={icon}
                    color={value ? 'primary' : 'default'}
                  />
                </ListItem>
              )
          )}
          <ListItem className={classes.smallNav}>
            <ExpandButton open={open} toggleDrawerOpen={toggleDrawerOpen} />
          </ListItem>
        </List>
      )}
    </div>
  );
}

BottomNav.propTypes = {
  toggleDrawerOpen: PT.func.isRequired,
  open: PT.bool.isRequired,
  bottomNavigation: PT.arrayOf(
    PT.shape({
      // eslint-disable-next-line react/forbid-prop-types
      icon: PT.any,
      id: PT.string,
      label: PT.string,
      path: PT.string,
      value: PT.bool,
    })
  ),
};

BottomNav.defaultProps = {
  bottomNavigation: [],
};
