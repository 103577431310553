import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import isEmpty from 'lodash/isEmpty';

export default function MetaTitle({ title, translate }) {
  const { t, i18n, ready } = useTranslation([], { useSuspense: false });
  const text = translate ? ready && t(title) : title;
  return (
    <Helmet>
      {!isEmpty(title) && (
        <title lang={i18n.language || 'en-GB'}>{text || null}</title>
      )}
    </Helmet>
  );
}

MetaTitle.propTypes = {
  title: PT.string,
  translate: PT.bool,
};

MetaTitle.defaultProps = {
  title: null,
  translate: true,
};
