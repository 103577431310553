import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';

export default (
  { projectId, jobNameShort, number, confidential },
  cookies,
  setCookie,
  removeCookie
) => {
  const favorite = cookies?.ddbFavoriteProjects
    ? cookies?.ddbFavoriteProjects[projectId]
    : null;

  const addFavorite = () => {
    const currentCookies = cookies?.ddbFavoriteProjects || {};
    removeCookie('ddbFavoriteProjects');
    setCookie(
      'ddbFavoriteProjects',
      {
        [projectId]: { jobName: jobNameShort, number, confidential },
        ...currentCookies,
      },
      {
        path: '/',
        maxAge: 31536000,
      }
    );
  };

  const removeFavorite = () => {
    const updatedCookies = omit(cookies.ddbFavoriteProjects, [projectId]);
    removeCookie('ddbFavoriteProjects');
    if (!isEmpty(updatedCookies))
      setCookie('ddbFavoriteProjects', updatedCookies, {
        path: '/',
        maxAge: 31536000,
      });
  };

  useEffect(() => {
    if (favorite) {
      const currentCookies = cookies?.ddbFavoriteProjects || {};
      removeCookie('ddbFavoriteProjects');
      setCookie(
        'ddbFavoriteProjects',
        {
          ...currentCookies,
          [projectId]: { jobName: jobNameShort, number, confidential },
        },
        {
          path: '/',
          maxAge: 31536000,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confidential]);

  return { addFavorite, removeFavorite, favorite };
};
