import { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { sortBy } from 'lodash';
import {
  parametersApi,
  environmentContextApi,
  handleApiError,
} from '../../../../api';

export default (parameterId, tagTypesLoading) => {
  const [disciplines, setDisciplines] = useState([]);
  const [disciplinesLoading, setDisciplinesLoading] = useState(true);
  const [allDisciplines, setAllDisciplines] = useState([]);

  const loadingTag = {
    id: 'loading',
    icon: <CircularProgress size={20} />,
    loadingTag: true,
    name: '...',
  };

  useEffect(() => {
    let didCancel = false;

    const getDisciplines = async () => {
      setDisciplinesLoading(true);

      const res = await parametersApi(`getAllParameterDisciplines`, {
        parameter_id: parameterId,
      }).catch(err => handleApiError(err, []));

      if (!didCancel && res) {
        const { disciplines: response } = res;
        const reformattedDisciplines = response?.map(discipline => {
          return {
            id: discipline.discipline_id,
            name: discipline.discipline_name,
            code: discipline.discipline_code,
          };
        });
        setDisciplines(reformattedDisciplines);
        setDisciplinesLoading(false);
      }
    };

    if (parameterId && !tagTypesLoading) {
      getDisciplines();
    }

    return () => {
      didCancel = true;
    };
  }, [parameterId, tagTypesLoading]);

  useEffect(() => {
    let didCancel = false;

    const getAllDisciplines = async () => {
      setDisciplinesLoading(true);

      const { disciplines: disciplinesResponse } = await environmentContextApi(
        'getDisciplines'
      ).catch(handleApiError);

      if (!didCancel) {
        const reformattedDisciplines = disciplinesResponse?.map(discipline => {
          return {
            id: discipline.discipline_id,
            name: discipline.discipline_name,
            code: discipline.discipline_code,
          };
        });
        setAllDisciplines(reformattedDisciplines);
        setDisciplinesLoading(false);
      }
    };

    if (!tagTypesLoading) {
      getAllDisciplines();
    }

    return () => {
      didCancel = true;
    };
  }, [tagTypesLoading]);

  const deleteDiscipline = async tagId => {
    const clonedDisciplines = [...disciplines];
    const newDisciplines = clonedDisciplines.map(tag => {
      if (tag.id === tagId) return loadingTag;
      return tag;
    });
    setDisciplines(newDisciplines);
    const res = await parametersApi(`deleteParameterDiscipline`, {
      tag_id: tagId,
      parameter_id: parameterId,
    }).catch(err => handleApiError(err, []));
    if (!res)
      setDisciplines(
        clonedDisciplines.filter(discipline => discipline.id !== tagId)
      );
    else setDisciplines(clonedDisciplines);
  };

  const addDiscipline = async (parameterIdToAdd, discipline) => {
    const clonedDisciplines = [...disciplines];
    setDisciplines([...clonedDisciplines, loadingTag]);
    const { disciplines: res } = await parametersApi(
      `postParameterDisciplines`,
      {
        body: { discipline_id: discipline.id },
        parameter_id: parameterIdToAdd,
      }
    ).catch(err => handleApiError(err, []));
    if (res) {
      setDisciplines([...clonedDisciplines, discipline]);
    } else {
      setDisciplines(clonedDisciplines);
    }
  };

  const availableDisciplines = sortBy(
    allDisciplines?.filter(
      discipline => !disciplines?.find(({ id }) => discipline.id === id)
    ),
    ['name']
  );

  return {
    disciplines,
    deleteDiscipline,
    disciplinesLoading,
    availableDisciplines,
    allDisciplines,
    addDiscipline,
  };
};
