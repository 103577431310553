import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  column: {
    height: '100%',
    width: '100%',
    maxWidth: 240,
    display: 'flex',
    flexDirection: 'column',
    flex: 'none',
    '&:last-child > div': {
      // This removes the border from the last header so no double border
      borderRight: 'none',
    },
  },
  list: {
    padding: 0,
    flexGrow: 1,
    overflowY: 'scroll',
  },
  currAsset: {
    backgroundColor: `${theme.palette.primary.light} !important`,
    color: theme.palette.primary.contrastText,
  },
  iconWidth: {
    maxWidth: 24,
    minWidth: 24,
  },
  capitalise: {
    textTransform: 'capitalize',
  },
  nested: {
    paddingLeft: theme.spacing(5),
  },
  subnested: {
    paddingLeft: theme.spacing(11),
  },
  addIcon: {
    fontSizer: 10,
    padding: 0,
  },
  newItem: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  editableRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  bold: {
    fontWeight: 'bold',
  },
  subAssetType: {
    width: '100%',
  },
}));
