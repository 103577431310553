/* eslint-disable no-confusing-arrow */
import PT from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ToolTip from '@material-ui/core/Tooltip';
import ChevronDoubleRight from 'mdi-material-ui/ChevronDoubleRight';
import ChevronDoubleLeft from 'mdi-material-ui/ChevronDoubleLeft';
import { useTranslation } from 'react-i18next';
import { Fab } from '../../../buttons';
import useStyles from './expand-button-styles';

export default function ExpandButton({ open, toggleDrawerOpen }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const Icon = () =>
    open ? (
      <ChevronDoubleLeft data-testid="expand-button-left" />
    ) : (
      <ChevronDoubleRight data-testid="expand-button-right" />
    );

  return (
    <ToolTip title={open ? t('showLess') : t('showMore')}>
      {open ? (
        <Fab size="small" onClick={toggleDrawerOpen} className={classes.fab}>
          <Icon />
        </Fab>
      ) : (
        <span>
          <IconButton onClick={toggleDrawerOpen}>
            <Icon />
          </IconButton>
        </span>
      )}
    </ToolTip>
  );
}

ExpandButton.propTypes = {
  toggleDrawerOpen: PT.func.isRequired,
  open: PT.bool.isRequired,
};
