import * as dateLocales from 'date-fns/locale';
import { enUS, plPL } from '@material-ui/core/locale';

export const LANGUAGES_LABELS = [
  {
    code: 'en-GB',
    text: 'English (GB)',
  },
  {
    code: 'pl-PL',
    text: 'Polskie (PL)',
  },
  {
    code: 'en-US',
    text: 'English (US)',
  },
];

export const LANGUAGES = LANGUAGES_LABELS.map(({ code }) => code);

export const localeDateMap = {
  'en-GB': dateLocales.enGB,
  'pl-PL': dateLocales.pl,
  'en-US': dateLocales.enUS,
};

export const localeThemeMap = { 'en-GB': enUS, 'pl-PL': plPL, 'en-US': enUS };
