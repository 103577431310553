// import axios from 'axios';
// import { getGraphToken } from '../auth';

// const BASE_URL = 'https://graph.microsoft.com/v1.0';

// export const getAvatar = async () => {
//   const token = await getGraphToken();
//   const response = await axios.get(`${BASE_URL}/me/photo/$value`, {
//     cache: true,
//     responseType: 'arraybuffer',
//     headers: { Authorization: token },
//   });
//   const base64 = Buffer.from(response.data, 'binary').toString('base64');
//   return `data:image/jpeg;base64,${base64}`;
// };

// export const getUserPhoto = async id => {
//   const token = await getGraphToken();
//   const response = await axios.get(`${BASE_URL}/users/${id}/photo/$value`, {
//     cache: true,
//     responseType: 'arraybuffer',
//     headers: { Authorization: token },
//   });
//   const base64 = Buffer.from(response.data, 'binary').toString('base64');
//   return `data:image/jpeg;base64,${base64}`;
// };

// export const getUserInformation = async () => {
//   const token = await getGraphToken();
//   const response = await axios.get(`${BASE_URL}/me`, {
//     cache: true,
//     headers: { Authorization: token },
//   });
//   return response.data;
// };

// // https://graph.microsoft.com/v1.0/sites/arup.sharepoint.com:/sites/DigitalDesignBriefApplication
// const siteId =
//   'arup.sharepoint.com,8911f928-17a5-4817-9fb5-726514b099e0,1d5bd7af-0d1e-4a52-b3fd-d228045cb1ae';

// export const getSharepointInfo = async id => {
//   const token = await getGraphToken();
//   const response = await axios.get(
//     `${BASE_URL}/sites/${siteId}/lists/${id}/items?expand=fields`,
//     {
//       cache: true,
//       headers: { Authorization: token },
//     }
//   );
//   return response.data;
// };

// export const getUserGuideInfo = async documentPath => {
//   const driveId =
//     'b!KPkRiaUXF0iftXJlFLCZ4K_XWx0eDVJKs_3SKARcsa4N_b2OmSSoQoxSHP1Cgn-I';
//   const token = await getGraphToken();
//   const response = await axios.get(
//     `${BASE_URL}/sites/${siteId}/drives/${driveId}/root:${documentPath}`,
//     {
//       cache: true,
//       headers: { Authorization: token },
//     }
//   );
//   if (response.data.folder) {
//     const {
//       data: { value },
//     } = await axios.get(
//       `${BASE_URL}/drives/${driveId}/items/${response.data.id}/children`,
//       {
//         cache: true,
//         headers: { Authorization: token },
//       }
//     );
//     const files = await Promise.all(
//       value.map(async child => {
//         const { data } = await axios.get(child['@microsoft.graph.downloadUrl']);
//         return { content: data, heading: child.name };
//       })
//     );

//     return files;
//   }
//   const file = await axios.get(response.data['@microsoft.graph.downloadUrl']);
//   return file.data;
// };

import axios from 'axios';
import { getGraphToken } from '../auth';

const BASE_URL = 'https://graph.microsoft.com/v1.0';

export const getAvatar = async () => {
  const token = await getGraphToken();
  const response = await axios.get(`${BASE_URL}/me/photo/$value`, {
    cache: true,
    responseType: 'arraybuffer',
    headers: { Authorization: token },
  });
  const base64 = Buffer.from(response.data, 'binary').toString('base64');
  return `data:image/jpeg;base64,${base64}`;
};

export const getUserInformation = async () => {
  const token = await getGraphToken();
  const response = await axios.get(`${BASE_URL}/me`, {
    cache: true,
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// https://graph.microsoft.com/v1.0/sites/arup.sharepoint.com:/sites/DigitalDesignBriefApplication
const siteId =
  'arup.sharepoint.com,8911f928-17a5-4817-9fb5-726514b099e0,1d5bd7af-0d1e-4a52-b3fd-d228045cb1ae';

export const getSharepointInfo = async id => {
  const token = await getGraphToken();
  const response = await axios.get(
    `${BASE_URL}/sites/${siteId}/lists/${id}/items?expand=fields`,
    {
      cache: true,
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

export const getUserGuideInfo = async documentPath => {
  const driveId =
    'b!KPkRiaUXF0iftXJlFLCZ4K_XWx0eDVJKs_3SKARcsa4N_b2OmSSoQoxSHP1Cgn-I';
  const token = await getGraphToken();
  const response = await axios.get(
    `${BASE_URL}/sites/${siteId}/drives/${driveId}/root:${documentPath}`,
    {
      cache: true,
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  if (response.data.folder) {
    const {
      data: { value },
    } = await axios.get(
      `${BASE_URL}/drives/${driveId}/items/${response.data.id}/children`,
      {
        cache: true,
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const files = await Promise.all(
      value.map(async child => {
        const { data } = await axios.get(child['@microsoft.graph.downloadUrl']);
        return { content: data, heading: child.name };
      })
    );

    return files;
  }
  const file = await axios.get(response.data['@microsoft.graph.downloadUrl']);
  return file.data;
};
