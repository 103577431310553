import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles(theme => {
  const color = ({ isFilterActive }) => {
    let colour = theme.palette.action.active;
    if (isFilterActive) {
      colour = theme.palette.primary.main;
      if (theme.palette.type === 'dark')
        colour = lighten(theme.palette.primary.main, 0.5);
    }
    return colour;
  };
  return {
    header: {
      backgroundColor: '#F05023',
      color: '#fff',
      borderBottom: `1px solid ${theme.palette.divider}`,
      minHeight: theme.spacing(8.1),
    },
    filterColor: {
      color,
    },
  };
});
