import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  // indent1: {
  //   paddingLeft: 56,
  // },
  // indent2: {
  //   paddingLeft: 72,
  // },
  // indentDivider1: {
  //   paddingLeft: 72,
  // },
  // body: {
  //   padding: 0,
  // },
  // navList: {
  //   paddingTop: 0,
  // },
  // selected: {
  //   backgroundColor:
  //     theme.palette.type !== 'dark'
  //       ? 'rgba(0, 0, 0, 0.04)'
  //       : 'rgba(255, 255, 255, 0.08)',
  // },
  avatar: {
    // gridArea: 'avatar',
    display: 'grid',
    placeItems: 'end',
  },
}));
