import PT from 'prop-types';
import clsx from 'clsx';
import Skeleton from '@material-ui/lab/Skeleton';
import ListItem from '@material-ui/core/ListItem';
import WarningIcon from '@material-ui/icons/Warning';
import Typography from '@material-ui/core/Typography';
import useStyles from './parameter-card-styles';
import StatusCircle from '../../status-circle';
import { formatTitle } from '../../../utils';

function ParameterCard({ parameter, loading, selected, handleClick }) {
  const classes = useStyles();

  return (
    <ListItem
      className={classes.root}
      button
      selected={selected}
      onClick={handleClick}
      data-cy="parameterCard"
    >
      {/* <div className={classes.avatar}>
        <StatusCircle status={parameter.status} size={40} loading={loading} />
      </div> */}
      <div className={clsx([classes.row])}>
        <div className={classes.parameterName}>
          {loading ? (
            <Skeleton width="90%" data-testid="loading" />
          ) : (
            <Typography variant="body1" noWrap>
              {formatTitle(parameter.name)}
            </Typography>
          )}
        </div>
        {/* <div className={clsx([classes.value])}>
          {loading ? (
            <Skeleton data-testid="loading" />
          ) : (
            <Typography className={classes.typography} noWrap>
              {parameter.valueWithUnit}
            </Typography>
          )}
        </div> */}
      </div>
      {/* <div className={clsx([classes.row, classes.row2])}>
        <div className={classes.asset}>
          {loading ? (
            <Skeleton data-testid="loading" />
          ) : (
            <Typography className={classes.typography} noWrap>
              {parameter.assetName}
            </Typography>
          )}
        </div>
        <div className={clsx([classes.nameDate, classes.alignRight])}>
          {loading ? (
            <Skeleton data-testid="loading" />
          ) : (
            <Typography className={classes.typography} noWrap>
              {parameter.staffNameWithDate}
            </Typography>
          )}
        </div>
      </div> */}
    </ListItem>
  );
}

function trimfloat(value) {
  if ((typeof value) === "number") {
    return value.toFixed(3);
  } else {
    return value
  }
}

ParameterCard.propTypes = {
  parameter: PT.shape({
    createdAt: PT.string,
    staffName: PT.string,
    assetName: PT.string,
    name: PT.string,
    status: PT.string,
    units: PT.string,
    internationalizedValue: PT.oneOfType([PT.string, PT.number, PT.bool]),
    hideWarning: PT.bool,
    valueWithUnit: PT.string,
    staffNameWithDate: PT.string,
  }),
  selected: PT.bool,
  loading: PT.bool.isRequired,
  handleClick: PT.func,
};
ParameterCard.defaultProps = {
  parameter: {},
  selected: false,
  handleClick: null,
};

export default ParameterCard;
