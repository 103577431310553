import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectPermissionContext } from '../../../context/project-permission-context';

export default () => {
  const {
    writePermission,
    checkPermission,
    approvePermission,
    adminPermission,
  } = React.useContext(ProjectPermissionContext);
  const { t } = useTranslation(['common', 'parameters']);

  const renderTooltipDeleteIcon = () => {
    return !writePermission
      ? [t('common:permission.notAllowed'), true]
      : [t('parameters:deleteParam'), false];
  };

  const renderTooltipEditCloseIcon = isEditing => {
    if (!writePermission) {
      return [t('common:permission.notAllowed'), true];
    }
    if (isEditing) {
      return [t('common:buttons.close'), false];
    }
    return [t('common:dataDrawer.header.editParameterTooltip'), false];
  };

  const renderTooltipReject = status => {
    if (status === 'answered' && (checkPermission || writePermission))
      return [t('common:reject'), false];
    if (status === 'checked' && (approvePermission || writePermission))
      return [t('common:reject'), false];
    if (status === 'approved' && (adminPermission || approvePermission))
      return [t('common:reject'), false];
    return [t('common:permission.notAllowed'), true];
  };

  const renderTooltipDoneIcon = status => {
    if (
      status === 'answered' &&
      (checkPermission || writePermission || approvePermission)
    ) {
      return [t('common:dataDrawer.header.statusTooltipChecked'), false];
    }
    if (status === 'checked' && approvePermission) {
      return [t('common:dataDrawer.header.statusTooltipApproved'), false];
    }
    if (status === 'approved') {
      return [t('common:dataDrawer.header.parameterApproved'), true];
    }
    return [t('common:permission.notAllowed'), true];
  };

  return {
    renderTooltipDeleteIcon,
    renderTooltipEditCloseIcon,
    renderTooltipReject,
    renderTooltipDoneIcon,
  };
};
