/* eslint-disable camelcase */
import { useState, useEffect } from 'react';

// Project utils
import { handleApiError, parameterMetadataApi } from '../../../../../../../api';

export default (
  tagType,
  tagTypesLoading,
  updateTags,
  selectedParameterTags
) => {
  const [tagsLoading, setTagsLoading] = useState(true);
  const [allTags, setAllTags] = useState([]);

  useEffect(() => {
    let didCancel = false;

    const getAllTags = async () => {
      setTagsLoading(true);

      const { tags: updatedTags } = await parameterMetadataApi('getTags', {
        tag_type_id: tagType.id,
      }).catch(handleApiError);

      if (!didCancel) {
        setAllTags(updatedTags);
        setTagsLoading(false);
      }
    };

    if (!tagTypesLoading && tagType.id) {
      getAllTags();
    }

    return () => {
      didCancel = true;
    };
  }, [tagType.id, tagTypesLoading]);

  const deleteTag = async (tagId, parameterId) => {
    const newTagList = selectedParameterTags.filter(tag => {
      return tag.id !== tagId;
    });
    updateTags(parameterId, newTagList);
  };

  // Update tags in parameter object in parent component
  // This is different from data-drawer tags where tags are stored in local state
  const addTag = async (parameterId, newTag) => {
    const newTagList = [...selectedParameterTags, newTag];
    updateTags(parameterId, newTagList);
  };

  const availableTags = allTags.filter(
    tag => !selectedParameterTags?.find(({ id }) => tag.id === id)
  );

  return {
    deleteTag,
    tagsLoading,
    availableTags,
    addTag,
  };
};
