import { useContext } from 'react';
import { ProjectContext } from '../../../context/project-context';
import { MetaTitle } from '../../../components/meta';

export default function ProjectMetadataTitle() {
  const { project, loading } = useContext(ProjectContext);
  return (
    // <MetaTitle title={loading ? '' : project.jobNameShort} translate={false} />
    <MetaTitle title={'SDP | Structural Data Platform'} translate={false} />
  );
}
