const grey = {
  50: '#FAFAFA',
  100: '#EFEFEF',
  200: '#DBDBDB',
  300: '#BEBEBE',
  400: '#AAAAAA',
  500: '#9B9B9B',
  600: '#767676',
  700: '#575757',
  800: '#424242',
  900: '#353535',
  // 100: '#1C1C1C'
};

export default grey;
