import PT from 'prop-types';
import Typography from '@material-ui/core/Typography';
import useStyles from './header-styles';
import { formatTitle } from '../../../../../utils';

function Header(props) {
  const { label } = props;

  const classes = useStyles();
  return (
    <div className={classes.header}>
      <Typography
        variant="caption"
        color="textSecondary"
        className={classes.headerText}
      >
        {formatTitle(label)}
      </Typography>
    </div>
  );
}

Header.propTypes = {
  label: PT.string,
};

Header.defaultProps = {
  label: '',
};

export default Header;
