import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    overflowY: 'hidden',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.action.hover,
  },
  alert: { padding: theme.spacing(2) },
  avatar: {
    // gridArea: 'avatar',
    display: 'grid',
    placeItems: 'end',
  },
}));
