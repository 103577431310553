import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import useStyles from './styles';

export default function SimpleAlerts({
  severity,
  variant,
  text,
  title,
  onClose,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Alert variant={variant} severity={severity} onClose={onClose}>
        {title && <AlertTitle>{title}</AlertTitle>}
        {text}
      </Alert>
    </div>
  );
}

SimpleAlerts.propTypes = {
  severity: PropTypes.string,
  variant: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  text: PropTypes.any.isRequired,
  onClose: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  title: PropTypes.any,
};

SimpleAlerts.defaultProps = {
  title: null,
  severity: 'error',
  variant: 'filled',
  onClose: null,
};
