import { createRef, useContext } from 'react';
import PT from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';

// Hooks
import useDropdown from './hooks';
import TextInputChip from '../text-input-chip';
import { ProjectPermissionContext } from '../../../context/project-permission-context';

function ChipDropdown({
  tagTypeName,
  addTag,
  availableTags,
  loading,
  parameterId,
}) {
  const chipRef = createRef();
  const { handleChange, handleInputChange, value, inputValue, setValue } =
    useDropdown(addTag, parameterId);
  const { writePermission } = useContext(ProjectPermissionContext);
  const { t } = useTranslation();

  return (
    <Tooltip
      title={writePermission ? '' : t('permission.notAllowed')}
      placement="top-start"
    >
      <span>
        <Autocomplete
          options={availableTags}
          getOptionLabel={option => option.name}
          inputValue={inputValue}
          value={value}
          onInputChange={handleInputChange}
          onChange={handleChange}
          disableClearable
          disabled={!writePermission}
          renderInput={params => (
            <TextInputChip
              forwardRef={chipRef}
              loading={loading}
              parameterId={parameterId}
              label={tagTypeName}
              {...params}
              setValue={setValue}
              disabled={!writePermission}
            />
          )}
        />
      </span>
    </Tooltip>
  );
}

ChipDropdown.propTypes = {
  tagTypeName: PT.string.isRequired,
  availableTags: PT.arrayOf(PT.shape({ name: PT.string.isRequired }).isRequired)
    .isRequired,
  addTag: PT.func.isRequired,
  loading: PT.bool.isRequired,
  parameterId: PT.oneOfType([PT.string, PT.number]).isRequired,
};

export default ChipDropdown;
