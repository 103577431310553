import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default (
  projectName,
  theAssets,
  setTheAssets,
  theAssetTypes,
  selectedAsset,
  setSelectedAsset
) => {
  const { t } = useTranslation(['parameters']);
  const [columns, setColumns] = useState([]);
  const [openAssetTypes, setOpenAssetTypes] = useState([]);
  const { projectId } = useParams();

  useEffect(() => {
    const newColumns = [];
    const setAllSelectedToFalse = () => {
      return theAssets.map(asset => ({
        ...asset,
        selected: false,
      }));
    };

    // If an asset has been selected, find it in the asset list and itererate up through parents setting their values to selected
    const makeSelectedPath = (items, selectedAssetId) => {
      const index = items.findIndex(asset => asset.id === selectedAssetId);

      if (items[index]) {
        // eslint-disable-next-line no-param-reassign
        items[index].selected = true;
        if (items[index].parent) {
          makeSelectedPath(items, items[index].parent);
        }
      }
      return items;
    };

    // generate columns based on selected element path
    const populateColumns = (assets, selectedAssetId, columnTitle) => {
      const col = assets.filter(item => item.parent === selectedAssetId);
      if (col?.length > 0) {
        newColumns.push({
          id: `column ${newColumns.length}`,
          assets: col,
          title: columnTitle
            ? t('parameters:assetContains', {
                columnTitle,
                interpolation: { escapeValue: false },
              })
            : '',
          parent: selectedAssetId,
        });
        // Autoselect single occurence asset, this saves the user numerous clicks
        if (col?.length === 1) {
          col[0].selected = true;
        }
        const selectedChild = col.find(item => item.selected);
        if (selectedChild) {
          populateColumns(assets, selectedChild.id, selectedChild?.name);
        }
      }
    };

    // if an asset is selected but no child assets have been added, an extra column, with appropriate assets types, is needed
    const checkIfAFinalColumnIsNeeded = () => {
      const lastColumn = newColumns[newColumns.length - 1];
      const lastColumnSelectedAsset = lastColumn?.assets.find(
        asset => asset.selected
      );
      if (lastColumnSelectedAsset) {
        const newColumnAssetTypes = theAssetTypes.filter(
          assetType =>
            assetType.parent_id === lastColumnSelectedAsset.asset_type.id
        );

        if (newColumnAssetTypes.length > 0) {
          newColumns.push({
            id: `column ${newColumns.length}`,
            assets: null,
            title: t('parameters:assetContains', {
              columnTitle: lastColumnSelectedAsset.name,
              interpolation: { escapeValue: false },
            }),
            parent: lastColumnSelectedAsset?.id,
            finalColumnAssetTypes: newColumnAssetTypes,
          });
        }
      }
    };

    // if there are no assets, make the first column show no-parent asset types

    const checkForBlankFirstColumn = () => {
      if (newColumns.length === 0) {
        const newColumnAssetTypes = theAssetTypes.filter(
          assetType => !assetType.parent_id
        );
        newColumns.push({
          id: `column ${newColumns.length}`,
          assets: null,
          title: t('parameters:assetContains', {
            columnTitle: projectName,
            interpolation: { escapeValue: false },
          }),
          finalColumnAssetTypes: newColumnAssetTypes,
        });
      }
    };

    // if there are fewer than 6 columns, make it up to 6 for neatness
    const addBlankColumns = () => {
      if (newColumns.length < 6) {
        for (let i = newColumns.length; i < 6; i++) {
          newColumns.push({
            assets: null,
            title: null,
            id: `column ${i}`,
          });
        }
      }
    };

    // if an asset has been selected, populate columns based on the parental hierarchy of that asset, if not use the standard asset list
    const makeColumns = async () => {
      const selectedPath = selectedAsset
        ? makeSelectedPath(setAllSelectedToFalse(), selectedAsset)
        : setAllSelectedToFalse();

      populateColumns(selectedPath, null, projectName);

      checkIfAFinalColumnIsNeeded();

      checkForBlankFirstColumn();

      addBlankColumns();

      setColumns(newColumns);
    };

    makeColumns();
  }, [
    selectedAsset,
    theAssets,
    projectName,
    theAssetTypes,
    t,
    setSelectedAsset,
  ]);

  const toggleAssetType = (parentAsset, assetType) => {
    const mergedString = `${parentAsset}:${assetType}`;
    if (openAssetTypes.includes(mergedString)) {
      setOpenAssetTypes(openAssetTypes.filter(e => e !== mergedString));
    } else {
      setOpenAssetTypes(current => [...current, mergedString]);
    }
  };

  const addAsset = (name, assetType, parent) => {
    const newUuid = uuidv4();
    const newAsset = {
      id: newUuid,
      parent: parent || null,
      asset_type: assetType,
      children: [],
      name,
      new: true,
      onClick: () => setSelectedAsset(newUuid),
      project_id: projectId,
      selected: false,
    };

    setTheAssets(theNewAssets => [...theNewAssets, newAsset]);
    setSelectedAsset(newUuid);
  };

  return { columns, theAssetTypes, openAssetTypes, toggleAssetType, addAsset };
};
