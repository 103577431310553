import PT from 'prop-types';
import MuiChip from '@material-ui/core/Chip';
import useStyles, { tagTypeLookup } from './tag-chip-styles';

function TagChip({ label, icon: Icon, tagType, holder, ...rest }) {
  const classes = useStyles({
    tagType,
    label,
    holder,
    clickable: !!rest?.onClick,
  });

  return (
    <MuiChip
      classes={{
        root: classes.baseChip,
        icon: classes.icon,
        label: classes.label,
        deleteIcon: classes.deleteIcon,
      }}
      label={label}
      icon={Icon || tagTypeLookup[tagType]?.icon || null}
      size="small"
      variant="outlined"
      {...rest}
    />
  );
}

TagChip.propTypes = {
  label: PT.string,
  icon: PT.node,
  tagType: PT.string,
  holder: PT.bool,
};

TagChip.defaultProps = {
  tagType: '',
  label: null,
  icon: null,
  holder: false,
};

export default TagChip;
