import React from 'react';
import PT from 'prop-types';
// import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import useStyles from './styles';
// import { withAppInsights } from '../../app/app-insights';
// import { ActionButton } from '../../components/buttons';

function ErrorPage({ err }) {
  const { t } = useTranslation(['errors']);
//   const classes = useStyles();
//   const history = useNavigate();
//   const navigate = path => history.push(path);
  return (
      <Typography variant="h3">cannot find page</Typography>
    // <Grid
    //   container
    //   direction="column"
    //   alignItems="center"
    //   justify="center"
    //   spacing={5}
    //   className={classes.content}
    // >
    //   <Grid item>
    //     <Typography variant="h2" align="center">
    //       {t('errors:errorPageMessage')}
    //     </Typography>
    //   </Grid>
    //   <Grid item>
    //     <Typography variant="h3" align="center">
    //       {err ? `${err.status} - ${err.msg}` : `404 - ${t('errors:msg404')}`}
    //     </Typography>
    //   </Grid>
    //   <Grid item>
    //     <Typography variant="h4" align="center">
    //       {err ? err.details : t('errors:details404')}
    //     </Typography>
    //   </Grid>
    //   <Grid item>
    //     <ActionButton onClick={() => navigate('/')}>
    //       {t('errors:goToHome')}
    //     </ActionButton>
    //   </Grid>
    // </Grid>

  );
}

ErrorPage.propTypes = {
  err: PT.shape({
    status: PT.number.isRequired,
    msg: PT.string.isRequired,
    details: PT.string,
  }),
};

ErrorPage.defaultProps = {
  err: null,
};

export default ErrorPage;
