import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { handleApiError, referenceDataApi } from '../../../../../api';

export default (
  open,
  dialogValue,
  setDialogValue,
  toggleOpen,
  setAllSources,
  setUpdateValues,
  setSelectedSource
) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [referenceError, setReferenceError] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const { projectId } = useParams();
  const baseUrl = process.env.REACT_APP_URL
    ? process.env.REACT_APP_URL.split('//')[1]
    : 'ddb-dev.arup.com';

  const updateDialogValue = ({ target: { value } }, field) => {
    setDialogValue({ ...dialogValue, [field]: value });
  };

  const updatePublicationDate = date => {
    setDialogValue({
      ...dialogValue,
      date_day: String(new Date(date).getDate()),
      date_month: String(new Date(date).getMonth()),
      date_year: String(new Date(date).getFullYear()),
    });
  };

  const updateTitle = event => {
    updateDialogValue(event, 'title');
  };

  const validateTitle = () => {
    setTitleError(dialogValue.title && dialogValue.title.length > 255);
  };

  const updateReference = event => {
    updateDialogValue(event, 'reference');
  };

  const validateReference = () => {
    setReferenceError(
      dialogValue.reference && dialogValue.reference.length > 255
    );
  };

  const updateUrl = event => {
    updateDialogValue(event, 'url');
  };

  const validateUrl = () => {
    setUrlError(dialogValue?.url && dialogValue?.url.length > 1000);
  };

  useEffect(() => {
    let didCancel = false;

    const getSources = async () => {
      const { source_types: sourceTypes } = await referenceDataApi(
        'getSourceTypes',
        {
          visible_only: true,
        }
      ).catch(handleApiError);
      if (!didCancel) setOptions(sourceTypes);
    };

    if (open) getSources();

    return () => {
      didCancel = true;
    };
  }, [open]);

  const handleClose = () => {
    setDialogValue({
      source_type_id: '',
      title: '',
      reference: '',
      url: '',
      date_year: '',
      date_month: '',
      date_day: '',
      reference_id: '',
      reference_table: '',
      reference_url: '',
    });

    toggleOpen();
  };

  const handleSubmit = async event => {
    const validTitle = !titleError;
    const validReference = !referenceError;
    const validUrl = !urlError;
    const validSource = [validTitle, validReference, validUrl];

    setLoading(true);
    event.preventDefault();

    const newSource = { ...dialogValue };

    Object.keys(newSource).forEach(key => {
      if (newSource[key] === '') {
        delete newSource[key];
      }
    });

    if (validSource.every(e => e === true)) {
      const postSource = async body => {
        const { source } = await referenceDataApi('addSource', {
          body: {
            ...body,
            reference_id: projectId,
            reference_table: 'project',
            reference_url: `${baseUrl}/api/projects`,
          },
        }).catch(handleApiError);
        setAllSources(allSources => {
          return [source, ...allSources];
        });
        setUpdateValues(updateValues => {
          return { ...updateValues, source_id: source.id };
        });
        setSelectedSource(source);
        return source;
      };

      if (projectId) {
        await postSource(newSource);
        setLoading(false);
        handleClose();
      }
    } else {
      setLoading(false);
    }
  };

  return {
    errors: { titleError, referenceError, urlError },
    updateFunctions: {
      updatePublicationDate,
      updateTitle,
      updateReference,
      updateUrl,
    },
    validateFunctions: {
      validateTitle,
      validateReference,
      validateUrl,
    },
    inputValue,
    setInputValue,
    options,
    loading,
    handleSubmit,
    handleClose,
  };
};
