const pink = {
  50: '#FFD7EF',
  100: '#FCAFDB',
  200: '#F897CC',
  300: '#FF79C0',
  400: '#F65FAC',
  500: '#E24595',
  600: '#D22D7D',
  700: '#AB1B66',
  800: '#912963',
  900: '#771C51',
  // 100: '#6E174E'
};

export default pink;
