import PT from 'prop-types';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
// Important: For material-ui-pickers v3 use v1.x version of @date-io adapters
import DateFnsUtils from '@date-io/date-fns';
import { useTranslation } from 'react-i18next';
import { localeDateMap } from '../../../i18n/languages';

function DatePicker({
  variant,
  format,
  inputVariant,
  margin,
  label,
  value,
  helperText,
  onChange,
  views,
  ...rest
}) {
  const { t, i18n } = useTranslation(['parameters']);

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={localeDateMap[i18n.language]}
    >
      <KeyboardDatePicker
        views={views}
        variant={variant}
        inputVariant={inputVariant}
        format={format}
        margin={margin}
        label={label}
        value={value}
        helperText={helperText}
        onChange={onChange}
        InputAdornmentProps={{ position: 'end' }}
        KeyboardButtonProps={{
          'aria-label': t('parameters:changeDate'),
        }}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
}

DatePicker.propTypes = {
  label: PT.string.isRequired,
  variant: PT.string,
  inputVariant: PT.string,
  format: PT.string,
  margin: PT.string,
  helperText: PT.string,
  value: PT.oneOfType([PT.string, PT.instanceOf(Date)]).isRequired,
  onChange: PT.func.isRequired,
  views: PT.arrayOf(PT.string.isRequired),
};

DatePicker.defaultProps = {
  variant: 'inline',
  inputVariant: 'outlined',
  format: 'dd/MM/yyyy',
  margin: 'normal',
  helperText: null,
  views: ['date'],
};

export default DatePicker;
