import React, { useContext } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useIsAuthenticated } from '@azure/msal-react';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import OnlineBadge from '../badge/Badge';
import useStyles from './avatar-styles';
import { UserContext } from '../../context/user-context';

function UserAvatar() {
  const { user } = useContext(UserContext);
  const isAuthenticated = useIsAuthenticated();
  const classes = useStyles();

  return isAuthenticated ? (
    <OnlineBadge
      data-testid="online"
      overlap="circle"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      variant="dot"
    >
      <Avatar
        alt={
          isAuthenticated && !isEmpty(user)
            ? user.userName.match(/\b(\w)/g).join('')
            : null
        }
        src={user.avatar}
        className={classes.avatarSmall}
      />
    </OnlineBadge>
  ) : (
    <Badge
      data-testid="offline"
      color="error"
      variant="dot"
      overlap="circle"
      badgeContent=" "
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <Avatar aria-label="avatar" className={classes.avatarSmall} />
    </Badge>
  );
}

export default UserAvatar;
