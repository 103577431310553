import PT from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import IconButton from '@material-ui/core/IconButton';
import useStyles from '../style';

function SortDropdown({
  options,
  label,
  handleSortDirection,
  sortDirection,
  disabled,
  getOptionLabel,
  ...rest
}) {
  const classes = useStyles();
  return (
    <Autocomplete
      {...rest}
      disabled={disabled}
      options={options}
      getOptionLabel={getOptionLabel}
      renderInput={params => (
        <TextField
          {...params}
          classes={{ root: classes.root }}
          label={label}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            'aria-label': label,
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  data-testid="toggleSort"
                  onClick={handleSortDirection}
                  disabled={disabled}
                  aria-label={
                    sortDirection === 'asc'
                      ? 'Ascending button'
                      : 'Descending button'
                  }
                >
                  {sortDirection === 'asc' ? (
                    <ArrowUpwardIcon data-testid="asc" />
                  ) : (
                    <ArrowDownwardIcon data-testid="desc" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}

SortDropdown.propTypes = {
  options: PT.arrayOf(PT.any).isRequired,
  label: PT.string,
  sortDirection: PT.string.isRequired,
  handleSortDirection: PT.func.isRequired,
  getOptionLabel: PT.func,
  disabled: PT.bool,
};

SortDropdown.defaultProps = {
  label: null,
  getOptionLabel: option => option.title,
  disabled: false,
};

export default SortDropdown;
