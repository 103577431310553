import { useState } from 'react';

export default (addTag, parameterId) => {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  // the "value" state with the value/onChange props combination.
  // This state represents the value selected by the user, for instance when pressing Enter.
  const handleChange = (_, newValue) => {
    if (newValue) {
      const { id, name, global_tag: globalTag } = newValue;
      const tag = { id, name, global_tag: globalTag };
      addTag(parameterId, tag);
    }
    setInputValue(''); // reset input after you have selected a chip
  };

  // the "input value" state with the inputValue/onInputChange props combination.
  // This state represents the value displayed in the textbox.
  const handleInputChange = (_, newInputValue) => {
    setInputValue(newInputValue);
  };

  return {
    handleChange,
    handleInputChange,
    value,
    inputValue,
    setValue,
  };
};
