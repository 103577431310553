import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  button: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)', // Transparent
    },
    ...theme.typography.body1,
    textTransform: 'none',
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  alignStart: {
    float: 'left',
  },
  chips: {
    marginLeft: 28,
  },
  skeletonChip: {
    borderRadius: '16px!important',
    marginBottom: '8px!important',
    marginRight: '8px!important',
  },
  skeletonHeader: {
    height: 24,
    marginBottom: '0.35em',
  },
  label: ({ typography }) => ({
    ...theme.typography[typography],
    color:
      typography === 'caption'
        ? theme.palette.text.secondary
        : theme.palette.text.primary,
  }),
}));

export default useStyles;
