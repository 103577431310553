import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import { useSnackbar } from 'notistack';
// import { SnackbarMessage } from '../../../components/snackbars';

function ErrorComponent({ error }) {
  const { t } = useTranslation();
//   const { enqueueSnackbar } = useSnackbar(['errors']);

  useEffect(() => {
    if (error) {
    //   enqueueSnackbar(
    //     <SnackbarMessage
    //       errorMessage={error.errorMessage}
    //       errorCode={error.errorCode}
    //     />,
    //     {
    //       persist: true,
    //       anchorOrigin: {
    //         vertical: 'top',
    //         horizontal: 'center',
    //       },
    //       variant: 'error',
    //       preventDuplicate: true,
    //     }
    //   );
    }
//   }, [error, t, enqueueSnackbar]);
}, [error, t]);

  return null;
}

ErrorComponent.propTypes = {
  error: PropTypes.shape({
    errorCode: PropTypes.string.isRequired,
    errorMessage: PropTypes.string.isRequired,
  }),
};

export default ErrorComponent;
