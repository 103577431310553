import PT from 'prop-types';
import { useNavigate } from 'react-router-dom';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { useTranslation } from 'react-i18next';
import useStyles from './bottom-nav-styles';

export default function BottomMenuNavigation({ navigation }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation(['settings', 'parameters']);

  return (
    <BottomNavigation
      value
      showLabels
      className={classes.navBar}
      data-testid="bottomNavigation"
    >
      {navigation.map(
        option =>
          option.userAccess && (
            <BottomNavigationAction
              classes={{
                root: classes.root,
                selected: classes.selected,
                wrapper: classes.wrapper,
              }}
              key={t(option.label)}
              label={t(option.label)}
              icon={option.icon}
              value={option.value}
              data-cy={`btm-nav-${t(option.label)}`}
              onClick={() => navigate(option.path)}
            />
          )
      )}
    </BottomNavigation>
  );
}

BottomMenuNavigation.propTypes = {
  navigation: PT.arrayOf(
    PT.shape({
      id: PT.string,
      label: PT.string,
      path: PT.string,
      // eslint-disable-next-line react/forbid-prop-types
      icon: PT.any,
      value: PT.bool,
      userAccess: PT.bool,
    })
  ).isRequired,
};
