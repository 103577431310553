import React from 'react';
import PT from 'prop-types';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import AccountCog from 'mdi-material-ui/AccountCog';
import { useIsAuthenticated } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';

function UserMenu({ anchorEl, handleClose, navigate, userLogout, userLogin }) {
  const { t } = useTranslation(['common', 'header']);
  const isAuthenticated = useIsAuthenticated();
  return (
    <Menu
      id="menu-user-account"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {/* <MenuItem
        onClick={() => {
          handleClose();
          navigate('/user-settings/details');
        }}
      >
        <ListItemIcon>
          <AccountCog />
        </ListItemIcon>
        {t('header:manageAccount')}
      </MenuItem> */}
      <MenuItem
        onClick={isAuthenticated ? userLogout : userLogin}
        data-cy="logout-button"
      >
        <ListItemIcon>
          {isAuthenticated ? <ExitToAppIcon /> : <LockOpenIcon />}
        </ListItemIcon>
        {isAuthenticated
          ? t('common:buttons.logout')
          : t('common:buttons.login')}
      </MenuItem>
    </Menu>
  );
}

UserMenu.propTypes = {
  anchorEl: PT.oneOfType([PT.object, PT.func]),
  handleClose: PT.func.isRequired,
  navigate: PT.func.isRequired,
  userLogout: PT.func.isRequired,
  userLogin: PT.func.isRequired,
};

UserMenu.defaultProps = {
  anchorEl: null,
};

export default UserMenu;
