const yellow = {
  50: '#FEFF99',
  100: '#FEF740',
  200: '#FFED1D',
  300: '#FFE011',
  400: '#FFD313',
  500: '#FFC317',
  600: '#FFB019',
  700: '#FA9B1E',
  800: '#D58E10',
  900: '#AE7007',
  // 100: '#856800'
};
export default yellow;
