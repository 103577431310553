import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  root: {
    margin: -theme.spacing(2),
    height: 'calc(100% + 32px)',
    width: 'auto',
  },
  editor: {
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
}));
