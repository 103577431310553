// todo: remove in refactor
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
import PT from 'prop-types';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { CircularProgress } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
// import { Person } from '@microsoft/mgt-react';
import { useTheme } from '@material-ui/styles';
import useStyles from './data-drawer-status-styles';
import useStatusHistory from './hooks';
import { formatDate } from '../../../utils';
import StatusCircle from '../../status-circle';
import DataDrawerGrid from '../grid';
import { parameterType } from '../../../types';
import { StyledIconButton } from '../../buttons';

function Status(props) {
  const { parameter, disabled } = props;
  const { open, handleChangeOpen, statusHistory, header, loading } =
    useStatusHistory(parameter);
  const classes = useStyles({ open });
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  const renderButton = () => {
    if (loading)
      return (
        <CircularProgress
          size={18}
          thickness={8}
          className={classes.disabled}
        />
      );
    if (open) return <KeyboardArrowDownIcon aria-label="Collapse button" />;
    return <KeyboardArrowRightIcon aria-label="Expand button" />;
  };

  return (
    <Box className={classes.box}>
      {open ? <Divider /> : null}
      <DataDrawerGrid
        icon={
          <CheckCircleOutlineIcon
            className={disabled ? classes.disabled : classes.toggleMainColor}
          />
        }
        caption={
          <Typography
            variant="caption"
            className={classes.toggleSecondaryColor}
          >
            {t('dataDrawer.status.statusHistory')}
          </Typography>
        }
        content={
          <Box className={classes.box}>
            <StyledIconButton
              onClick={disabled ? null : handleChangeOpen}
              icon={renderButton()}
            />
            <Typography variant="subtitle1" display="inline">
              {loading ? (
                <Skeleton height={30} width={600} display="inline" />
              ) : (
                header
              )}
            </Typography>
            <Collapse in={open}>
              <Table aria-label="Version history table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={clsx([classes.status, classes.noPadding])}
                      align="left"
                    >
                      {t('dataDrawer.status.title')}
                    </TableCell>
                    <TableCell
                      className={clsx([classes.status, classes.noPadding])}
                      align="left"
                    >
                      {t('dataDrawer.status.statusHistory')}
                    </TableCell>
                    <TableCell
                      className={clsx([classes.status, classes.noPadding])}
                      align="left"
                    >
                      {t('dataDrawer.status.updatedBy')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {statusHistory.map(revision => {
                    const status = revision?.type?.name || 'Unanswered';
                    const statusLabel =
                      status.charAt(0).toUpperCase() + status.slice(1);
                    const dateText = revision?.created_at;
                    const date = dateText
                      ? formatDate(dateText, undefined, i18n.language)
                      : null;
                    const email = revision?.created_by?.email;
                    return (
                      <TableRow key={revision.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          padding="none"
                          className={classes.topBorder}
                        >
                          <Grid
                            container
                            direction="row"
                            spacing={3}
                            alignItems="center"
                          >
                            <Grid item>
                              <StatusCircle status={status} />
                            </Grid>
                            <Grid item>
                              <Typography>{statusLabel}</Typography>
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell className={classes.topBorder} align="left">
                          {date}
                        </TableCell>
                        <TableCell className={classes.topBorder} align="left">
                          {/* <Person
                            personQuery={email}
                            avatarSize="large"
                            personCardInteraction={2}
                            className={
                              theme.palette.type === 'dark' && 'mgt-dark'
                            }
                            view={4}
                            showPresence
                            fallbackDetails={{
                              userPrincipalName: revision?.created_by?.email,
                              mail: revision?.created_by?.email,
                              officeLocation:
                                revision?.created_by.location_name,
                              givenName: revision?.created_by?.staff_name,
                            }}
                          /> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Collapse>
          </Box>
        }
      />
    </Box>
  );
}
Status.propTypes = {
  parameter: parameterType.isRequired,
  disabled: PT.bool.isRequired,
};
export default Status;
