import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Project utils
import { handleApiError, referenceDataApi } from '../../../../api';

export default param => {
  const [allSources, setAllSources] = useState([]);
  const [sourceError, setSourceError] = useState(false);
  const [open, setOpen] = useState(false);

  const [dialogValue, setDialogValue] = useState({
    source_type_id: '',
    title: '',
    reference: '',
    reference_id: '',
    reference_table: '',
    reference_url: '',
    // todo: on post should separate out, to be kept as single date in state
    date_day: new Date().getDate().toString(),
    date_month: new Date().getMonth().toString(),
    date_year: new Date().getFullYear().toString(),
  });
  const { projectId } = useParams();

  const toggleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    let didCancel = false;

    const loadAllSources = async () => {
      const { sources } = await referenceDataApi('getSources', {
        reference_id: projectId,
        include_global: 'true',
      }).catch(handleApiError);

      if (!didCancel) {
        setAllSources(sources);
      }
    };
    if (projectId) loadAllSources();

    return () => {
      didCancel = true;
    };
  }, [projectId]);

  useEffect(() => {
    if (param) {
      setSourceError(false);
    }
  }, [param]);

  return {
    allSources,
    setAllSources,
    sourceError,
    setSourceError,
    open,
    toggleOpen,
    setDialogValue,
    dialogValue,
  };
};
