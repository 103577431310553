import { useState, useEffect } from 'react';
import { parameterMetadataApi, handleApiError } from '../../../../api';

export default () => {
  const [tagTypes, setTagTypes] = useState([]);
  const [tagTypesLoading, setTagTypesLoading] = useState(true);

  useEffect(() => {
    let didCancel = false;
    setTagTypesLoading(true);

    const getTagTypes = async () => {
      const { tag_types: newTagTypes } = await parameterMetadataApi(
        'getTagTypes'
      ).catch(handleApiError);

      if (!didCancel && newTagTypes) {
        setTagTypes(newTagTypes);
      }
      setTagTypesLoading(false);
    };

    getTagTypes();

    return () => {
      didCancel = true;
    };
  }, []);

  return {
    tagTypes,
    tagTypesLoading,
  };
};
