import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { UserContext } from './user-context';
import { userApi } from '../api';
import config from '../config';

export const ProjectPermissionContext = React.createContext();

export const ProjectPermissionProvider = ({ children }) => {
  const [readPermission, setReadPermission] = useState(false);
  const [writePermission, setWritePermission] = useState(false);
  const [checkPermission, setCheckPermission] = useState(false);
  const [approvePermission, setApprovePermission] = useState(false);
  const [adminPermission, setAdminPermission] = useState(false);
  const { user } = useContext(UserContext);
  const { projectId } = useParams();

  useEffect(() => {
    const getProjectPermissions = async () => {
      const {
        rules: { [user.email.toLowerCase()]: userPermissions },
      } = await userApi('getUserRules', {
        emails: [user.email],
        type: 'Project',
      });
      const scopePermissions = userPermissions
        .filter(permission => permission.scope_id === projectId)
        .map(scopePermission => scopePermission.action_id);

      setReadPermission(scopePermissions.includes('read'));
      setWritePermission(scopePermissions.includes('write'));
      setCheckPermission(scopePermissions.includes('check'));
      setApprovePermission(scopePermissions.includes('approve'));
      setAdminPermission(scopePermissions.includes('admin'));
    };

    const setEnvPermissions = () => {
      setReadPermission(true);
      setWritePermission(true);
      setCheckPermission(true);
      setApprovePermission(true);
      setAdminPermission(true);
    };

    if (user.email) {
      if (config.featureFlags.isRoleBasedPermissionEnabled)
        getProjectPermissions();
      else setEnvPermissions();
    }
  }, [projectId, readPermission, user.email]);

  return (
    <ProjectPermissionContext.Provider
      value={{
        readPermission,
        writePermission,
        checkPermission,
        approvePermission,
        adminPermission,
      }}
    >
      {children}
    </ProjectPermissionContext.Provider>
  );
};

ProjectPermissionProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
