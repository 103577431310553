import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  root: {
    fontSize: theme.typography.pxToRem(13),
    paddingRight: '26px',
    color: theme.palette.type === 'light' && theme.palette.primary.main,
  },
  hint: {
    '&::placeholder': {
      color: theme.palette.type === 'light' && theme.palette.primary.main,
      opacity: '1',
      overflow: 'visible',
    },
  },
}));
