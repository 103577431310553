import React from 'react';
import { useTranslation } from 'react-i18next';
import handleUser from './HandleUser';
import UserAvatar from './Avatar';
import UserMenu from './Menu';
// import { StyledIconButton } from '../../buttons';

import '@arc-web/components/dist/components/icon-button/arc-icon-button.js';
import StyledIconButton from '../buttons/StyledIconButton';

function User() {
  const { t } = useTranslation();
  const { anchorEl, userLogout, userLogin, handleMenu, handleClose, navigate } = handleUser();

  return (
    <>
      <StyledIconButton
        title={t('header:manageAccount')}
        data-testid="userAvatar"
        color="inherit"
        onClick={handleMenu}
        aria-label={t('header:manageAccount')}
        icon={<UserAvatar />}
        data-cy="manage-account-icon"
      />
      <UserMenu
        anchorEl={anchorEl}
        userLogout={userLogout}
        navigate={navigate}
        handleClose={handleClose}
        userLogin={userLogin}
      />
    </>
  );
}

export default User;
