import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  avatar: {
    height: 80,
    width: 80,
    padding: 0,
    margin: '6.5px 0',
    position: 'relative',
  },
  subheader: {
    paddingLeft: theme.spacing(3),
  },
  title: {
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    marginRight: theme.spacing(8),
    fontSize: '20px',
  },
  editHeader: { backgroundColor: theme.palette.background.edit },
  editTitle: {
    paddingLeft: theme.spacing(3),
  },
  skeletonChip: {
    borderRadius: '16px!important',
  },
  progress: {
    padding: theme.spacing(1.5),
  },
  buttonGroup: {
    marginTop: theme.spacing(2.5),
  },
}));
