import {
  formatInternationalizedValue,
  formatValueUnit,
  formatRelativeDate,
} from '../../../../utils';

export default function formatParameterInfo(parameter, i18n) {
  const status = parameter?.revision?.status || 'unanswered';
  const values = parameter?.revision?.values;
  const createdAt = parameter?.revision?.createdAt;
  const createdBy = parameter?.revision?.createdBy;
  const name = parameter?.parameterType?.name || parameter?.parameter_type.name;
  const parents = parameter?.parents;

  const currValue = values?.[0];
  const value = currValue?.value || (currValue?.value === false ? 'false' : '');
  const internationalizedValue =
    parameter?.parameter_type?.dataType === 'number' &&
    typeof value === 'number'
      ? formatInternationalizedValue(value, i18n.language)
      : value;
  const units = currValue?.unit?.name || '';
  const valueWithUnit = formatValueUnit(
    internationalizedValue,
    units,
    i18n.language
  );
  const assetName = parents?.[0]?.name || '';
  const staffName = createdBy?.staffName || '';
  const isValid = parameter?.revision?.valid?.isValid;
  const hideWarning = typeof isValid !== 'undefined' ? isValid : true;
  const staffNameWithDate = `${staffName} ${formatRelativeDate(
    createdAt,
    undefined,
    i18n.language
  )}`;

  return {
    id: parameter.id,
    status,
    name,
    hideWarning,
    internationalizedValue,
    units,
    valueWithUnit,
    assetName,
    staffName,
    createdAt,
    staffNameWithDate,
  };
}
