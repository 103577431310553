import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';

const FabButton = withStyles(theme => ({
  root: {
    backgroundColor:
      theme.palette.type !== 'dark' && theme.palette.primary.main,
    color: theme.palette.type !== 'dark' && theme.palette.primary.contrastText,
    margin: theme.spacing(0.95),
    '&:hover': {
      backgroundColor:
        theme.palette.type !== 'dark' && theme.palette.primary.main,
      color:
        theme.palette.type !== 'dark' && theme.palette.primary.contrastText,
    },
  },
}))(Fab);

export default FabButton;
