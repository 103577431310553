/* eslint-disable no-console */
import { PublicClientApplication, LogLevel } from '@azure/msal-browser';

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you are a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

const msalConfig = {
  auth: {
    // clientId: '51e2bcd2-cd52-4ff0-9140-4430f860a417',
    clientId: 'b6227b7d-91a1-45da-a1a6-328c26105f68', // PDSP client id
    authority:
      'https://login.microsoftonline.com/4ae48b41-0137-4599-8661-fc641fe77bea/',
    validateAuthority: true,
    navigateToLoginRequestUrl: false,
    redirectUri: `${window.location.origin}/auth.html`,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
    secureCookies: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        // eslint-disable-next-line default-case
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          // case LogLevel.Info:
          //   console.info(message);
          //   return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
        }
      },
      piiLoggingEnabled: false,
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
    asyncPopups: false,
  },
};

// export const loginRequest = {
//   scopes: ['User.Read'],
// };

// export const ddbTokenRequest = {
//   scopes: ['api://ddb-api/user_impersonation'],
// };

export const loginRequest = {
  scopes: ['https://graph.microsoft.com/.default'],
};

export const ddbTokenRequest = {
  scopes: ['api://ddb-api/.default'],
};

export const powerbiTokenRequest = {
  scopes: ['https://analysis.windows.net/powerbi/api/Report.Read.All'],
};

const msalInstance = new PublicClientApplication(msalConfig);

export default msalInstance;
