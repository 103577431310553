import PT from 'prop-types';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import Castle from 'mdi-material-ui/Castle';
import Skeleton from '@material-ui/lab/Skeleton';
import useStyles from './no-image-styles';
import config from '../../../../config';

function NoImage({ loading, ...rest }) {
  const classes = useStyles();
  const { t } = useTranslation(['projects'], { useSuspense: false });
  return (
    <CardMedia title="noImage" className={classes.media} {...rest}>
      {loading ? (
        <Skeleton
          variant="rect"
          width="100%"
          height="100%"
          data-testid="loading"
        />
      ) : (
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.root}
          data-testid="noImage"
        >
          <Grid item>
            {config.env === 'ut' ? (
              <Castle className={classes.icon} />
            ) : (
              <CameraAltIcon color="disabled" />
            )}
          </Grid>
          {config.env !== 'ut' && (
            <Grid item>
              <Typography className={classes.text}>
                {t('projects:noProjectImage')}
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    </CardMedia>
  );
}

NoImage.propTypes = {
  loading: PT.bool.isRequired,
};

export default NoImage;
