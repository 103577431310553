import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  drawer: {
    flexShrink: 0,
    width: props => props.width,
  },
  drawerPaper: { width: props => props.width },
  drawerOpen: {
    width: props => props.width,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
  },
  drawerContent: {
    overflowY: 'auto',
    height: `calc(100vh - 56px - 56px)`,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: `calc(100vh - 48px - 56px)`,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100vh - 64px - 56px)`,
    },
  },
  drawerContentTemp: {
    overflowY: 'auto',
    height: `calc(100vh - 56px)`,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: `calc(100vh - 48px)`,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100vh - 48px)`,
    },
  },
}));
