import { useState } from 'react';

export default () => {
  const mockTagTypes = [
    { id: '1', name: 'client' },
    { id: '2', name: 'code' },
    { id: '3', name: 'location' },
    { id: '4', name: 'process' },
    { id: '5', name: 'scope' },
    { id: '6', name: 'sector' },
    { id: '7', name: 'skill' },
    { id: '8', name: 'workstage' },
  ];

  const [mockData, setMockData] = useState([
    { id: '1a', name: 'UK', tag_type: mockTagTypes[2] },
    { id: '2a', name: 'Sector 101', tag_type: mockTagTypes[5] },
    { id: '3a', name: 'Arup', tag_type: mockTagTypes[0] },
    { id: '4a', name: 'Spain', tag_type: mockTagTypes[2] },
    { id: '5a', name: 'Stage 5', tag_type: mockTagTypes[7] },
  ]);

  const onChipClick = () => {
    // functionality in here for viewing hierarchy of tags
  };

  const addTag = () => {
    // functionality in here to add a new tag to an entry
  };

  const deleteTag = () => {
    // functionality in here to delete a tag from an entry
  };

  return {
    mockTagTypes,
    mockData,
    setMockData,
    onChipClick,
    addTag,
    deleteTag,
  };
};
