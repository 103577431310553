import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserInformation } from '../api/graph';
import Button from '@material-ui/core/Button';

export default function ManageAccount() {
    const navigate = useNavigate();
    const [refreshTokenExist, setRefreshTokenExist] = useState();
    const [speckleRefreshTokenExist, setSpeckleRefreshTokenExist] = useState();

    const HEADERS = {
        'Authorization': 'GAue4ZNVIC1MCuBbBINxpdxG0Wj5Sxr5EEr5wwU2', 
        'Content-Type': 'application/json'
    }
    const URL = 'https://3itzmwbrk5.execute-api.ap-southeast-1.amazonaws.com/prod';

    useEffect(() => {
        const code = new URLSearchParams(window.location.search).get("code");
        if (code) {
            initAuthCodeFlowToken(code);
        }
        const access_code = new URLSearchParams(window.location.search).get("access_code");
        if (access_code) {
            initSpeckleAuthCodeFlowToken(access_code);
        }
    }, []);

    useEffect(() => {
        checksetRefreshTokenExist();
    }, []);
    
    const initAuthCodeFlow = () => {
        
        const requestObject = {
            method:'GET' ,
            headers: HEADERS
        }
    
        fetch(URL, requestObject)
        .then(res => res.json())
        .then(res => {
            if (res.body.message === 'success') {
                window.location.href = res.body.url;
            }
        })     
    }

    const initAuthCodeFlowToken = (code) => {
        const data = {
            'code': code
        };

        const requestObject = {
            method:'POST' ,
            headers: HEADERS,
            body: JSON.stringify(data)
        }
    
        fetch(URL, requestObject)
        .then(res => res.json())
        .then(res => {
            navigate('/manage-account');
            checksetRefreshTokenExist();
        })     
    }

    const revokeAuthCodeFlow = () => {
        getUserInformation()
        .then(username => {
            const data = {
                'username': username.userPrincipalName
            };
    
            const requestObject = {
                method:'DELETE' ,
                headers: HEADERS,
                body: JSON.stringify(data)
            }
        
            fetch(URL, requestObject)
            .then(checksetRefreshTokenExist)
        })
    }

    const URL_speckle = 'https://dyim3zusjc.execute-api.ap-southeast-1.amazonaws.com/prod/';

    const initSpeckleAuthCodeFlow = () => {
        const requestObject = {
            method:'GET' ,
            headers: HEADERS
        }
    
        fetch(URL_speckle, requestObject)
        .then(res => res.json())
        .then(res => {
            if (res.body.message === 'success') {
                // store challenge
                const challenge = res.body.url.substring(res.body.url.lastIndexOf('/') + 1);
                localStorage.setItem("CHALLENGE", challenge);
                // REDIRECT
                window.location.href = res.body.url;
            }
        })     
    }

    const initSpeckleAuthCodeFlowToken = (code) => {
        const challenge = localStorage.getItem("CHALLENGE");
        const data = {
            'code': code,
            'challenge': challenge
        };

        const requestObject = {
            method:'POST' ,
            headers: HEADERS,
            body: JSON.stringify(data)
        }
    
        fetch(URL_speckle, requestObject)
        .then(res => res.json())
        .then(res => {
            navigate('/manage-account');
            checksetRefreshTokenExist();
        })     
    }

    const revokeSpeckleAuthCodeFlow = () => {
        getUserInformation()
        .then(username => {
            const data = {
                'username': username.userPrincipalName
            };
    
            const requestObject = {
                method:'DELETE' ,
                headers: HEADERS,
                body: JSON.stringify(data)
            }
        
            fetch(URL_speckle, requestObject)
            .then(checksetRefreshTokenExist)
        })
    }


    const checksetRefreshTokenExist = () => {
        getUserInformation()
        .then(checkuserNameExist)
        .then(res => {
            setRefreshTokenExist(res['ddb_exist']);
            setSpeckleRefreshTokenExist(res['speckle_exist']);
        });
    }

    const checkuserNameExist = (username) => {
        const data = {
            'username': username.userPrincipalName
        };

        const requestObject = {
            method:'POST' ,
            headers: HEADERS,
            body: JSON.stringify(data)
        }
    
        return fetch('https://libn6hgfr7.execute-api.ap-southeast-1.amazonaws.com/prod', requestObject)
        .then(res => res.json())
        .then(res => {
            return res.body;
        })
    }

    return (
        <div className="App">
            <div className="body">
                
                <div className="title">
                    <h2>Automation Settings</h2>
                    <p>
                        The Structural Data Platform can automatically processes your uploaded models and Speckle data.<br/>
                         The results are stored in the Digital Design Brief.<br/>
                        <a href="https://arupapc-my.sharepoint.com/:p:/g/personal/jason_chen_arup_com/EXgPyva0eIBChSiPvRI6HxoB3WybMOFuvscGjiqVGc_HIg?e=J3wA7a" target="_blank">
                            Click here to find out more.
                        </a>
                    </p>
                    <img src="/assets/workflow.png" width="800" />
                    <br/>

                    <h2>Enable Automation</h2>
                    <p>
                    Give consent to this platform to automate tasks on your behalf.
                    <br/>
                    This allows this platform to connect to your Digital Design Brief and Speckle accounts.
                    <br/>
                    You can revoke this anytime.
                    </p>
                    {
                        speckleRefreshTokenExist ?
                        <Button variant="contained"
                        style={{
                            backgroundColor: "tomato",
                        }}
                        onClick={revokeSpeckleAuthCodeFlow}>Revoke Speckle</Button>
                        :
                        <Button variant="contained" onClick={initSpeckleAuthCodeFlow}>Approve Speckle</Button>
                    }
                    <br/><br/>
                    {
                        refreshTokenExist ?
                        <Button variant="contained"
                        style={{
                            backgroundColor: "tomato",
                        }}
                        onClick={revokeAuthCodeFlow}>Revoke Digital Design Brief</Button>
                        :
                        <Button variant="contained" onClick={initAuthCodeFlow}>Approve Digital Design Brief</Button>
                    }
                </div> 
            </div>
        </div>
    );
}
    