import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
    menuItem: {
        
    },
    menuText: {
        paddingLeft: 16,
    }
}));
