/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-depth */
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import { useImmer } from 'use-immer';
import PT from 'prop-types';
import { isEmpty, findIndex } from 'lodash';
import { camelCaseKeys } from '../utils';
import { parametersApi } from '../api';

export const ParametersContext = React.createContext();

export const ParametersProvider = ({ children }) => {
  const { search } = useLocation();
  const { projectId } = useParams();

  const [parameters, setParameters] = useImmer([]);
  const [hasMoreParameters, setHasMoreParameters] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [searchParams, setSearchParams] = useSearchParams({
    order: 'asc',
    sort_by: 'parameter_type_name',
  });
  const pageLimit = useRef(200);

  const getParameters = async ({ startIndex }) => {
    if (startIndex === 0) setLoading(true);
    const source = axios.CancelToken.source();
    const { parameter_id: parameterId, ...parsedSearch } = qs.parse(
      searchParams.toString()
    );
    
    try {
      const { parameters: response } = await parametersApi(
        'getAllParameters',
        {
          ...parsedSearch,
          project_id: projectId,
          page_limit: pageLimit.current,
          offset: startIndex,
        },
        source.token
      );

      if (response) {
        const camelCaseParameters = response.map(parameter => {
          return camelCaseKeys(parameter);
        });
        if (startIndex === 0) {
          setParameters(camelCaseParameters);
        } else {
          setParameters(currentParameters => [
            ...currentParameters,
            ...camelCaseParameters,
          ]);
        }
        setHasMoreParameters(camelCaseParameters.length >= pageLimit.current);
        setLoading(false);
      }
    } catch (err) {
      setError(err.response.data);
      setLoading(false);
    }
  };
  
  const updateParametersList = (editedPar, parameterId) => {
    const camelCaseParameter = camelCaseKeys(editedPar);
    const index = findIndex(parameters, par => par.id === parameterId);
    setParameters(currPars => {
      currPars[index] = camelCaseParameter; // eslint-disable-line no-param-reassign
    });
  };

  // useEffect(() => {
  //   if (!searchParams.get('parameter_id') || isEmpty(parameters)) {
  //     getParameters({ startIndex: 0 });
  //   }
  // }, [searchParams]);

  useEffect(() => {
    if (searchParams.get('asset_id') && !searchParams.get('parameter_id')) {
      getParameters({ startIndex: 0 });
    } else {
      setLoading(false);
    }
  }, [searchParams]);

  // useEffect(() => {
  //   if (searchParams.get('sdp_id') && !searchParams.get('model_id')) {
  //     console.log('parameter context triggered')
  //     getParameters({ startIndex: 0 });
  //   } else {
  //     setLoading(false);
  //   }
  // }, [searchParams]);

  // useEffect(() => {
  //   const parsedSearch = qs.parse(search, { ignoreQueryPrefix: true });
  //   const { sort_by: sortBy, order } = { ...parsedSearch };

  //   const addSortDefaults = () => {
  //     const defaults = {};
  //     if (!order) defaults.order = searchParams.get('order');
  //     if (!sortBy) defaults.sort_by = searchParams.get('sort_by');
  //     if (!isEmpty(defaults)) setSearchParams(defaults, { replace: true });
  //   };

  //   addSortDefaults();

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <ParametersContext.Provider
      value={{
        parameters,
        setParameters,
        pageLimit: pageLimit.current,
        loading,
        hasMoreParameters,
        getParameters,
        error,
        searchParams,
        setSearchParams,
        updateParametersList,
      }}
    >
      {children}
    </ParametersContext.Provider>
  );
};

ParametersProvider.propTypes = {
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]).isRequired,
};
