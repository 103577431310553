// todo: update and remove in refactor
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-undef */
/* eslint-disable import/named */
import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import DataDrawerGrid from '../grid';
import Tags from '../tags';
import DisciplineTags from '../discipline-tags';
import Source from '../source';
import History from '../history';
import Status from '../status';
import config from '../../../config';
import Entries from '../multiple-entries/entries';
import EntriesTags from '../multiple-entries/tags';
import useStyles from './data-drawer-content-styles';
import useTagTypes from './hooks';
import useMultipleEntriesTags from '../multiple-entries/tags/hooks';
import formatParameterInfo from '../../../routes/parameters-page/parameter-list/helper';
import { parameterType } from '../../../types';
import Value from '../value';

function Content(props) {
  const { parameter, editMode, setOpenModal, toggleOpenAddEntriesWizard } =
    props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { tagTypes, tagTypesLoading } = useTagTypes();
  const { mockTagTypes } = useMultipleEntriesTags();

  const { id, source, internationalizedValue, valueWithUnit, units } =
    formatParameterInfo(parameter, i18n);
  return (
    <Grid
      item
      className={clsx([classes.grow, classes.widerMargin, classes.content])}
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
    >
      {/* <Value
        internationalizedValue={units}
        valueWithUnit={units}
      /> */}
      {/* {!config.featureFlags.isMultipleEntriesEnabled && (
        <Source source={source} />
      )} */}

{!config.featureFlags.isMultipleEntriesEnabled ? (
        <DataDrawerGrid
          icon={
            <LocalOfferIcon className={editMode ? classes.disabled : null} />
          }
          caption=""
          content={
            <Grid>
              <Typography variant="caption" color="textSecondary">
                {t('Context (for Structural Data Platform)')}
              </Typography>
              {tagTypesLoading || !id ? (
                <>
                  <Skeleton width={200} height={30} />
                  <Skeleton width={200} height={30} />
                  <Skeleton width={200} height={30} />
                  <Skeleton width={200} height={30} />
                </>
              ) : (
                <>
                  {/* {tagTypes.map(tag => (
                    <Tags
                      key={tag.id}
                      parameterId={id}
                      disabled={false}
                      tagType={tag}
                      tagTypesLoading={tagTypesLoading}
                    />
                  ))} */}
                  <DisciplineTags
                    parameterId={id}
                    tagTypesLoading={tagTypesLoading}
                    disabled={false}
                  />
                </>
              )}
            </Grid>
          }
        />
      ) : (
        <DataDrawerGrid
          icon={
            <LocalOfferIcon className={editMode ? classes.disabled : null} />
          }
          caption=""
          content={
            <Grid container>
              <Typography variant="caption" color="textSecondary">
                {t('tags')}
              </Typography>
              <>
                {mockTagTypes.map(tagType => (
                  <EntriesTags tagType={tagType} key={tagType.id} />
                ))}
              </>
            </Grid>
          }
        />
      )}

      {/* {!config.featureFlags.isMultipleEntriesEnabled && (
        <History parameter={parameter} disabled={editMode} />
      )} */}
      {/* {!config.featureFlags.isMultipleEntriesEnabled && (
        <Status parameter={parameter} disabled={editMode} />
      )} */}

      {config.featureFlags.isMultipleEntriesEnabled && (
        <Entries
          parameter={parameter}
          setOpenModal={setOpenModal}
          toggleOpenAddEntriesWizard={toggleOpenAddEntriesWizard}
        />
      )}

      
    </Grid>
  );
}

Content.propTypes = {
  parameter: parameterType.isRequired,
  editMode: PT.bool.isRequired,
  setOpenModal: PT.func.isRequired,
  toggleOpenAddEntriesWizard: PT.func.isRequired,
};

export default Content;
