import PT from 'prop-types';
import ListIcon from '@material-ui/icons/List';
import { useTranslation } from 'react-i18next';
import { ParameterCard } from '../../../../../../components/cards';
import EmptyState from '../../../../../../components/empty-state';
import useStyles from './add-parameter-list-styles';
import { parameterType } from '../../../../../../types';
import formatParameterInfo from '../../../helper';

function AddParameterList({
  selectedParameter,
  parameters,
  loading,
  switchParameter,
}) {
  const classes = useStyles();
  const { t, i18n } = useTranslation(['parameters']);
  return (
    <div className={classes.root}>
      <div className={classes.body}>
        {parameters.length ? (
          parameters.map(parameter => {
            const updatedParameter = formatParameterInfo(parameter, i18n);
            return (
              <ParameterCard
                key={parameter.id}
                loading={loading}
                parameter={updatedParameter}
                handleClick={() => switchParameter(parameter)}
                selected={selectedParameter?.id === parameter.id}
              />
            );
          })
        ) : (
          <EmptyState
            Icon={ListIcon}
            title={t('parameters:noParams')}
            subtitle={t('parameters:tryNewParam')}
          />
        )}
      </div>
    </div>
  );
}

AddParameterList.propTypes = {
  selectedParameter: PT.shape({ id: PT.string.isRequired }),
  parameters: PT.arrayOf(parameterType).isRequired,
  switchParameter: PT.func.isRequired,
  loading: PT.bool.isRequired,
};

AddParameterList.defaultProps = {
  selectedParameter: {},
};

export default AddParameterList;
