import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  table: {
    // Margins added to avoid the cards margin
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    marginBottom: theme.spacing(-3),
    flexGrow: 1,
    overflowX: 'scroll',
    overflowY: 'hidden',
    display: 'flex',
  },
}));
