import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ToolTip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import useStyles from './navigation-menu-styles';

export default function NavigationMenu({
  drawerOpen,
  navigationList,
  navigationListTitle,
  toggleDrawerOpen,
}) {
  const classes = useStyles({ drawerOpen });
  const { t } = useTranslation(['user', 'settings', 'parameters']);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <div className={classes.title} data-testid="navigationMenu">
        {drawerOpen ? (
          <Typography variant="h6">
            {t(navigationListTitle).toUpperCase()}
          </Typography>
        ) : (
          <ToolTip title={t(navigationListTitle)} data-testid="tooltip">
            <Avatar variant="rounded" data-testid="avatar">
              {t(navigationListTitle)
                .match(/\b(\w)/g)
                .slice(0, 2)}
            </Avatar>
          </ToolTip>
        )}
      </div>
      <Divider light />
      <List className={classes.navList}>
        {navigationList.map(
          page =>
            page.userAccess && (
              <ListItem
                data-testid="navigationMenuListItem"
                key={page.path}
                data-cy={`nav-menu-${t(page.text)}`}
                button
                selected={pathname === page.path}
                onClick={() => {
                  navigate(page.path);
                  if (drawerOpen) toggleDrawerOpen();
                }}
              >
                {drawerOpen ? (
                  <ListItemText primary={t(page.text)} />
                ) : (
                  <ToolTip title={t(page.text)}>{page.icon}</ToolTip>
                )}
              </ListItem>
            )
        )}
      </List>
    </>
  );
}

NavigationMenu.propTypes = {
  toggleDrawerOpen: PT.func.isRequired,
  drawerOpen: PT.bool.isRequired,
  navigationListTitle: PT.string.isRequired,
  navigationList: PT.arrayOf(
    PT.shape({
      path: PT.string.isRequired,
      'data-cy': PT.string,
      text: PT.string.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      icon: PT.any.isRequired,
      userAccess: PT.bool.isRequired,
    })
  ).isRequired,
};
