import React, { useState, useEffect } from 'react';
import YearPicker from "react-year-picker"
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import ClipLoader from "react-spinners/ClipLoader";
import {Link, useNavigate} from 'react-router-dom';
import { getUserInformation } from '../api/graph';
import './Upload.css';

const Upload = () => {

    const [email, setEmail] = useState();
    const [DefaultCountry, SetDefaultCountry] = useState();
    const [DefaultProj, SetDefaultProj] = useState();
    const [DefaultProjNo, SetDefaultProjNo] = useState();
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [formInputs, setFormInputs] = useState({});
    const [yearInput, setYearInput] = useState(null);
    const [countryInput, setCountryInput] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const url = window.location.href;
    var pos = url.search("edit=");
    var PKey = "";
    var EditFlag=false;
    var TargetRecord = [];
    if (pos > -1){
        PKey=url.substring(pos+5,url.length);
        EditFlag=true;
    }
    // console.log(EditFlag);

    const handleFileInput = (e) => setSelectedFiles(e.target.files);

    const uploadFile = (files) => {
        // console.log(files);

        document.getElementById("submitButtonId").innerHTML = 'Loading';
        document.getElementById("submitButtonId").setAttribute("disabled","disabled");
        setLoading(true)

        if (!files || files.length < 1) {
            setLoading(false)
            alert('upload a model');
            document.getElementById("submitButtonId").innerHTML = 'Submit';
            document.getElementById("submitButtonId").removeAttribute("disabled");
            return;
        }
        
        const requestObject = {
            method:'POST' ,
            body: JSON.stringify({
                fileNames: Array.from(files).map(x => x.name),
                formInputs: formInputs
            }),
            headers: {
                'Authorization': 'vVxkP3Bkx25g8WrOwOhowhEhuRKzjYJ5IBJavPGc', 
                'Content-Type': 'application/json'
            },
        }

        fetch('https://506uvi5ks1.execute-api.ap-southeast-1.amazonaws.com/prod', requestObject)
        .then(res => res.json())
        .then(res => {
            // console.log(res.signedUrls);
            
            if (res.message === "succeed") {
                const signUrls = res.signedUrls.map((x, i) => {
                    return fetch(x.url, {
                        method: 'PUT',
                        body: files[i],
                        headers: {
                            'Content-Type': files[i].name.split('.').pop()
                        },
                    })
                    .then(res => res.text());
                })
                Promise.all(signUrls)
                .then(res => {
                    // console.log(res)
                    console.log("done");
                    window.location.reload(false);
                    alert("Upload Complete")
                    return;
                })
            }
        })
    }
    const getRecord = (PKey) => {
        const requestObject = {
            method:'POST' ,
            body: JSON.stringify({"uniqueId": PKey}),
            headers: {
                'Authorization': 'vVxkP3Bkx25g8WrOwOhowhEhuRKzjYJ5IBJavPGc', 
                'Content-Type': 'application/json'
            },
            
        }
        fetch('https://pddsnll2w5.execute-api.ap-southeast-1.amazonaws.com/prod', requestObject)
            .then(res => res.json())
            .then(data => {
                const items = data && data.data && data.data.Items;
                if (!items) return
                TargetRecord=JSON.parse(JSON.stringify(data.data.Items)).filter(function(entry){
                    return entry.uniqueId === PKey;
                });
                console.log(TargetRecord[0]);
                if (typeof TargetRecord[0] === "undefined"){
                    cancelClick();
                    return
                } 
                setEmail(TargetRecord[0].info.email);
                // Set default value
                var newFormInputs = formInputs;
                if (newFormInputs === null) {
                    newFormInputs = {}
                }
                newFormInputs["uniqueId"] = PKey;
                newFormInputs["datetimeUTC"] = TargetRecord[0].datetimeUTC;
                SetDefaultCountry(TargetRecord[0].info.projectcountry);
                newFormInputs["projectcountry"]=TargetRecord[0].info.projectcountry;
                SetDefaultProj(TargetRecord[0].info.projectname);
                newFormInputs["projectname"] = TargetRecord[0].info.projectname;
                newFormInputs["jobnumber"] = TargetRecord[0].info.jobnumber;
                SetDefaultProjNo(TargetRecord[0].info.jobnumber);
                // Building
                if ((TargetRecord[0].info.ModelTypeBuilding) || (TargetRecord[0].info.Usage_Residential)||(TargetRecord[0].info.Usage_Commercial) || (TargetRecord[0].info.Usage_Office) || (TargetRecord[0].info.Usage_Retail) || (TargetRecord[0].info.Usage_Leisure) || (TargetRecord[0].info.Usage_Hotel) || (TargetRecord[0].info.Usage_Industrial) || (TargetRecord[0].info.Usage_Governmental) || (TargetRecord[0].info.Usage_Educational)){
                    document.getElementById("ModelTypeBuildingId").checked = true;
                    document.getElementById('SectionBuildingId').style.display = 'block';
                    newFormInputs["ModelTypeBuilding"] = true;
                    // Building usage
                    var tempstring="";
                    if (TargetRecord[0].info.Usage_Residential){
                        document.getElementById("Usage_Residential").checked = true;
                        tempstring="Usage_Residential";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.Usage_Commercial){
                        document.getElementById("Usage_Commercial").checked = true;
                        tempstring="Usage_Commercial";
                        newFormInputs[tempstring]=true;
                    }   
                    if (TargetRecord[0].info.Usage_Office){
                        document.getElementById("Usage_Office").checked = true;
                        tempstring="Usage_Office";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.Usage_Retail){
                        document.getElementById("Usage_Retail").checked = true;
                        tempstring="Usage_Retail";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.Usage_Leisure){
                        document.getElementById("Usage_Leisure").checked = true;
                        tempstring="Usage_Leisure";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.Usage_Hotel){
                        document.getElementById("Usage_Hotel").checked = true;
                        tempstring="Usage_Hotel";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.Usage_Industrial){
                        document.getElementById("Usage_Industrial").checked = true;
                        tempstring="Usage_Industrial";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.Usage_Governmental){
                        document.getElementById("Usage_Governmental").checked = true;
                        tempstring="Usage_Governmental";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.Usage_Educational){
                        document.getElementById("Usage_Educational").checked = true;
                        tempstring="Usage_Educational";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.Usage_Other){
                        document.getElementById("Usage_Other").checked = true;
                        document.getElementById("OtherUsageTextId").defaultValue = TargetRecord[0].info.OtherUsageText;
                        newFormInputs["Usage_Other"]=true;
                        newFormInputs["OtherUsageText"]=TargetRecord[0].info.OtherUsageText;
                    }
                    else
                        delete formInputs["OtherUsageText"];
                    // Gravity system
                    tempstring="";
                    if (TargetRecord[0].info.GravSystem_Composite){
                        document.getElementById("GravSystem_Composite").checked=true;
                        tempstring="GravSystem_Composite";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.GravSystem_SlabBeam){
                        document.getElementById("GravSystem_SlabBeam").checked=true;
                        tempstring="GravSystem_SlabBeam";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.GravSystem_SlabColumn){
                        document.getElementById("GravSystem_SlabColumn").checked=true;
                        tempstring="GravSystem_SlabColumn";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.GravSystem_SlabPT){
                        document.getElementById("GravSystem_SlabPT").checked=true;
                        tempstring="GravSystem_SlabPT";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.GravSystem_BeamPT){
                        document.getElementById("GravSystem_BeamPT").checked=true;
                        tempstring="GravSystem_BeamPT";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.GravSystem_Other){
                        document.getElementById("GravSystem_Other").checked=true;
                        document.getElementById("OtherGravSystemText").defaultValue=TargetRecord[0].info.OtherGravSystemText;
                        newFormInputs["GravSystem_Other"]=true;
                        newFormInputs["OtherGravSystemText"]=TargetRecord[0].info.OtherGravSystemText;
                    }
                    else
                        delete formInputs["OtherGravSystemText"];
                    // Lateral system
                    tempstring="";
                    if (TargetRecord[0].info.LatSystem_RCMomentFrame){
                        document.getElementById("LatSystem_RCMomentFrame").checked=true;
                        tempstring="LatSystem_RCMomentFrame";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.LatSystem_RCShearwall){
                        document.getElementById("LatSystem_RCShearwall").checked=true;
                        tempstring="LatSystem_RCShearwall";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.LatSystem_RCCore){
                        document.getElementById("LatSystem_RCCore").checked=true;
                        tempstring="LatSystem_RCCore";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.LatSystem_SteelMomentFrame){
                        document.getElementById("LatSystem_SteelMomentFrame").checked=true;
                        tempstring="LatSystem_SteelMomentFrame";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.LatSystem_SteelBracedFrame){
                        document.getElementById("LatSystem_SteelBracedFrame").checked=true;
                        tempstring="LatSystem_SteelBracedFrame";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.OtherLatSystemText){
                        document.getElementById("LatSystem_Other").checked=true;
                        document.getElementById("OtherLatSystemText").defaultValue=TargetRecord[0].info.OtherLatSystemText;
                        newFormInputs["LatSystem_Other"]=true;
                        newFormInputs["OtherLatSystemText"]=TargetRecord[0].info.OtherLatSystemText;
                    }
                    else
                        delete formInputs["OtherLatSystemText"];
                }
                // Bridge.
                tempstring="";
                if (TargetRecord[0].info.ModelTypeBridge){
                    document.getElementById("ModelTypeBridgeId").checked = true;
                    document.getElementById('SectionBridgeId').style.display = 'block';
                    newFormInputs["ModelTypeBridge"] = true;
                    // Bridge usage
                    if (TargetRecord[0].info.BridgeUsage_Road){
                        document.getElementById("BridgeUsage_Road").checked=true;
                        tempstring="BridgeUsage_Road";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.BridgeUsage_Foot){
                        document.getElementById("BridgeUsage_Foot").checked=true;
                        tempstring="BridgeUsage_Foot";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.BridgeUsage_Rail){
                        document.getElementById("BridgeUsage_Rail").checked=true;
                        tempstring="BridgeUsage_Rail";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.BridgeUsage_CanalAqueduct){
                        document.getElementById("BridgeUsage_CanalAqueduct").checked=true;
                        tempstring="BridgeUsage_CanalAqueduct";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.BridgeUsage_Other){
                        document.getElementById("BridgeUsage_Other").checked=true;
                        document.getElementById("BridgeUsage_OtherTextId").defaultValue=TargetRecord[0].info.BridgeUsage_OtherText;
                        newFormInputs["BridgeUsage_Other"]=true;
                        newFormInputs["BridgeUsage_OtherText"]=TargetRecord[0].info.BridgeUsage_OtherText;
                    }
                    else
                        delete formInputs["BridgeUsage_OtherText"];
                    // Bridge structural system
                    tempstring="";
                    if (TargetRecord[0].info.BridgeSystem_CableStayed){
                        document.getElementById("BridgeSystem_CableStayed").checked=true;
                        tempstring="BridgeSystem_CableStayed";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.BridgeSystem_AnchoredSuspension){
                        document.getElementById("BridgeSystem_AnchoredSuspension").checked=true;
                        tempstring="BridgeSystem_AnchoredSuspension";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.BridgeSystem_SelfAnchoredSuspension){
                        document.getElementById("BridgeSystem_SelfAnchoredSuspension").checked=true;
                        tempstring="BridgeSystem_SelfAnchoredSuspension";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.BridgeSystem_GirderBridge){
                        document.getElementById("BridgeSystem_GirderBridge").checked=true;
                        tempstring="BridgeSystem_GirderBridge";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.BridgeSystem_Truss){
                        document.getElementById("BridgeSystem_Truss").checked=true;
                        tempstring="BridgeSystem_Truss";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.BridgeSystem_Vierendeel){
                        document.getElementById("BridgeSystem_Vierendeel").checked=true;
                        tempstring="BridgeSystem_Vierendeel";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.BridgeSystem_Arch){
                        document.getElementById("BridgeSystem_Arch").checked=true;
                        tempstring="BridgeSystem_Arch";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.BridgeSystem_ThroughArch){
                        document.getElementById("BridgeSystem_ThroughArch").checked=true;
                        tempstring="BridgeSystem_ThroughArch";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.BridgeSystem_Opening){
                        document.getElementById("BridgeSystem_Opening").checked=true;
                        tempstring="BridgeSystem_Opening";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.BridgeSystem_Floating){
                        document.getElementById("BridgeSystem_Floating").checked=true;
                        tempstring="BridgeSystem_Floating";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.BridgeSystem_OtherText){
                        document.getElementById("BridgeSystem_Other").checked=true;
                        document.getElementById("BridgeSystem_OtherTextId").defaultValue=TargetRecord[0].info.BridgeSystem_OtherText;
                        newFormInputs["BridgeSystem_Other"]=true;
                        newFormInputs["BridgeSystem_OtherText"]=TargetRecord[0].info.BridgeSystem_OtherText;
                    }
                    else
                        delete formInputs["BridgeSystem_OtherText"];
                }
                // Spaceframe
                tempstring="";
                if (TargetRecord[0].info.ModelTypeSpaceframe){
                    document.getElementById("ModelTypeSpaceframeId").checked = true;
                    document.getElementById('SectionSpaceframeId').style.display = 'block';
                    newFormInputs["ModelTypeSpaceframe"] = true;
                    // Spaceframe usage
                    if (TargetRecord[0].info.SpaceframeUsage_LongSpanRoof){
                        document.getElementById("SpaceframeUsage_LongSpanRoof").checked=true;
                        tempstring="SpaceframeUsage_LongSpanRoof";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.SpaceframeUsage_Canopy){
                        document.getElementById("SpaceframeUsage_Canopy").checked=true;
                        tempstring="SpaceframeUsage_Canopy";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.SpaceframeUsage_Skylight){
                        document.getElementById("SpaceframeUsage_Skylight").checked=true;
                        tempstring="SpaceframeUsage_Skylight";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.SpaceframeUsage_Facade){
                        document.getElementById("SpaceframeUsage_Facade").checked=true;
                        tempstring="SpaceframeUsage_Facade";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.SpaceframeUsage_Stadium){
                        document.getElementById("SpaceframeUsage_Stadium").checked=true;
                        tempstring="SpaceframeUsage_Stadium";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.SpaceframeUsage_Other){
                        document.getElementById("SpaceframeUsage_Other").checked=true;
                        document.getElementById("SpaceframeUsage_OtherTextId").defaultValue=TargetRecord[0].info.SpaceframeUsage_OtherText;
                        newFormInputs["SpaceframeUsage_Other"]=true;
                        newFormInputs["SpaceframeUsage_OtherText"]=TargetRecord[0].info.SpaceframeUsage_OtherText;
                    }
                    else
                        delete formInputs["SpaceframeUsage_OtherText"];
                    // Spaceframe structural system
                    tempstring="";
                    if (TargetRecord[0].info.SpaceframeSystem_SpaceFrame){
                        document.getElementById("SpaceframeSystem_SpaceFrame").checked=true;
                        tempstring="SpaceframeSystem_SpaceFrame";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.SpaceframeSystem_DiagridLattice){
                        document.getElementById("SpaceframeSystem_DiagridLattice").checked=true;
                        tempstring="SpaceframeSystem_DiagridLattice";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.SpaceframeSystem_OneWayTruss){
                        document.getElementById("SpaceframeSystem_OneWayTruss").checked=true;
                        tempstring="SpaceframeSystem_OneWayTruss";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.SpaceframeSystem_TwoWayTruss){
                        document.getElementById("SpaceframeSystem_TwoWayTruss").checked=true;
                        tempstring="SpaceframeSystem_TwoWayTruss";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.SpaceframeSystem_SpaceTruss){
                        document.getElementById("SpaceframeSystem_SpaceTruss").checked=true;
                        tempstring="SpaceframeSystem_SpaceTruss";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.SpaceframeSystem_Shell){
                        document.getElementById("SpaceframeSystem_Shell").checked=true;
                        tempstring="SpaceframeSystem_Shell";
                        newFormInputs[tempstring]=true;
                    }
                    if (TargetRecord[0].info.SpaceframeSystem_Other){
                        document.getElementById("SpaceframeSystem_Other").checked=true;
                        document.getElementById("SpaceframeSystem_OtherTextId").defaultValue=TargetRecord[0].info.SpaceframeSystem_OtherText;
                        newFormInputs["SpaceframeSystem_Other"]=true;
                        newFormInputs["SpaceframeSystem_OtherText"]=TargetRecord[0].info.SpaceframeSystem_OtherText;
                    }
                    else
                        delete formInputs["SpaceframeSystem_OtherText"];
                }
                // Other building type
                if (TargetRecord[0].info.ModelTypeOther){
                    document.getElementById("ModelTypeOtherId").checked = true;
                    document.getElementById("ModelTypeOtherTextId").defaultValue=TargetRecord[0].info.ModelTypeOtherText;
                    newFormInputs["ModelTypeOther"]=true;
                    newFormInputs["ModelTypeOtherText"]=TargetRecord[0].info.ModelTypeOtherText;
                }
                tempstring="";
                // Aditional - Design stage
                if (TargetRecord[0].info.DSOpt==="Conceptual"){
                    document.getElementById("Conceptual").checked = true;
                    tempstring="Conceptual";
                }
                if (TargetRecord[0].info.DSOpt==="Schematic"){
                    document.getElementById("Schematic").checked = true;
                    tempstring="Schematic";
                }
                if (TargetRecord[0].info.DSOpt==="DetailedDesign"){
                    document.getElementById("DetailedDesign").checked = true;
                    tempstring="DetailedDesign";
                }
                if (TargetRecord[0].info.DSOpt==="Approvals"){
                    document.getElementById("Approvals").checked = true;
                    tempstring="Approvals";
                }
                if (TargetRecord[0].info.DSOpt==="Construction"){
                    document.getElementById("Construction").checked = true;
                    tempstring="Construction";
                }
                if (TargetRecord[0].info.DSOpt==="AssessmentExistingDesign"){
                    document.getElementById("AssessmentExistingDesign").checked = true;
                    tempstring="AssessmentExistingDesign";
                }
                if (TargetRecord[0].info.DSOpt==="DDOther"){
                    document.getElementById("DSOtherId").checked = true;
                    document.getElementById("DSOptTextId").defaultValue=TargetRecord[0].info.DSOptText;
                    tempstring="DDOther";
                    newFormInputs["DSOptText"]=TargetRecord[0].info.DSOptText;
                }
                else
                    delete formInputs["DSOptText"];
                newFormInputs["DSOpt"] = tempstring;
                // Additional - quality
                
                if (TargetRecord[0].info.QualityOpt === "ValidatedModel"){
                    document.getElementById("ValidatedModel").checked = true;
                    tempstring="ValidatedModel";
                }
                if (TargetRecord[0].info.QualityOpt === "VerifiedModel"){
                    document.getElementById("VerifiedModel").checked = true;
                    tempstring="VerifiedModel";
                }
                    
                if (TargetRecord[0].info.QualityOpt === "StudyModel"){
                    document.getElementById("StudyModel").checked = true;
                    tempstring="StudyModel";
                }       
                if (TargetRecord[0].info.QualityOpt === "UnknownQuality"){
                    document.getElementById("UnknownQuality").checked = true;
                    document.getElementById("UnknownQualityText").defaultValue=TargetRecord[0].info.QualityOptText;
                    tempstring=TargetRecord[0].info.UnknownQualityText;
                    newFormInputs["QualityOptText"]=TargetRecord[0].info.QualityOptText;
                }
                else
                    delete formInputs["QualityOptText"];
                newFormInputs["QualityOpt"] = tempstring;
                // Additional comments
                if (TargetRecord[0].info.additionalComments){
                    document.getElementById("additionalCommentsId").defaultValue=TargetRecord[0].info.additionalComments;
                    newFormInputs["additionalComments"] = TargetRecord[0].info.additionalComments;
                }
                setFormInputs(newFormInputs);       
            });
        }
    const handleInputChange = (event) => {
        const target = event.target;
        const checkVal = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var newFormInputs = formInputs;

        if (newFormInputs === null) {
            newFormInputs = {}
        }
        newFormInputs[name] = checkVal;
        setFormInputs(newFormInputs);

        if (target.id === 'ModelTypeBuildingId') {
            if (checkVal) {
                document.getElementById('SectionBuildingId').style.display = 'block';
            } else {
                document.getElementById('SectionBuildingId').style.display = 'none';
            }
        }
        if (target.id === 'ModelTypeBridgeId') {
            if (checkVal) {
                document.getElementById('SectionBridgeId').style.display = 'block';
            } else {
                document.getElementById('SectionBridgeId').style.display = 'none';
            }
        }
        if (target.id === 'ModelTypeSpaceframeId') {
            if (checkVal) {
                document.getElementById('SectionSpaceframeId').style.display = 'block';
            } else {
                document.getElementById('SectionSpaceframeId').style.display = 'none';
            }
        }
    }

    const handleInputChangeYear = (val) => {
        setYearInput(val);
        var newFormInputs = formInputs;

        if (newFormInputs === null) {
            newFormInputs = {}
        }
        newFormInputs["projectyear"] = val;
        setFormInputs(newFormInputs);
    }
    const handleInputChangeCountry = (val) => {
        setCountryInput(val);
        var newFormInputs = formInputs;

        if (newFormInputs === null) {
            newFormInputs = {}
        }
        newFormInputs["projectcountry"] = val;
        setFormInputs(newFormInputs);
    }
    const cancelClick = () => {
        navigate("/");
        window.location.reload();
    }
    const editForm = (formInputs)=>{
        console.log(formInputs);
        var backupPK=formInputs.uniqueId;
        var backupTime=formInputs.datetimeUTC;
        delete formInputs["uniqueId"];
        delete formInputs["datetimeUTC"];
        document.getElementById("submitButtonId").innerHTML = 'Loading';
        document.getElementById("submitButtonId").setAttribute("disabled","disabled");
        // Submit edit
        setLoading(true)
        const requestObject = {
            method:'POST' ,
            body: JSON.stringify({"uniqueId": backupPK, "datetimeUTC": backupTime, "info": formInputs}),
            headers: {
                'Authorization': 'vVxkP3Bkx25g8WrOwOhowhEhuRKzjYJ5IBJavPGc', 
                'Content-Type': 'application/json'
            },
            
        }
        fetch('https://vvhbj8bv7g.execute-api.ap-southeast-1.amazonaws.com/prod', requestObject)
        .then(res => res.json())
        .then(res => {
            // console.log(res.signedUrls);
            // console.log(res)
            if (res.message === "succeed") {
                console.log("done");
                window.location.reload(false);
                alert("Edit Complete");
                window.location.href ="/";
                return;
            }
        })
    }
    function showButton (EditFlag){
        if(EditFlag){
            document.getElementById('editButtonId').style.display = 'block';
            document.getElementById('cancelButtonId').style.display = 'block';
            document.getElementById('submitButtonId').style.display = 'none';
        }
        else{
            document.getElementById('editButtonId').style.display = 'none';
            document.getElementById('cancelButtonId').style.display = 'none';
            document.getElementById('submitButtonId').style.display = 'block';
        }
    }

    useEffect(() => {
        if (EditFlag){
            getRecord(PKey);
        }
        getUserInformation()
        .then(user => {
            setEmail(user.userPrincipalName);
            var newFormInputs = formInputs;
            if (!newFormInputs) {
                newFormInputs = {}
            }
            newFormInputs["email"] = user.userPrincipalName;
            setFormInputs(newFormInputs);
        });
        showButton(EditFlag);
    }, [])

    return <>
        <h3>General</h3>

        <div className="input"><label>1. Your email<br /><input type="text" name="email" onChange={handleInputChange} defaultValue={email}/></label></div>
        <div className="input"><label>2. Upload model file/s <span className="required">(required)</span><br /><input type="file" id="files" name="files" multiple onChange={handleFileInput} disabled={EditFlag} /></label></div>
        <div className="input"><label>3. Job Number (try your best, but input as "000000" if you don't have one) <span className="required">(required)</span><br/><input type="text" name="jobnumber" onChange={handleInputChange} /></label></div>
        <div className="input"><label>4. Project Name (as best as you can remember)<br /><input type="text" name="projectname" onChange={handleInputChange} defaultValue={DefaultProj} /></label></div>

        <div className="input"><label>5. Project Design Year<br />
        {/* <input type="year" name="projectyear" onChange={handleInputChange} /> */}
        <YearPicker 
        value={yearInput}
        onChange={(val) => handleInputChangeYear(val)}  />
        </label></div>

        <div className="input"><label>6. Project Location<br />
        <CountryDropdown
        value={countryInput}
        defaultOptionLabel={DefaultCountry}
        showDefaultOption = {true}
        //   name="projectcountry"
        onChange={(val) => handleInputChangeCountry(val)} 
        />          
        </label></div>

        {/*  What type of model ??  */}
        <div className="input"><label>7. What type of model is it? (multiple options) <span className="required">(required)</span><br /></label>
            <input type="checkbox" id="ModelTypeBuildingId" name="ModelTypeBuilding" value="ModelTypeBuilding" onChange={handleInputChange} />
            <label for="ModelTypeBuilding">Building</label><br/>
            <input type="checkbox" id="ModelTypeBridgeId" name="ModelTypeBridge" value="ModelTypeBridge" onChange={handleInputChange} />
            <label for="ModelTypeBridge">Bridge</label><br/>
            <input type="checkbox" id="ModelTypeSpaceframeId" name="ModelTypeSpaceframe" value="ModelTypeSpaceframe" onChange={handleInputChange} />
            <label for="ModelTypeSpaceframe">Spaceframe (roofs, canopies, etc.)</label><br/>
            <input type="checkbox" id="ModelTypeOtherId" name="ModelTypeOther" value="ModelTypeOther" onChange={handleInputChange} />
            <input type="text" id = "ModelTypeOtherTextId" name="ModelTypeOtherText" placeholder="Other" onChange={handleInputChange} />
        </div>

        {/*  START Building model type  */}
        <div id="SectionBuildingId" className="section">
            <hr />
            <h3>Building Section</h3>

            <div className="input"><label> Building usages (multiple options)? Optional<br /></label>
                <input type="checkbox" id="Usage_Residential" name="Usage_Residential" value="Residential"  onChange={handleInputChange} />
                <label for="Usage_Residential"> Residential</label><br/>

                <input type="checkbox" id="Usage_Commercial" name="Usage_Commercial" value="Commercial" onChange={handleInputChange} />
                <label for="Usage_Commercial"> Commercial</label><br/>

                <input type="checkbox" id="Usage_Office" name="Usage_Office" value="Office" onChange={handleInputChange} />
                <label for="Usage_Office"> Office</label><br/>

                <input type="checkbox" id="Usage_Retail" name="Usage_Retail" value="Retail" onChange={handleInputChange} />
                <label for="Usage_Retail"> Retail</label><br/>

                <input type="checkbox" id="Usage_Leisure" name="Usage_Leisure" value="Leisure" onChange={handleInputChange} />
                <label for="Usage_Leisure"> Leisure</label><br/>

                <input type="checkbox" id="Usage_Hotel" name="Usage_Hotel" value="Hotel" onChange={handleInputChange} />
                <label for="Usage_Hotel"> Hotel</label><br/> 

                <input type="checkbox" id="Usage_Industrial" name="Usage_Industrial" value="Industrial" onChange={handleInputChange} />
                <label for="Usage_Industrial"> Industrial</label><br/>

                <input type="checkbox" id="Usage_Governmental" name="Usage_Governmental" value="Governmental" onChange={handleInputChange} />
                <label for="Usage_Governmental"> Governmental</label><br/>

                <input type="checkbox" id="Usage_Educational" name="Usage_Educational" value="Educational" onChange={handleInputChange} />
                <label for="Usage_Educational"> Educational</label><br/>

                <input type="checkbox" id="Usage_Other" name="Usage_Other" value="Other" onChange={handleInputChange} />
                <input type="text" id="OtherUsageTextId" name="OtherUsageText" placeholder="Other" onChange={handleInputChange} />            
            </div>

            <div className="input"><label> Structural gravity system (multiple options)? Optional<br /></label>
                <input type="checkbox" id="GravSystem_SlabBeam" name="GravSystem_SlabBeam" value="SlabBeam" onChange={handleInputChange} />
                <label for="GravSystem_SlabBeam"> Slab on beams (1- or 2-way)</label><br/>

                <input type="checkbox" id="GravSystem_SlabColumn" name="GravSystem_SlabColumn" value="SlabColumn" onChange={handleInputChange} />
                <label for="GravSystem_SlabColumn"> Slab on columns (with or without drops)</label><br/>

                <input type="checkbox" id="GravSystem_SlabPT" name="GravSystem_SlabPT" value="SlabPT" onChange={handleInputChange} />
                <label for="GravSystem_SlabPT"> Post Tensioning Slab</label><br/>

                <input type="checkbox" id="GravSystem_BeamPT" name="GravSystem_BeamPT" value="BeamPT" onChange={handleInputChange} />
                <label for="GravSystem_BeamPT"> Post Tensioning Band Beams</label><br/>

                <input type="checkbox" id="GravSystem_Composite" name="GravSystem_Composite" value="Composite" onChange={handleInputChange} />
                <label for="GravSystem_Composite"> Composite (concrete on steel decking)</label><br/>

                <input type="checkbox" id="GravSystem_Other" name="GravSystem_Other" value="Other" onChange={handleInputChange} />
                <input type="text" name="OtherGravSystemText" id="OtherGravSystemText" placeholder="Other" onChange={handleInputChange} />            
            </div>

            <div className="input"><label> Structural lateral system (multiple options)? Optional<br /></label>
                <input type="checkbox" id="LatSystem_RCMomentFrame" name="LatSystem_RCMomentFrame" value="RCMomentFrame" onChange={handleInputChange} />
                <label for="LatSystem_RCMomentFrame"> RC Moment Frame</label><br/>

                <input type="checkbox" id="LatSystem_RCShearwall" name="LatSystem_RCShearwall" value="RCShearwall" onChange={handleInputChange} />
                <label for="LatSystem_RCShearwall"> RC Shear Wall</label><br/>

                <input type="checkbox" id="LatSystem_RCCore" name="LatSystem_RCCore" value="RCCore" onChange={handleInputChange} />
                <label for="LatSystem_RCCore"> RC Core</label><br/>

                <input type="checkbox" id="LatSystem_SteelMomentFrame" name="LatSystem_SteelMomentFrame" value="SteelMomentFrame" onChange={handleInputChange} />
                <label for="LatSystem_SteelMomentFrame"> Steel Moment Frame</label><br/>

                <input type="checkbox" id="LatSystem_SteelBracedFrame" name="LatSystem_SteelBracedFrame" value="SteelBracedFrame" onChange={handleInputChange} />
                <label for="LatSystem_SteelBracedFrame"> Steel Braced Frame</label><br/>

                <input type="checkbox" id="LatSystem_SteelBracedCore" name="LatSystem_SteelBracedCore" value="SteelBracedCore" onChange={handleInputChange} />
                <label for="LatSystem_SteelBracedCore"> Steel Braced Core</label><br/>

                <input type="checkbox" id="LatSystem_SRCColumn" name="LatSystem_SRCColumn" value="SRCColumn" onChange={handleInputChange} />
                <label for="LatSystem_SRCColumn"> SRC Column Frame</label><br/>

                <input type="checkbox" id="LatSystem_CTFColumn" name="LatSystem_CTFColumn" value="CTFColumn" onChange={handleInputChange} />
                <label for="LatSystem_CTFColumn"> CFT Column Frame</label><br/>

                <input type="checkbox" id="LatSystem_TimberMomentFrame" name="LatSystem_TimberMomentFrame" value="TimberMomentFrame" onChange={handleInputChange} />
                <label for="LatSystem_TimberMomentFrame"> Timber Moment Frame</label><br/>

                <input type="checkbox" id="LatSystem_TimberBracedFrame" name="LatSystem_TimberBracedFrame" value="TimberBracedFrame" onChange={handleInputChange} />
                <label for="LatSystem_TimberBracedFrame"> Timber Braced Frame</label><br/>

                <input type="checkbox" id="LatSystem_MegaColumn" name="LatSystem_MegaColumn" value="MegaColumn" onChange={handleInputChange} />
                <label for="LatSystem_MegaColumn"> Mega Column</label><br/>

                <input type="checkbox" id="LatSystem_Outrigger" name="LatSystem_Outrigger" value="Outrigger" onChange={handleInputChange} />
                <label for="LatSystem_Outrigger"> Outrigger</label><br/>

                <input type="checkbox" id="LatSystem_BeltTruss" name="LatSystem_BeltTruss" value="BeltTruss" onChange={handleInputChange} />
                <label for="LatSystem_BeltTruss"> Belt Truss</label><br/>

                <input type="checkbox" id="LatSystem_MegaBrace" name="LatSystem_MegaBrace" value="Megabrace" onChange={handleInputChange} />
                <label for="LatSystem_MegaBrace"> Mega Brace </label><br/>

                <input type="checkbox" id="LatSystem_Diagrid" name="LatSystem_Diagrid" value="Diagrid" onChange={handleInputChange} />
                <label for="LatSystem_Diagrid"> Diagrid </label><br/>

                <input type="checkbox" id="LatSystem_Other" name="LatSystem_Other" value="Other" onChange={handleInputChange} />
                <input type="text" id="OtherLatSystemText" name="OtherLatSystemText" placeholder="Other" onChange={handleInputChange} />
            </div>

        </div> 
        {/*  END Building model type  */}

        {/*  START Bridge model type  */}
        <div id="SectionBridgeId" className="section">  
            <hr />
            <h3>Bridge Section</h3>

            <div className="input"><label>Bridge usages (multiple options)? Optional <br /></label>
                <input type="checkbox" id="BridgeUsage_Road" name="BridgeUsage_Road" value="Road" onChange={handleInputChange} />
                <label for="BridgeUsage_Road"> Road bridge</label><br/>

                <input type="checkbox" id="BridgeUsage_Foot" name="BridgeUsage_Foot" value="Foot" onChange={handleInputChange} />
                <label for="BridgeUsage_Foot"> Foot bridge</label><br/>

                <input type="checkbox" id="BridgeUsage_Rail" name="BridgeUsage_Rail" value="Rail" onChange={handleInputChange} />
                <label for="BridgeUsage_Rail"> Rail bridge</label><br/>

                <input type="checkbox" id="BridgeUsage_CanalAqueduct" name="BridgeUsage_CanalAqueduct" value="CanalAqueduct" onChange={handleInputChange} />
                <label for="BridgeUsage_CanalAqueduct"> Canal bridge / Aqueduct</label><br/>

                <input type="checkbox" id="BridgeUsage_OtherId" name="BridgeUsage_Other" value="Other" onChange={handleInputChange} />
                <input type="text" id="BridgeUsage_OtherTextId" name="BridgeUsage_OtherText" placeholder="Other" onChange={handleInputChange} />            
            </div>

            <div className="input"><label>Bridge structural systems (multiple options)? Optional<br /></label>
                <input type="checkbox" id="BridgeSystem_CableStayed" name="BridgeSystem_CableStayed" value="CableStayed" onChange={handleInputChange} />
                <label for="BridgeSystem_CableStayed"> Cable-stayed bridge</label><br/>

                <input type="checkbox" id="BridgeSystem_AnchoredSuspension" name="BridgeSystem_AnchoredSuspension" value="AnchoredSuspension" onChange={handleInputChange} />
                <label for="BridgeSystem_AnchoredSuspension"> Anchored suspension bridge</label><br/>

                <input type="checkbox" id="BridgeSystem_SelfAnchoredSuspension" name="BridgeSystem_SelfAnchoredSuspension" value="SelfAnchoredSuspension" onChange={handleInputChange} />
                <label for="BridgeSystem_SelfAnchoredSuspension"> Self-anchored suspension bridge</label><br/>

                <input type="checkbox" id="BridgeSystem_GirderBridge" name="BridgeSystem_GirderBridge" value="GirderBridge" onChange={handleInputChange} />
                <label for="BridgeSystem_GirderBridge"> Girder bridge (beam or box, incl PT)</label><br/>

                <input type="checkbox" id="BridgeSystem_Truss" name="BridgeSystem_Truss" value="Truss" onChange={handleInputChange} />
                <label for="BridgeSystem_Truss"> Truss bridge (Warren, N-truss etc)</label><br/>

                <input type="checkbox" id="BridgeSystem_Vierendeel" name="BridgeSystem_Vierendeel" value="Vierendeel" onChange={handleInputChange} />
                <label for="BridgeSystem_Vierendeel"> Vierendeel bridge</label><br/>

                <input type="checkbox" id="BridgeSystem_Arch" name="BridgeSystem_Arch" value="Arch" onChange={handleInputChange} />
                <label for="BridgeSystem_Arch"> Arch bridge</label><br/>

                <input type="checkbox" id="BridgeSystem_ThroughArch" name="BridgeSystem_ThroughArch" value="ThroughArch" onChange={handleInputChange} />
                <label for="BridgeSystem_ThroughArch"> Through-arch bridge</label><br/>

                <input type="checkbox" id="BridgeSystem_Floating" name="BridgeSystem_Floating" value="Floating" onChange={handleInputChange} />
                <label for="BridgeSystem_Floating"> Floating bridge</label><br/>

                <input type="checkbox" id="BridgeSystem_Opening" name="BridgeSystem_Opening" value="Opening" onChange={handleInputChange} />
                <label for="BridgeSystem_Opening"> Opening-bridge</label><br/>

                <input type="checkbox" id="BridgeSystem_Other" name="BridgeSystem_Other" value="Other" onChange={handleInputChange} />
                <input type="text" id="BridgeSystem_OtherTextId" name="BridgeSystem_OtherText" placeholder="Other" onChange={handleInputChange} />            
            </div>

        </div> 
        {/*  END Bridge model type  */}

        {/*  START Spaceframe model type  */}
        <div id="SectionSpaceframeId" className="section">  
            <hr />
            <h3>Spaceframe Section</h3>
            
            <div className="input"><label>Spaceframe usages (multiple options)? Optional<br /></label>
                <input type="checkbox" id="SpaceframeUsage_LongSpanRoof" name="SpaceframeUsage_LongSpanRoof" value="LongSpanRoof" onChange={handleInputChange} />
                <label for="SpaceframeUsage_LongSpanRoof"> Long-span roof</label><br/>

                <input type="checkbox" id="SpaceframeUsage_Canopy" name="SpaceframeUsage_Canopy" value="Canopy" onChange={handleInputChange} />
                <label for="SpaceframeUsage_Canopy"> Canopy </label><br/>

                <input type="checkbox" id="SpaceframeUsage_Skylight" name="SpaceframeUsage_Skylight" value="Skylight" onChange={handleInputChange} />
                <label for="SpaceframeUsage_Skylight"> Skylight </label><br/>

                <input type="checkbox" id="SpaceframeUsage_Facade" name="SpaceframeUsage_Facade" value="Facade" onChange={handleInputChange} />
                <label for="SpaceframeUsage_Facade"> Facade</label><br/>

                <input type="checkbox" id="SpaceframeUsage_Stadium" name="SpaceframeUsage_Stadium" value="Stadium" onChange={handleInputChange} />
                <label for="SpaceframeUsage_Stadium"> Stadium</label><br/>               

                <input type="checkbox" id="SpaceframeUsage_Other" name="SpaceframeUsage_Other" value="Other" onChange={handleInputChange} />
                <input type="text" id="SpaceframeUsage_OtherTextId" name="SpaceframeUsage_OtherText" placeholder="Other" onChange={handleInputChange} />            
            </div>

            <div className="input"><label>Spaceframe structural systems (multiple options)? Optional<br /></label>
                <input type="checkbox" id="SpaceframeSystem_SpaceFrame" name="SpaceframeSystem_SpaceFrame" value="SpaceFrame" onChange={handleInputChange} />
                <label for="SpaceframeSystem_SpaceFrame"> Space-frame</label><br/>

                <input type="checkbox" id="SpaceframeSystem_DiagridLattice" name="SpaceframeSystem_DiagridLattice" value="DiagridLattice" onChange={handleInputChange} />
                <label for="SpaceframeSystem_DiagridLattice"> Diagrid / lattice</label><br/>

                <input type="checkbox" id="SpaceframeSystem_OneWayTruss" name="SpaceframeSystem_OneWayTruss" value="OneWayTruss" onChange={handleInputChange} />
                <label for="SpaceframeSystem_OneWayTruss"> One-way truss</label><br/>

                <input type="checkbox" id="SpaceframeSystem_TwoWayTruss" name="SpaceframeSystem_TwoWayTruss" value="TwoWayTruss" onChange={handleInputChange} />
                <label for="SpaceframeSystem_TwoWayTruss"> Two-way truss</label><br/>

                <input type="checkbox" id="SpaceframeSystem_SpaceTruss" name="SpaceframeSystem_SpaceTruss" value="SpaceTruss" onChange={handleInputChange} />
                <label for="SpaceframeSystem_SpaceTruss"> Space truss</label><br/>

                <input type="checkbox" id="SpaceframeSystem_Shell" name="SpaceframeSystem_Shell" value="Shell" onChange={handleInputChange} />
                <label for="SpaceframeSystem_Shell"> Shell</label><br/>

                <input type="checkbox" id="SpaceframeSystem_Other" name="SpaceframeSystem_Other" value="Other" onChange={handleInputChange} />
                <input type="text" id="SpaceframeSystem_OtherTextId" name="SpaceframeSystem_OtherText" placeholder="Other" onChange={handleInputChange} />            
            </div>
            
        </div> 
        {/*  END Spaceframe model type  */}

        <hr />
        <h3>Additional</h3>
        <div className="input"><label>What is the design stage (if known)? Optional<br /></label>

            <input type="radio" id="Conceptual" name="DSOpt" value="Conceptual" onChange={handleInputChange} />
            <label for="Conceptual"> Conceptual</label><br/>
            <input type="radio" id="Schematic" name="DSOpt" value="Schematic" onChange={handleInputChange} />
            <label for="Schematic"> Schematic</label><br/>
            <input type="radio" id="DetailedDesign" name="DSOpt" value="DetailedDesign" onChange={handleInputChange} />
            <label for="DetailedDesign"> Detailed Design</label><br/>
            <input type="radio" id="Approvals" name="DSOpt" value="Approvals" onChange={handleInputChange} />
            <label for="Approvals"> Approvals</label><br/>
            <input type="radio" id="Construction" name="DSOpt" value="Construction" onChange={handleInputChange} />
            <label for="Construction"> Construction</label><br/>
            <input type="radio" id="AssessmentExistingDesign" name="DSOpt" value="AssessmentExistingDesign" onChange={handleInputChange} />
            <label for="AssessmentExistingDesign"> Assessment of Existing Design</label><br/>
            <input type="radio" id="DSOtherId" name="DSOpt" value="DDOther" onChange={handleInputChange} />
            <input type="text" name="DSOptText" id="DSOptTextId" placeholder="Other" onChange={handleInputChange} />
            
        </div>

        <div className="input"><label>What is the likely quality of the model (best estimate)?<br /></label>
            
            <input type="radio" id="ValidatedModel" name="QualityOpt" value="ValidatedModel" onChange={handleInputChange}/>
            <label for="ValidatedModel"> Validated (e.g. used for design submissions)</label><br/>
            <input type="radio" id="VerifiedModel" name="QualityOpt" value="VerifiedModel" onChange={handleInputChange} />
            <label for="VerifiedModel"> Verified (e.g. used for design, but not used for submissions)</label><br/>
            <input type="radio" id="StudyModel" name="QualityOpt" value="StudyModel" onChange={handleInputChange} />
            <label for="StudyModel"> Study model (e.g. parametric studies)</label><br/>
            <input type="radio" id="UnknownQuality" name="QualityOpt" value="UnknownQuality" onChange={handleInputChange} />
            <input type="text" id="UnknownQualityText" name="QualityOptText"placeholder="Other" onChange={handleInputChange} />
        </div>
        

        <div className="input"><label>Additional Comments<br /><textarea type="textarea" name="additionalComments" id="additionalCommentsId" rows={3} cols={48} onChange={handleInputChange} /></label></div>


        <div className="input"><button id="editButtonId" onClick={() => editForm(formInputs)}>Submit</button>&nbsp;
        <button id="cancelButtonId" onClick={(cancelClick)}>Cancel</button></div>
        <div className="input"><button id="submitButtonId" onClick={() => uploadFile(selectedFiles)}>Submit</button></div>

        <ClipLoader color={"#ffffff"} loading={loading} size={150} />
    </>
}

export default Upload;