import PT from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import QuestionAnswerSharpIcon from '@material-ui/icons/QuestionAnswerSharp';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '../../../../../../components/pickers';
import EmptyState from '../../../../../../components/empty-state';
import StatusCircle from '../../../../../../components/status-circle';
import DataDrawerGrid from '../../../../../../components/data-drawer/grid';
import SelectSource from '../../../../../../components/source/select-source';
import Tags from './views/add-parameter-tags';
import DisciplineTags from './views/add-parameter-discipline-tags';
import useHooks from './hooks';
import useStyles from './add-parameter-edit-styles';
import {
  parameterType,
  parameterTypeType,
  sourceType,
  unitType,
  dictionaryOfStringType,
  assetTypePropType,
} from '../../../../../../types';
import { StyledIconButton } from '../../../../../../components/buttons';
import SelectUnit from '../../../../../../components/units/select-units';

function AddParameterEdit({
  selectedParameter,
  deleteParameter,
  sources,
  unit,
  updateDisciplineTags,
  updateTags,
  parameterTypes,
  updateParameterType,
  updateValue,
  updateValues,
  setUpdateValues,
  updateUnit,
  typesLoading,
  requiredError,
  setRequiredError,
  updateParentAssets,
  requiredDataType,
  setRequiredDataType,
  inputDataType,
  setInputDataType,
  errorValue,
  setErrorValue,
  additionalAssets,
  additionalAssetsLoaded,
  handleAddingMoreParentAssets,
  assetHierarchy,
  selectedAsset,
  unitTypes,
  selectedSource,
  setSelectedSource,
}) {
  const classes = useStyles();
  const { t } = useTranslation(['common', 'parameters']);

  const {
    handleParameterInputChange,
    parameterInput,
    tagTypesLoading,
    handleParameterTypeChange,
    handleParameterValueChange,
    handleDateChange,
    handleBooleanChange,
    handleUnitSelection,
  } = useHooks(
    selectedParameter,
    sources,
    requiredDataType,
    setRequiredDataType,
    inputDataType,
    setInputDataType,
    errorValue,
    setErrorValue,
    updateParameterType,
    updateValue,
    updateUnit,
    unit,
    setUpdateValues
  );
  let selectedParameterTags = [];
  if (selectedParameter && selectedParameter.tag_ids) {
    selectedParameterTags = selectedParameter.tag_ids;
  }

  let selectedParamDisciplineTags = [];
  if (selectedParameter && selectedParameter.discipline_ids) {
    selectedParamDisciplineTags = selectedParameter.discipline_ids;
  }

  const renderSwitch = param => {
    const getHelperText = () => {
      if (errorValue[selectedParameter.id]) {
        if (requiredDataType[selectedParameter.id]) {
          return t('parameters:pleaseEnterValue', {
            key:
              requiredDataType[selectedParameter.id] === 'string'
                ? 'text'
                : requiredDataType[selectedParameter.id],
          });
        }
        return t('parameters:selectName');
      }
      return false;
    };
    switch (param) {
      case 'date':
        return (
          <DatePicker
            className={clsx([classes.grow, classes.widerMargin])}
            disableToolbar
            label="DD/MM/YYYY"
            disabled={
              selectedParameter.parameter_type.id === '' &&
              selectedParameter.revision.values[0].value === 'Enter value'
            }
            value={
              selectedParameter.revision.values[0].value === 'Enter value'
                ? null
                : selectedParameter.revision.values[0].value
            }
            onChange={handleDateChange}
          />
        );
      case 'boolean':
        return (
          <FormControl
            className={clsx([classes.grow, classes.widerMargin])}
            disabled={
              selectedParameter.parameter_type.id === '' &&
              selectedParameter.revision.values[0].value === 'Enter value'
            }
            variant="outlined"
          >
            <InputLabel id="boolean">Value</InputLabel>
            <Select
              labelId="boolean"
              label="value"
              autoWidth
              value={
                selectedParameter.revision.values[0].value === 'Enter value'
                  ? ''
                  : selectedParameter.revision.values[0].value
              }
              onChange={handleBooleanChange}
            >
              <MenuItem value="">
                <em>{t('none')}</em>
              </MenuItem>
              <MenuItem value="true">{t('true')}</MenuItem>
              <MenuItem value="false">{t('false')}</MenuItem>
            </Select>
          </FormControl>
        );
      default:
        return (
          <TextField
            className={clsx([classes.grow, classes.widerMargin])}
            variant="outlined"
            label="Value"
            disabled={
              selectedParameter.parameter_type.id === '' &&
              selectedParameter.revision.values[0].value === 'Enter value'
            }
            value={
              selectedParameter.revision.values[0].value === 'Enter value'
                ? ''
                : selectedParameter.revision.values[0].value
            }
            onChange={handleParameterValueChange}
            error={errorValue[selectedParameter.id]}
            helperText={getHelperText()}
          />
        );
    }
  };

  return selectedParameter ? (
    <Card className={classes.fullHeight}>
      <CardHeader
        classes={{
          avatar: classes.avatar,
          title: classes.title,
          action: classes.action,
        }}
        title={
          <Autocomplete
            className={clsx([classes.grow, classes.widerMargin])}
            value={selectedParameter.parameter_type}
            onChange={handleParameterTypeChange}
            inputValue={parameterInput}
            onInputChange={(_, newInputValue) =>
              handleParameterInputChange(newInputValue)
            }
            onBlur={({ target }) => setRequiredError(!target.value)}
            options={parameterTypes}
            loading={typesLoading}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                required
                error={requiredError}
                helperText={
                  requiredError
                    ? t('parameters:selectName')
                    : `*${t('requiredError')}`
                }
                label={t('parameters:paramName')}
                variant="outlined"
              />
            )}
          />
        }
        titleTypographyProps={{
          variant: 'h5',
        }}
        avatar={
          <StatusCircle
            status={
              selectedParameter?.revision?.status || t('parameters:unanswered')
            }
            size={80}
          />
        }
        action={
          <StyledIconButton
            title={t('parameters:removeParam')}
            aria-label={t('parameters:removeParam')}
            onClick={() => deleteParameter(selectedParameter.id)}
            icon={<DeleteIcon color="primary" />}
          />
        }
      />
      <Divider />
      <CardContent>
        <Grid
          item
          className={clsx([classes.grow, classes.widerMargin, classes.content])}
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
        >
          {/* {selectedParameter?.additionalAssets?.length > 0 && (
            <DataDrawerGrid
              icon={<DynamicFeedIcon />}
              caption={
                <Typography variant="caption" color="textSecondary">
                  {t('parameters:parentAssets')}
                </Typography>
              }
              // to do: when API can handle posting parameter to multiple parents, update here using additionalAssets
              content={
                <Grid item container direction="column">
                  <FormGroup className={classes.checkboxHolder}>
                    <FormControlLabel
                      key={selectedAsset.id}
                      control={
                        <Checkbox
                          color="default"
                          inputProps={{
                            'aria-label': t(
                              'parameters.selectAdditionalParentAsset'
                            ),
                          }}
                          checked
                          onChange={() =>
                            updateParentAssets(
                              selectedParameter.id,
                              selectedAsset.id
                            )
                          }
                        />
                      }
                      label={
                        <span>
                          <Breadcrumbs maxItems={2} aria-label="breadcrumb">
                            {assetHierarchy.map(item => (
                              <Typography
                                className={classes.BreadCrumbHeader}
                                key={item.id}
                              >
                                {item.name}
                              </Typography>
                            ))}
                            <Typography>{selectedAsset.name}</Typography>
                          </Breadcrumbs>
                        </span>
                      }
                    />
                    {additionalAssets.length >= 50 &&
                      additionalAssets.length % 50 === 0 && (
                        <StyledIconButton
                          variant="contained"
                          onClick={handleAddingMoreParentAssets}
                          className={classes.dotsIcon}
                          disabled
                          icon={
                            <>
                              <MoreHorizIcon />
                              {!additionalAssetsLoaded && (
                                <CircularProgress size={20} />
                              )}
                            </>
                          }
                        />
                      )}
                  </FormGroup>
                </Grid>
              }
            />
          )} */}

          {/* <DataDrawerGrid
            icon={<QuestionAnswerSharpIcon />}
            caption={
              <Typography variant="caption" color="textSecondary">
                {t('parameters:value')}
              </Typography>
            }
            content={
              <Grid item container direction="row" justify="space-between">
                <Grid>
                  {renderSwitch(requiredDataType[selectedParameter.id])}
                </Grid>
                <SelectUnit
                  onUnitSelection={handleUnitSelection}
                  defaultOrSelectedUnit={
                    ((selectedParameter.revision.status === 'unanswered' &&
                      selectedParameter.revision.values[0].unit?.id === null) ??
                      unit) ||
                    selectedParameter.revision.values[0].unit
                  }
                  unitTypes={unitTypes}
                />
              </Grid>
            }
          /> */}
          {/* <DataDrawerGrid
            icon={<LibraryBooksIcon />}
            caption={
              <Typography variant="caption" color="textSecondary">
                {t('source')}
              </Typography>
            }
            content={
              <Grid item container direction="column">
                <SelectSource
                  setUpdateValues={setUpdateValues}
                  updateValues={updateValues}
                  selectedSource={selectedSource}
                  setSelectedSource={setSelectedSource}
                />
              </Grid>
            }
          />
          <DataDrawerGrid
            icon={<LocalOfferIcon />}
            caption={
              <Typography variant="caption" color="textSecondary">
                {t('tags')}
              </Typography>
            }
            content={
              <div>
                <Tags
                  selectedParameterTags={selectedParameterTags}
                  parameterId={selectedParameter.id}
                  disabled={false}
                  tagType={{
                    name: 'Parameter Category',
                    id: 'f18d3070-dae5-4386-996e-9c70d796e558',
                  }}
                  tagTypesLoading={tagTypesLoading}
                  updateTags={updateTags}
                />
                <DisciplineTags
                  selectedParamDisciplineTags={selectedParamDisciplineTags}
                  parameterId={selectedParameter.id}
                  tagTypesLoading={tagTypesLoading}
                  disabled={false}
                  updateDisciplineTags={updateDisciplineTags}
                />
              </div>
            }
          /> */}
        </Grid>
      </CardContent>
    </Card>
  ) : (
    <div className={classes.root}>
      <EmptyState
        Icon={DescriptionIcon}
        title={t('parameters:noParamSelected')}
        subtitle={t('parameters:clickEditParam')}
      />
    </div>
  );
}

AddParameterEdit.propTypes = {
  selectedParameter: parameterType.isRequired,
  deleteParameter: PT.func.isRequired,
  parameterTypes: PT.arrayOf(parameterTypeType).isRequired,
  sources: PT.arrayOf(sourceType).isRequired,
  unit: unitType.isRequired,
  updateParameterType: PT.func.isRequired,
  setUpdateValues: PT.func.isRequired,
  updateValue: PT.func.isRequired,
  updateValues: PT.oneOfType([PT.array, PT.object]).isRequired,
  updateUnit: PT.func.isRequired,
  typesLoading: PT.bool.isRequired,
  sourcesLoading: PT.bool.isRequired,
  unitsLoading: PT.bool.isRequired,
  requiredError: PT.bool.isRequired,
  setRequiredError: PT.func.isRequired,
  updateDisciplineTags: PT.func.isRequired,
  updateTags: PT.func.isRequired,
  updateParentAssets: PT.func.isRequired,
  requiredDataType: dictionaryOfStringType.isRequired,
  setRequiredDataType: PT.func.isRequired,
  inputDataType: dictionaryOfStringType.isRequired,
  setInputDataType: PT.func.isRequired,
  errorValue: dictionaryOfStringType.isRequired,
  setErrorValue: PT.func.isRequired,
  handleAddingMoreParentAssets: PT.func.isRequired,
  additionalAssets: PT.arrayOf(parameterTypeType).isRequired,
  additionalAssetsLoaded: PT.bool.isRequired,
  assetHierarchy: PT.arrayOf(assetTypePropType).isRequired,
  selectedAsset: PT.arrayOf(assetTypePropType).isRequired,
  unitTypes: unitType.isRequired,
  selectedSource: PT.shape(PT.object).isRequired,
  setSelectedSource: PT.func.isRequired,
};

export default AddParameterEdit;
