import PT from 'prop-types';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import LinkIcon from '@material-ui/icons/Link';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTranslation } from 'react-i18next';
import { StyledIconButton } from '../../../buttons';
import useStyles from './header-styles';

function ActionHeader({
  loading,
  onClick,
  avatar,
  title,
  subheader,
  actionTooltip,
}) {
  const classes = useStyles();
  const { t } = useTranslation(['projects'], { useSuspense: false });

  return (
    <CardHeader
      data-testid="card-header"
      className={classes.header}
      avatar={
        loading ? (
          <Skeleton
            variant="circle"
            width={40}
            height={40}
            data-testid="loading"
          />
        ) : (
          <Avatar aria-label="avatar">{avatar}</Avatar>
        )
      }
      action={
        !loading && (
          <StyledIconButton
            title={t(actionTooltip)}
            onClick={onClick}
            icon={<LinkIcon />}
          />
        )
      }
      title={loading ? <Skeleton data-testid="loading" /> : title}
      subheader={
        loading ? <Skeleton data-testid="loading" width="30%" /> : subheader
      }
    />
  );
}

ActionHeader.propTypes = {
  loading: PT.bool.isRequired,
  onClick: PT.func.isRequired,
  actionTooltip: PT.string.isRequired,
  avatar: PT.oneOfType([PT.string, PT.number]),
  title: PT.oneOfType([PT.string, PT.number]),
  subheader: PT.oneOfType([PT.string, PT.number]),
};

ActionHeader.defaultProps = {
  avatar: null,
  title: null,
  subheader: null,
};

export default ActionHeader;
