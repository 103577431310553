class ApiError extends Error {
  constructor(OriginalError) {
    super(OriginalError.message);
    Object.assign(this, OriginalError, OriginalError.response?.data);
    this.name = 'ApiError';
    this.status = OriginalError.response?.status;
  }
}

export default ApiError;
