import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import Row from '../../../../../../../components/chips/display-chips/row';
import useAddParameterTags from '../hooks/use-add-parameter-tags';
import { tagTypeType } from '../../../../../../../types';

function Tags({
  selectedParameterTags,
  parameterId,
  disabled,
  tagType,
  tagTypesLoading,
  updateTags,
}) {
  const { t } = useTranslation(['parameters']);
  const { tagsLoading, availableTags, deleteTag, addTag } = useAddParameterTags(
    tagType,
    tagTypesLoading,
    updateTags,
    selectedParameterTags
  );

  const tagLookup = (convert, tagTypeName) => {
    const dict = {
      plural: {
        Calculation: t('parameters:calculations'),
        'Parameter Category': t('parameters:categories'),
        Report: t('parameters:reports'),
      },
      singular: {
        Calculation: t('parameters:calculation'),
        'Parameter Category': t('parameters:category'),
        Report: t('parameters:report'),
      },
    };
    return dict[convert][tagTypeName];
  };

  return (
    <Row
      tags={selectedParameterTags}
      tagTypeid={tagType.id}
      tagTypeName={tagLookup('plural', tagType.name)}
      disabled={disabled}
      tagsLoading={tagsLoading}
      deleteTag={deleteTag}
      parameterId={parameterId}
      addTag={addTag}
      availableTags={availableTags}
    />
  );
}

Tags.propTypes = {
  selectedParameterTags: PT.arrayOf(
    PT.shape({ name: PT.string.isRequired }).isRequired
  ).isRequired,
  parameterId: PT.oneOfType([PT.string, PT.number]).isRequired,
  disabled: PT.bool.isRequired,
  tagTypesLoading: PT.bool.isRequired,
  tagType: tagTypeType.isRequired,
  updateTags: PT.func.isRequired,
};

export default Tags;
