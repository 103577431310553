import React ,{useState, useEffect} from 'react';

const FetchTable = () => {
    const [table, setTable]=useState();
    const getTable = () => {
        const requestObject = {

            method:'GET' ,
                headers: {
                    'Authorization': 'vVxkP3Bkx25g8WrOwOhowhEhuRKzjYJ5IBJavPGc',
                    'Content-Type': 'application/json'
                },
            }
        
            fetch('https://d6wk5r26f2.execute-api.ap-southeast-1.amazonaws.com/prod/', requestObject)
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                const items = data && data.data && data.data.Items;

                if (!items) return

                const hashKey = items.map(x => String(x.datetimeUTC)).sort().reverse();
                const PKId = []
                var hashTable = {};
                    for (var i=0; i<items.length;i++){
                        
                        var currhash = String(items[i].datetimeUTC);
                        var id = currhash+"-Id"
                        hashTable[id] = items[i]["uniqueId"]
                        PKId.push(hashTable[id])
                        var JN=currhash+"-JN";
                        hashTable[JN]=items[i]["info"].jobnumber;
                        var ProjName = currhash+"-ProjName";
                        hashTable[ProjName]=items[i]["info"].projectname;
                        var ProjectCountry = currhash+"-Country";
                        hashTable[ProjectCountry] = items[i]["info"].projectcountry;
                        var Email = currhash+"-email";
                        hashTable[Email] = items[i]["info"].email;
                      
    
                        var UsageHash = currhash+"-Usage";
                        var usage=[];

                        /* building */
                        if (items[i]["info"].Usage_Residential)
                            usage.push("Residential");
                        if (items[i]["info"].Usage_Commercial)
                            usage.push("Commercial");        
                        if (items[i]["info"].Usage_Office)
                            usage.push("Office");        
                        if (items[i]["info"].Usage_Retail)
                            usage.push("Retail");        
                        if (items[i]["info"].Usage_Leisure)
                            usage.push("Leisure");        
                        if (items[i]["info"].Usage_Hotel)
                            usage.push("Hotel");        
                        if (items[i]["info"].Usage_Industrial)
                            usage.push("Industrial");
                        if (items[i]["info"].Usage_Governmental)
                            usage.push("Governmental");
                        if (items[i]["info"].Usage_Educational)
                            usage.push("Educational");
                        if (items[i]["info"].Usage_Other)
                            usage.push(items[i]["info"].OtherUsageText);
                        

                        /* bridge */ 
                        if (items[i]["info"].BridgeUsage_Road)
                            usage.push("Road Bridge");
                        if (items[i]["info"].BridgeUsage_Foot)
                            usage.push("Foot Bridge");
                        if (items[i]["info"].BridgeUsage_Rail)
                            usage.push("Rail Bridge");
                        if (items[i]["info"].BridgeUsage_CanalAqueduct)
                            usage.push("CanalAqueduct Bridge");
                        if (items[i]["info"].BridgeUsage_Other)
                            usage.push(items[i]["info"].BridgeUsage_OtherText);                        

                        /* spaceframe */
                        if (items[i]["info"].SpaceframeUsage_LongSpanRoof)
                            usage.push("Long-span roof");
                        if (items[i]["info"].SpaceframeUsage_Canopy)
                            usage.push("Canopy");
                        if (items[i]["info"].SpaceframeUsage_Skylight)
                            usage.push("Skylight");
                        if (items[i]["info"].SpaceframeUsage_Facade)
                            usage.push("Facade");
                        if (items[i]["info"].SpaceframeUsage_Stadium)
                            usage.push("Stadium");
                        if (items[i]["info"].SpaceframeUsage_Other)
                            usage.push(items[i]["info"].SpaceframeUsage_OtherText);


                        var tempstring=usage[0];
                        if (usage.length>1){
                            for (var j=1; j<usage.length;j++)
                                tempstring=tempstring+", "+usage[j];
                        }
                        hashTable[UsageHash] = tempstring;
        
                        var GS = currhash+"-GravitySystem";
                        var sgs=[];

                        /* building */
                        if (items[i]["info"].GravSystem_Composite)
                            sgs.push("Composite");
                        if (items[i]["info"].GravSystem_SlabBeam)
                            sgs.push("Slab on beams")
                        if (items[i]["info"].GravSystem_SlabColumn)
                            sgs.push("Slab on columns")
                        if (items[i]["info"].GravSystem_SlabPT)
                            sgs.push("Post Tensioning Slab")
                        if (items[i]["info"].GravSystem_BeamPT)
                            sgs.push("Post Tensioning Band Beams")
                        if (items[i]["info"].GravSystem_Other)
                            sgs.push(items[i]["info"].OtherGravSystemText)

                        /* bridge */
                        if (items[i]["info"].BridgeSystem_CableStayed)
                            sgs.push("Cable-stayed bridge");
                        if (items[i]["info"].BridgeSystem_AnchoredSuspension)
                            sgs.push("Anchored suspension bridge");
                        if (items[i]["info"].BridgeSystem_SelfAnchoredSuspension)
                            sgs.push("Self-anchored suspension bridge");
                        if (items[i]["info"].BridgeSystem_GirderBridge)
                            sgs.push("Girder bridge (beam or box, incl PT)");
                        if (items[i]["info"].BridgeSystem_Truss)
                            sgs.push("Truss bridge (Warren, N-truss etc)");
                        if (items[i]["info"].BridgeSystem_Vierendeel)
                            sgs.push("Vierendeel bridge");
                        if (items[i]["info"].BridgeSystem_Arch)
                            sgs.push("Arch bridge"); 
                        if (items[i]["info"].BridgeSystem_ThroughArch)
                            sgs.push("Through-arch bridge");
                        if (items[i]["info"].BridgeSystem_Floating)
                            sgs.push("Floating bridge");
                        if (items[i]["info"].BridgeSystem_Opening)
                            sgs.push("Opening bridge");
                        if (items[i]["info"].BridgeSystem_Other)
                            sgs.push(items[i]["info"].BridgeSystem_OtherText);

                        /* Spaceframe */
                        if (items[i]["info"].SpaceframeSystem_SpaceFrame)
                            sgs.push("Space-frame");
                        if (items[i]["info"].SpaceframeSystem_DiagridLattice)
                            sgs.push("Diagrid / lattice");
                        if (items[i]["info"].SpaceframeSystem_OneWayTruss)
                            sgs.push("One-way truss");
                        if (items[i]["info"].SpaceframeSystem_TwoWayTruss)
                            sgs.push("Two-way truss");
                        if (items[i]["info"].SpaceframeSystem_SpaceTruss)
                            sgs.push("Space truss");
                        if (items[i]["info"].SpaceframeSystem_Shell)
                            sgs.push("Shell");
                        if (items[i]["info"].SpaceframeSystem_Other)
                            sgs.push(items[i]["info"].SpaceframeSystem_OtherText);

                        tempstring=sgs[0]
                        if (sgs.length>1){
                            for (var j =1; j<sgs.length;j++)
                                tempstring=tempstring+", "+sgs[j];
                        }
                        hashTable[GS]=tempstring;
        
                        var LS = currhash+"-Lateral";
                        var lat=[];
                        if (items[i]["info"].LatSystem_RCMomentFrame)
                            lat.push("RC Moment Frame");
                        if (items[i]["info"].LatSystem_RCShearwall)
                            lat.push("RC Shear wall");
                        if (items[i]["info"].LatSystem_RCCore)
                            lat.push("RC Core");
                        if (items[i]["info"].LatSystem_SteelMomentFrame)
                            lat.push("Steel Moment Frame");
                        if (items[i]["info"].LatSystem_SteelBracedFrame)
                            lat.push("Steel Braced Frame");
                        if (items[i]["info"].OtherLatSystemText)
                            lat.push(items[i]["info"].OtherLatSystemText);
                        tempstring=lat[0]
                        if (lat.length>1){
                            for (j =1; j<lat.length;j++)
                                tempstring=tempstring+", "+lat[j];
                        }
                        hashTable[LS] = tempstring;
        
                        var DS=currhash+"-DS";
                        if (items[i]["info"].DSOpt==="Conceptual")
                            hashTable[DS] = "Conceptual";
                        if (items[i]["info"].DSOpt==="Schematic")
                            hashTable[DS] = "Schematic";
                        if (items[i]["info"].DSOpt==="DetailedDesign")
                            hashTable[DS] = "Detailed Design";
                        if (items[i]["info"].DSOpt==="Approvals")
                            hashTable[DS] = "Approvals";
                        if (items[i]["info"].DSOpt==="Construction")
                            hashTable[DS] = "Construction";
                        if (items[i]["info"].DSOpt==="AssessmentExistingDesign")
                            hashTable[DS] = "Assessment of Existing Design";
                        if (items[i]["info"].DSOpt==="DDOther")
                            hashTable[DS] = items[i]["info"].DSOtherText;
        
                        var quality=currhash+"-quality";
                        if (items[i]["info"].QualityOpt === "ValidatedModel")
                            hashTable[quality] = "Validated";
                        if (items[i]["info"].QualityOpt === "VerifiedModel")
                            hashTable[quality] = "Verified";
                        if (items[i]["info"].QualityOpt === "StudyModel")
                            hashTable[quality] = "Study model";           
                        if (items[i]["info"].QualityOpt === "UnknownQuality")
                            hashTable[quality] = items[i]["info"].UnknownQualityText;
                        
                        var uploadDate=currhash+"-date";
                        var pos=currhash.length;
                        for (var j=0; j<currhash.length;j++){
                            if (currhash[j]==='T')
                                pos=j;
                        }
                        hashTable[uploadDate] = currhash.substring(0,pos);
                        var fileext=[]
                        var fileextkey = currhash+"-ext";
                        for (var j=0; j<items[i]["fileNames"].length;j++){
                            fileext.push(items[i]["fileNames"][j].ext);
                        }
                        tempstring=fileext[0]
                        if (fileext.length>1){
                            for (j =1; j<fileext.length;j++)
                                tempstring=tempstring+", "+fileext[j];
                        }
                        hashTable[fileextkey] = tempstring;
                    }
    
                    var ActualTable = [];
                    var headerlist = ["Index", "Model Name", "Location", "Project Usage","Structural Gravity System", "Structural Lateral System", "Design Stage", "Quality of Model", "File Extension", "File Upload Date", "Email","Edit"]
                    ActualTable.push(headerlist);
                    for (let i = 0; i < hashKey.length; i++){
                        var CellContent = [hashKey.length - i, hashTable[hashKey[i]+"-ProjName"],hashTable[hashKey[i]+"-Country"], hashTable[hashKey[i]+"-Usage"], hashTable[hashKey[i]+"-GravitySystem"],hashTable[hashKey[i]+"-Lateral"], hashTable[hashKey[i]+"-DS"], hashTable[hashKey[i]+"-quality"], hashTable[hashKey[i]+"-ext"], hashTable[hashKey[i]+"-date"], hashTable[hashKey[i]+"-email"],hashTable[hashKey[i]+"-Id"]]
                            ActualTable.push(CellContent);
                    }
                    // console.log(ActuralTable);
                setTable(ActualTable)
            });
    }

    useEffect(() => {
        getTable();
    },[]);

    return (
        <div className="Fetchtable">
            {table && (
                <table>
                    <thead>
                        <tr>
                            {table[0].map((item, index) => {
                            return <th>{item}</th>;
                            })}
                        </tr>
                    </thead>
                        <tbody>
                            {table.slice(1, table.length).map((item, index) => {
                                return (
                                    <tr>
                                        <td>{item[0]}</td>
                                        <td>{item[1]}</td>
                                        <td>{item[2]}</td>
                                        <td>{item[3]}</td>
                                        <td>{item[4]}</td>
                                        <td>{item[5]}</td>
                                        <td>{item[6]}</td>
                                        <td>{item[7]}</td>
                                        <td>{item[8]}</td>
                                        <td>{item[9]}</td>
                                        <td>{item[10]}</td>
                                        <td>
                                            <button id="GoEditButton" onClick={() => window.location.href = '?edit=' + item[11]}>Edit</button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                </table>
            )}
            
        </div>
    )

}

export default FetchTable;