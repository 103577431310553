import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const ActionButton = withStyles(theme => ({
  root: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    textTransform: 'none',
  },
}))(Button);

export default ActionButton;
