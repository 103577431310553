import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  widerMargin: {
    margin: '8px',
  },
  content: {
    padding: theme.spacing(1),
  },
  valueInput: {
    flexGrow: 8,
    margin: '8px',
  },
  disabled: { color: theme.palette.text.disabled },
}));
