import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import useStyles from './favourites-styles';
import ConfidentialChip from '../../chips/confidential';

function FavoritesList() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation(['home']);
  const [cookies] = useCookies(['ddbFavoriteProjects']);

  const favorites = Object.entries(cookies?.ddbFavoriteProjects || {}).map(
    ([k, v]) => ({ id: k, ...v })
  );

  return (
    <Paper variant="outlined" elevation={0} data-testid="favourites">
      <List
        dense
        subheader={<ListSubheader>{t('home:favourites')}</ListSubheader>}
        component="nav"
        className={classes.root}
        aria-label={t('home:favourites')}
        data-cy="favList"
      >
        {favorites.length ? (
          favorites.map(fav => (
            <ListItem
              button
              alignItems="flex-start"
              key={fav.id}
              onClick={() => navigate(`/projects/${fav.id}/parameters`)}
              data-cy="favGoToProject"
            >
              <ListItemAvatar aria-label="Job name">
                <Avatar>{fav.jobName}</Avatar>
              </ListItemAvatar>
              <ListItemText primary={fav.jobName} secondary={fav.number} />
              <ListItemSecondaryAction>
                {fav.confidential && <ConfidentialChip />}
              </ListItemSecondaryAction>
            </ListItem>
          ))
        ) : (
          <ListItem>
            <ListItemText
              primary={t('home:noFavourites')}
              secondary={t('home:addFavourites')}
            />
          </ListItem>
        )}
      </List>
    </Paper>
  );
}

export default FavoritesList;
