import commonConfig from './common';

export default {
  url: {
    ...commonConfig.url,
    api: {
      base: 'https://ddb.arup.com',
    },
  },
  featureFlags: {
    isMultipleEntriesEnabled: false,
    isLanguageCheckEnabled: true,
    isRoleBasedPermissionEnabled: false,
    isProjectSearchEnabled: true,
  },
  env: 'development',
};
