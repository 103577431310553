import commonConfig from './common';

export default {
  url: {
    ...commonConfig.url,
    api: {
      base: 'https://ddb.arup.com',
    },
  },
  featureFlags: {
    isLanguageCheckEnabled: false, // should always be false
    isMultipleEntriesEnabled: false,
    isRoleBasedPermissionEnabled: false,
    isProjectSearchEnabled: false,
  },
  env: 'production',
};
