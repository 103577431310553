import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from './unauthenticated-styles';
// import AppFooter from '../../../components/footer';

function Authorizing() {
  const classes = useStyles();
  const { t } = useTranslation('home');

  return (
    <>
      <main id="main-content" tabIndex="-1" data-testid="authorising-page">
        <Container maxWidth="lg" className={classes.main}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={4}
            className={classes.content}
          >
            <Grid item sm={12} md={6}>
              <Typography
                variant="h3"
                component="h1"
                color="inherit"
                gutterBottom
                className={classes.title}
              >
                Sign-in Required
              </Typography>
              <Button
                className={classes.button}
                variant="outlined"
                disabled
                data-cy="login-in-progress"
                startIcon={<CircularProgress size={30} thickness={6} />}
              >
                {t('home:loginInProgress')}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </main>
      {/* <AppFooter /> */}
    </>
  );
}

export default Authorizing;
