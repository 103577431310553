// todo: remove in refactor
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
import { useContext } from 'react';
import PT from 'prop-types';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import SourceIcon from '@material-ui/icons/LibraryBooks';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { CircularProgress } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Badge from '@material-ui/core/Badge';
import BlockIcon from '@material-ui/icons/Block';
import StarIcon from '@material-ui/icons/Star';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import CheckIcon from '@material-ui/icons/Check';
import { useTranslation } from 'react-i18next';
import DataDrawerGrid from '../../grid';
import StatusCircle from '../../../status-circle';
import { ActionButton, StyledIconButton } from '../../../buttons';
import EditEntriesWizard from '../edit-entries-wizard';
import useHooks from './hooks';
import useStyles from './entries-styles';
import { parameterType } from '../../../../types';
import { ProjectPermissionContext } from '../../../../context/project-permission-context';

function Entries(props) {
  const { parameter, setOpenModal, toggleOpenAddEntriesWizard } = props;
  const { writePermission, approvePermission, adminPermission } = useContext(
    ProjectPermissionContext
  );
  const { t } = useTranslation(['common']);

  const {
    open,
    handleOpen,
    loading,
    header,
    mockData,
    handlePopoverOpen,
    handleSourceOpen,
    handleRelevanceOpen,
    handleEditEntryOpen,
    anchorEl,
  } = useHooks(parameter);
  const classes = useStyles();

  const renderIcon = () => {
    if (loading) return <CircularProgress size={18} thickness={8} />;
    if (open) return <KeyboardArrowDownIcon aria-label="Collapse button" />;
    return <KeyboardArrowRightIcon aria-label="Expand button" />;
  };

  return (
    <Box>
      {open ? <Divider /> : null}
      <DataDrawerGrid
        icon={
          <Badge
            badgeContent={mockData.length}
            classes={{ badge: classes.counter }}
          >
            <SourceIcon />
          </Badge>
        }
        caption={
          <Typography variant="caption">{t('common:entries')}</Typography>
        }
        content={
          <Box>
            <StyledIconButton onClick={handleOpen} icon={renderIcon()} />
            <Typography variant="subtitle1" display="inline">
              {loading ? (
                <Skeleton height={30} width={600} display="inline" />
              ) : (
                header
              )}
            </Typography>
            <Collapse in={open}>
              <Tooltip
                title={!writePermission && t('common:permission.notAllowed')}
              >
                <ActionButton onClick={() => toggleOpenAddEntriesWizard()}>
                  {t('common:multipleEntries.newEntry')}
                </ActionButton>
              </Tooltip>
              <Table aria-label="Entries Table" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHead}>
                      {t('common:relevance')}
                    </TableCell>
                    <TableCell className={classes.tableHead}>
                      {t('common:source')}
                    </TableCell>
                    <TableCell className={classes.tableHead}>
                      {t('common:multipleEntries.entryValue')}
                    </TableCell>
                    <TableCell className={classes.tableHead}>
                      {t('common:addSource.source.docType')}
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title={
                          !(approvePermission || adminPermission) &&
                          t('common:permission.notAllowed')
                        }
                      >
                        <ActionButton>
                          {t('common:multipleEntries.approveEntries')}
                        </ActionButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mockData.map(entry => (
                    <TableRow key={entry.id}>
                      <TableCell className={classes.tableCell}>
                        <Grid container alignItems="center">
                          {entry.relevance.map(rel => (
                            <Grid item key={rel}>
                              {rel === 'favourite' && (
                                <StarIcon className={classes.starIcon} />
                              )}
                              {rel === 'limiting value' && (
                                <PriorityHighIcon
                                  className={classes.roundIcon}
                                />
                              )}
                              {rel === 'non compliant' && (
                                <BlockIcon
                                  className={classes.nonCompliantIcon}
                                />
                              )}
                              {rel === '' && (
                                <span className={classes.noIcon} />
                              )}
                            </Grid>
                          ))}
                          <Grid item>
                            <StyledIconButton
                              title={
                                !writePermission &&
                                t('common:permission.notAllowed')
                              }
                              onClick={e => handleRelevanceOpen(entry.id, e)}
                              disabled={!writePermission}
                              icon={
                                <ArrowDropDownIcon aria-label="Expand button" />
                              }
                            />
                          </Grid>
                        </Grid>
                        <Popover
                          anchorEl={anchorEl}
                          open={entry.relevanceOpen}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          onClose={e => handleRelevanceOpen(entry.id, e)}
                        >
                          <p>
                            <ActionButton className={classes.popoverButton}>
                              <PriorityHighIcon className={classes.roundIcon} />
                              <Typography
                                variant="body2"
                                className={classes.relevanceText}
                              >
                                {t('common:multipleEntries.limitingValue')}
                              </Typography>
                              {entry.relevance.includes('limiting value') && (
                                <CheckIcon
                                  className={classes.checkIcon}
                                  aria-label="Check icon"
                                />
                              )}
                            </ActionButton>
                          </p>
                          <p>
                            <ActionButton className={classes.popoverButton}>
                              <StarIcon className={classes.starIcon} />
                              <Typography
                                variant="body2"
                                className={classes.relevanceText}
                              >
                                {t('common:favourite')}
                              </Typography>
                              {entry.relevance.includes('favourite') && (
                                <CheckIcon
                                  className={classes.checkIcon}
                                  aria-label="Check icon"
                                />
                              )}
                            </ActionButton>
                          </p>
                          <p>
                            <ActionButton className={classes.popoverButton}>
                              <BlockIcon className={classes.nonCompliantIcon} />
                              <Typography
                                variant="body2"
                                className={classes.relevanceText}
                              >
                                {t('common:nonCompliant')}
                              </Typography>
                              {entry.relevance.includes('non compliant') && (
                                <CheckIcon
                                  className={classes.checkIcon}
                                  aria-label="Check icon"
                                />
                              )}
                            </ActionButton>
                          </p>
                        </Popover>
                      </TableCell>
                      <TableCell className={classes.sourceCell}>
                        <Grid container alignItems="center">
                          <Grid item>
                            <StyledIconButton
                              onClick={() => handleSourceOpen(entry.id)}
                              icon={
                                entry.sourceOpen ? (
                                  <KeyboardArrowDownIcon aria-label="Collapse button" />
                                ) : (
                                  <KeyboardArrowRightIcon aria-label="Expand button" />
                                )
                              }
                            />
                          </Grid>
                          <Grid item>{entry.source.source}</Grid>
                        </Grid>
                        <Collapse
                          in={entry.sourceOpen}
                          className={classes.sourceData}
                        >
                          <p>
                            {t(
                              'common:dataDrawer.addEntriesWizard.locationInSource'
                            )}
                            :{entry.source.valueLocation}
                          </p>
                          <p>
                            {t('common:addSource.source.directoryUrl')}:
                            {entry.source.directoryURL}
                          </p>
                          <p>
                            {t('common:published')}: {entry.source.published}
                          </p>
                          <p>
                            {t('common:comment')}: {entry.source.comment}
                          </p>
                        </Collapse>
                      </TableCell>
                      <TableCell className={classes.cellAlign}>
                        {entry.entryValue}
                      </TableCell>
                      <TableCell className={classes.cellAlign}>
                        {entry.type}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Grid container alignItems="center">
                          <Grid item>
                            <StatusCircle status={entry.status} />
                          </Grid>
                          <Grid item>
                            <StyledIconButton
                              title={
                                !writePermission &&
                                t('common:permission.notAllowed')
                              }
                              onClick={e => handlePopoverOpen(entry.id, e)}
                              disabled={!writePermission}
                              aria-label="Expand"
                              icon={<ArrowDropDownIcon />}
                            />
                          </Grid>
                          <Grid item>
                            <StyledIconButton
                              title={
                                !writePermission &&
                                t('common:permission.notAllowed')
                              }
                              onClick={() => handleEditEntryOpen(entry.id)}
                              disabled={!writePermission}
                              aria-label="Edit"
                              icon={
                                <EditIcon className={classes.editButtons} />
                              }
                            />
                          </Grid>
                          <Grid item>
                            <StyledIconButton
                              title={
                                !(approvePermission || adminPermission) &&
                                t('common:permission.notAllowed')
                              }
                              disabled={!(approvePermission || adminPermission)}
                              aria-label="Delete"
                              icon={
                                <DeleteIcon className={classes.editButtons} />
                              }
                            />
                          </Grid>
                        </Grid>
                        <Popover
                          anchorEl={anchorEl}
                          open={entry.popoverOpen}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          onClose={e => handlePopoverOpen(entry.id, e)}
                        >
                          <p>
                            {entry.status === 'answered' && (
                              <ActionButton className={classes.popoverButton}>
                                {t('common:multipleEntries.checkEntry')}
                              </ActionButton>
                            )}
                            {entry.status === 'checked' && (
                              <ActionButton className={classes.popoverButton}>
                                {t('common:multipleEntries.approveEntry')}
                              </ActionButton>
                            )}
                          </p>
                          <p>
                            <ActionButton
                              className={classes.popoverButton}
                              onClick={() => setOpenModal(true)}
                            >
                              {t('common:multipleEntries.rejectEntry')}
                            </ActionButton>
                          </p>
                        </Popover>
                        <EditEntriesWizard
                          parameter={parameter}
                          entry={entry}
                          handleEditEntryOpen={handleEditEntryOpen}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </Box>
        }
      />
      {open ? <Divider /> : null}
    </Box>
  );
}

Entries.propTypes = {
  parameter: parameterType.isRequired,
  setOpenModal: PT.func.isRequired,
  toggleOpenAddEntriesWizard: PT.func.isRequired,
};

export default Entries;
