import DeleteIcon from '@material-ui/icons/Delete';
import PT from 'prop-types';
import StyledIconButton from '../styled';
import useButton from '../../hooks/use-button';

function DeleteButton({ onClick }) {
  const { renderTooltipDeleteIcon } = useButton();

  return (
    <StyledIconButton
      type="delete"
      title={renderTooltipDeleteIcon()[0]}
      variant="outlined"
      onClick={onClick}
      disabled={renderTooltipDeleteIcon()[1]}
      icon={<DeleteIcon />}
    />
  );
}

DeleteButton.propTypes = {
  onClick: PT.func.isRequired,
};
export default DeleteButton;
