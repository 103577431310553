/* eslint-disable no-confusing-arrow */
import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  navList: {
    paddingTop: 0,
  },
  title: {
    padding: props =>
      props.drawerOpen ? theme.spacing(1, 2, 1, 2) : theme.spacing(1),
  },
}));
