// todo: remove in refactor
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { useState, useEffect } from 'react';

export default parameter => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  // test data for now
  // relevance = favourite, limiting value(max/min?), non-compliant
  const [mockData, setMockData] = useState([
    {
      id: 1,
      relevance: ['favourite', 'limiting value'],
      source: {
        source: 'CIBSE Guide A 2019',
        valueLocation: 'Page 22',
        directoryURL: 'wwww.BritishGuideOffice.com/guide/2020',
        published: '17/01/2020',
        comment: 'This has been assigned due to...',
      },
      entryValue: '6',
      type: 'Regulation',
      status: 'checked',
      sourceOpen: false,
      popoverOpen: false,
      relevanceOpen: false,
      editEntryOpen: false,
    },
    {
      id: 2,
      relevance: ['limiting value'],
      source: {
        source: 'CIBSE Guide A 2019',
        valueLocation: 'Page 22',
        directoryURL: 'wwww.BritishGuideOffice.com/guide/2020',
        published: '17/01/2020',
        comment: 'This has been assigned due to...',
      },
      entryValue: '8',
      type: 'Regulation',
      status: 'answered',
      sourceOpen: false,
      popoverOpen: false,
      relevanceOpen: false,
      editEntryOpen: false,
    },
    {
      id: 3,
      relevance: [''],
      source: {
        source: 'CIBSE Guide A 2019',
        valueLocation: 'Page 22',
        directoryURL: 'wwww.BritishGuideOffice.com/guide/2020',
        published: '17/01/2020',
        comment: 'This has been assigned due to...',
      },
      entryValue: '11',
      type: 'Regulation',
      status: 'approved',
      sourceOpen: false,
      popoverOpen: false,
      relevanceOpen: false,
      editEntryOpen: false,
    },
    {
      id: 4,
      relevance: ['non compliant'],
      source: {
        source: 'CIBSE Guide A 2019',
        valueLocation: 'Page 22',
        directoryURL:
          'wwww.BritishGuideOffice.com/guide/2020 ashjahashash ahashasskas kjasjasjashasjd jasdjs jshdjsjsh',
        published: '17/01/2020',
        comment: 'This has been assigned due to...',
      },
      entryValue: '12',
      type: 'Regulation',
      status: 'checked',
      sourceOpen: false,
      popoverOpen: false,
      relevanceOpen: false,
      editEntryOpen: false,
    },
  ]);

  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (id, event) => {
    const updateMockData = mockData.map(data => {
      if (id === data.id) {
        setAnchorEl(event.currentTarget);
        return { ...data, popoverOpen: !data.popoverOpen };
      }
      return { ...data, popoverOpen: false };
    });
    setMockData(updateMockData);
  };

  const handleSourceOpen = id => {
    const updateMockData = mockData.map(data => {
      if (id === data.id) {
        return { ...data, sourceOpen: !data.sourceOpen };
      }
      return { ...data, sourceOpen: false };
    });
    setMockData(updateMockData);
  };

  const handleRelevanceOpen = (id, event) => {
    const updateMockData = mockData.map(data => {
      if (id === data.id) {
        setAnchorEl(event.currentTarget);
        return { ...data, relevanceOpen: !data.relevanceOpen };
      }
      return { ...data, relevanceOpen: false };
    });
    setMockData(updateMockData);
  };

  const handleEditEntryOpen = id => {
    const updateMockData = mockData.map(data => {
      if (id === data.id) {
        return { ...data, editEntryOpen: !data.editEntryOpen };
      }
      return { ...data, editEntryOpen: false };
    });
    setMockData(updateMockData);
  };

  const [header, setHeader] = useState('');
  const [compliantEntries, setCompliantEntries] = useState([]);
  const [nonCompliantEntries, setNonCompliantEntries] = useState([]);
  useEffect(() => {
    setLoading(true);
    const getHeader = () => {
      mockData.forEach(dataItem => {
        dataItem.relevance.forEach(relevance => {
          if (relevance === 'favourite') {
            setHeader(dataItem.source.source);
          }
        });
      });
    };
    const getCompliant = () => {
      const newCompliantEntries = mockData.filter(
        entry => !entry.relevance.includes('non compliant')
      );
      setCompliantEntries(newCompliantEntries);
      const newNonCompliantEntries = mockData.filter(entry =>
        entry.relevance.includes('non compliant')
      );
      setNonCompliantEntries(newNonCompliantEntries);
    };
    if (parameter?.id) {
      getHeader();
      getCompliant();
      setLoading(false);
    }
  }, [mockData, parameter.id]);

  return {
    open,
    handleOpen,
    loading,
    header,
    compliantEntries,
    nonCompliantEntries,
    mockData,
    handlePopoverOpen,
    handleSourceOpen,
    handleRelevanceOpen,
    handleEditEntryOpen,
    anchorEl,
  };
};
