import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  tableCell: {
    verticalAlign: 'top',
    borderBottom: 'none',
    borderTop: '1px solid',
    borderTopColor: 'rgb(224, 224, 224)',
  },
  cellAlign: {
    verticalAlign: 'top',
    borderBottom: 'none',
    borderTop: '1px solid',
    borderTopColor: 'rgb(224, 224, 224)',
    paddingTop: 31,
  },
  roundIcon: {
    border: '1px solid black',
    borderRadius: '50%',
    display: 'inline-block',
    marginLeft: -7,
  },
  starIcon: {
    color: '#f2da00',
    border: '1px solid black',
    borderRadius: '50%',
    display: 'inline-block',
    marginLeft: -7,
  },
  noIcon: {
    border: '1px solid black',
    borderRadius: '50%',
    display: 'inline-block',
    width: 24,
    height: 24,
    marginLeft: -7,
  },
  nonCompliantIcon: {
    marginLeft: -7,
  },
  sourceCell: {
    verticalAlign: 'top',
    borderBottom: 'none',
    borderTop: '1px solid',
    borderTopColor: 'rgb(224, 224, 224)',
    maxWidth: 350,
  },
  sourceData: {
    marginLeft: 48,
  },
  editButtons: {
    color: 'black',
  },
  counter: {
    backgroundColor: 'white',
    border: '1px solid black',
    borderRadius: '50%',
    marginTop: 5,
  },
  tableHead: {
    color: theme.palette.text.secondary,
  },
  popoverButton: {
    backgroundColor: 'white',
  },
  checkIcon: {
    color: 'green',
  },
  relevanceText: {
    marginLeft: 5,
  },
}));
