// import apiGenerator from './api-generator';
// import {
//   commentsClient,
//   parametersClient,
//   environmentContextClient,
//   qaClient,
//   parameterMetadataClient,
//   referenceDataClient,
//   userClient,
// } from './clients';

// export { getAvatar, getUserInformation } from './graph';

// export const parametersApi = async (operationId, query, cancelToken) =>
//   apiGenerator(parametersClient, operationId, query, cancelToken);
// export const referenceDataApi = async (operationId, query, cancelToken) =>
//   apiGenerator(referenceDataClient, operationId, query, cancelToken);
// export const parameterMetadataApi = async (operationId, query, cancelToken) =>
//   apiGenerator(parameterMetadataClient, operationId, query, cancelToken);
// export const environmentContextApi = async (operationId, query, cancelToken) =>
//   apiGenerator(environmentContextClient, operationId, query, cancelToken);
// export const commentsApi = async (operationId, query, cancelToken) =>
//   apiGenerator(commentsClient, operationId, query, cancelToken);
// export const qaApi = async (operationId, query, cancelToken) =>
//   apiGenerator(qaClient, operationId, query, cancelToken);
// export const userApi = async (operationId, query, cancelToken) =>
//   apiGenerator(userClient, operationId, query, cancelToken);

import ApiError from './api-error';
import handleApiError from './handle-api-error';
import apiGenerator from './api-generator';
import {
  commentsClient,
  parametersClient,
  environmentContextClient,
  qaClient,
  parameterMetadataClient,
  referenceDataClient,
  userClient,
} from './clients';

export { getUserInformation } from './graph';

export const parametersApi = async (operationId, query, cancelToken) =>
  apiGenerator(parametersClient, operationId, query, cancelToken);
export const referenceDataApi = async (operationId, query, cancelToken) =>
  apiGenerator(referenceDataClient, operationId, query, cancelToken);
export const parameterMetadataApi = async (operationId, query, cancelToken) =>
  apiGenerator(parameterMetadataClient, operationId, query, cancelToken);
export const environmentContextApi = async (operationId, query, cancelToken) =>
  apiGenerator(environmentContextClient, operationId, query, cancelToken);
export const commentsApi = async (operationId, query, cancelToken) =>
  apiGenerator(commentsClient, operationId, query, cancelToken);
export const qaApi = async (operationId, query, cancelToken) =>
  apiGenerator(qaClient, operationId, query, cancelToken);
export const userApi = async (operationId, query, cancelToken) =>
  apiGenerator(userClient, operationId, query, cancelToken);

export { ApiError };

export { handleApiError };
