import { useState, useEffect } from 'react';

export default (theAssets, theAssetTypes) => {
  const [orderedAssetSummary, setOrderedAssetSummary] = useState([]);

  useEffect(() => {
    const filteredAssets = theAssets.filter(asset => asset.new);

    const buildMyParents = (parent, returnArray) => {
      if (parent) {
        const item = theAssets.find(asset => asset.id === parent);
        returnArray.push(item?.name);
        buildMyParents(item?.parent, returnArray);
      }
      return returnArray;
    };

    const makeAssetTypeTheParent = () => {
      const uniqueAssetTypes = [
        ...new Set(filteredAssets.map(asset => asset.asset_type.id)),
      ].map(id => {
        return {
          id,
          name: theAssetTypes.find(assetType => assetType.id === id).name,
          level: theAssetTypes.find(assetType => assetType.id === id).level,
          open: true,
          assets: filteredAssets
            .filter(asset => asset.new && asset.asset_type.id === id)
            .map(asset => {
              return {
                id: asset.id,
                name: asset.name,
                parentage: buildMyParents(asset.parent, []).reverse(),
              };
            }),
        };
      });

      setOrderedAssetSummary(
        uniqueAssetTypes.sort((a, b) => a.level - b.level)
      );
    };

    makeAssetTypeTheParent();
  }, [theAssetTypes, theAssets]);

  const handleToggleOpen = index => {
    const newAssetTypes = [...orderedAssetSummary];
    newAssetTypes[index].open = !newAssetTypes[index].open;
    setOrderedAssetSummary(newAssetTypes);
  };

  return {
    orderedAssetSummary,
    handleToggleOpen,
  };
};
