/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PT from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ToolTip from '@material-ui/core/Tooltip';
import { useTheme } from '@material-ui/styles';

const StyledIconButton = ({
  title,
  tooltipProps,
  icon,
  'data-testid': dataTestId,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <ToolTip title={title} {...tooltipProps} data-cy="icon-button-tooltip">
      <span data-testid={dataTestId}>
        <IconButton
          color={'default'}
          {...rest}
          data-cy="icon-button"
        >
          {icon}
        </IconButton>
      </span>
    </ToolTip>
  );
};

StyledIconButton.propTypes = {
  onClick: PT.func,
  title: PT.string,
  tooltipProps: PT.object,
  icon: PT.any.isRequired,
  'data-testid': PT.string,
};

StyledIconButton.defaultProps = {
  onClick: () => {},
  title: '',
  tooltipProps: {},
  'data-testid': null,
};

export default StyledIconButton;
