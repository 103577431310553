/* eslint-disable no-unused-vars */
import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    '& label.Mui-focused': {
      color: lighten(theme.palette.primary.main, 0.5),
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: lighten(theme.palette.primary.main, 0.5),
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: lighten(theme.palette.primary.main, 0.5),
      },
    },
  },
}));
