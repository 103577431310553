import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { parametersApi, handleApiError } from '../../../../api';
import config from '../../../../config';
import {
  DeleteButton,
  UpdateStatusButton,
  RejectButton,
  EditButton,
  CopyButton,
} from '../../../buttons';

export default (
  parameter,
  editMode,
  toggleEditMode,
  screenMd,
  parameterId,
  updateStatus,
  setOpenModal
) => {
  const { t } = useTranslation(['common', 'parameters']);
  const [searchParams, setSearchParams] = useSearchParams();
  const [assetsLoading, setAssetsLoading] = useState(true);
  const [assetList, setAssetList] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [displayWarning, setDisplayWarning] = useState(false);

  useEffect(() => {
    let didCancel = false;
    const source = axios.CancelToken.source();

    let parents = parameter?.parents || [];
    setAssetList(parents);
    setAssetsLoading(true);

    const getParentAssets = async () => {
      let parentId = parents.length && parents[parents.length - 1].parent;
      // sdp - remove header tag in parameter column UI , also has a async undefined bug in sdp
      // while (parentId && !didCancel) {
      //   // eslint-disable-next-line no-await-in-loop
      //   const { asset: parentAsset } = await parametersApi(
      //     'getAsset',
      //     {
      //       asset_id: parentId,
      //     },
      //     source.token
      //   ).catch(err => handleApiError(err, []));
      //   parents = [parentAsset, ...parents];
      //   parentId = parentAsset.parent;
      // }
      if (!didCancel) {
        setAssetList(parents);
        setAssetsLoading(false);
      }
    };

    if (parents.length > 0) getParentAssets();
    else setAssetsLoading(false);

    return () => {
      didCancel = true;
      source.cancel();
    };
  }, [parameter]);

  const deleteParameter = async () => {
    setDeleteLoading(true);

    await parametersApi('deleteParameter', {
      parameter_id: parameter.id,
    }).catch(err => handleApiError(err, []));
    setDeleteLoading(false);
    searchParams.delete('parameter_id');
    setSearchParams(qs.parse(searchParams.toString()));
  };

  const warningClose = () => {
    setDisplayWarning(false);
  };

  const warningAccept = () => {
    deleteParameter();
    warningClose();
  };

  const getHeaderActions = () => {
    const headerActions = [];
    if (!editMode) {
      // if (screenMd) {
      //   headerActions.push(
      //     <CopyButton
      //       toBeCopied={parameterId}
      //       toolTipText={t('common:dataDrawer:header.copyTooltip')}
      //     />
      //   );
      // }
      headerActions.push(
        <DeleteButton
          onClick={() => {
            setDisplayWarning(true);
          }}
        />
      );
      if (!config.featureFlags.isMultipleEntriesEnabled) {
        if (
          parameter?.revision?.values &&
          parameter?.revision?.status !== 'rejected'
        ) {
          // headerActions.push(
          //   <UpdateStatusButton
          //     updateStatus={updateStatus}
          //     status={parameter?.revision?.status}
          //   />
          // );
        }
        if (
          parameter?.revision?.status !== 'rejected' ||
          parameter?.revision?.status !== 'unanswered' ||
          parameter?.revision?.status !== undefined
        ) {
          // headerActions.push(
          //   <RejectButton
          //     status={parameter?.revision?.status}
          //     onClick={() => setOpenModal(true)}
          //   />
          // );
        }
      }
    }
    // if (!config.featureFlags.isMultipleEntriesEnabled) {
    //   headerActions.push(
    //     <EditButton onClick={toggleEditMode} isEditing={editMode} />
    //   );
    // }
    return headerActions;
  };

  return {
    assetList,
    assetsLoading,
    setAssetsLoading,
    deleteLoading,
    deleteParameter,
    displayWarning,
    setDisplayWarning,
    warningClose,
    warningAccept,
    getHeaderActions,
  };
};
