import PT from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import useStyles from './status-circle-styles';

function StatusCircle({ status, size, thickness, loading }) {
  const classes = useStyles({ size });
  const statusTip = {
    unanswered: 0,
    answered: 100 / 3,
    checked: 200 / 3,
    approved: 100,
  };

  return loading ? (
    <Skeleton
      variant="circle"
      width={size}
      height={size}
      data-testid="statusLoading"
    />
  ) : (
    <Grid container className={classes.iconDiv}>
      {status === 'rejected' ? (
        <Grid item>
          <Tooltip wrapper="span" title={status}>
            <PriorityHighIcon className={classes[status]} />
          </Tooltip>
        </Grid>
      ) : (
        <Grid item>
          <CircularProgress
            size={size}
            thickness={thickness}
            variant="determinate"
            value={100}
            className={classes.iconBackground}
          />
          <Tooltip wrapper="span" title={status}>
            <CircularProgress
              size={size}
              thickness={thickness}
              variant="determinate"
              value={statusTip[status]}
              className={classes[status]}
            />
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
}

StatusCircle.propTypes = {
  status: PT.string,
  size: PT.number,
  thickness: PT.number,
  loading: PT.bool,
};

StatusCircle.defaultProps = {
  status: 'unanswered',
  size: 30,
  thickness: 10,
  loading: false,
};

export default StatusCircle;
