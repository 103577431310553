/* eslint-disable no-confusing-arrow */
import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  // truncateOverflow: {
  //   maxHeight: `calc(${theme.typography.body1.lineHeight}rem * 2)`,
  //   overflow: 'hidden',
  //   display: 'flex',
  //   alignItems: 'center',
  // },
  heading: {
    // backgroundColor: '#007aff',
    color: '#000',
    borderBottom: `1px solid ${theme.palette.divider}`,
    minHeight: theme.spacing(8.1),
  },
  // avatar: { marginBottom: theme.spacing(1) },
}));
