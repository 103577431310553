/* eslint-disable no-console */
// todo: remove eslint rules in refactor
// this file is temperamental please do not add to it
/* eslint-disable no-shadow */
/* eslint-disable camelcase */

import { useState, useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import { startCase, uniqWith, isEqual } from 'lodash';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import { parametersApi, handleApiError } from '../../../../api';
import { ParametersContext } from '../../../../context/parameters-context';

export default (projectName, navigationRefresh, toggleDrawerOpen) => {
  const [lastAsset, setLastAsset] = useState('');
  const { projectId } = useParams();
  const { t } = useTranslation(['common', 'parameters']);
  const [history, setHistory] = useState([]); // Array of previous states
  const [currentLinks, setCurrentLinks] = useState([]); // Links to be passed to nav component
  const [loading, setLoading] = useState(true);
  const { searchParams, setSearchParams } = useContext(ParametersContext);
  const parsedSearch = qs.parse(searchParams.toString());

  const routerNavigate = useCallback(
    ({ asset_id, project_parameters_only }) => {
      if (!asset_id && !project_parameters_only) {
        // empty query (all parameters)
        setSearchParams({ ...parsedSearch });
      } else if (project_parameters_only) {
        // query for project_id project level parameters only
        setSearchParams({
          ...parsedSearch,
          project_parameter: project_parameters_only,
        });
      } else {
        const [assetId] = asset_id;
        setSearchParams({
          // ...parsedSearch,
          asset_id: assetId,
          
        });
      }
      // toggleDrawerOpen(); // sdp - disabled changing UI of speckle parameter column
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, setSearchParams]
  );

  // Function called when a list item is is clicked
  const navigate = useCallback(
    async (query, name) => {
      setLoading(true);
      // Function to update the history based on what is clicked
      const addToHistory = (name, links, isAsset = false) => {
        setHistory(history => {
          const newLinks = [
            {
              // First item in history should always be a back button to the last state
              id: history[history.length - 1].id,
              label: history[history.length - 1].name,
              back: true,
              onClick: () => setHistory(history => history.slice(0, -1)),
            },
            { id: 'divider0', divider: true }, // Add a divider below the back button
          ];

          const isCurrentlyAsset = isAsset && history.length > 1; // Check if the last click is an asset so that is can be selected
          if (isCurrentlyAsset) {
            newLinks.push(
              {
                id: `${name} ${query.parent_id}`,
                label: name,
                primaryTypographyProps: { fontWeight: 500 },
                onClick: () => {
                  setLastAsset('');
                  routerNavigate({
                    asset_id: query.parent_id,
                  }); // onClick query by asset_id
                },
              },
              ...links.map(e => ({
                ...e,
                indent: 1, // Add indent to text
              }))
            );
          } else newLinks.push(...links);

          return history.concat([
            // Add new element to end of history
            {
              name,
              links: newLinks,
              onClick: index => setHistory(history => history.slice(0, index)),
            },
          ]);
        });
      };

    
      const { assets } = await parametersApi('getAssets', query).catch(err =>
        handleApiError(err, [])
      );
      // Get all unique asset types from the assets call
      const uniqAssTyp = uniqWith(
        assets.map(({ asset_type }) => asset_type),
        isEqual
      );

      // Check if more than one asset type
      if (uniqAssTyp.length > 1) {
        // Create a list of asset types if more than 1 asset type
        const links = Array.from(uniqAssTyp).map(({ id, name }) => {
          // Create an array of assets that are children of the current asset type
          const children = assets.reduce(
            (acc, { id: assetId, name, asset_type, children }) => {
              const hasChildren = Boolean(children.length); // Check if the asset has children
              const isDuplicate = acc.some(
                ({ label }) => label.toLowerCase() === name.toLowerCase()
              );
              // Check if the item already exists
              if (asset_type.id === id) {
                // Check if this is the asset type matches
                if (isDuplicate) {
                  acc.map(listItem => {
                    if (listItem.label.toLowerCase() === name.toLowerCase())
                      listItem.parent_id.push(id); // Push the id to the parents if exists
                    return listItem;
                  });
                } else {
                  const listItem = {
                    id: assetId,
                    label: name,
                    parent_id: [id],
                    onClick() {
                      setLastAsset('');
                      // If the asset has children, continue navigating down the tree,  else set selected asset for highlighting
                      if (hasChildren)
                        navigate(
                          { asset_id: [assetId], project_id: [projectId] },
                          name
                        );
                      else setLastAsset(name);
                      return routerNavigate({
                        asset_id: [assetId],
                      }); // and query the parameters by asset_id
                    },
                    children: hasChildren,
                  };
                  acc.push(listItem);
                }
              }
              return acc;
            },
            []
          );

          return {
            id,
            label: name,
            onClick: () => addToHistory(name, children),
            children: true,
          };
        });

        addToHistory(name, links, true);
      } else {
        // Create a list of assets if there is only one asset type
        const links = assets.reduce((acc, { id, name, children }) => {
          const hasChildren = Boolean(children?.length);

          const isDuplicate = acc.some(
            ({ label }) => label.toLowerCase() === name.toLowerCase()
          );
          // Check if the item already exists
          if (isDuplicate) {
            acc.map(listItem => {
              if (listItem.label.toLowerCase() === name.toLowerCase())
                listItem.parent_id.push(id); // Push the id to the parents if exists
              return listItem;
            });
          } else {
            const listItem = {
              id,
              label: name,
              parent_id: [id],
              onClick() {
                setLastAsset('');
                const { parent_id } = listItem;
                // If the asset has children, continue navigating down the tree,  else set selected asset for highlighting
                if (hasChildren)
                  navigate({ parent_id, project_id: [projectId] }, name);
                else setLastAsset(name);
                return routerNavigate({ asset_id: parent_id }); // and query the parameters by asset_id
              },
              children: hasChildren,
            };
            acc.push(listItem);
          }
          return acc;
        }, []);

        addToHistory(name, links, true);
      }
    },
    [projectId, routerNavigate]
  );

  // On project change
  useEffect(() => {
    let didCancel = false;
    const source = axios.CancelToken.source();
    
    

    const getAssetTypes = async (after = "", links_cur = []) => {
      setLoading(true);
      const response = await parametersApi('getAssets', {
        asset_type_id: ['8882fd17-20bb-4e55-8d36-4a54dca1599a'], // prod generic asset type
        project_id: "00ea91bb-9c7c-42e1-b728-14e4373e4f1e", // sdp generic project
        // asset_type_id: ["577cfd8d-8da0-4d78-b4a4-c81ab728d4bf"] // dev
        after: after,
        sort_by: "name",
        order: "asc",
      }).catch(err => handleApiError(err, []));
      
      // console.log(response)

      if (response) {
        const links = [
          ...links_cur,
          ...(response.assets
            ?.map(e => ({
              id: e.id,
              label: startCase(e.name, false),
              onClick: () => {
                // routerNavigate({}); // sdp - disabled all parameters route
                // routerNavigate({ project_parameters_only: true }); // sdp - disabled project parameters route
                return routerNavigate({
                  asset_id: [e.id]
                }); // sdp - enable query the parameters by asset_id route
              },
              children: false,
            }))
            ?.sort((a, b) => a.label.localeCompare(b.label)) || []), // sdp - sort by parameter name alphabetic order
        ];
        
        // check if next
        const resp_after = response.paging?.cursors?.after;
        
        if (!didCancel) {
          // Set initial history
          const resetHistory = () => setHistory([{ name: projectName, links, onClick: resetHistory }]);
          resetHistory();
          if (resp_after && resp_after !== "") {
            getAssetTypes(resp_after, links);
          } else {
            
            setLoading(false);
          }
        }
      }
    };

    if (projectId) getAssetTypes();

    return () => {
      didCancel = true;
      source.cancel();
    };
  }, [navigate, projectId, projectName, routerNavigate, navigationRefresh, t]);

  // Update the current list of items
  useEffect(() => {
    setCurrentLinks(history.length ? history[history.length - 1].links : []);
    setLoading(!history.length);
  }, [history]);

  return { links: currentLinks, history, loading, lastAsset };
};
