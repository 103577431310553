import PT from 'prop-types';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import StyledIconButton from '../styled';
import useButton from '../../hooks/use-button';

function UpdateStatusButton({ updateStatus, status }) {
  const { renderTooltipDoneIcon } = useButton();
  return (
    <StyledIconButton
      type="updateStatus"
      title={renderTooltipDoneIcon(status)[0]}
      variant="outlined"
      onClick={() => {
        if (status === 'answered') updateStatus('checked');
        if (status === 'checked') updateStatus('approved');
      }}
      disabled={renderTooltipDoneIcon(status)[1]}
      icon={
        status === 'answered' ? (
          <DoneIcon aria-label="Done icon" />
        ) : (
          <DoneAllIcon aria-label="Done all icon" />
        )
      }
    />
  );
}

UpdateStatusButton.propTypes = {
  updateStatus: PT.func.isRequired,
  status: PT.string.isRequired,
};
export default UpdateStatusButton;
