import { useContext } from 'react';
import ToolTip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import useStyles from './add-parameter-button-style';
import ParameterWizard from './parameter-wizard';
import { ProjectPermissionContext } from '../../../../context/project-permission-context';
import useHooks from './hooks';
import { Fab } from '../../../../components/buttons';

function AddParameterButton() {
  const { search } = useLocation();
  const { asset_id: assetId, project_parameter: projectParameter } = {
    ...qs.parse(search, { ignoreQueryPrefix: true }),
  };
  const { writePermission } = useContext(ProjectPermissionContext);
  const { parameterWizardOpen, handleToggleOpen } = useHooks();
  const classes = useStyles();
  const { t } = useTranslation(['common', 'parameters']);

  const disabled = !(assetId || projectParameter);

  const renderToolTip = () => {
    if (!writePermission) return t('common:permission.notAllowed');
    if (disabled) return t('parameters:selectAsset');
    return t('parameters:addParameters');
  };

  // Tooltips are not allowed on disabled buttons - this switches the element to a div when disabled to allow the tooltip
  const adjustedButtonPropsToAllowTooltip = {
    disabled: !writePermission ? true : disabled,
    component: disabled ? 'span' : undefined,
    onClick: disabled ? undefined : handleToggleOpen,
    className: classes.iconButton,
  };

  return (
    <>
      <ToolTip
        title={renderToolTip()}
        data-testid="add-parameter-button"
        data-cy="icon-button-tooltip"
        {...adjustedButtonPropsToAllowTooltip}
      >
        <span>
          <Fab
            variant="round"
            size="small"
            style={{ transform: 'scale(0.8)' }}
            aria-label="Add"
            disabled={disabled}
          >
            <AddIcon />
          </Fab>
        </span>
      </ToolTip>
      <ParameterWizard
        open={parameterWizardOpen}
        handleToggleOpen={handleToggleOpen}
      />
    </>
  );
}

export default AddParameterButton;
