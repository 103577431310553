import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import qs from 'qs';
import useStyles from './header-styles';
import AddParameterButton from '../add-parameter';
import { StyledIconButton } from '../../../../components/buttons';
import Typography from '@material-ui/core/Typography';

function Header({
  handleFilterActive,
  isFilterActive,
  searchParams,
  setSearchParams,
}) {
  const classes = useStyles({ isFilterActive });
  const { t } = useTranslation(['parameters, common']);
  const parsedSearch = qs.parse(searchParams.toString());

  const handleSortDirection = () =>
    setSearchParams({
      ...parsedSearch,
      order: parsedSearch.order === 'asc' ? 'desc' : 'asc',
    });

  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      data-testid="parameterListHeader"
      className={classes.header}
    >
      <Grid item>
        <Typography className={classes.truncateOverflow}>
          PARAMETERS
        </Typography>
        
        {/* <StyledIconButton
          color="default"
          title={t('common:filterAndSort')}
          onClick={handleFilterActive}
          aria-label="Filter and Sort"
          icon={
            <FilterListIcon
              data-testid="filterIcon"
              classes={{ root: classes.filterColor }}
            />
          }
        />
        <StyledIconButton
          color="default"
          title={t('common:sortDirection')}
          onClick={handleSortDirection}
          aria-label="Sort direction"
          icon={
            parsedSearch.order === 'asc' ? (
              <ArrowUpwardIcon data-testid="asc" />
            ) : (
              <ArrowDownwardIcon data-testid="desc" />
            )
          }
        /> */}
      </Grid>
      {/* <Grid item>
        <AddParameterButton />
      </Grid> */}
    </Grid>
  );
}

Header.propTypes = {
  handleFilterActive: PT.func.isRequired,
  setSearchParams: PT.func.isRequired,
  isFilterActive: PT.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  searchParams: PT.any.isRequired,
};

export default Header;
