import PT from 'prop-types';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import clsx from 'clsx';
import useStyles from './asset-wizard-summary-list-styles';
import useHooks from './hooks';
import { assetTypeType } from '../../../../types';

function AssetWizardSummaryList(props) {
  const { theAssets, theAssetTypes } = props;
  const classes = useStyles();

  const { orderedAssetSummary, handleToggleOpen } = useHooks(
    theAssets,
    theAssetTypes
  );

  return (
    <div className={clsx(classes.verticalScroll, classes.list)}>
      {orderedAssetSummary.map((assetType, i) => (
        <List disablePadding key={assetType.id}>
          <ListItem
            button
            onClick={() => {
              handleToggleOpen(i);
            }}
          >
            <ListItemIcon>
              {assetType.open ? (
                <KeyboardArrowDownIcon aria-label="Collapse" />
              ) : (
                <KeyboardArrowRightIcon aria-label="Expand" />
              )}
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ variant: 'h6' }}
              className={classes.header}
              primary={`${assetType.name} (${assetType.assets.length}) `}
            />
          </ListItem>
          <Collapse in={assetType.open}>
            {assetType.assets.map(asset => (
              <ListItem className={classes.noPadding} key={asset.id}>
                <ListItemText primary={asset.name} />
                <Breadcrumbs maxItems={2} aria-label="breadcrumb">
                  {asset.parentage.map((item, j) => (
                    <Typography
                      className={classes.breadcrumbHeader}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${item} ${j}`}
                    >
                      {item}
                    </Typography>
                  ))}
                </Breadcrumbs>
              </ListItem>
            ))}
          </Collapse>
        </List>
      ))}
    </div>
  );
}

AssetWizardSummaryList.propTypes = {
  theAssets: PT.arrayOf(
    PT.shape({ name: PT.string.isRequired, asset_id: PT.string })
  ).isRequired,
  theAssetTypes: PT.arrayOf(assetTypeType).isRequired,
};

export default AssetWizardSummaryList;
