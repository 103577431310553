import { makeStyles, lighten, darken } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  media: {
    height: 200,
    width: '100%',
  },
  root: {
    height: '100%',
    backgroundColor:
      theme.palette.type === 'dark'
        ? lighten(theme.palette.background.default, 0.2)
        : darken(theme.palette.background.default, 0.05),
  },
  text: {
    color: theme.palette.text.disabled,
  },
  icon: { fontSize: '140px', color: theme.palette.primary.light },
}));

export default useStyles;
