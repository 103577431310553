import { useContext } from 'react';
import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import ToolTip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import Divider from '@material-ui/core/Divider';
import useStyles from './nav-heading-styles';
import { ProjectPermissionContext } from '../../../../context/project-permission-context';
import { Fab } from '../../../../components/buttons';
import { FavouritesButton } from '../../../../components/favourites';
import ConfidentialChip from '../../../../components/chips/confidential';

function NavigationHeading({
  drawerOpen,
  project,
  loading,
  toggleAssetWizard,
  toggleDrawerOpen,
}) {
  const { writePermission } = useContext(ProjectPermissionContext);
  const { t } = useTranslation(['common', 'parameters']);
  const classes = useStyles({ drawerOpen });

  return (
    <>
    
      <Grid
        container
        direction='row'
        justify="space-between"
        alignItems="center"
        className={classes.heading}
        data-testid="navigationHeading"
      >
        <Grid item xs>
          <Typography className={classes.truncateOverflow}>
            PROJECTS
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {project?.confidential && <ConfidentialChip iconOnly />}
        </Grid>
      </Grid>
      <Divider light />
    </>
  );
}

NavigationHeading.propTypes = {
  project: PT.shape({ jobNameShort: PT.string, confidential: PT.bool })
    .isRequired,
  loading: PT.bool.isRequired,
  drawerOpen: PT.bool.isRequired,
  toggleAssetWizard: PT.func.isRequired,
  toggleDrawerOpen: PT.func,
};

NavigationHeading.defaultProps = {
  toggleDrawerOpen: () => {},
};

export default NavigationHeading;
