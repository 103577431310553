import PT from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useStyles from '../style';

function BasicDropdown({ getOptionLabel, options, label, ...rest }) {
  const classes = useStyles();
  return (
    <Autocomplete
      {...rest}
      options={options}
      getOptionLabel={getOptionLabel}
      renderInput={params => (
        <TextField
          {...params}
          classes={{ root: classes.root }}
          label={label}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            'aria-label': label,
          }}
        />
      )}
    />
  );
}
BasicDropdown.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  options: PT.array.isRequired,
  label: PT.string,
  getOptionLabel: PT.func,
};

BasicDropdown.defaultProps = {
  label: null,
  getOptionLabel: option => option.title,
};

export default BasicDropdown;
