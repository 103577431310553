/* eslint-disable no-console */
// todo: remove eslint rules in refactor
// this file is temperamental please do not add to it
/* eslint-disable no-shadow */
/* eslint-disable camelcase */

import { useState, useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import { startCase, uniqWith, isEqual } from 'lodash';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import { parametersApi, handleApiError } from '../../../api';
import { ParametersContext } from '../../../context/parameters-context';
// import useAccessToken from '../../../../components/useAccessToken';
import { getToken } from '../../../auth';
// C:\Users\yun.sung\sdp-app\src\components\useAccessToken.js
// C:\Users\yun.sung\sdp-app\src\routes\projects\navigation\hooks\index.js

export default () => {
  const [lastAsset, setLastAsset] = useState('');
  const { projectId } = useParams();
  const { t } = useTranslation(['common', 'parameters']);
  const [history, setHistory] = useState([]); // Array of previous states
  const [currentLinks, setCurrentLinks] = useState([]); // Links to be passed to nav component
  const [loading, setLoading] = useState(true);
  const { searchParams, setSearchParams } = useContext(ParametersContext);
  const parsedSearch = qs.parse(searchParams.toString());
  const [genericProjects, setGenericProjects] = useState([]);
  const [specificProjects, setSpecificProjects] = useState([]);
  const [models, setModels] = useState([]);
  const [parameters, setParameters] = useState([]);
  const [loadingModelDetails, setLoadingModelDetails] = useState(false);
  const [loadingParameterDetails, setLoadingParameterDetails] = useState(false);
  const [selectedProject, setSelectProject] = useState();
  // const { token } = useAccessToken();

  const GENERIC_ASSET_TYPE_ID = "8882fd17-20bb-4e55-8d36-4a54dca1599a";
  const GENERIC_PROJECT_ID = "11a4d826-72e0-4841-b7fd-6421da46b4bb";
  const SDP_TAG_ID = 'ea240f70-ebbf-11ec-8ea0-0242ac120002';
  const SDP_TAG_ALIAS = 'SDP Category';
  const SDP_SOURCE_ID = '86101028-0967-4b53-8151-a934534c3939';
  const API_URL = "https://sandbox.ddb.arup.com";

  const [token, setToken] = useState();

    useEffect(() => {
        getToken().then(token => {
            setToken(token);
        })
    }, []);

  useEffect(async () => {
    if (token) {
      // get generic projects from generic asset id tagged with project SDP
      // const { assets: data } = await parametersApi('getAssets', {
      //   asset_type_id: GENERIC_ASSET_TYPE_ID,
      //   project_id: genericProjectId,
      // }).catch(err => handleApiError(err, []));

      const headers = {"Authorization": token, "version": '1'}
      const {data: { assets: assets }} = await axios.get(API_URL + `/api/assets?asset_type_id=${GENERIC_ASSET_TYPE_ID}&project_id=${GENERIC_PROJECT_ID}&show_active=true&show_deleted=false&sort_by=name&order=desc&page_limit=1000`, {headers})

      const result_projects = assets.map(x => {
        return {
          ...x,
          label: `Project ${x.name}`,
          onClick: async () => {
            setLoadingModelDetails(true);
            setModels([]);
            // projects/11a4d826-72e0-4841-b7fd-6421da46b4bb/parameters?order=asc&sort_by=parameter_type_name&asset_id=461ca0eb-c099-4e2b-a0a9-9fb7bb78bdaf
            // assets?project_id=990530bc-eacc-4a2f-be5e-486942fc4ded
            
            const {data: { parameters: model_parameters }} = await axios.get(API_URL + `/api/parameters?asset_id=${x.id}&&project_id=${GENERIC_PROJECT_ID}&project_parameter=false&sort_by=parameter_type_name&order=desc&page_limit=1000&show_active=true&show_deleted=false`, {headers});
            
            const result_model_parameters = model_parameters.map(x => {
              return {
                ...x,
                'asset_id': x.id,
                'api_url': API_URL,
              };
            })

            setParameters(result_model_parameters);
            // setSelectProject(`Project ${x.name}`);
            setSelectProject(null);
            setLoadingModelDetails(false);
          },
        }
      })
      .sort((a, b) => a.label.localeCompare(b.label))
      
      setGenericProjects(result_projects);
      setLoading(false);
    }
  }, [token])

  useEffect(async () => {
    if (token) {

      const headers = {"Authorization": token, "version": '1'};
      
      let next_temp = API_URL + `/api/tags?page_limit=1000&show_active=true&show_deleted=false&order=desc&sort_by=created_at&tag_type_id=${SDP_TAG_ID}&omit_global=true`;
      
      let sdp_project_ids = [];
      
      while (next_temp) {
        const {data: { tags: tags, paging: paging }} = await axios.get(next_temp, {headers});
        sdp_project_ids = sdp_project_ids.concat(tags.filter(x => x.tag_type.name === SDP_TAG_ALIAS).map(x => x.tag_scope));
        if (paging && 'next' in paging) {
          // console.log(paging['next'])
          next_temp = paging['next'];
        } else {
          next_temp = null;
        }
      }

      const projects_headers = {"Authorization": token, "Version": "2"};

      const sdp_project_ids_unqiue = [...new Set(sdp_project_ids)];
      const sdp_project_ids_string = sdp_project_ids_unqiue.map(x => `project_id=${x}`).join('&');
      const {data: { projects: projects }} = await axios.get(API_URL + `/api/projects?${sdp_project_ids_string}&sort_by=created_at&order=desc&page_limit=1000`, {headers: projects_headers});
      // const {data: { projects: projects }} = await axios.get(`https://sandbox.ddb.arup.com/api/projects?sort_by=created_at&show_active=true&show_deleted=false&order=desc&page_limit=50`, {projects_headers});
      
      const result_projects = projects.filter(x => x.number !== "07476000").map(x => {
        console.log(x)
        return {
          ...x,
          id: x.project_id,
          label: x.short_title,
          jn: x.number,
          onClick: async () => {
            setLoadingModelDetails(true);
            setParameters([]);
            // projects/11a4d826-72e0-4841-b7fd-6421da46b4bb/parameters?order=asc&sort_by=parameter_type_name&asset_id=461ca0eb-c099-4e2b-a0a9-9fb7bb78bdaf
            // assets?project_id=990530bc-eacc-4a2f-be5e-486942fc4ded
            const {data: { assets: model_assets }} = await axios.get(API_URL + `/api/assets?project_id=${x.project_id}&show_active=true&show_deleted=false&sort_by=name&order=desc&page_limit=1000`, {headers});
            
            const result_model_assets = model_assets.map(y => {
              return {
                ...y,
                modelOnClick: async () => {
                  setLoadingParameterDetails(true);
                  const {data: { parameters: model_parameters }} = await axios.get(API_URL + `/api/parameters?asset_id=${y.id}&&project_id=${x.project_id}&project_parameter=false&sort_by=parameter_type_name&order=desc&page_limit=1000&show_active=true&show_deleted=false`, {headers});
                  const result_model_parameters = model_parameters.map(x => {
                    return {
                      ...x,
                      'asset_id': y.id,
                      'api_url': API_URL,
                    };
                  })
                  setParameters(result_model_parameters);
                  setLoadingParameterDetails(false);
                }
              }
            })
            setModels(result_model_assets);

            // get all model parameters from model assets
            const all_model_parameters = await Promise.all(model_assets.map(z => axios.get(API_URL + `/api/parameters?asset_id=${z.id}&&project_id=${x.project_id}&project_parameter=false&sort_by=parameter_type_name&order=desc&page_limit=1000&show_active=true&show_deleted=false`, {headers})))
            
            const viz_result = [{'text_1': `Project ${x.job_name_short}`, 'id': 1, 'father': null, color: '#f3cb21'}];
            let count = 2;
            for (var v1 = 0; v1 < model_assets.length; v1++) {
              viz_result.push({'text_1': `Model ${model_assets[v1].name}`, 'id': count, 'father': 1, color: '#007aff'});
              const curr_count = count;
              count += 1;
              for (var v2 = 0; v2 < all_model_parameters[v1].data.parameters.length; v2++) {
                viz_result.push({'text_1': `${all_model_parameters[v1].data.parameters[v2].parameter_type.name}`, 'id': count, 'father': curr_count, color: '#F05023'});
                count += 1;
              }
            }
            
            setSelectProject(viz_result);
            setLoadingModelDetails(false);
          }
        }
      })
      .sort((a, b) => a.label.localeCompare(b.label))
      
      setSpecificProjects(result_projects);
      setLoading(false);
    }
  }, [token])

  const routerNavigate = useCallback(
    ({ asset_id, project_parameters_only }) => {
      if (!asset_id && !project_parameters_only) {
        // empty query (all parameters)
        setSearchParams({ ...parsedSearch });
      } else if (project_parameters_only) {
        // query for project_id project level parameters only
        setSearchParams({
          ...parsedSearch,
          project_parameter: project_parameters_only,
        });
      } else {
        // todo: temp fix to remove array from asset id
        const [assetId] = asset_id;
        setSearchParams({
          // ...parsedSearch,
          asset_id: assetId,
          
        });
      }
      // toggleDrawerOpen(); // sdp - disabled changing UI of speckle parameter column
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, setSearchParams]
  );

  // Function called when a list item is is clicked
  const navigate = useCallback(
    async (query, name) => {
      setLoading(true);
      // Function to update the history based on what is clicked
      const addToHistory = (name, links, isAsset = false) => {
        setHistory(history => {
          const newLinks = [
            {
              // First item in history should always be a back button to the last state
              id: history[history.length - 1].id,
              label: history[history.length - 1].name,
              back: true,
              onClick: () => setHistory(history => history.slice(0, -1)),
            },
            { id: 'divider0', divider: true }, // Add a divider below the back button
          ];

          const isCurrentlyAsset = isAsset && history.length > 1; // Check if the last click is an asset so that is can be selected
          if (isCurrentlyAsset) {
            newLinks.push(
              {
                id: `${name} ${query.parent_id}`,
                label: name,
                primaryTypographyProps: { fontWeight: 500 },
                onClick: () => {
                  setLastAsset('');
                  routerNavigate({
                    asset_id: query.parent_id,
                  }); // onClick query by asset_id
                },
              },
              ...links.map(e => ({
                ...e,
                indent: 1, // Add indent to text
              }))
            );
          } else newLinks.push(...links);

          return history.concat([
            // Add new element to end of history
            {
              name,
              links: newLinks,
              onClick: index => setHistory(history => history.slice(0, index)),
            },
          ]);
        });
      };

    
      const { assets } = await parametersApi('getAssets', query).catch(err =>
        handleApiError(err, [])
      );
      // Get all unique asset types from the assets call
      const uniqAssTyp = uniqWith(
        assets.map(({ asset_type }) => asset_type),
        isEqual
      );

      // Check if more than one asset type
      if (uniqAssTyp.length > 1) {
        // Create a list of asset types if more than 1 asset type
        const links = Array.from(uniqAssTyp).map(({ id, name }) => {
          // Create an array of assets that are children of the current asset type
          const children = assets.reduce(
            (acc, { id: assetId, name, asset_type, children }) => {
              const hasChildren = Boolean(children.length); // Check if the asset has children
              const isDuplicate = acc.some(
                ({ label }) => label.toLowerCase() === name.toLowerCase()
              );
              // Check if the item already exists
              if (asset_type.id === id) {
                // Check if this is the asset type matches
                if (isDuplicate) {
                  acc.map(listItem => {
                    if (listItem.label.toLowerCase() === name.toLowerCase())
                      listItem.parent_id.push(id); // Push the id to the parents if exists
                    return listItem;
                  });
                } else {
                  const listItem = {
                    id: assetId,
                    label: name,
                    parent_id: [id],
                    onClick() {
                      setLastAsset('');
                      // If the asset has children, continue navigating down the tree,  else set selected asset for highlighting
                      if (hasChildren)
                        navigate(
                          { asset_id: [assetId], project_id: [projectId] },
                          name
                        );
                      else setLastAsset(name);
                      return routerNavigate({
                        asset_id: [assetId],
                      }); // and query the parameters by asset_id
                    },
                    children: hasChildren,
                  };
                  acc.push(listItem);
                }
              }
              return acc;
            },
            []
          );

          return {
            id,
            label: name,
            onClick: () => addToHistory(name, children),
            children: true,
          };
        });

        addToHistory(name, links, true);
      } else {
        // Create a list of assets if there is only one asset type
        const links = assets.reduce((acc, { id, name, children }) => {
          const hasChildren = Boolean(children?.length);

          const isDuplicate = acc.some(
            ({ label }) => label.toLowerCase() === name.toLowerCase()
          );
          // Check if the item already exists
          if (isDuplicate) {
            acc.map(listItem => {
              if (listItem.label.toLowerCase() === name.toLowerCase())
                listItem.parent_id.push(id); // Push the id to the parents if exists
              return listItem;
            });
          } else {
            const listItem = {
              id,
              label: name,
              parent_id: [id],
              onClick() {
                setLastAsset('');
                const { parent_id } = listItem;
                // If the asset has children, continue navigating down the tree,  else set selected asset for highlighting
                if (hasChildren)
                  navigate({ parent_id, project_id: [projectId] }, name);
                else setLastAsset(name);
                return routerNavigate({ asset_id: parent_id }); // and query the parameters by asset_id
              },
              children: hasChildren,
            };
            acc.push(listItem);
          }
          return acc;
        }, []);

        addToHistory(name, links, true);
      }
    },
    [projectId, routerNavigate]
  );

  // // On project change
  // useEffect(() => {
  //   let didCancel = false;
  //   const source = axios.CancelToken.source();

  //   const getAssetTypes = async () => {
  //     setLoading(true);
  //     const response = await parametersApi('getAssets', {
  //       asset_type_id: ['8882fd17-20bb-4e55-8d36-4a54dca1599a'], // prod
  //       project_id: "00ea91bb-9c7c-42e1-b728-14e4373e4f1e" // sdp generic project EA
  //       // asset_type_id: ["577cfd8d-8da0-4d78-b4a4-c81ab728d4bf"] // dev
  //     }).catch(err => handleApiError(err, []));
      
  //     if (response) {
  //       const links = [
  //         ...(response.assets
  //           ?.map(e => ({
  //             id: e.id,
  //             label: startCase(e.name, false),
  //             onClick: () => {
  //               // routerNavigate({}); // sdp - disabled all parameters route
  //               // routerNavigate({ project_parameters_only: true }); // sdp - disabled project parameters route
  //               return routerNavigate({
  //                 asset_id: [e.id]
  //               }); // sdp - enable query the parameters by asset_id route
  //             },
  //             children: false,
  //           }))
  //           ?.sort((a, b) => a.label.localeCompare(b.label)) || []), // sdp - sort by parameter name alphabetic order
  //       ];
  //       console.log(links)
  //       if (!didCancel) {
  //         // Set initial history
  //         const resetHistory = () =>
  //           setHistory([{ name: projectName, links, onClick: resetHistory }]);
  //         resetHistory();
  //         setLoading(false);
  //       }
  //     }
  //   };

  //   if (projectId) getAssetTypes();

  //   return () => {
  //     didCancel = true;
  //     source.cancel();
  //   };
  // }, [navigate, projectId, projectName, routerNavigate, navigationRefresh, t]);

  // Update the current list of items
  useEffect(() => {
    setCurrentLinks(history.length ? history[history.length - 1].links : []);
    setLoading(!history.length);
  }, [history]);

  return { links: currentLinks, history, loading, lastAsset, genericProjects, specificProjects, models, parameters, loadingModelDetails, loadingParameterDetails, selectedProject };
};
