import { Suspense } from 'react';
import Grid from '@material-ui/core/Grid';
import useStyles from './dashboard-page-styles';
import ContainerDimensions from 'react-container-dimensions';

export default function Dashboard() {
  const classes = useStyles();

  return (
    <ContainerDimensions>
      {({width, height}) =>
        <iframe 
        title="sdp-prod-for-web-report-server" 
        width = {width - 10} // margin to stop recursive size update
        height = {height - 10 }
        src = {"https://app.powerbi.com/reportEmbed?reportId=ede8b119-8bd4-4a28-9d2a-f16fa3961b5a&autoAuth=true&ctid=4ae48b41-0137-4599-8661-fc641fe77bea"}
        frameBorder = "0" 
        allowFullScreen = {true}
        >
        </iframe>
      }
    </ContainerDimensions>
  )
}