import PT from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import useStyles from './empty-state-styles';

function EmptyState({ Icon, title, subtitle }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root} data-testid="emptyState">
      <Icon color="disabled" className={classes.icon} />
      <Typography variant="h3" className={classes.title}>
        {t(title)}
      </Typography>
      <Typography variant="h5" className={classes.subtitle}>
        {t(subtitle)}
      </Typography>
    </div>
  );
}

EmptyState.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  Icon: PT.any.isRequired,
  title: PT.string.isRequired,
  subtitle: PT.string.isRequired,
};

export default EmptyState;
