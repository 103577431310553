/* eslint-disable react/no-array-index-key */
import PT from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import Chip from '@material-ui/core/Chip';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import qs from 'qs';
import GoBackButton from '../../buttons/icons/go-back';
import StatusCircle from '../../status-circle';
import useStyles from './data-drawer-header-styles';
import useHeaders from './hooks';
import { parameterType } from '../../../types';
import WarningDialog from '../../dialogs/warning';
import { CopyButton, SpeedDialMenu } from '../../buttons';
import CustomCardHeader from '../../cards/card-header';
import { formatTitle } from '../../../utils';

function DataCardHeader({
  loading,
  parameter,
  editMode,
  toggleEditMode,
  updateStatus,
  setOpenModal,
}) {
  const classes = useStyles();
  const { t } = useTranslation(['common', 'parameters']);
  const screenMd = useMediaQuery(theme => theme.breakpoints.down('md'));
  const screenSm = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const screenXs = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const [searchParams, setSearchParams] = useSearchParams();
  const parameterId = searchParams.get('parameter_id');

  const {
    assetList,
    assetsLoading,
    deleteLoading,
    displayWarning,
    warningClose,
    warningAccept,
    getHeaderActions,
  } = useHeaders(
    parameter,
    editMode,
    toggleEditMode,
    screenMd,
    parameterId,
    updateStatus,
    setOpenModal
  );

  const renderWarningDialog = () => {
    return (
      <WarningDialog
        open={displayWarning}
        handleAccept={warningAccept}
        handleClose={warningClose}
        title={t('parameters:removeParamConfirm')}
        body={t('parameters:removeParamInfo')}
        secondaryButtonText={t('common:buttons.cancel')}
        primaryButtonText={t('common:buttons.delete')}
      />
    );
  };
  return (
    <CustomCardHeader
      renderAvatar={
        <Grid container>
          {screenXs && (
            <Grid item xs={6} sm={6}>
              <GoBackButton
                onClick={() => {
                  searchParams.delete('parameter_id');
                  setSearchParams(qs.parse(searchParams.toString()));
                }}
              />
            </Grid>
          )}
          {/* <Grid item xs={6} sm={12} md={12}>
            <StatusCircle
              status={parameter?.revision?.status || 'unanswered'}
              size={80}
            />
          </Grid> */}
        </Grid>
      }
      renderTitle={formatTitle(parameter?.parameterType?.name)}
      titleTypographyProps={{ className: classes.title }}
      renderSubheader={
        <Grid container>
          <Grid item xs={6} sm={8} md={8}>
            <Breadcrumbs
              maxItems={screenXs || screenSm ? 2 : 3}
              separator={<NavigateNextIcon fontSize="small" color="inherit" />}
            >
              {assetsLoading && (
                <Skeleton
                  className={classes.skeletonChip}
                  height={32}
                  width={100}
                />
              )}
              {assetList.map(({ name, id }) => (
                <Chip key={id} size="small" label={name} />
              ))}
            </Breadcrumbs>
          </Grid>
        </Grid>
      }
      subheaderTypographyProps={{ className: classes.subheader }}
      isLoading={loading}
      renderAction={
        screenMd && !editMode ? (
          <Grid container>
            <Grid item xs={12}>
              <SpeedDialMenu actions={getHeaderActions()} />
            </Grid>
            <Grid item>
              {deleteLoading && (
                <div className={classes.progress}>
                  <CircularProgress size={24} />
                </div>
              )}
            </Grid>
            {renderWarningDialog()}
          </Grid>
        ) : (
          <Grid container justify="flex-end" className={classes.buttonGroup}>
            {/* <Grid item>
              {deleteLoading && (
                <div className={classes.progress}>
                  <CircularProgress size={24} />
                </div>
              )}
            </Grid> */}
            {/* <Grid item>
              {!editMode && (
                <CopyButton
                  toBeCopied={parameterId}
                  toolTipText={t('common:dataDrawer:header.copyTooltip')}
                />
              )}
            </Grid> */}
            {getHeaderActions().map((action, index) => {
              return (
                <Grid key={index} item>
                  {action}
                </Grid>
              );
            })}
            {renderWarningDialog()}
          </Grid>
        )
      }
    />
  );
}

DataCardHeader.propTypes = {
  loading: PT.bool.isRequired,
  parameter: parameterType.isRequired,
  editMode: PT.bool.isRequired,
  setOpenModal: PT.func.isRequired,
  toggleEditMode: PT.func.isRequired,
  updateStatus: PT.func.isRequired,
};

export default DataCardHeader;
