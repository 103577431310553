import React , {useState, useEffect} from 'react';
import PT from 'prop-types';
// import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import useStyles from './styles';
// import { ActionButton } from '../../components/buttons';
import './FileUpload.css'

import Upload from '../components/Upload';
import FetchTable from '../components/FetchTable';

function FileUpload({ err }) {
  // const { t } = useTranslation(['errors']);
  // const classes = useStyles();

  const [recordCount, setRecordCount] = useState(null);

  const getRecordCounts = () => {
    const requestObject = {
        method:'GET' ,
        headers: {
            'Authorization': 'vVxkP3Bkx25g8WrOwOhowhEhuRKzjYJ5IBJavPGc', 
            'Content-Type': 'application/json'
        },
    }

    fetch('https://f2wfibva97.execute-api.ap-southeast-1.amazonaws.com/prod', requestObject)
    .then(res => res.json())
    .then(res => {
        // console.log(res);
        if (res.message === 'success') {
            setRecordCount(res.count);
        }
    })        
  }
  
  useEffect(() => {
    getRecordCounts();
  });

  return (
    <div className="App">
      <div className="body">
        <div className="disclaimer">
          <p>This webpage and data storage is hosted on an Arup AWS account<br/>All communication is secured behind an API layer</p>
        </div>

        <hr />
        
        <div className="title">
          <h2>Upload Structural Models<span className="titleCount">{recordCount ? recordCount: "0"} Projects Added</span></h2>
          
          <p>
            This is for uploading structural analysis models (E2K, $ET or GWB) for reference. Please provide a project name and some files.
            <br />
            All other information is very much welcomed, but may be omitted.
          </p>
        </div>

        <hr />

        <div className="form">
        <Upload />
        </div>
        
        <hr />

        <div className="help">
        <h2>Additional Information</h2>
          <table>
            <tr>
              <th>Extensions</th>
              <th>Text Description</th>
              <th>
                Help
              </th>
            </tr>
            <tr>
              <td>E2K, $ET, EDB</td>
              <td>ETABS text files</td>
              <td>ETABS text files are saved with every model file (EDB file). If this is not present, you can always export to E2K, or if not possible, you can provide the EDB binary file.</td>
            </tr>
            <tr>
              <td>GWA, GWB</td>
              <td>GSA model file (without results)</td>
              <td>GSA files can get very large with results. Please delete results before uploading (if possible), or let us know directly that you have a large file for us.</td>
            </tr>
            <tr>
              <td>S2K, $2K, SDB</td>
              <td></td>
              <td>SAP2000 text files are saved with every model file (SDB file). If this is not present, you can always export to S2K, or if not possible, you can provide the EDB binary file.</td>
            </tr>
            <tr>
              <td>YDB</td>
              <td>YJK model file (please also provide E2K export)</td>
              <td>YJK can export to ETABS format. Please provide the exported E2K file as well as the YJK file.</td>
            </tr>
          </table>
          <p>General Note: If you have a large file (&gt;100MB), please delete results if they are present before saving the the file and uploading it. Text files for ETABS and SAP2000 are strongly preferred to binary files, since we can automatically process the text files. You can let us know by email if you have a large file that you want to provide.</p>
          <table>
            <tr>
              <th>扩展名</th>
              <th>说明</th>
              <th>
                帮助
              </th>
            </tr>
            <tr>
              <td>E2K, $ET, EDB</td>
              <td>ETABS 文字档  (text file)</td>
              <td>ETABS文字档与每个模型文件（EDB 文件）一起储存。 如不存在，可尝试输出到成E2K，亦可以提供 EDB 二进制文件</td>
            </tr>
            <tr>
              <td>GWA, GWB</td>
              <td>GSA 模型文件 (不包含计算结果)</td>
              <td>GSA 文件可能会变得非常大。 请尽量在上传前删除计算结果，或者直接告诉我们您有一个大文件要发送给我们。</td>
            </tr>
            <tr>
              <td>S2K, $2K, SDB</td>
              <td></td>
              <td>SAP2000 文字档与每个模型文件（SDB 文件）一起储存。 如不存在，可尝试输出到成S2K，亦可以提供 EDB 二进制文件</td>
            </tr>
            <tr>
              <td>YDB</td>
              <td>GSA 模型文件 (请提供E2K输出)</td>
              <td>YJK可以输出为ETABS格式。 请提供输出的 E2K 文件以及 YJK 文件。</td>
            </tr>
          </table>
          <p>注意：如果您有一个大的文件 (&gt;100MB)，请在储存和上传文件之前删除计算结果（如有）。 由于文本档案较易于处理，ETABS 和 SAP2000 的文檔会比二进制文件更好。 如果您要提供大文件，可以通过电子邮件通知我们。</p>
        </div>
      </div>

      <hr />
      
      <h2>Upload History</h2>
      
      <FetchTable />
    </div>
  );
  }

  FileUpload.propTypes = {

  };

  FileUpload.defaultProps = {

};

export default FileUpload;
