import { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMsal, useAccount } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
// import { Providers, ProviderState } from '@microsoft/mgt-element';
import { loginRequest } from '../auth/auth-provider';
import { getUserInformation } from '../api';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { instance, inProgress, accounts } = useMsal();
  const activeAccount = useAccount(accounts[0] || {});

  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);

  if (activeAccount) instance.setActiveAccount(activeAccount);

  const login = () => {
    instance.loginPopup(loginRequest);
    // Providers.globalProvider.setState(ProviderState.SignedIn);
  };

  const logout = () => {
    instance.logoutPopup({ account: activeAccount || accounts[0] });
    // Providers.globalProvider.setState(ProviderState.SignedOut);
  };

  useEffect(() => {
    const getUserDetails = async () => {
      const userData = await getUserInformation();

      const userObj = {
        employeeId: userData.id,
        firstName: userData.givenName,
        lastName: userData.surname,
        userName: userData.displayName,
        office: userData.officeLocation,
        jobTitle: userData.jobTitle,
        email: userData.userPrincipalName,
      };

      setUser(userObj);
      setLoading(false);
    };

    if (instance.getActiveAccount() && inProgress === InteractionStatus.None) {
      // Providers.globalProvider.setState(ProviderState.SignedIn);
      getUserDetails();
    }
  }, [inProgress, instance]);

  useEffect(() => {
    if (inProgress !== InteractionStatus.None) {
      
    }
      // Providers.globalProvider.setState(ProviderState.Loading);
  });

  return (
    <UserContext.Provider
      value={{
        user,
        login,
        logout,
        loading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

// import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import { useMsal } from '@azure/msal-react';
// import { EventType, InteractionStatus } from '@azure/msal-browser';
// import { isEmpty } from 'lodash';
// import { loginRequest } from '../auth/auth-provider';
// import { getAvatar, getUserInformation } from '../api/graph';

// export const UserContext = React.createContext();

// export const UserProvider = ({ children }) => {
//   const { instance, inProgress, accounts } = useMsal();
//   const [user, setUser] = useState({});

//   if (!isEmpty(accounts)) {
//     instance.setActiveAccount(accounts[0]);
//   }

//   instance.addEventCallback(event => {
//     if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
//       const { account } = event.payload;
//       instance.setActiveAccount(account);
//     }
//   });

//   const login = () => instance.loginPopup(loginRequest);

//   const logout = () => instance.logoutPopup({ account: accounts[0] });

//   useEffect(() => {
//     const getUserDetails = async () => {
//       const avatarRes = await getAvatar();
//       const userData = await getUserInformation();

//       const userObj = {
//         employeeId: userData.id,
//         avatar: avatarRes,
//         firstName: userData.givenName,
//         lastName: userData.surname,
//         userName: userData.displayName,
//         office: userData.officeLocation,
//         jobTitle: userData.jobTitle,
//         email: userData.mail,
//       };
//       setUser(userObj);
//     };

//     if (instance.getActiveAccount() && inProgress === InteractionStatus.None)
//       getUserDetails();
//   }, [inProgress, instance]);

//   return (
//     <UserContext.Provider value={{ user, login, logout }}>
//       {children}
//     </UserContext.Provider>
//   );
// };

// UserProvider.propTypes = {
//   children: PropTypes.oneOfType([
//     PropTypes.arrayOf(PropTypes.node),
//     PropTypes.node,
//   ]).isRequired,
// };