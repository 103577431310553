import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import {
  white,
  red,
  orange,
  yellow,
  green,
  blue,
  purple,
  pink,
  grey,
  black,
  gold,
} from './colors';
import config from '../config';

const rawTheme = {
  palette: {
    red: {
      light: red[500],
      main: red[800],
      dark: red[900],
      contrastText: white[0],
    },
    orange: {
      main: orange[600],
      light: orange[400],
      dark: orange[800],
      contrastText: white[0],
    },
    yellow: {
      light: yellow[300],
      main: yellow[500],
      contrastText: black[0],
    },
    green: {
      light: green[300],
      main: green[500],
      contrastText: black[0],
    },
    blue: {
      main: blue[300],
      contrastText: black[0],
    },
    purple: {
      light: purple[100],
      main: purple[800],
      dark: purple[900],
      contrastText: white[0],
    },
    pink: {
      main: pink[300],
      contrastText: black[0],
    },
    gold: {
      light: gold[100],
      main: gold[500],
      dark: gold[900],
      contrastText: white[0],
    },
    grey: {
      main: white[0],
      light: grey[50],
      dark: grey[400],
      contrastText: black[0],
    },
  },
};

const envThemeColour = {
  development: rawTheme.palette.purple,
  ut: rawTheme.palette.gold,
  production: rawTheme.palette.orange,
};

const muiTheme = (type, i18n) =>
  createMuiTheme(
    {
      overrides: {
        MuiAutocomplete: {
          option: {
            overflowWrap: 'anywhere',
            hyphens: 'auto',
          },
        },
      },
      palette: {
        type,
        primary: envThemeColour[config.env],
        secondary: rawTheme.palette.grey,
        common: {
          black: black[0],
          white: white[0],
        },
        warning: {
          main: rawTheme.palette.orange.light,
          dark: rawTheme.palette.orange.main,
        },
        error: rawTheme.palette.red,
        success: rawTheme.palette.green,
        info: rawTheme.palette.blue,
        border: {
          hover: {
            light: grey[200],
            dark: grey[900],
          },
          active: {
            light: grey[300],
            dark: grey[800],
          },
        },
        status: {
          unanswered: rawTheme.palette.red.light,
          answered: rawTheme.palette.red.light,
          checked: rawTheme.palette.yellow.main,
          approved: rawTheme.palette.green.main,
        },
      },
    },
    i18n
  );

const theme = (type, i18n) => responsiveFontSizes(muiTheme(type, i18n));

export default theme;
