// todo: remove in refactor
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import { useContext } from 'react';
import clsx from 'clsx';
import PT from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { StyledIconButton } from '../../../buttons';
import Chip from '../chip';
import Dropdown from '../../chip-dropdown';
import useStyles from './chip-row-styles';
import useRow from './hooks';
import { tagType } from '../../../../types';
import { ProjectPermissionContext } from '../../../../context/project-permission-context';

function Row({
  tags,
  tagTypeName,
  disabled,
  tagsLoading,
  deleteTag,
  parameterId,
  availableTags,
  addTag,
}) {
  const classes = useStyles({ typography: 'caption' });

  const { open, handleChangeOpen } = useRow();
  const { writePermission } = useContext(ProjectPermissionContext);

  const permission = id => {
    if (writePermission) return () => deleteTag(id, parameterId);
    return null;
  };

  const renderButton = () => {
    if (disabled) return <KeyboardArrowRightIcon aria-label="Expand button" />;
    if (open) return <KeyboardArrowDownIcon aria-label="Collapse button" />;
    return <KeyboardArrowRightIcon />;
  };

  if (tagTypeName) {
    return (
      <div key={tagTypeName}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item>
            <StyledIconButton
              onClick={disabled ? null : handleChangeOpen}
              icon={renderButton()}
              disabled
            />
            <Typography variant="caption" color="textSecondary">
              {tagTypeName}
            </Typography>
          </Grid>
        </Grid>
        <Collapse in={!disabled && open} className={classes.chips}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            {tagsLoading ? (
              <Skeleton
                className={classes.skeletonChip}
                height={32}
                width={75}
              />
            ) : (
              tags.map(
                ({
                  id,
                  name,
                  // global_tag: globalTag = true,
                  loadingTag = false,
                  icon,
                }) => {
                  return (
                    <Grid item key={id}>
                      <Chip
                        className={clsx(classes.alignStart)}
                        label={name}
                        icon={icon}
                        color="default"
                        // TO DO: Once we have a tagging systems in place we should handle non-global parameters. For now disabling causes a confusion.
                        // disabled={!globalTag}
                        clickable={false}
                        onDelete={
                          deleteTag &&
                          !disabled &&
                          !loadingTag &&
                          permission(id)
                        }
                      />
                    </Grid>
                  );
                }
              )
            )}
            {!disabled && !tagsLoading && (
              <Grid key="AddingSelection" item>
                <Dropdown
                  tagTypeName={tagTypeName}
                  addTag={addTag}
                  className={classes.alignStart}
                  loading={tagsLoading}
                  availableTags={availableTags}
                  parameterId={parameterId}
                />
              </Grid>
            )}
          </Grid>
        </Collapse>
      </div>
    );
  }
  return null;
}

Row.propTypes = {
  tags: PT.arrayOf(tagType).isRequired,
  tagTypeName: PT.string,
  deleteTag: PT.func.isRequired,
  disabled: PT.bool.isRequired,
  tagsLoading: PT.bool.isRequired,
  parameterId: PT.oneOfType([PT.string, PT.number]).isRequired,
  availableTags: PT.arrayOf(tagType).isRequired,
  addTag: PT.func.isRequired,
};

export default Row;
