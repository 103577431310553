// import { InteractionRequiredAuthError } from '@azure/msal-browser';
// import msalInstance, { loginRequest, ddbTokenRequest } from './auth-provider';

// const tokenRequest = async request => {
//   let token;
//   try {
//     const account = await msalInstance.getActiveAccount();
//     token = await msalInstance.acquireTokenSilent({ ...request, account });
//   } catch (error) {
//     try {
//       // fallback to interaction when silent call fails
//       if (error instanceof InteractionRequiredAuthError) {
//         token = await msalInstance.acquireTokenPopup(request);
//       } else {
//         // eslint-disable-next-line no-console
//         console.error(error);
//       }
//     } catch (err) {
//       // eslint-disable-next-line no-console
//       console.error(err);
//     }
//   }
//   return token;
// };
// export const getToken = async () => {
//   const token = await tokenRequest(ddbTokenRequest);
//   return `Bearer ${token.accessToken}`;
// };

// export const getGraphToken = async () => {
//   const token = await tokenRequest(loginRequest);
//   return `Bearer ${token.accessToken}`;
// };

import { InteractionRequiredAuthError } from '@azure/msal-browser';
import msalInstance, { loginRequest, ddbTokenRequest } from './auth-provider';

const tokenRequest = async request => {
  let token;
  try {
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();
    token = await msalInstance.acquireTokenSilent({
      ...request,
      account: activeAccount || accounts[0],
    });
  } catch (error) {
    try {
      // fallback to interaction when silent call fails
      token = await msalInstance.acquireTokenPopup(request);
      // if (error instanceof InteractionRequiredAuthError) {
      // } else {
      //   // eslint-disable-next-line no-console
      //   console.error(error);
      // }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
  return token;
};

export const getToken = async () => {
  const token = await tokenRequest(ddbTokenRequest);
  return token && `Bearer ${token.accessToken}`;
};

export const getGraphToken = async () => {
  const token = await tokenRequest(loginRequest);
  return token && token.accessToken;
};
