import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  root: {
    flexGrow: 1,
    boxShadow: 'none',
  },
  content: {
    overflow: 'hidden',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  verticalScroll: {
    flexGrow: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    margin: `0 ${theme.spacing(-2)}px`,
    marginBottom: theme.spacing(-3),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  list: {
    padding: theme.spacing(2),
  },
  error: {
    marginTop: theme.spacing(-2),
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    marginBottom: theme.spacing(2),
  },
  parameterHolder: {
    border: '1px solid black',
    borderRadius: 5,
  },
  assetList: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  asset: {
    margin: 0,
    padding: 0,
  },
  table: {
    borderCollapse: 'collapse',
    borderSpacing: 10,
  },
  tr: {
    border: '1px solid lightgrey',
    padding: 10,
  },
  td: {
    verticalAlign: 'top',
    padding: 10,
  },
  breadcrumbHeader: {
    fontSize: 25,
  },
  circular: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
