import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const NewButton = props => {
  const { t } = useTranslation();
  return <Button {...props}>{t('buttons.accept')}</Button>;
};

const CookiesButton = withStyles(() => ({
  root: {
    textTransform: 'none',
    'font-style': 'italic',
    textDecoration: 'underline',
  },
}))(NewButton);

export default CookiesButton;
