import { Suspense } from 'react';
import clsx from 'clsx';
import PT from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Skeleton } from '@material-ui/lab';
import Divider from '@material-ui/core/Divider';
import handleNavigationDrawer from './hooks';
import useStyles from './responsive-styles';
import PermanentDrawer from '../permanent';
import NavigationMenu from './navigation-menu';

export default function ResponsiveContainer({
  drawerWidth,
  navigation: Navigation,
  bottomNavigation,
  useNavigationMenu,
  children,
  ...rest
}) {
  const screenMd = useMediaQuery(theme => theme.breakpoints.up('md'));
  const { toggleDrawerOpen, open } = handleNavigationDrawer();
  const classes = useStyles({ drawerWidth, open });

  return (
    <>
      {/* <PermanentDrawer
        width={drawerWidth}
        open={open}
        toggleDrawerOpen={toggleDrawerOpen}
        bottomNavigation={bottomNavigation}
      > */}
      <Suspense
        fallback={
          <>
            <Skeleton height={50} />
            <Divider light />
          </>
        }
      >
        {useNavigationMenu ? (
          <NavigationMenu
            drawerOpen={open}
            toggleDrawerOpen={screenMd ? () => { } : toggleDrawerOpen}
            {...rest}
          />
        ) : (
          <Navigation
            drawerOpen={open}
            toggleDrawerOpen={screenMd ? () => { } : toggleDrawerOpen}
            {...rest}
          />
        )}
      </Suspense>
      {/* </PermanentDrawer> */}
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: screenMd,
        })}
      >
        {children}
      </div>
    </>
  );
}

ResponsiveContainer.propTypes = {
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]).isRequired,
  drawerWidth: PT.number,
  useNavigationMenu: PT.bool,
  navigation: PT.func,
  bottomNavigation: PT.arrayOf(
    PT.shape({
      // eslint-disable-next-line react/forbid-prop-types
      icon: PT.any,
      id: PT.string,
      label: PT.string,
      path: PT.string,
      value: PT.bool,
    })
  ),
};

ResponsiveContainer.defaultProps = {
  drawerWidth: 375,
  bottomNavigation: [],
  useNavigationMenu: true,
  navigation: null,
};
