import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  icon: {
    fontSize: '4vw',
  },
  root: {
    textAlign: 'center',
    paddingTop: theme.spacing(5),
  },
  title: {
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightMedium,
  },
  subtitle: {
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(13),
  },
}));
