import { useState, useEffect } from 'react';
import {
  environmentContextApi,
  handleApiError,
} from '../../../../../../../api';

export default (
  tagTypesLoading,
  updateDisciplineTags,
  selectedParamDisciplineTags
) => {
  const [disciplinesLoading, setDisciplinesLoading] = useState(true);
  const [allDisciplines, setAllDisciplines] = useState([]);

  useEffect(() => {
    let didCancel = false;

    const getAllDisciplines = async () => {
      setDisciplinesLoading(true);

      const { disciplines } = await environmentContextApi(
        'getDisciplines'
      ).catch(handleApiError);

      if (!didCancel) {
        const reformattedDisciplines = disciplines.map(discipline => {
          return {
            id: discipline.discipline_id,
            name: discipline.discipline_name,
            code: discipline.discipline_code,
          };
        });
        setAllDisciplines(reformattedDisciplines);
        setDisciplinesLoading(false);
      }
    };

    if (!tagTypesLoading) {
      getAllDisciplines();
    }

    return () => {
      didCancel = true;
    };
  }, [tagTypesLoading]);

  const deleteDiscipline = async (tagId, parameterId) => {
    const newDisciplineTagList = selectedParamDisciplineTags.filter(tag => {
      return tag.id !== tagId;
    });
    updateDisciplineTags(parameterId, newDisciplineTagList);
  };

  const addDiscipline = async (parameterId, newDisciplineTag) => {
    const newDisciplineTagList = [
      ...selectedParamDisciplineTags,
      newDisciplineTag,
    ];
    updateDisciplineTags(parameterId, newDisciplineTagList);
  };

  const availableDisciplines = allDisciplines.filter(
    discipline =>
      !selectedParamDisciplineTags?.find(({ id }) => discipline.id === id)
  );

  return {
    deleteDiscipline,
    disciplinesLoading,
    availableDisciplines,
    addDiscipline,
  };
};
