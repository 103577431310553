import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr',
    gridTemplateAreas: "'body'",
    overflowY: 'hidden',
    maxHeight: '100%',
    backgroundColor: theme.palette.action.hover,
    height: '100%',
  },

  header: {
    gridArea: 'header',
    padding: theme.spacing(0.5),
  },
  divider: { marginTop: theme.spacing(0.5) },
  body: {
    gridArea: 'body',
    maxHeight: '100%',
    overflowY: 'auto',
  },
}));
