import PT from 'prop-types';
import { useContext } from 'react';
import useHooks from './hooks';
import WizardRoot from '../../../components/dialogs/wizard';
import { ProjectContext } from '../../../context/project-context';

function AssetWizard({ open, toggleOpen, triggerNavigationRefresh }) {
  const { project, loading } = useContext(ProjectContext);
  const projectName = loading ? '' : project.jobNameShort;
  const {
    activeStep,
    steps,
    handleBack,
    primaryAction,
    stepContent,
    handleAccept,
    primaryActionText,
    disabled,
  } = useHooks(open, toggleOpen, projectName, triggerNavigationRefresh);

  return (
    <WizardRoot
      open={open}
      activeStep={activeStep}
      steps={steps}
      stepContent={stepContent}
      handleBack={handleBack}
      handleAccept={handleAccept}
      primaryButtonProps={{
        label: primaryActionText,
        onClick: primaryAction,
        disabled,
      }}
    />
  );
}

AssetWizard.propTypes = {
  open: PT.bool.isRequired,
  toggleOpen: PT.func.isRequired,
  triggerNavigationRefresh: PT.func.isRequired,
};

export default AssetWizard;
