import { useState } from 'react';

export default () => {
  const [navigationRefresh, setNavigationRefresh] = useState(0); // incrementing number. Update to cause refresh
  const [parameterListRefresh, setParameterListRefresh] = useState(0); // incrementing number. Update to cause refresh

  const triggerNavigationRefresh = () => {
    setNavigationRefresh(old => old + 1);
  };

  const triggerParameterListRefresh = () => {
    setParameterListRefresh(old => old + 1);
  };

  return {
    triggerNavigationRefresh,
    navigationRefresh,
    triggerParameterListRefresh,
    parameterListRefresh,
  };
};
