import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  button: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)', // Transparent
    },
    ...theme.typography.body1,
    textTransform: 'none',
    textAlign: 'left',
    padding: 0,
  },
  noBottomPadding: {
    paddingBottom: 0,
    color: theme.palette.text.secondary,
  },
  // This is a workaround until better way of centering the status circle is developed
  noPadding: {
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    color: theme.palette.text.secondary,
  },
  disabled: { color: theme.palette.text.disabled },
  toggleMainColor: ({ open }) => ({
    color: open ? theme.palette.primary.main : theme.palette.text.primary,
  }),
  toggleSecondaryColor: ({ open }) => ({
    color: open ? theme.palette.primary.main : theme.palette.text.secondary,
  }),
  topBorder: {
    borderBottom: 'none',
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  status: {
    width: '5%',
  },
  date: {
    width: '15%',
  },
  name: {
    width: '20%',
  },
  source: {
    width: '25%',
  },
  value: {
    width: '35%',
  },
}));

export default useStyles;
