// todo: remove in refactor
/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import InputBase from '@material-ui/core/InputBase';
import { useTheme } from '@material-ui/styles';
import Chip from '../display-chips/chip';
import useStyles from './text-input-chip-styles';

function TextInputChip(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { label, InputProps, forwardRef, disabled, id, inputProps } = props;
  const { t } = useTranslation();
  return (
    <Chip
      color={theme.palette.type === 'dark' ? 'default' : 'primary'}
      variant="outlined"
      icon={<AddIcon />}
      forwardRef={forwardRef}
      disabled={disabled}
      label={
        <InputBase
          {...InputProps}
          label="Combo box"
          variant="outlined"
          className={classes.root}
          classes={{ input: classes.hint }}
          disabled={disabled}
          placeholder={t('addItem', { name: label })}
          id={id}
          inputProps={inputProps}
        />
      }
    />
  );
}

TextInputChip.propTypes = {
  id: PT.string,
  disabled: PT.bool.isRequired,
  label: PT.string.isRequired,
  inputProps: PT.object,
  forwardRef: PT.object,
};

export default TextInputChip;
