// todo: refactor
import PT from 'prop-types';
import { useContext } from 'react';
import Divider from '@material-ui/core/Divider';
import RightIcon from '@material-ui/icons/ArrowRight';
import BackArrow from '@material-ui/icons/ArrowBack';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Skeleton from '@material-ui/lab/Skeleton';
import ListItemText from '@material-ui/core/ListItemText';
import { ProjectContext } from '../../../context/project-context';
import useStyles from './navigation-styles';
import { startCase } from '../../../utils';
import LinkIcon from '@material-ui/icons/Link';

import NavigationHeading from './heading';

function Navigation({
  navigationRefresh,
  drawerOpen,
  toggleDrawerOpen,
  toggleAssetWizard,
  loading, genericProjects, specificProjects
}) {
  const classes = useStyles();
  const { project, loading: componentLoading } = useContext(ProjectContext);

  
  return (
    <>
      <NavigationHeading
        drawerOpen={drawerOpen}
        loading={componentLoading}
        project={project}
        toggleAssetWizard={toggleAssetWizard}
        toggleDrawerOpen={toggleDrawerOpen}
      />
      {
        <div className={classes.body}>
          {loading ? (
            <List>
              {[0, 1, 2, 3, 4, 5, 6].map(item => (
                <ListItem key={item}>
                  <ListItemText primary={<Skeleton />} />
                </ListItem>
              ))}
            </List>
          ) : (
            <List className={classes.navList}>
              {
                specificProjects.concat(genericProjects).map(({
                  id, 
                  label, 
                  onClick,
                  jn,
                }) => {
                  return (
                    <ListItem
                      key={id}
                      button
                      onClick={onClick}
                      className= {
                        classes.selected
                      }
                    >
                      {/* {back && (
                        <ListItemIcon
                          className={
                            back
                              ? null
                              : classes[`indent${(indent || 0) + baseIndent}`]
                          }
                        >
                          <BackArrow
                            data-testid="back-arrow"
                            aria-label="Back"
                          />
                        </ListItemIcon>
                      )} */}
                      <ListItemText
                        key={id}
                        primary={label}
                        // primaryTypographyProps={primaryTypographyProps}
                        // className={
                        //   back
                        //     ? null
                        //     : classes[`indent${(indent || 0) + baseIndent}`]
                        // }
                        data-testid={`List - ${label}`}
                      />
                      {/* {children ? (
                        <RightIcon data-testid="right-arrow" />
                      ) : null} */}
                      {
                        jn && (
                        <div className={classes.avatar}>
                          <LinkIcon style={{ color: '#f3cb21' }} onClick={() => window.open(`https://projects.intranet.arup.com/?layout=projects.proj.view&jp=OA&jn=${jn}`, '_blank').focus()}/>
                        </div>
                        )
                      }
                    </ListItem>
                  )
                })
              }
              {/* {links.map(
                ({
                  label,
                  id,
                  children,
                  primaryTypographyProps,
                  divider,
                  back,
                  indent,
                  onClick,
                }) => {
                  // Only indent if there is a back button
                  const baseIndent = history.length === 1 ? 0 : 1;
                  return divider ? (
                    <div
                      key={`divider ${label}`}
                      className={
                        classes[`indentDivider${(indent || 0) + baseIndent}`]
                      }
                    >
                      <Divider data-testid="divider" />
                    </div>
                  ) : (
                    <ListItem
                      key={id}
                      button
                      onClick={onClick}
                      className={
                        (lastAsset === label && classes.selected) ||
                        (primaryTypographyProps &&
                          lastAsset === '' &&
                          classes.selected)
                      }
                    >
                      {back && (
                        <ListItemIcon
                          className={
                            back
                              ? null
                              : classes[`indent${(indent || 0) + baseIndent}`]
                          }
                        >
                          <BackArrow
                            data-testid="back-arrow"
                            aria-label="Back"
                          />
                        </ListItemIcon>
                      )}
                      <ListItemText
                        key={id}
                        primary={startCase(label)}
                        primaryTypographyProps={primaryTypographyProps}
                        className={
                          back
                            ? null
                            : classes[`indent${(indent || 0) + baseIndent}`]
                        }
                        data-testid={`List - ${label}`}
                      />
                      {children ? (
                        <RightIcon data-testid="right-arrow" />
                      ) : null}
                      
                    </ListItem>
                  );
                }
              )} */}
            </List>
          )}
        </div>
      }
    </>
  );
}

Navigation.propTypes = {
  navigationRefresh: PT.number.isRequired,
  toggleDrawerOpen: PT.func.isRequired,
  toggleAssetWizard: PT.func.isRequired,
  drawerOpen: PT.bool.isRequired,
};

export default Navigation;
