import { useState } from 'react';

export default () => {
  const [parameterWizardOpen, setParameterWizardOpen] = useState(false);

  const handleToggleOpen = () => {
    setParameterWizardOpen(!parameterWizardOpen);
  };

  return {
    parameterWizardOpen,
    handleToggleOpen,
  };
};
