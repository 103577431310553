import PT from 'prop-types';
import Row from '../../chips/display-chips/row';
import useDisciplineTags from './hooks';

function DisciplineTags({ parameterId, disabled, tagTypesLoading }) {
  const {
    disciplines,
    disciplinesLoading,
    availableDisciplines,
    deleteDiscipline,
    addDiscipline,
  } = useDisciplineTags(parameterId, tagTypesLoading);

  return (
    <>
      <Row
        tags={[]}
        tagTypeName="Job Number"
        disabled={disabled}
        tagsLoading={disciplinesLoading}
        deleteTag={deleteDiscipline}
        parameterId={parameterId}
        addTag={addDiscipline}
        availableTags={[]}
      />
      <Row
        tags={[]}
        tagTypeName="Region"
        disabled={disabled}
        tagsLoading={disciplinesLoading}
        deleteTag={deleteDiscipline}
        parameterId={parameterId}
        addTag={addDiscipline}
        availableTags={[]}
      />
    </>
  );
}

DisciplineTags.propTypes = {
  parameterId: PT.oneOfType([PT.string, PT.number]).isRequired,
  disabled: PT.bool.isRequired,
  tagTypesLoading: PT.bool.isRequired,
};

export default DisciplineTags;
