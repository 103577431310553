import createClient from './create-client';
import config from '../../config';

const BASE_URL = process.env.REACT_APP_URL || config.url.api.base;

const baseDocsUrl = `${BASE_URL}/documentation`;

const commentsServiceUrl = `${baseDocsUrl}/comments_service/json`;
const commentsServiceVersion = 0;

const parametersServiceUrl = `${baseDocsUrl}/parameter_service/json`;
const parametersServiceVersion = 1;

const environmentContextServiceUrl = `${baseDocsUrl}/environment_context_service/json`;
const environmentContextServiceVersion = 2;

const qaServiceUrl = `${baseDocsUrl}/qa_service/json`;
const qaServiceVersion = 0;

const parameterMetadataServiceUrl = `${baseDocsUrl}/parameter_metadata_service/json`;
const parameterMetadataServiceVersion = 0;

const referenceDataServiceUrl = `${baseDocsUrl}/reference_data_service/json`;
const referenceDataServiceVersion = 1;

const userServiceUrl = `${baseDocsUrl}/user_service/json`;
const userServiceVersion = 0;

export const commentsClient = createClient(
  commentsServiceUrl,
  commentsServiceVersion
);
export const parametersClient = createClient(
  parametersServiceUrl,
  parametersServiceVersion
);
export const environmentContextClient = createClient(
  environmentContextServiceUrl,
  environmentContextServiceVersion
);
export const qaClient = createClient(qaServiceUrl, qaServiceVersion);

export const parameterMetadataClient = createClient(
  parameterMetadataServiceUrl,
  parameterMetadataServiceVersion
);
export const referenceDataClient = createClient(
  referenceDataServiceUrl,
  referenceDataServiceVersion
);

export const userClient = createClient(userServiceUrl, userServiceVersion);
