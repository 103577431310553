import { useState } from 'react';

export default handleAcceptRoot => {
  const [warningOpen, setWarningOpen] = useState(false);

  const handleCancel = () => {
    setWarningOpen(true);
  };

  const handleWarningClose = () => setWarningOpen(false);

  const handleAccept = () => {
    handleWarningClose();
    handleAcceptRoot();
  };

  return {
    handleCancel,
    warningOpen,
    handleAccept,
    handleWarningClose,
  };
};
