import upperFirst from 'lodash/upperFirst';
import words from 'lodash/words';

const nonDestructivePattern = /[^ ]+/g;

export default (string, destructive = true) =>
  words(
    `${string}`.replace(/['\u2019]/g, ''),
    destructive ? undefined : nonDestructivePattern
  ).reduce(
    (result, word, index) => result + (index ? ' ' : '') + upperFirst(word),
    ''
  );
