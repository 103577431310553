import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  content: {
    overflow: 'hidden',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  circular: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
