import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  primary: {
    color: theme.palette.primary.main,
  },
  secondary: {
    color: theme.palette.text.secondary,
  },
  leftAlign: {
    marginRight: 'auto',
  },
  paper: {
    height: '90vh',
    display: 'flex',
    flexFlow: 'column',
  },
  container: {
    height: '100vh',
  },
  actions: {
    marginTop: 'auto',
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  content: {
    backgroundColor: theme.palette.background.default,
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'column',
    padding: theme.spacing(2),
  },
  stepLabel: {
    textAlign: 'center',
  },
  optionLabel: {
    verticalAlign: 'text-top',
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  titleLeft: {
    borderRight: '1px solid theme.palette.grey[80]',
  },
  paddedHeader: {
    paddingLeft: 10,
    paddingTop: 10,
  },
}));
