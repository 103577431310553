export default {
  url: {
    apiDocs: lang =>
      `https://arup.sharepoint.com/sites/DigitalDesignBriefApplication/User%20Guide%20%20API%20Docs/Forms/AllItems.aspx?id=%2Fsites%2FDigitalDesignBriefApplication%2FUser%20Guide%20%20API%20Docs%2F${lang}%2Fapi%2Ddocs%2Fapi%2Ddocs%2Emd&parent=%2Fsites%2FDigitalDesignBriefApplication%2FUser%20Guide%20%20API%20Docs%2F${lang}%2Fapi%2Ddocs`,
    repoBackend: 'https://github.com/arup-group/ddb-microservices',
    repoFrontend: 'https://github.com/arup-group/ddb-web',
    sharepoint:
      'https://arup.sharepoint.com/sites/DigitalDesignBriefApplication',
    yammerGroup:
      'https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIzMTM0MDIyNDUxMiJ9/all',
    devDiaries:
      'https://arup.sharepoint.com/teams/global-design-automation-team/_layouts/15/SeeAll.aspx?Page=%2Fteams%2Fglobal-design-automation-team%2FSitePages%2FDigital-Design-Brief.aspx&InstanceId=0b816542-4c66-4e5e-b6ff-afe31db192db',
    feedback: 'https://feedback.arup.com/digital-design-brief/22',
    changelog: 'https://arup-group.github.io/ddb-web/CHANGELOG.html',
  },
};
