import PT from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import useAddSource from './hooks';
import useStyles from './add-source-styles';
import { DatePicker } from '../../../pickers';

function AddSource({
  open,
  setDialogValue,
  dialogValue,
  toggleOpen,
  setAllSources,
  setUpdateValues,
  setSelectedSource,
}) {
  const { t } = useTranslation();
  const {
    inputValue,
    setInputValue,
    options,
    loading,
    handleSubmit,
    handleClose,
    errors,
    updateFunctions,
    validateFunctions,
  } = useAddSource(
    open,
    dialogValue,
    setDialogValue,
    toggleOpen,
    setAllSources,
    setUpdateValues,
    setSelectedSource
  );

  const classes = useStyles();

  return (
    <Dialog open={open}>
      <form onSubmit={handleSubmit}>
        <DialogTitle id="form-dialog-title">
          {t('addSource.newSource')}
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12}>
              <TextField
                autoFocus
                id="title"
                value={dialogValue.title}
                onChange={updateFunctions.updateTitle}
                onBlur={validateFunctions.validateTitle}
                helperText={errors.titleError && t('addSource.errorTitle')}
                error={errors.titleError}
                label={t('source')}
                type="text"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="reference"
                onChange={updateFunctions.updateReference}
                onBlur={validateFunctions.validateReference}
                error={errors.referenceError}
                helperText={
                  errors.referenceError && t('addSource.errorReference')
                }
                label={t('addSource.source.documentReferenceLabel')}
                type="text"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="url"
                onChange={updateFunctions.updateUrl}
                onBlur={validateFunctions.validateUrl}
                error={errors.urlError}
                helperText={errors.urlError && t('addSource.errorURL')}
                label={t('addSource.source.directoryUrl')}
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={dialogValue.source_type_id}
                onChange={(event, value) => {
                  const typeId = value?.id || '';
                  setDialogValue({
                    ...dialogValue,
                    source_type_id: typeId,
                  });
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                id="controllable-states-demo"
                options={options}
                getOptionLabel={option => {
                  if (typeof option === 'string') {
                    const chosenOption = options.find(items => {
                      return items.id === dialogValue.source_type_id;
                    });
                    return chosenOption?.name || '';
                  }
                  return option?.name || '';
                }}
                renderInput={params => (
                  <TextField
                    id="documentType"
                    {...params}
                    label={t('addSource.source.docType')}
                    type="text"
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                className={classes.contentSpacing}
                autoOk
                fullWidth
                disableFuture
                label={t('addSource.source.publicationDate')}
                value={
                  new Date(
                    dialogValue.date_year,
                    dialogValue.date_month,
                    dialogValue.date_day
                  )
                }
                onChange={updateFunctions.updatePublicationDate}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={loading}>
            {t('buttons.cancel')}
          </Button>
          <div className={classes.wrapper}>
            <Button type="submit" color="primary" disabled={loading}>
              {t('buttons.add')}
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
}

AddSource.propTypes = {
  open: PT.bool.isRequired,
  setDialogValue: PT.func.isRequired,
  dialogValue: PT.shape({
    title: PT.string.isRequired,
    source_type_id: PT.string.isRequired,
    date_year: PT.string.isRequired,
    date_month: PT.string.isRequired,
    date_day: PT.string.isRequired,
  }).isRequired,
  toggleOpen: PT.func.isRequired,
  setAllSources: PT.func.isRequired,
  setUpdateValues: PT.func.isRequired,
  setSelectedSource: PT.func.isRequired,
};

export default AddSource;
