// todo: update and remove in refactor
/* eslint-disable react/forbid-prop-types */
import { useRef } from 'react';
import PT from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-i18next';
import Column from './column';
import useStyles from './asset-wizard-tree-styles';
import useHooks from './hooks';
import { assetTypePropType, assetTypeType } from '../../../../types';

function AssetWizardTree({
  projectName,
  theAssets,
  setTheAssets,
  theAssetTypes,
  selectedAsset,
  setSelectedAsset,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const tableRef = useRef(null);
  const { columns, openAssetTypes, toggleAssetType, addAsset } = useHooks(
    projectName,
    theAssets,
    setTheAssets,
    theAssetTypes,
    selectedAsset,
    setSelectedAsset
  );

  return (
    <Card variant="outlined" className={classes.root}>
      <CardContent className={classes.wrapper}>
        <Typography variant="h5" component="h2" gutterBottom>
          {t('parameters:addAssets')}
        </Typography>
        <Typography variant="body2" component="p">
          {t('parameters:addAssetToTree')}
        </Typography>
        <div className={classes.table} ref={tableRef}>
          <Divider />
          {columns.map(column => (
            <Column
              theAssetTypes={theAssetTypes}
              column={column}
              key={column.id}
              toggleAssetType={toggleAssetType}
              openAssetTypes={openAssetTypes}
              addAsset={addAsset}
            />
          ))}
        </div>
      </CardContent>
    </Card>
  );
}

AssetWizardTree.propTypes = {
  projectName: PT.string.isRequired,
  theAssets: PT.arrayOf(assetTypePropType, assetTypeType).isRequired,
  setTheAssets: PT.func.isRequired,
  theAssetTypes: PT.arrayOf(assetTypeType).isRequired,
  setSelectedAsset: PT.func.isRequired,
  selectedAsset: PT.string.isRequired,
};

export default AssetWizardTree;
