import PT from 'prop-types';
import CancelIcon from '@material-ui/icons/Cancel';
import StyledIconButton from '../styled';
import useButton from '../../hooks/use-button';

function RejectButton({ onClick, status }) {
  const { renderTooltipReject } = useButton(status);
  return (
    <StyledIconButton
      type="rejectParameter"
      title={renderTooltipReject(status)[0]}
      variant="outlined"
      onClick={onClick}
      disabled={renderTooltipReject(status)[1]}
      icon={<CancelIcon />}
    />
  );
}

RejectButton.propTypes = {
  onClick: PT.func.isRequired,
  status: PT.string,
};
RejectButton.defaultProps = {
  status: '',
};

export default RejectButton;
