import PT from 'prop-types';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../utils';
import useStyles from './data-drawer-source-styles';
import useSource from './hooks';
import DataDrawerGrid from '../grid';
import { StyledIconButton } from '../../buttons';

function Source(props) {
  const { t, i18n } = useTranslation();
  const {
    source: {
      title = t('dataDrawer.notDefined'),
      reference,
      url: referenceUrl,
      date_day: dateDay,
      date_month: dateMonth,
      date_year: dateYear,
      source_type: sourceType,
    },
  } = props;

  let date = dateYear;
  if (dateDay)
    date = formatDate(
      new Date(dateYear, dateMonth, dateDay),
      undefined,
      i18n.language
    );
  if (dateMonth)
    formatDate(new Date(dateYear, dateMonth), undefined, i18n.language);

  const sourceTypeName = sourceType?.name; // TODO: Update to match correct shape when source updated

  const { open, handleChangeOpen } = useSource();

  const classes = useStyles({ open });

  const sourceProperties = [
    {
      caption: t('dataDrawer.source.documentReferenceLabel'),
      content: reference, // TODO: Check if this still exists in the new model
    },
    {
      caption: t('dataDrawer.source.directoryUrl'),
      content: referenceUrl,
    },
    {
      caption: t('dataDrawer.source.publicationDate'),
      content: date || null,
    },
    { caption: t('dataDrawer.source.docType'), content: sourceTypeName },
  ];

  return (
    <Box width="100%">
      {open ? <Divider /> : null}
      <DataDrawerGrid
        icon={<LibraryBooksIcon className={classes.toggleMainColor} />}
        caption={
          <Typography
            variant="caption"
            className={classes.toggleSecondaryColor}
          >
            {t('source')}
          </Typography>
        }
        content={
          <Box>
            <StyledIconButton
              onClick={handleChangeOpen}
              icon={
                open ? (
                  <KeyboardArrowDownIcon aria-label="Collapse button" />
                ) : (
                  <KeyboardArrowRightIcon aria-label="Expand button" />
                )
              }
            />

            <Typography variant="subtitle1" display="inline">
              {title}
            </Typography>
            <Collapse in={open}>
              {sourceProperties.map(property => (
                <DataDrawerGrid
                  icon={
                    <LibraryBooksIcon className={classes.toggleMainColor} />
                  }
                  key={property.caption}
                  caption={
                    <Typography variant="caption" color="textSecondary">
                      {property.caption}
                    </Typography>
                  }
                  content={
                    <Typography variant="body1">{property.content}</Typography>
                  }
                />
              ))}
            </Collapse>
          </Box>
        }
      />
      {open ? <Divider /> : null}
    </Box>
  );
}

Source.propTypes = {
  source: PT.shape({
    id: PT.oneOfType([PT.string, PT.number]),
    source_type: PT.shape({
      id: PT.oneOfType([PT.string, PT.number]).isRequired,
      name: PT.string.isRequired,
    }),
    reference_url: PT.string,
    date_day: PT.string,
    date_month: PT.string,
    date_year: PT.string,
    title: PT.string,
    reference: PT.string,
    date: PT.string,
    url: PT.string,
    created_at: PT.string,
    updated_at: PT.string,
    deleted_at: PT.string,
  }).isRequired,
};

export default Source;
