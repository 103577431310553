import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  headerRow: {
    alignItems: 'flex-end',
  },
  content: {
    backgroundColor: theme.palette.background.default,
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'column',
    padding: theme.spacing(2),
  },
  paddedHeader: {
    paddingLeft: 10,
    paddingTop: 10,
    paddingBottom: 10,
  },
  breadcrumbHeader: {
    fontSize: 25,
  },
  breadHolder: {
    marginLeft: 10,
  },
}));
