import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PT from 'prop-types';
import Fab from '../../fab';

function GoBackButton({ onClick }) {
  return (
    <Fab title="Go back" size="small" onClick={onClick} aria-label="Go back">
      <ArrowBackIcon />
    </Fab>
  );
}
GoBackButton.propTypes = {
  onClick: PT.func,
};

GoBackButton.defaultProps = {
  onClick: () => {},
};

export default GoBackButton;
