import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  column: {
    height: '100%',
    
  },
  details: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    overflowY: 'auto',
    overflowX: "hidden",
  },
  root: {
    height: 'calc(80vh - 400px)',
  },
  viz: {
    // height: 'calc(20vh - 30px)',
    height: 'calc(500px)',
    width: '100%',
  },
}));
