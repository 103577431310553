const blue = {
  50: '#E2F1FF',
  100: '#ADE5F7',
  200: '#7CD4F2',
  300: '#4EC0EB',
  400: '#28AAE1',
  500: '#2092D3',
  600: '#1E75C3',
  700: '#2962B2',
  800: '#2148A8',
  900: '#1C2F81',
  // 100:'#192866'
};

export default blue;
