import { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import AssetWizardTree from '../asset-wizard-tree';
import AssetWizardSummary from '../asset-wizard-summary';
import { parametersApi, handleApiError } from '../../../../api';

export default (open, toggleOpen, projectName, triggerNavigationRefresh) => {
  const { projectId } = useParams();
  const { search } = useLocation();
  const { t } = useTranslation(['common', 'parameters']);
  const [activeStep, setActiveStep] = useState(0);
  const [primaryActionText, setPrimaryActionText] = useState('Next');
  const [disabled, setDisabled] = useState(true);
  const [steps, setSteps] = useState([
    {
      label: t('parameters:addAssets'),
      optional: false,
      completed: false,
    },
    { label: t('summary'), optional: false, completed: false },
  ]);
  const [theAssets, setTheAssets] = useState([]);
  const [theAssetTypes, setTheAssetTypes] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState('');
  const [primaryAction, setPrimaryAction] = useState({});

  useEffect(() => {
    const query = qs.parse(search, { ignoreQueryPrefix: true });
    if (query?.asset_id) setSelectedAsset(query.asset_id);

    return () => {
      setSelectedAsset('');
    };
  }, [search]);

  useEffect(() => {
    let didCancel = false;

    const getAssets = async () => {
      const { asset_types: assetTypes } = await parametersApi(
        'getAllAssetTypes'
      ).catch(err => handleApiError(err, []));
      const query = {
        project_id: projectId,
      };

      /* This is used to give asset types an order, based on their place in the hierarchy - used to display asset types in asset-wizard-summary */
      const countLevel = (element, count) => {
        const parent = assetTypes.find(
          currParent => currParent.id === element.parent
        );
        if (parent && parent?.parent) {
          return countLevel(parent, count + 1);
        }
        return count;
      };
      assetTypes.forEach(element => {
        const newElement = { ...element };
        newElement.level = newElement.parent ? countLevel(newElement, 1) : 0;
      });

      let { assets } = await parametersApi('getAssets', query).catch(err =>
        handleApiError(err, [])
      );

      if (!didCancel) {
        assets = assets.map(asset => ({
          ...asset,
          selected: false,
          onClick: () => setSelectedAsset(asset.id),
        }));
        setTheAssetTypes(assetTypes);
        setTheAssets(assets);
      }
    };

    if (open) getAssets();

    return () => {
      didCancel = true;
      setTheAssetTypes([]);
      setTheAssets([]);
    };
  }, [projectId, setTheAssetTypes, setTheAssets, open]);

  const stepContent = [
    <AssetWizardTree
      key="wizard tree"
      theAssets={theAssets}
      setTheAssets={setTheAssets}
      projectName={projectName}
      theAssetTypes={theAssetTypes}
      selectedAsset={selectedAsset}
      setSelectedAsset={setSelectedAsset}
    />,
    <AssetWizardSummary
      key="wizard summary"
      theAssets={theAssets}
      theAssetTypes={theAssetTypes}
      disabled={disabled}
    />,
  ];

  useEffect(() => {
    /* enable next button if new assets have been created */
    setDisabled(!theAssets.some(asset => asset?.new));
  }, [theAssets]);

  const handleClose = useCallback(() => {
    setActiveStep(0);
    setSelectedAsset('');
    toggleOpen();
  }, [toggleOpen]);

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleAccept = () => {
    // remove any 'new' assets that have not been posted
    setTheAssets(theAssets.filter(asset => !asset.new));
    handleClose();
  };

  useEffect(() => {
    // Update primary action dependant on the step
    let didCancel = false;

    const addAssets = async body => {
      const { assets } = await parametersApi('postAsset', {
        body,
      }).catch(err => handleApiError(err, []));
      return assets;
    };

    const handleNext = () => {
      setSteps(currSteps => {
        const currStepsClone = JSON.parse(JSON.stringify(currSteps));
        currStepsClone[activeStep].completed = true;
        return currStepsClone;
      });

      if (!didCancel) setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleFinish = async () => {
      setDisabled(true);
      if (theAssets.length) {
        // Map body for addAssets call to add project_id of project
        const body = {
          assets: theAssets
            .filter(asset => asset.new)
            .map(asset => {
              const newAsset = {
                asset_id: asset.id,
                asset_type_id: asset.asset_type.id,
                name: asset.name,
                project_id: projectId,
              };
              if (asset.parent) newAsset.parent_id = asset.parent; // remove null parent_ids
              return newAsset;
            }),
        };
        await addAssets(body).then(res => {
          if (res.length > 0) {
            setTheAssets(
              theAssets.map(asset => {
                return { ...asset, new: false };
              })
            );
          }
        });

        triggerNavigationRefresh();
        handleClose();
      }
    };

    if (!didCancel)
      setPrimaryAction(() => (activeStep === 1 ? handleFinish : handleNext));

    return () => {
      setPrimaryAction({});
      didCancel = true;
    };
  }, [activeStep, handleClose, theAssets, projectId, triggerNavigationRefresh]);

  useEffect(() => {
    let didCancel = false;
    if (!didCancel) {
      setPrimaryActionText(
        activeStep === 1 ? t('buttons.finish') : t('buttons.next')
      );
    }
    return () => {
      didCancel = true;
      setPrimaryActionText('');
    };
  }, [activeStep, t]);

  return {
    activeStep,
    steps,
    handleBack,
    primaryAction,
    stepContent,
    handleAccept,
    primaryActionText,
    disabled,
  };
};
