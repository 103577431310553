import { useState } from 'react';
import PT from 'prop-types';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useTranslation } from 'react-i18next';
import StyledIconButton from '../styled';

function CopyButton({ toBeCopied, toolTipText }) {
  const { t } = useTranslation();
  const [showCopied, setShowCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(toBeCopied);
    setShowCopied(true);
  };

  const handleCloseToolTip = () => {
    setTimeout(() => setShowCopied(false), 500);
  };

  return (
    <StyledIconButton
      title={showCopied ? t('copy.copied') : t('copy.copyTo', { toolTipText })}
      tooltipProps={{ leaveDelay: 0, onClose: handleCloseToolTip }}
      variant="outlined"
      onClick={handleCopy}
      disabled={!toBeCopied}
      aria-label={
        showCopied ? t('copy.copied') : t('copy.copyTo', { toolTipText })
      }
      icon={<FileCopyIcon />}
    />
  );
}

CopyButton.defaultProps = {
  toBeCopied: null,
  toolTipText: null,
};

CopyButton.propTypes = {
  toBeCopied: PT.string,
  toolTipText: PT.string,
};

export default CopyButton;
