import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  skeletonBody: {
    margin: theme.spacing(4),
  },
  content: {
    overflow: 'scroll',
    height: '100%',
  },
  modal: {
    backgroundColor: theme.palette.background.paper,
    maxWidth: 500,
    maxHeight: 300,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 'auto',
    marginTop: '20%',
    borderRadius: 5,
    boxShadow:
      'box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);',
  },
  textfield: {
    minWidth: 300,
    minHeight: 200,
    marginLeft: 25,
  },
  cancelButton: {
    marginLeft: 25,
  },
  submitButton: {
    float: 'right',
    marginRight: 25,
  },
  title: {
    marginLeft: 25,
  },
  alert: { padding: theme.spacing(3) },
}));
