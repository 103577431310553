import React, { Suspense, Navigate } from 'react';
import { BrowserRouter as Router, Route, Routes, useRouteMatch, Redirect } from 'react-router-dom';
import PrivateRoute from './private/Private';
import { MsalProvider } from '@azure/msal-react';
import { UserProvider } from './context/user-context';
import { ThemeProvider } from './context/theme-context';
import pca from './auth/auth-provider';
import history from './History';
import ErrorPage from './routes/ErrorPage';
import Home from './routes/Home'
import ManageAccount from './routes/ManageAccount'
import './App.css';

import '@arc-web/components/dist/components/container/arc-container.js';
import '@arc-web/components/dist/components/sidebar/arc-sidebar.js';
import '@arc-web/components/dist/components/menu/arc-menu.js';
import '@arc-web/components/dist/components/menu-item/arc-menu-item.js';
import '@arc-web/components/dist/components/bottombar/arc-bottombar.js';
import '@arc-web/components/dist/components/navbar/arc-navbar.js';
import '@arc-web/components/dist/components/icon-button/arc-icon-button.js';
import '@arc-web/components/dist/components/button/arc-button.js';
import '@arc-web/components/dist/components/icon/arc-icon.js';
import '@arc-web/components/dist/themes/index.css'
import '@arc-web/components/dist/themes/light.css'

import User from './components/header/User';
import Avatar from '@material-ui/core/Avatar';

import Navigation from './components/header/Navigation';
import FileUpload from './routes/FileUpload';
import ParameterMapping from './routes/parameter-mapping';
import Projects from './routes/projects';
import Dashboard from './routes/dashboard';

import { ProjectProvider } from './context/project-context';
import { ParametersProvider } from './context/parameters-context';
import { ProjectPermissionProvider } from './context/project-permission-context';
import ProjectMetadataTitle from './routes/projects/metadata-title';

function App() {
  return (
    // <Router location={history.location} navigator={history}>
    <ThemeProvider useSuspense={false}>
    <Router history={history}>
      <MsalProvider instance={pca}>
        <UserProvider>
          <arc-container>
            <arc-navbar slot="nav" logo="/logos/logo.svg" home="/" arup={true}>
            <arc-icon-button name="info" href="https://docs.sdp.arup.com" target="_blank">About</arc-icon-button>
              <React.Suspense fallback={<Avatar />}>
                <User />
              </React.Suspense>
            </arc-navbar>
            
            <arc-sidebar slot="side">
              <Navigation />
            </arc-sidebar>

            <div slot="bottom"></div>

            <div style={{height: '100%'}}>
              <Routes slot="default">
                <Route exact path='/' element={<FileUpload />} />
                <Route
                  path='/projects/:projectId/parameter-mapping'
                  element={
                    // <PrivateRoute>
                      <ParameterMapping />
                    // </PrivateRoute>
                  }
                />

                <Route
                  path='/projects'
                  element={
                    <ProjectProvider>
                      <ProjectPermissionProvider>
                        <ProjectMetadataTitle />
                          <ParametersProvider>
                            <Projects />
                          </ParametersProvider>
                        </ProjectPermissionProvider>
                      </ProjectProvider>
                  }
                />

                <Route
                  path="/projects/:sdpId"
                  element={
                    // <PrivateRoute>
                      <Projects />
                    // </PrivateRoute>
                  }
                />
                
                <Route
                  path="/projects/:sdpId/models/:modelId"
                  element={
                    // <PrivateRoute>
                      <Projects />
                    // </PrivateRoute>
                  }
                />

                <Route
                  path='/manage-account'
                  element={
                    // <PrivateRoute>
                      <ManageAccount />
                    // </PrivateRoute>
                  }
                  />
                <Route
                  path='/file-upload'
                  element={
                    // <PrivateRoute >
                      <FileUpload />
                    // </PrivateRoute>
                  }
                  />

                <Route
                  path='/dashboard'
                  element={
                    // <PrivateRoute >
                      <Dashboard />
                    // </PrivateRoute>
                  }
                  />

                <Route
                  path='/error'
                  element={
                    <Suspense fallback={null}>
                      <ErrorPage />
                    </Suspense>
                  }
                  />
              </Routes>

            </div>
          </arc-container>  
        </UserProvider>
      </MsalProvider>
    </Router>
    </ThemeProvider>
  );
}

export default App;
