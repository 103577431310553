import PT from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '../../../pickers';
import useHooks from './hooks';
import useStyles from './add-entries-wizard-styles';

function AddEntriesWizard(props) {
  const {
    open,
    setOpen,
    setUpdateValues,
    setAllSources,
    defaultUnits,
    parameterDataType,
  } = props;
  const {
    handleClose,
    unitArray,
    inputUnit,
    setInputUnit,
    dialogValue,
    setDialogValue,
    inputValue,
    setInputValue,
    updateFunctions,
    errors,
    validateFunctions,
    options,
  } = useHooks(
    setOpen,
    setUpdateValues,
    setAllSources,
    defaultUnits,
    parameterDataType
  );
  const classes = useStyles();
  const { t } = useTranslation(['common', 'parameters']);

  return (
    <Modal open={open}>
      <div className={classes.modal}>
        <h3 className={classes.title}>{t('parameters:newEntry')}</h3>
        <div className={classes.content}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextField
                variant="outlined"
                autoFocus
                fullWidth
                required
                label="Value"
              />
            </Grid>
            <Grid item xs={4}>
              {unitArray.length === 1 && unitArray[0].name === '' ? null : (
                <Autocomplete
                  value={dialogValue.unit}
                  onChange={updateFunctions.updateUnit}
                  inputValue={inputUnit}
                  onInputChange={(_, newInputValue) =>
                    setInputUnit(newInputValue)
                  }
                  options={unitArray}
                  loading={false}
                  loadingText={t('parameters:selectName')}
                  getOptionLabel={option =>
                    option.name || t('parameters:noUnit')
                  }
                  renderInput={params => (
                    <TextField
                      variant="outlined"
                      {...params}
                      fullWidth
                      label={t('parameters:unit')}
                    />
                  )}
                />
              )}
            </Grid>
          </Grid>
          <TextField
            className={classes.contentSpacing}
            variant="outlined"
            id="value"
            value={dialogValue.Location}
            onChange={updateFunctions.updateLocation}
            onBlur={validateFunctions.validateLocation}
            helperText={
              errors.locationError && t('addSource.errorLocationInSource')
            }
            error={errors.locationError}
            label={t('dataDrawer.addEntriesWizard.locationInSource')}
            type="text"
            fullWidth
            required
          />
          <TextField
            id="value"
            className={classes.contentSpacing}
            value={dialogValue.comment}
            onChange={updateFunctions.updateComment}
            onBlur={validateFunctions.validateComment}
            helperText={errors.commentError && t('addSource.errorComment')}
            error={errors.commentError}
            label={t('dataDrawer.addEntriesWizard.reasonsForAddition')}
            type="text"
            fullWidth
            multiline
            rows={3}
            variant="outlined"
          />
          <TextField
            className={classes.contentSpacing}
            variant="outlined"
            id="title"
            value={dialogValue.title}
            onChange={updateFunctions.updateTitle}
            onBlur={validateFunctions.validateTitle}
            helperText={errors.titleError && t('addSource.errorTitle')}
            error={errors.titleError}
            label={t('source')}
            type="text"
            fullWidth
            required
          />
          <TextField
            className={classes.contentSpacing}
            variant="outlined"
            id="reference"
            value={dialogValue.reference}
            onChange={updateFunctions.updateReference}
            onBlur={validateFunctions.validateReference}
            error={errors.referenceError}
            helperText={errors.referenceError && t('addSource.errorReference')}
            label={t('addSource.source.documentReferenceLabel')}
            type="text"
            fullWidth
            required
          />
          <TextField
            className={classes.contentSpacing}
            variant="outlined"
            id="url"
            onChange={updateFunctions.updateUrl}
            onBlur={validateFunctions.validateUrl}
            error={errors.urlError}
            helperText={errors.urlError && t('addSource.errorURL')}
            label={t('addSource.source.directoryUrl')}
            type="text"
            fullWidth
          />
          <Autocomplete
            className={classes.contentSpacing}
            value={dialogValue.source_type_id}
            onChange={(event, value) => {
              const typeId = value?.id || '';
              setDialogValue({
                ...dialogValue,
                source_type_id: typeId,
              });
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            id="controllable-states-demo"
            options={options}
            getOptionLabel={option => {
              if (typeof option === 'string') {
                const chosenOption = options.find(items => {
                  return items.id === dialogValue.source_type_id;
                });
                return chosenOption?.name || '';
              }
              return option?.name || '';
            }}
            renderInput={params => (
              <TextField
                variant="outlined"
                id="documentType"
                {...params}
                label={t('addSource.source.docType')}
                type="text"
                fullWidth
                required
              />
            )}
          />
          <DatePicker
            className={classes.contentSpacing}
            autoOk
            fullWidth
            disableFuture
            label={t('addSource.source.publicationDate')}
            value={
              new Date(
                dialogValue.date_year,
                dialogValue.date_month,
                dialogValue.date_day
              )
            }
            onChange={updateFunctions.updatePublicationDate}
          />
        </div>
        <div className={classes.buttons}>
          <Button className={classes.submitButton}>
            {t('buttons.submit')}
          </Button>
          <Button
            className={classes.cancelButton}
            onClick={() => handleClose()}
          >
            {t('buttons.cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

AddEntriesWizard.propTypes = {
  open: PT.bool.isRequired,
  setOpen: PT.func.isRequired,
  setUpdateValues: PT.func.isRequired,
  setAllSources: PT.func.isRequired,
  // todo: update in refactor
  // eslint-disable-next-line react/forbid-prop-types
  defaultUnits: PT.object.isRequired,
  parameterDataType: PT.string.isRequired,
};

export default AddEntriesWizard;
