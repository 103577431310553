import { Suspense } from 'react';
import Grid from '@material-ui/core/Grid';
import DescriptionIcon from '@material-ui/icons/Description';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import EmptyState from '../../components/empty-state';
// import { withAppInsights } from '../../app/app-insights';
import Navigation from './navigation';
import Details from '../../components/data-drawer-min';
import ParameterList from './parameter-list';
import { useData, handleAssetWizard } from './hooks';
import useStyles from './parameters-page-styles';
import getBottomNavigation from '../../../src/components/bottom-navigation';
import AssetWizard from './asset-wizard';
import { ResponsiveContainer } from '../../components/drawer';
import { ProjectProvider } from '../../context/project-context';
import { ParametersProvider } from '../../context/parameters-context';
import { ProjectPermissionProvider } from '../../context/project-permission-context';
import ProjectMetadataTitle from './metadata-title';

function ParameterMapping() {
  const screenXs = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const classes = useStyles();
  const { projectId } = useParams();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const parameterId = !!searchParams.get('parameter_id');

  const { triggerNavigationRefresh, navigationRefresh } = useData();
  const { toggleAssetWizard, openAssetWizard } = handleAssetWizard();
  
  return (
    <ProjectProvider>
      <ProjectPermissionProvider>
        <ProjectMetadataTitle />
          <ParametersProvider>
          
            <Grid
              container
              className={clsx(classes.root)}
              data-testid="parameter-mapping"
            >
              {!(screenXs && parameterId) && (
                <Grid item xs={screenXs ? true : 3} className={clsx([classes.details, classes.column])}>
                  <ResponsiveContainer
                    navigationRefresh={navigationRefresh}
                    toggleAssetWizard={toggleAssetWizard}
                    useNavigationMenu={false}
                    navigation={Navigation}
                    // bottomNavigation={getBottomNavigation(projectId, pathname)}
                  />
              </Grid>
              )}
              {!(screenXs && parameterId) && (
                <Grid item xs={screenXs ? true : 4} className={clsx([classes.details, classes.column])}>
                  <ParameterList />
                </Grid>
              )}
              {parameterId ? (
                <Grid
                  item
                  xs
                  className={clsx([classes.details, classes.column])}
                >
                  <Suspense fallback={null}>
                    <Details />
                  </Suspense>
                </Grid>
              ) : (
                !screenXs && (
                  <Grid
                    item
                    xs
                    className={clsx([classes.details, classes.column])}
                  >
                    <Suspense fallback={null}>
                      <EmptyState
                        Icon={DescriptionIcon}
                        title="common:dataDrawer.emptyState.title"
                        subtitle="common:dataDrawer.emptyState.subtitle"
                      />
                    </Suspense>
                  </Grid>
                )
              )}
            </Grid>
        </ParametersProvider>
        <Suspense fallback={null}>
          <AssetWizard
            open={openAssetWizard}
            toggleOpen={toggleAssetWizard}
            triggerNavigationRefresh={triggerNavigationRefresh}
          />
        </Suspense>
      </ProjectPermissionProvider>
    </ProjectProvider>
  );
}

export default ParameterMapping;
