import { useState, useEffect, useCallback } from 'react';
import { isEqual, flattenDeep } from 'lodash';
import { useParams } from 'react-router-dom';
import {
  parametersApi,
  referenceDataApi,
  handleApiError,
} from '../../../../../../api';

export default (
  parameters,
  setParameters,
  selectedAsset,
  additionalAssets,
  setErrorValue
) => {
  const [unitTypes, setUnitTypes] = useState({});
  const [selectedParameter, setSelectedParameter] = useState(null);
  const [updateValues, setUpdateValues] = useState({
    source_id: null,
  });
  const [parameterTypes, setParameterTypes] = useState([]);
  const [sources, setSources] = useState([]);
  const [unit, setUnit] = useState({});
  const [typesLoading, setTypesLoading] = useState(true);
  const [sourcesLoading, setSourcesLoading] = useState(true);
  const [unitsLoading, setUnitsLoading] = useState(true);
  const [requiredError, setRequiredError] = useState(false);
  const [displayWarning, setDisplayWarning] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [selectedSource, setSelectedSource] = useState({ title: '' });

  const { projectId } = useParams();

  const validateParameter = parameter => {
    // Does this parameter meet the API requirements? Here are the possible conditions:
    // parameter_type_id is not populated = invalid ('missing title')
    // parameter_type_id is populated and the parameters source, unit and value are all empty = valid
    // parameter_type_id is populated and the parameters source, unit and value are all populated = valid
    // parameter_type_id is populated. Some of source, unit and value are all populated, but not all = invalid ('incomplete values')
    // all fields are empty = invalid ('empty')

    // feels like there should be a better way of doing this - all suggestions welcome

    let isValid;
    let reason;

    // convert properties to shorter named variables for brevity
    const myType = parameter.parameter_type.id;
    const mySource = parameter?.revision.source_id;
    const myUnit = parameter?.revision.values[0].unit.id;
    let myValue = parameter?.revision.values[0].value;

    if (myValue === 'Enter value') {
      myValue = false;
    }

    if (
      myType &&
      ((mySource && (myUnit || !parameter.units_are_required) && myValue) ||
        (!mySource && (!myUnit || !parameter.units_are_required) && !myValue))
    ) {
      isValid = true;
    } else if (
      myType &&
      (!mySource || (!myUnit && parameter.units_are_required) || !myValue)
    ) {
      isValid = false;
      reason = 'incomplete values';
    } else if (!myType) {
      isValid = false;
      reason = 'missing title';
    } else {
      isValid = false;
      reason = 'empty';
    }

    return { isValid, reason };
  };

  const warningClose = () => {
    setDisplayWarning(false);
    setDeleteId('');
  };

  const deleteParameter = targetId => {
    setSelectedSource(null);
    setParameters(prevParameters =>
      prevParameters.filter(parameter => parameter.id !== targetId)
    );
    setSelectedParameter(null);

    setErrorValue(prevErrorValue => {
      const errorValueClone = { ...prevErrorValue };
      delete errorValueClone[targetId];
      return errorValueClone;
    });
  };

  const warningAccept = () => {
    deleteParameter(deleteId);
    warningClose();
  };

  useEffect(() => {
    let didCancel = false;
    if (selectedParameter?.parameter_type?.id) {
      const parameterType = parameterTypes.find(
        ({ id }) => id === selectedParameter.parameter_type.id
      );
      const defaultUnit = parameterType?.default_unit?.id
        ? {
            id: parameterType?.default_unit?.id,
            name: parameterType?.default_unit?.name,
          }
        : {};

      const getUnitType = async () => {
        const { unit_type: unitType } = await parametersApi('getUnitType', {
          unit_type_id: parameterType?.unit_type?.id,
        }).catch(err => handleApiError(err, []));
        if (!didCancel) setUnitTypes(unitType);
      };

      if (parameterType?.unit_type?.id) {
        getUnitType();
      }
      if (!didCancel) {
        setUnit(defaultUnit);
        setUnitsLoading(false);
      }
    } else if (!didCancel) {
      setUnit({});
      setUnitTypes({});
      setUnitsLoading(true);
    }

    return () => {
      didCancel = true;
    };
  }, [parameterTypes, selectedParameter, parameters]);

  useEffect(() => {
    let didCancel = false;

    const getParameterTypes = async () => {
      const query = {
        not_project_id: projectId,
        not_asset_id: selectedAsset?.id,
        asset_type_id: selectedAsset?.asset_type_id || '',
      };
      const { parameter_types: data } = await parametersApi(
        'getAllParameterTypes',
        query
      ).catch(err => handleApiError(err, []));
      const currParameterTypes = data.map(parameterType => ({
        ...parameterType,
        value: parameterType.name,
      }));
      if (!didCancel) {
        setParameterTypes(currParameterTypes);
        setTypesLoading(false);
      }
    };

    if (projectId) getParameterTypes();
    return () => {
      didCancel = true;
    };
  }, [projectId, selectedAsset]);

  useEffect(() => {
    let didCancel = false;
    const getSources = async () => {
      const { sources: data } = await referenceDataApi('getSources').catch(
        handleApiError
      );
      if (!didCancel) {
        setSources(data);
        setSourcesLoading(false);
      }
    };
    getSources();
    return () => {
      didCancel = true;
    };
  }, []);

  const switchParameter = newParameter => {
    setSelectedParameter(newParameter);
    const currentSource = sources.find(
      element => element.id === newParameter?.revision?.source_id
    );
    setSelectedSource(currentSource || { title: null });
  };

  const handleDelete = targetId => {
    const newParameter = {
      id: targetId,
      additionalAssets,
      parameter_type: {
        id: '',
        name: 'New Parameter',
      },
      revision: {
        status: 'unanswered',
        source_id: '',
        values: [
          {
            value: 'Enter value',
            unit: {
              name: '',
              id: '',
            },
          },
        ],
        valid: false,
      },
    };

    const parameter = parameters.find(({ id }) => id === targetId);

    if (isEqual(parameter, newParameter)) {
      deleteParameter(targetId);
    } else {
      setDeleteId(targetId);
      setDisplayWarning(true);
    }
  };

  useEffect(() => {
    const updateSource = () => {
      setParameters(currParameters =>
        currParameters.map(parameter => {
          const currParameter = { ...parameter };
          if (currParameter.id === selectedParameter?.id) {
            currParameter.revision.source_id = updateValues?.source_id || '';
            currParameter.revision.valid = validateParameter(currParameter);
          }
          return currParameter;
        })
      );
    };
    if (updateValues?.source_id) {
      updateSource();
      setUpdateValues({
        source_id: null,
      });
    }
  }, [selectedParameter, setParameters, updateValues]);

  useEffect(() => {
    if (selectedParameter?.revision)
      selectedParameter.revision.valid = validateParameter(selectedParameter);
  }, [updateValues, selectedParameter]);

  const updateDisciplineTags = useCallback(
    (targetId, newDisciplineTagList) => {
      setParameters(currParameters =>
        currParameters.map(parameter => {
          const currParameter = { ...parameter };
          if (currParameter.id === targetId) {
            currParameter.discipline_ids = newDisciplineTagList;
            setSelectedParameter(currParameter); // ensure selectedParameter has tag information
          }
          return currParameter;
        })
      );
    },
    [setParameters]
  );

  const updateTags = useCallback(
    (targetId, newTagList) => {
      setParameters(currParameters => {
        const updatedParameters = currParameters.map(parameter => {
          const currParameter = { ...parameter };
          if (currParameter.id === targetId) {
            currParameter.tag_ids = newTagList;
            setSelectedParameter(currParameter); // ensure selectedParameter has tag information
          }
          return currParameter;
        });
        return updatedParameters;
      });
    },
    [setParameters]
  );

  const filterAdditionalAssetsToPreventDuplicates = async (
    parameterId,
    parameterTypeId
  ) => {
    const query = {
      parameter_type_id: parameterTypeId,
      project_id: projectId,
    };

    const { parameters: matchingParameters } = await parametersApi(
      'getAllParameters',
      query
    ).catch(err => handleApiError(err, []));
    const parentAssetArray = matchingParameters.length
      ? flattenDeep(
          matchingParameters.map(parameter =>
            parameter.parents.map(parent => parent.id)
          )
        )
      : [];

    setParameters(currParameters =>
      currParameters.map(parameter => {
        const currParameter = { ...parameter };
        if (currParameter.id === parameterId) {
          currParameter.additionalAssets = currParameter.additionalAssets.map(
            asset => {
              const currAsset = { ...asset };
              currAsset.show = !parentAssetArray.includes(currAsset.id);
              // only want to post parameters to selected parent asset
              // update when able to post parameters to multiple parents
              currAsset.checked = currAsset.id === selectedAsset.id;
              return currAsset;
            }
          );
        }
        return currParameter;
      })
    );
  };

  const updateParameterType = (targetId, newParameterType) => {
    setParameters(currParameters =>
      currParameters.map(parameter => {
        const currParameter = { ...parameter };
        if (currParameter.id === targetId) {
          currParameter.parameter_type.id = newParameterType?.id || '';
          currParameter.parameter_type.name =
            newParameterType?.name || 'New Parameter';
          currParameter.revision.values[0].unit.id = null;
          currParameter.revision.values[0].unit.name = '';
          currParameter.revision.valid = validateParameter(currParameter);
        }
        return currParameter;
      })
    );
    if (additionalAssets && newParameterType?.id)
      filterAdditionalAssetsToPreventDuplicates(targetId, newParameterType.id);
  };

  const updateUnit = (targetId, newUnit) => {
    setParameters(currParameters =>
      currParameters.map(parameter => {
        const currParameter = { ...parameter };
        if (currParameter.id === targetId) {
          currParameter.revision.values[0].unit.id = newUnit?.id || null;
          currParameter.revision.values[0].unit.name = newUnit?.name || null;
          currParameter.revision.valid = validateParameter(currParameter);
        }
        return parameter;
      })
    );
  };

  const updateValue = (targetId, newValue) => {
    setParameters(currParameters =>
      currParameters.map(parameter => {
        const currParameter = { ...parameter };
        if (currParameter.id === targetId) {
          currParameter.revision.values[0].value = newValue || 'Enter value';
          if (newValue) {
            currParameter.revision.status = 'answered';
            updateUnit(
              targetId,
              currParameter.revision.values[0].unit?.id !== null
                ? selectedParameter.revision.values[0].unit
                : unit
            );
          } else {
            currParameter.revision.status = 'unanswered';
          }
          currParameter.revision.valid = validateParameter(currParameter);
        }
        return currParameter;
      })
    );
  };

  const updateParentAssets = (targetId, additionalAssetAssetId) => {
    setParameters(currParameters =>
      currParameters.map(parameter => {
        const currParameter = { ...parameter };
        if (currParameter.id === targetId && currParameter.additionalAssets) {
          currParameter.additionalAssets.map(additionalAsset => {
            const currAdditionalAsset = { ...additionalAsset };
            if (
              currAdditionalAsset.id === additionalAssetAssetId &&
              currAdditionalAsset.id !== selectedAsset.id
            ) {
              currAdditionalAsset.checked = !currAdditionalAsset.checked;
            }
            return currAdditionalAsset;
          });
        }
        return currParameter;
      })
    );
  };

  return {
    unitTypes,
    selectedParameter,
    parameterTypes,
    sources,
    unit,
    switchParameter,
    handleDelete,
    updateDisciplineTags,
    updateTags,
    updateParameterType,
    updateValue,
    updateValues,
    setUpdateValues,
    updateUnit,
    typesLoading,
    sourcesLoading,
    unitsLoading,
    requiredError,
    setRequiredError,
    displayWarning,
    warningClose,
    warningAccept,
    updateParentAssets,
    selectedSource,
    setSelectedSource,
  };
};
