import PT from 'prop-types';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import useStyles from './data-drawer-grid-styles';

function DataDrawerGrid(props) {
  const { icon, caption, content } = props;
  const classes = useStyles();
  return (
    <Grid
      item
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      wrap="nowrap"
    >
      {icon && (
        <Grid className={classes.icon} item>
          {icon}
        </Grid>
      )}
      <Grid item className={clsx([classes.grow, classes.content])}>
        <Grid item>{caption}</Grid>
        <Grid item>{content}</Grid>
      </Grid>
    </Grid>
  );
}

DataDrawerGrid.propTypes = {
  icon: PT.node.isRequired,
  caption: PT.node.isRequired,
  content: PT.node.isRequired,
};

export default DataDrawerGrid;
