import PT from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import useStyles from './action-card-style';
import ActionHeader from '../components/header';
import NoImage from '../components/media/no-image';

function ActionCard({
  loading,
  children,
  header,
  actions,
  actionArea,
  media,
  ...rest
}) {
  const classes = useStyles();

  return (
    <Card className={classes.card} {...rest}>
      {header && <ActionHeader loading={loading} {...header} />}
      <CardActionArea
        {...actionArea}
        data-testid="card-action-area"
        disabled={loading}
      >
        {media ? (
          <CardMedia data-testid="card-media" disabled={loading} {...media} />
        ) : (
          <NoImage loading={loading} />
        )}
        <CardContent className={classes.content}>{children}</CardContent>
      </CardActionArea>
      {actions && (
        <CardActions
          data-testid="card-actions"
          disableSpacing
          data-cy="card-action"
        >
          {actions}
        </CardActions>
      )}
    </Card>
  );
}

ActionCard.propTypes = {
  loading: PT.bool.isRequired,
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]).isRequired,
  header: PT.shape({
    onClick: PT.func.isRequired,
    avatar: PT.oneOfType([PT.string, PT.number]),
    title: PT.oneOfType([PT.string, PT.number]),
    subheader: PT.oneOfType([PT.string, PT.number]),
  }),
  actionArea: PT.shape({ onClick: PT.func }),
  actions: PT.node,
  media: PT.shape({ src: PT.string, image: PT.string }),
};

ActionCard.defaultProps = {
  header: null,
  media: null,
  actions: null,
  actionArea: null,
};

export default ActionCard;
