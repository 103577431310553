const orange = {
  50: '#FFE0C6',
  100: '#FFD280',
  200: '#FEC59B',
  300: '#FFAD77',
  400: '#F99052',
  500: '#FA7333',
  600: '#F05023',
  700: '#E14C22',
  800: '#BD4A2A',
  900: '#9A3C21',
  // 100: '#752B16'
};

export default orange;
