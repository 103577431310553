import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { ActionButton } from '../../../../../../components/buttons';
import AddParameterStep from '../../add-parameter-step';
import useStyles from './parameter-wizard-add-parameters-styles';
import {
  parameterType,
  assetTypePropType,
  dictionaryOfStringType,
  dictionaryOfBooleanType,
} from '../../../../../../types';

function ParameterWizardAddParameters({
  parameters,
  setParameters,
  addNewParameter,
  selectedAsset,
  assetHierarchy,
  additionalAssets,
  requiredDataType,
  setRequiredDataType,
  inputDataType,
  setInputDataType,
  errorValue,
  setErrorValue,
  additionalAssetsLoaded,
  handleAddingMoreParentAssets,
}) {
  const classes = useStyles();
  const { t } = useTranslation(['parameters']);

  return (
    <>
      {assetHierarchy && (
        <Grid container>
          <Grid item className={classes.breadHolder}>
            <Typography className={classes.breadcrumbHeader}>
              {t('parameters:addParametersTo')}
            </Typography>
          </Grid>
          <Grid item className={classes.breadHolder}>
            <Breadcrumbs maxItems={2} aria-label="breadcrumb">
              {assetHierarchy.map(item => (
                <Typography className={classes.breadcrumbHeader} key={item.id}>
                  {item.name}
                </Typography>
              ))}
              <Typography
                className={classes.breadcrumbHeader}
                color="textSecondary"
              >
                {selectedAsset.name || t('projectLevel')}
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      )}

      <Grid container className={classes.headerRow}>
        <Grid item xs={4} className={classes.paddedHeader}>
          <ActionButton
            onClick={addNewParameter}
            disabled={!additionalAssetsLoaded}
          >
            {t('parameters:addParameter')}
            {!additionalAssetsLoaded && (
              <CircularProgress color="inherit" size={20} />
            )}
          </ActionButton>
        </Grid>
        <Grid item xs={8} className={classes.paddedHeader}>
          <Typography variant="h6" component="h4">
            {t('parameters:paramDetail')}
          </Typography>
        </Grid>
      </Grid>
      <Divider />

      <DialogContent className={classes.content}>
        <AddParameterStep
          parameters={parameters}
          setParameters={setParameters}
          selectedAsset={selectedAsset}
          assetHierarchy={assetHierarchy}
          additionalAssets={additionalAssets}
          requiredDataType={requiredDataType}
          setRequiredDataType={setRequiredDataType}
          inputDataType={inputDataType}
          setInputDataType={setInputDataType}
          errorValue={errorValue}
          setErrorValue={setErrorValue}
          additionalAssetsLoaded={additionalAssetsLoaded}
          handleAddingMoreParentAssets={handleAddingMoreParentAssets}
        />
      </DialogContent>
    </>
  );
}

ParameterWizardAddParameters.propTypes = {
  parameters: PT.arrayOf(parameterType).isRequired,
  setParameters: PT.func.isRequired,
  addNewParameter: PT.func.isRequired,
  selectedAsset: assetTypePropType,
  assetHierarchy: PT.arrayOf(assetTypePropType),
  additionalAssets: PT.arrayOf(assetTypePropType).isRequired,
  requiredDataType: dictionaryOfStringType.isRequired,
  setRequiredDataType: PT.func.isRequired,
  inputDataType: dictionaryOfStringType.isRequired,
  setInputDataType: PT.func.isRequired,
  errorValue: dictionaryOfBooleanType.isRequired,
  setErrorValue: PT.func.isRequired,
  additionalAssetsLoaded: PT.bool.isRequired,
  handleAddingMoreParentAssets: PT.func.isRequired,
};

ParameterWizardAddParameters.defaultProps = {
  selectedAsset: {},
  assetHierarchy: [],
};

export default ParameterWizardAddParameters;
