// todo: remove in refactor
/* eslint-disable react/forbid-prop-types */
import PT from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import useStyles from './edit-entries-wizard-styles';
import { parameterType, entryType } from '../../../../types';

function EditEntriesWizard(props) {
  const { t } = useTranslation();
  const { parameter, entry, handleEditEntryOpen } = props;
  const classes = useStyles();
  return (
    <Modal open={entry.editEntryOpen}>
      <div className={classes.modal}>
        <h3 className={classes.title}>{t('multipleEntries.editEntry')}</h3>
        <div className={classes.content}>
          <Grid container>
            <Grid item xs={8}>
              <TextField
                variant="outlined"
                label="Value"
                className={classes.fullWidth}
                defaultValue={entry.entryValue}
              />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={2}>
              <TextField
                variant="outlined"
                disabled
                className={classes.fullWidth}
                defaultValue={parameter?.revision?.values[0]?.unit?.name || ''}
              />
            </Grid>
          </Grid>
          <p>
            <TextField
              variant="outlined"
              disabled
              className={classes.fullWidth}
              defaultValue={entry.source.source}
            />
          </p>
          <TextField
            className={classes.fullWidth}
            id="comment"
            label={t('multipleEntries.label')}
            variant="outlined"
            multiline
            rows={4}
          />
        </div>
        <div className={classes.buttons}>
          <Button className={classes.submitButton}>
            {t('buttons.submit')}
          </Button>
          <Button
            onClick={e => handleEditEntryOpen(e)}
            className={classes.cancelButton}
          >
            {t('buttons.cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

EditEntriesWizard.propTypes = {
  parameter: parameterType.isRequired,
  entry: entryType.isRequired,
  handleEditEntryOpen: PT.func.isRequired,
};

export default EditEntriesWizard;
