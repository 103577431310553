import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import formatTitle from '../../../../utils/format-title';
import useMultipleEntriesTags from './hooks';
import TagChip from '../../../chips/tag-chip';

function EntriesTags({ tagType }) {
  const { t } = useTranslation();
  const { mockData, onChipClick, addTag, deleteTag } = useMultipleEntriesTags();
  const tags = mockData.filter(tag => tag.tag_type.id === tagType.id);

  return (
    <Grid container direction="row" justify="flex-start" alignItems="center">
      <Grid item data-testid="tag-type-holder">
        <TagChip
          label={t('parameters:tagTitle', {
            tagType: formatTitle(tagType.name),
          })}
          tagType={tagType.name}
          holder
        />
      </Grid>
      {tags.map(tag => (
        <Grid item key={tag.name}>
          <TagChip
            label={tag.name}
            onClick={onChipClick}
            tagType={tagType.name}
            onDelete={deleteTag}
            deleteIcon={<ArrowDropDownIcon />}
          />
        </Grid>
      ))}
      <Grid item data-testid="add-tag-chip">
        <TagChip icon={<AddIcon />} onClick={addTag} tagType="addTag" />
      </Grid>
    </Grid>
  );
}

EntriesTags.propTypes = {
  tagType: PT.shape({ name: PT.string.isRequired, id: PT.string.isRequired })
    .isRequired,
};

export default EntriesTags;
