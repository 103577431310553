import { useState, useEffect, Suspense } from 'react';
import Grid from '@material-ui/core/Grid';
import DescriptionIcon from '@material-ui/icons/Description';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import EmptyState from '../../components/empty-state';
// import { withAppInsights } from '../../app/app-insights';
import Navigation from './navigation';
import Details from '../../components/data-drawer-min';
import ParameterList from './parameter-list';
import ModelsList from './models-list';

import useStyles from './parameters-page-styles';
import getBottomNavigation from '../../../src/components/bottom-navigation';

import { ResponsiveContainer } from '../../components/drawer';
import { TreevizReact } from 'treeviz-react';

import useNavigation from './hooks';

export default function Projects() {

    // return projects with filter
    // return projects from kl100
    
    const screenXs = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const classes = useStyles();
    const { projectId } = useParams();
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const parameterId = !!searchParams.get('parameter_id');
    
    const { links, history, loading, lastAsset, genericProjects, specificProjects, models, parameters, loadingModelDetails, loadingParameterDetails, selectedProject } = useNavigation();
    

    const [viz, setViz] = useState();

    // useEffect(() => {
    //   if (!viz) {
    //     const data = [
    //       { id: 1, text_1: 'Structural Data Platform', text_2: '', father: null, color: '#f3cb21' },
    //       { id: 2, text_1: 'Speckle', text_2: '', father: 1, color: '#007aff' },
    //       { id: 3, text_1: 'Digital Design Brief', text_2: '', father: 2, color: '#F05023' },
    //     ];
    //     setViz(Foo(data));
    //   }
    // }, [])

    useEffect(() => {
      if (selectedProject) {
        setViz(Foo(selectedProject));
      } else {
        const data = [
          { id: 1, text_1: 'Structural Data Platform', text_2: '', father: null, color: '#f3cb21' },
          { id: 2, text_1: 'Speckle', text_2: '', father: 1, color: '#007aff' },
          { id: 3, text_1: 'Digital Design Brief', text_2: '', father: 2, color: '#F05023' },
        ];
        setViz(Foo(data));
      }
    }, [selectedProject])
    
    const linkShapeOptions = {
      quadraticBeziers: 'quadraticBeziers',
      orthogonal: 'orthogonal',
      curve: 'curve',
    };

    const Foo = (data) => {
      return (
        // <TreevizReact
        //   data={data}
        //   idKey={'id'}
        //   relationnalField={'father'}
        //   nodeWidth={200}
        //   nodeHeight={100}
        //   mainAxisNodeSpacing={2}
        //   secondaryAxisNodeSpacing={1.3}
        //   renderNode={(node) =>
        //     `<div style="height:${node.settings.nodeHeight}px;display:flex;align-items:center;margin-left:12px">Node name: ${node.data.text_1}</div>`
        //   }
        //   onNodeClick={(node) => console.log('you clicked on node ' + node.id)}
        //   duration={500}
        //   linkWidth={(node) => 3}
        // />
        <TreevizReact
          data={data}
          relationnalField={'father'}
          nodeWidth={120}
          nodeHeight={80}
          areaHeight={500}
          areaWidth={2000}
          mainAxisNodeSpacing={2}
          secondaryAxisNodeSpacing={2}
          linkShape={'quadraticBeziers'}
          renderNode={data => {
            const nodeData = data.data;
            const settings = data.settings;
            let result = '';
            if (data.depth !== 2) {
              result = `<div class="box" style='cursor:pointer;height:${settings.nodeHeight}px; width:${settings.nodeWidth}px;display:flex;flex-direction:column;justify-content:center;align-items:center;background-color:${nodeData.color};border-radius:5px;'><div><strong>
          ${nodeData.text_1}
          </i></div></div>`;
            } else {
              result = `<div class="box" style='cursor:pointer;height:${
                settings.nodeHeight
              }px; width:${
                settings.nodeHeight
              }px;display:flex;flex-direction:column;justify-content:center;align-items:center;background-color:${
                nodeData.color
              };border-radius:${settings.nodeHeight / 2}px;'><div><strong>
          ${nodeData.text_1}
          </strong></div></div>`;
            }
            return result;
          }}
          onNodeClick={(node) => console.log('you clicked on node ' + node.id)}
          duration={600}
          isHorizontal
          linkWidth={node => 10}
        />
      );
    };

    return (
      <>
        <Grid
          container
          className={clsx(classes.viz)}
        >
          {viz}
        </Grid>
        <Grid
          container
          className={clsx(classes.root)}
          data-testid="parameter-mapping"
        >
          <Grid item xs className={clsx([classes.details, classes.column])}>
            <ResponsiveContainer
              // navigationRefresh={navigationRefresh}
              // toggleAssetWizard={}
              useNavigationMenu={false}
              navigation={Navigation}
              loading={loading}
              genericProjects={genericProjects}
              specificProjects={specificProjects}
              // bottomNavigation={getBottomNavigation(projectId, pathname)}
            />
        </Grid>

          <Grid>

          </Grid>

          <Grid item xs className={clsx([classes.details, classes.column])}>
            <ModelsList
              sdpModels={models}
              loadingDetails={loadingModelDetails}
            />
          </Grid>

          <Grid item xs className={clsx([classes.details, classes.column])}>
            <ParameterList
            sdpParameters={parameters}
            loadingDetails={loadingParameterDetails}
            />
          </Grid>

        {/* {parameterId ? (
          <Grid
            item
            xs
            className={clsx([classes.details, classes.column])}
          >
            <Suspense fallback={null}>
              <Details />
            </Suspense>
          </Grid>
        ) : (
          !screenXs && (
            <Grid
              item
              xs
              className={clsx([classes.details, classes.column])}
            >
              <Suspense fallback={null}>
                <EmptyState
                  Icon={DescriptionIcon}
                  title="common:dataDrawer.emptyState.projecttitle"
                  subtitle="common:dataDrawer.emptyState.projectsubtitle"
                />
              </Suspense>
            </Grid>
          )
        )} */}
      </Grid>
      </>
          
    )
}