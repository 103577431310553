import MuiChip from '@material-ui/core/Chip';
import withStyles from '@material-ui/core/styles/withStyles';

const Chip = withStyles(theme => ({
  root: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))(({ forwardRef, ...props }) => (
  <MuiChip className={props.classes.root} ref={forwardRef} {...props} />
));

export default Chip;
