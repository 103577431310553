import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  header: {
    height: 32,
    paddingLeft: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  headerText: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    lineHeight: '1em',
  },
}));
