/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import PT from 'prop-types';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import useStyles from './speed-dial-styles';

function SpeedDialMenu({ actions }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpenClose = () => {
    setOpen(!open);
  };

  return (
    <SpeedDial
      ariaLabel="SpeedDial"
      className={classes.speedDial}
      icon={<MoreVertIcon />}
      onClick={handleOpenClose}
      open={open}
      direction="down"
      FabProps={{ size: 'small' }}
    >
      {actions.map((action, index) => (
        <SpeedDialAction key={index} icon={action} tooltipTitle="" />
      ))}
    </SpeedDial>
  );
}

SpeedDialMenu.propTypes = {
  actions: PT.arrayOf(PT.object.isRequired).isRequired,
};

export default SpeedDialMenu;
