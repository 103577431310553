import axios from 'axios';
import { useIsAuthenticated } from '@azure/msal-react';
import React, { useState, useEffect, useCallback } from 'react';
import { mapKeys, camelCase, isEmpty, omit } from 'lodash';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import { environmentContextApi } from '../api';

export const ProjectContext = React.createContext();

export const ProjectProvider = ({ children }) => {
  const { projectId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const initialData = state ? state.project : {};
  const isAuthenticated = useIsAuthenticated();
  const [cookies, setCookie, removeCookie] = useCookies([
    'ddbFavoriteProjects',
  ]);

  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(initialData || {});
  const [requestStatus, setRequestStatus] = useState({
    status: 'idle',
  });

  useEffect(() => {
    let didCancel = false;
    const source = axios.CancelToken.source();

    // eslint-disable-next-line consistent-return
    const getProjects = async () => {
      setLoading(true);

      try {
        const response = await environmentContextApi(
          'getProjectById',
          { project_id: projectId },
          source.token
        );
        if (!didCancel && response) {
          const { project: projectResponse } = response;
          const camelCaseProject = mapKeys(projectResponse, (v, k) =>
            camelCase(k)
          );
          setProject(camelCaseProject);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          const updatedCookies = omit(cookies.ddbFavoriteProjects, [projectId]);
          removeCookie('ddbFavoriteProjects');
          if (!isEmpty(updatedCookies))
            setCookie('ddbFavoriteProjects', updatedCookies, {
              path: '/',
              maxAge: 31536000,
            });
        }
        navigate('/error', {
          state: { status: err.response.status, ...err.response.data },
          replace: true,
        });
      }
    };

    if (isAuthenticated && projectId && isEmpty(initialData)) getProjects();
    if (!isEmpty(initialData)) setLoading(false);

    return () => {
      source.cancel();
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, projectId]);

  const updateProjectConfidentiality = useCallback(
    async confidential => {
      try {
        setRequestStatus({ status: 'loading' });
        const { project: updatedProject } = await environmentContextApi(
          'patchProjectById',
          {
            project_id: projectId,
            body: { confidential },
          }
        );
        const camelCaseProject = mapKeys(updatedProject, (v, k) =>
          camelCase(k)
        );
        setProject(camelCaseProject);
        setRequestStatus({ status: 'success' });
      } catch (error) {
        setRequestStatus({ status: 'error', error });
      }
    },
    [projectId]
  );

  const resetUpdateRequest = useCallback(
    () =>
      setRequestStatus({
        status: 'idle',
      }),
    []
  );

  return (
    <ProjectContext.Provider
      value={{
        project,
        loading,
        requestStatus,
        updateProjectConfidentiality,
        resetUpdateRequest,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

ProjectProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
