import PT from 'prop-types';
import { List, InfiniteLoader, AutoSizer } from 'react-virtualized';

function InfiniteList({
  loadMoreRows,
  list,
  hasNextPage,
  loader,
  loading,
  children,
  loaderNumber,
  rowHeight,
}) {
  const rowCount = hasNextPage ? list.length + 5 : list.length;

  const isRowLoaded = ({ index }) => !hasNextPage || index < list.length;

  const rowRenderer = ({ index, key, style }) => {
    let content;
    if (!isRowLoaded({ index }) || loading) content = loader;
    else content = children[index];

    return (
      <div key={key} style={style}>
        {content}
      </div>
    );
  };

  return (
    <InfiniteLoader
      isRowLoaded={isRowLoaded}
      loadMoreRows={loadMoreRows}
      rowCount={loading ? loaderNumber : rowCount}
      minimumBatchSize={50}
    >
      {({ onRowsRendered, registerChild }) => (
        <AutoSizer>
          {({ width, height }) => (
            <List
              ref={registerChild}
              onRowsRendered={onRowsRendered}
              rowRenderer={rowRenderer}
              width={width}
              height={height}
              rowCount={loading ? loaderNumber : rowCount}
              rowHeight={rowHeight}
            />
          )}
        </AutoSizer>
      )}
    </InfiniteLoader>
  );
}

InfiniteList.propTypes = {
  list: PT.arrayOf(PT.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  loader: PT.any.isRequired,
  loaderNumber: PT.number.isRequired,
  rowHeight: PT.number.isRequired,
  loadMoreRows: PT.func.isRequired,
  hasNextPage: PT.bool.isRequired,
  loading: PT.bool.isRequired,
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]).isRequired,
};

export default InfiniteList;
