import { makeStyles, darken, lighten } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  iconBackground: {
    position: 'absolute',
    zIndex: '1',
    color:
      theme.palette.type === 'dark'
        ? lighten(theme.palette.background.default, 0.04)
        : darken(theme.palette.background.default, 0.1),
  },
  unanswered: {
    position: 'absolute',
    zIndex: '2',
    color: theme.palette.status.unanswered,
  },
  answered: {
    position: 'absolute',
    zIndex: '3',
    color: theme.palette.status.answered,
  },
  checked: {
    position: 'absolute',
    zIndex: '4',
    color: theme.palette.status.checked,
  },
  approved: {
    position: 'absolute',
    zIndex: '5',
    color: theme.palette.status.approved,
  },
  rejected: ({ size }) => ({
    position: 'absolute',
    color: theme.palette.status.answered,
    width: size,
    height: size,
  }),
  iconDiv: ({ size }) => ({
    width: size,
    height: size,
    position: 'relative',
  }),
}));
