import { useState, useEffect } from 'react';
import { parametersApi, handleApiError } from '../../../../../../api';

export default (column, theAssetTypes, openAssetTypes) => {
  const [reOrderedAssetTypes, setReOrderedAssetTypes] = useState([]);
  const [assetParentId, setAssetParentId] = useState('');
  const [selectedAsset, setSelectedAsset] = useState({});
  const [newAssetError, setNewAssetError] = useState(false);

  const toggleTheInputBox = i => {
    const updatedAssetTypes = [...reOrderedAssetTypes];
    updatedAssetTypes[i].showAddAssetInput =
      !updatedAssetTypes[i].showAddAssetInput;
    setReOrderedAssetTypes(updatedAssetTypes);
  };

  const updateInputValue = (i, text) => {
    const updatedAssetTypes = [...reOrderedAssetTypes];
    updatedAssetTypes[i].addAssetInputText = text;
    setReOrderedAssetTypes(updatedAssetTypes);
  };

  useEffect(() => {
    let reOrdered = [];
    let activeAssetTypes = [];
    const { assets } = column;

    const makeAssetTypeTheParent = async () => {
      // if this column has assets, get a filtered list of all relevant asset types and then reattach the assets to their parent asset type
      if (assets) {
        const assetParent = assets[0].asset_type.parent_id;
        setAssetParentId(assets[0].parent);
        activeAssetTypes = theAssetTypes.filter(
          assetType => assetType.parent_id === assetParent
        );

        reOrdered = await Promise.all(
          activeAssetTypes.map(async assetType => {
            const childAssets = assets.filter(
              asset => asset.asset_type.id === assetType.id
            );
            const childAssetNames = assets.map(a => a.name);
            if (assetType.asset_sub_type) {
              const { asset_sub_types: assetSubTypes } = await parametersApi(
                `getAssetSubTypes`,
                {
                  asset_type_id: assetType.id,
                }
              ).catch(err => handleApiError(err, []));
              /* eslint-disable no-param-reassign */
              assetType.asset_sub_types = assetSubTypes.filter(subType => {
                return !childAssetNames.includes(subType.name);
              });
            }

            return {
              ...assetType,
              assets: childAssets,
              open:
                openAssetTypes.includes(`${assetParentId}:${assetType.id}`) ||
                childAssets.some(asset => asset.selected),
              showAddAssetInput: false,
              addAssetInputText: '',
            };
          })
        );

        // if there are no assets this may be the last column, which still needs populating with asset types
      } else if (column?.finalColumnAssetTypes) {
        reOrdered = await Promise.all(
          column.finalColumnAssetTypes.map(async assetType => {
            if (assetType.asset_sub_type) {
              const { asset_sub_types: assetSubTypes } = await parametersApi(
                `getAssetSubTypes`,
                {
                  asset_type_id: assetType.id,
                }
              ).catch(err => handleApiError(err, []));
              assetType.asset_sub_types = assetSubTypes;
            }
            return {
              ...assetType,
              assets: [],
              open: false,
            };
          })
        );
      }

      setReOrderedAssetTypes(reOrdered);
    };

    makeAssetTypeTheParent();
  }, [assetParentId, theAssetTypes, column, openAssetTypes]);

  return {
    reOrderedAssetTypes,
    assetParentId,
    toggleTheInputBox,
    updateInputValue,
    selectedAsset,
    setSelectedAsset,
    newAssetError,
    setNewAssetError,
  };
};
