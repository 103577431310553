const purple = {
  50: '#F0DCFF',
  100: '#E6C0FF',
  200: '#DEA7FF',
  300: '#D285FF',
  400: '#CB71FF',
  500: '#B941FF',
  600: '#9E00FF',
  700: '#8211D8',
  800: '#661FB2',
  900: '#542CBC',
  // 100: '#41215E'
};
export default purple;
