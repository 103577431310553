import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  column: {
    height: '100%',
  },
  details: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    overflowY: 'auto',
    overflowX: "hidden",
  },
  root: {
    height: 'calc(100vh - 90px)',
  },
}));
