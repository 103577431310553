import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  content: {
    overflow: 'hidden',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  verticalScroll: {
    flexGrow: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    margin: `0 ${theme.spacing(-2)}px`,
    marginBottom: theme.spacing(-3),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  list: {
    padding: theme.spacing(2),
  },
  error: {
    marginTop: theme.spacing(-2),
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    marginBottom: theme.spacing(2),
  },
  header: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  noPadding: {
    padding: 0,
    paddingLeft: theme.spacing(+9),
  },
  circular: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
