import { useState, useEffect } from 'react';

// Project utils
import { handleApiError, parameterMetadataApi } from '../../../../../../../api';

export default (
  selectedParameter,
  sources,
  requiredDataType,
  setRequiredDataType,
  inputDataType,
  setInputDataType,
  errorValue,
  setErrorValue,
  updateParameterType,
  updateValue,
  updateUnit,
  unit,
  setUpdateValues
) => {
  const [parameterInput, setParameterInput] = useState('');
  const [unitInput, setUnitInput] = useState('');
  const [sourceInput, setSourceInput] = useState('');
  const [tagTypes, setTagTypes] = useState([]);
  const [tagTypesLoading, setTagTypesLoading] = useState(true);

  const handleParameterInputChange = input => setParameterInput(input);
  const handleUnitInputChange = input => setUnitInput(input);
  const handleSourceInputChange = input => setSourceInput(input);

  const handleUnitSelection = e => {
    const unitDetails = JSON.parse(e.target.value);
    const parameter = {
      ...selectedParameter,
      revision: {
        ...selectedParameter.revision,
        values: [...selectedParameter.revision.values],
      },
    };
    parameter.revision.values[0].unit_id = unitDetails.id || null;
    parameter.revision.values[0].unit.id = unitDetails.id || null;
    parameter.revision.values[0].unit.name = unitDetails.name || null;
    setUpdateValues(parameter);
  };
  const handleDateChange = date => {
    if (date) {
      updateValue(selectedParameter.id, date);
      updateUnit(selectedParameter.id, unit);
    } else {
      updateUnit(selectedParameter.id, null);
    }
  };

  const handleBooleanChange = event => {
    if (event.target.value === 'true' || event.target.value === 'false') {
      updateValue(selectedParameter.id, event.target.value);
      updateUnit(selectedParameter.id, unit);
    } else {
      updateValue(selectedParameter.id, null);
      updateUnit(selectedParameter.id, null);
    }
  };

  const handleParameterTypeChange = (_, parameterType) => {
    updateValue(selectedParameter.id, null);
    setErrorValue({ ...errorValue, [selectedParameter.id]: false });
    setRequiredDataType({
      ...requiredDataType,
      [selectedParameter.id]: parameterType ? parameterType.data_type : null,
    });
    updateParameterType(selectedParameter.id, parameterType);
  };

  const handleParameterValueChange = ({ target }) => {
    if (target.value) {
      if (Number.isFinite(Number(target.value))) {
        // Required type can be number, float or integer
        if (requiredDataType[selectedParameter.id] === 'integer') {
          setInputDataType({
            ...inputDataType,
            [selectedParameter.id]: Number.isInteger(Number(target.value))
              ? 'integer'
              : 'float',
          });
          setErrorValue({
            ...errorValue,
            [selectedParameter.id]: !Number.isInteger(Number(target.value)),
          });
        } else if (requiredDataType[selectedParameter.id] === 'float') {
          // want to allow for integer values here too - any number value is accepted
          setInputDataType({
            ...inputDataType,
            [selectedParameter.id]: 'float', // no check to be made as float & integers cover all numbers
          });
          setErrorValue({
            ...errorValue,
            [selectedParameter.id]: false,
          });
        } else {
          setInputDataType({
            ...inputDataType,
            [selectedParameter.id]: 'number',
          });
          setErrorValue({
            ...errorValue,
            [selectedParameter.id]:
              requiredDataType[selectedParameter.id] !== 'number',
          });
        }
      } else {
        setInputDataType({
          ...inputDataType,
          [selectedParameter.id]: 'string',
        });
        setErrorValue({
          ...errorValue,
          [selectedParameter.id]:
            requiredDataType[selectedParameter.id] !== 'string',
        });
        if (unit.name === '') {
          updateUnit(selectedParameter.id, unit);
        }
      }
    } else {
      // we are resetting to blank input
      setErrorValue({
        ...errorValue,
        [selectedParameter.id]: false,
      });
      if (unit.name === '') {
        updateUnit(selectedParameter.id, unit);
      }
    }
    updateValue(selectedParameter.id, target.value);
  };

  useEffect(() => {
    let didCancel = false;

    if (
      !didCancel &&
      selectedParameter?.parameter_type?.name &&
      selectedParameter?.parameter_type?.name !== 'New Parameter'
    )
      setParameterInput(selectedParameter?.parameter_type?.name);
    else if (!didCancel) setParameterInput('');

    return () => {
      didCancel = true;
    };
  }, [selectedParameter]);

  useEffect(() => {
    let didCancel = false;
    if (!didCancel && selectedParameter?.revision.values?.[0]?.unit?.id)
      setUnitInput(
        selectedParameter?.revision.values?.[0]?.unit?.name || 'No unit'
      );
    else if (!didCancel) setUnitInput('');

    return () => {
      didCancel = true;
    };
  }, [selectedParameter]);

  useEffect(() => {
    let didCancel = false;
    if (!didCancel && selectedParameter?.revision?.source_id) {
      const source = sources.find(
        ({ id }) => selectedParameter.revision.source_id === id
      );
      setSourceInput(source?.title || '');
    } else if (!didCancel) setSourceInput('');

    return () => {
      didCancel = true;
    };
  }, [selectedParameter, sources]);

  useEffect(() => {
    let didCancel = false;
    setTagTypesLoading(true);

    const getTagTypes = async () => {
      const { tag_types: newTagTypes } = await parameterMetadataApi(
        'getTagTypes'
      ).catch(handleApiError);

      if (!didCancel && newTagTypes) {
        setTagTypes(newTagTypes);
      }
      if (!didCancel) setTagTypesLoading(false);
    };

    getTagTypes();

    return () => {
      didCancel = true;
    };
  }, []);

  return {
    handleParameterInputChange,
    parameterInput,
    handleUnitInputChange,
    unitInput,
    handleSourceInputChange,
    sourceInput,
    tagTypesLoading,
    tagTypes,
    handleParameterTypeChange,
    handleParameterValueChange,
    handleDateChange,
    handleBooleanChange,
    handleUnitSelection,
  };
};
