import { darken, makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  navBar: {
    backgroundColor:
      theme.palette.type === 'dark'
        ? darken(theme.palette.background.paper, 0.2)
        : darken(theme.palette.background.paper, 0.1),
    display: 'flex',
    justifyContent: 'flex-start',
  },
  root: {
    flex: '0',
    '&$selected': {
      paddingTop: 6,
      color:
        theme.palette.type === 'dark'
          ? theme.palette.text.primary
          : theme.palette.primary.main,
      backgroundColor: theme.palette.background.paper,
      borderBottomLeftRadius: theme.spacing(1.5),
      borderBottomRightRadius: theme.spacing(1.5),
      boxShadow:
        theme.palette.type === 'dark'
          ? '0 8px 8px -1px black'
          : '0 8px 8px -1px grey',
    },
  },
  selected: {},
}));
