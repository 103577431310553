import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    padding: theme.spacing(1.5),
    height: '100%',
  },
  applyButton: {
    marginTop: theme.spacing(1),
    float: 'right',
  },
  filter: { margin: theme.spacing(1.5, 0, 1) },
  divider: { margin: theme.spacing(1.5, 0, 1) },
  title: { padding: theme.spacing(0, 0, 0, 1) },
}));
