import { makeStyles, fade } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import WarningIcon from '@material-ui/icons/Warning';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PT from 'prop-types';

const useStyles = makeStyles(theme => ({
  confidential: {
    padding: theme.spacing(1.5),
    '&$disabled': {
      backgroundColor: fade(theme.palette.error.main, 0.8),
      color: theme.palette.common.white,
    },
  },
  disabled: {},
}));

function ConfidentialChip({ iconOnly }) {
  const classes = useStyles();
  const screenSm = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const { t } = useTranslation(['projects'], { useSuspense: false });
  return (
    <Tooltip title={t('projects:confidential')}>
      <span>
        <Fab
          disabled
          size="small"
          variant="extended"
          classes={{
            root: classes.confidential,
            disabled: classes.disabled,
          }}
          aria-label={t('projects:confidential')}
        >
          <WarningIcon fontSize="small" />
          {!iconOnly && !screenSm && t('projects:confidential')}
        </Fab>
      </span>
    </Tooltip>
  );
}

ConfidentialChip.propTypes = {
  iconOnly: PT.bool,
};

ConfidentialChip.defaultProps = {
  iconOnly: false,
};

export default ConfidentialChip;
