import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useStyles from './navigation-style';

import Folder from '@material-ui/icons/FolderOutlined';
import Description from '@material-ui/icons/DescriptionOutlined';

function Navigation() {
  const navigate = useNavigate();
  const classes = useStyles();
  return (
      <arc-menu>
            <arc-menu-item onClick={() => navigate(`/manage-account`)}>
                <Description slot="prefix" />
                <span slot="prefix" className={classes.menuText}>Automation Settings</span> 
            </arc-menu-item>
            
            <arc-menu-item onClick={() => navigate(`/`)}>
                <Folder slot="prefix" />
                <span slot="prefix" className={classes.menuText}>Upload Models</span> 
            </arc-menu-item>


            <arc-menu-item onClick={() => navigate(`/projects`)}>
                <Description slot="prefix" />
                <span slot="prefix" className={classes.menuText}>Projects</span> 
            </arc-menu-item>

            <arc-menu-item
                onClick={() => {
                    navigate(`/projects/00ea91bb-9c7c-42e1-b728-14e4373e4f1e/parameter-mapping`) // prod
                    // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    //     // navigate(`/projects/1a349b70-4950-e711-8104-005056b57459/parameter-mapping`) //  dev
                    // } else {
                    //     navigate(`/projects/00ea91bb-9c7c-42e1-b728-14e4373e4f1e/parameter-mapping`) // prod
                    // }
                }
                }
            >
            
            <Description slot="prefix" />
                <span slot="prefix" className={classes.menuText}>
                Parameter Mapping
                </span>
            </arc-menu-item>

            
            
            <arc-menu-item onClick={() => navigate(`/dashboard`)}>
                <Description slot="prefix" />
                <span slot="prefix" className={classes.menuText}>
                Charts
                </span>
            </arc-menu-item>

        </arc-menu>
  );
}

export default Navigation;
