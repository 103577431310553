/* eslint-disable no-nested-ternary */
import { Suspense, useContext, useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import ListIcon from '@material-ui/icons/List';
import EmptyState from '../../../components/empty-state';
import useStyles from './parameter-list-styles';
import Header from './header';
import ParameterListFilters from './filters';
import InfiniteList from '../../../components/infinite-list';
import Alert from '../../../components/alerts';
import { ModelCard } from '../../../components/cards';
import { ParametersContext } from '../../../context/parameters-context';
import formatParameterInfo from './helper';
import LinkIcon from '@material-ui/icons/Link';

function ModelsList({sdpModels, loadingDetails}) {
  const {
    parameters,
    loading,
    hasMoreParameters,
    getParameters,
    pageLimit,
    error,
    searchParams,
    setSearchParams,
  } = useContext(ParametersContext);

  const classes = useStyles();
  const { i18n } = useTranslation([], { useSuspense: false });
  const [isFilterActive, setIsFilterActive] = useState(false);

  const handleFilterActive = () => {
    setIsFilterActive(!isFilterActive);
  };

  return (
    <div className={classes.root}>
      <Suspense fallback={null}>
        <Header
          handleFilterActive={handleFilterActive}
          isFilterActive={isFilterActive}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      </Suspense>
      {isFilterActive ? (
        <Suspense fallback={null}>
          <ParameterListFilters
            handleFilterActive={handleFilterActive}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        </Suspense>
      ) : isEmpty(sdpModels) ? (
        <Suspense fallback={null}>
          {error ? (
            <div className={classes.alert}>
              <Alert title={error.msg} text={error.details} />
            </div>
          ) : (
            <EmptyState
              Icon={ListIcon}
              title="parameters:projectnoParams"
              subtitle="parameters:projectsubtitle"
            />
          )}
        </Suspense>
      ) : (

          <InfiniteList
            loadMoreRows={getParameters}
            hasNextPage={hasMoreParameters}
            rowHeight={70}
            list={sdpModels}
            loading={loadingDetails}
            loaderNumber={pageLimit}
            loader={<ModelCard loading />}
          >
            {sdpModels.map((parameter, index) => {
              // const updatedParameter = formatParameterInfo(parameter, i18n);
              return (
                <ModelCard
                  key={parameter.id}
                  loading={loadingDetails}
                  parameter={parameter}
                  index={index + 1}
                  selected={searchParams.get('parameter_id') === parameter.id}
                  children={
                    <div className={classes.avatar}>
                      <LinkIcon style={{ color: '#007aff' }} onClick={() => window.open(`https://v2.speckle.arup.com/streams/${parameter.name}`, '_blank').focus()}/>
                    </div>
                  }
                />
              );
            })}
          </InfiniteList>

      )}
    </div>
  );
}

export default ModelsList;
