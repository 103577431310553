import PT from 'prop-types';
import Row from '../../../../../../../components/chips/display-chips/row';
import useDisciplineTags from '../hooks/use-add-parameter-discipline-tags';

function DisciplineTags({
  selectedParamDisciplineTags,
  parameterId,
  disabled,
  tagTypesLoading,
  updateDisciplineTags,
}) {
  const {
    disciplinesLoading,
    availableDisciplines,
    deleteDiscipline,
    addDiscipline,
  } = useDisciplineTags(
    tagTypesLoading,
    updateDisciplineTags,
    selectedParamDisciplineTags
  );

  return (
    <Row
      tags={selectedParamDisciplineTags}
      tagTypeName="Disciplines"
      disabled={disabled}
      tagsLoading={disciplinesLoading}
      deleteTag={deleteDiscipline}
      parameterId={parameterId}
      addTag={addDiscipline}
      availableTags={availableDisciplines}
    />
  );
}

DisciplineTags.propTypes = {
  selectedParamDisciplineTags: PT.arrayOf(
    PT.shape({ name: PT.string.isRequired }).isRequired
  ).isRequired,
  parameterId: PT.oneOfType([PT.string, PT.number]).isRequired,
  disabled: PT.bool.isRequired,
  tagTypesLoading: PT.bool.isRequired,
  updateDisciplineTags: PT.func.isRequired,
};

export default DisciplineTags;
