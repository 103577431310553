// import { isPlainObject, size } from 'lodash';
// import axios from 'axios';
// import { getToken } from '../auth';

// const apiGenerator = async (client, operationId, query, cancelToken) => {
//   const api = await client;
//   const token = await getToken();
//   const apiVersion = api.spec.info.version.split('.')[0];

//   if (api.url.includes('localhost')) api.spec.schemes = ['http'];

//   const requestInterceptor = request => {
//     const axiosRequest = {
//       ...request,
//       userFetch: async (url, req) => {
//         const newRequest = {
//           ...req,
//           data: req.body,
//           headers: { ...req.headers, Version: apiVersion },
//           withCredentials: true,
//           cancelToken,
//         };
//         const axiosResponse = await axios(url, newRequest);
//         // If body doesn't come back then give default value null
//         // https://developer.mozilla.org/en-US/docs/Web/API/Response/Response
//         const body = isPlainObject(axiosResponse.data)
//           ? JSON.stringify(axiosResponse.data)
//           : null;
//         const init = {
//           status: axiosResponse.status,
//         };
//         // If no keys for headers don't add to the response
//         if (size(axiosResponse.headers)) init.headers = axiosResponse.headers;
//         return new Response(body, init);
//       },
//     };
//     return axiosRequest;
//   };
//   const request = {
//     operationId,
//     parameters: query,
//     requestInterceptor,
//     securities: { authorized: { api_key: { value: token } } },
//   };

//   if (query?.body) request.requestBody = query.body;

//   // TODO: Try catch statement needs un commenting and errors handled correctly, within components
//   // try {
//   //   const { body } = await api.execute({
//   //     operationId,
//   //     parameters: query
//   //   });
//   //   return body;
//   // } catch (err) {
//   //   console.error(err);
//   //   console.error('data fetch error: ', operationId, { ...err });

//   //   const error = {
//   //     ...err.response.data,
//   //     status: err.response.status
//   //   };
//   //   return Promise.reject(error);
//   // }

//   const data = await api.execute(request).catch(err => {
//     if (err.constructor.name !== 'Cancel')
//       // eslint-disable-next-line no-console
//       console.error('data fetch error: ', err);
//   });

//   const body = data?.body || {};
//   // temp fix for projects page
//   if (body?.projects && body?.favourites) return body;
//   return Object.values(body)[0];
// };

// export default apiGenerator;

/* eslint-disable no-console */
import { isPlainObject, size } from 'lodash';
import axios from 'axios';
import { getToken } from '../auth';
import ApiError from './api-error';

// eslint-disable-next-line consistent-return
const apiGenerator = async (client, operationId, query, cancelToken) => {
  const api = await client;
  const token = await getToken();
  const apiVersion = api.spec.info.version.split('.')[0];

  if (api.url.includes('localhost')) api.spec.schemes = ['http'];

  const requestInterceptor = request => {
    const axiosRequest = {
      ...request,
      userFetch: async (url, req) => {
        const newRequest = {
          ...req,
          data: req.body,
          headers: { ...req.headers, Version: apiVersion },
          withCredentials: true,
          cancelToken,
        };
        const axiosResponse = await axios(url, newRequest);
        // If body doesn't come back then give default value null
        // https://developer.mozilla.org/en-US/docs/Web/API/Response/Response
        const body = isPlainObject(axiosResponse.data)
          ? JSON.stringify(axiosResponse.data)
          : null;
        const init = {
          status: axiosResponse.status,
        };
        // If no keys for headers don't add to the response
        if (size(axiosResponse.headers)) init.headers = axiosResponse.headers;
        return new Response(body, init);
      },
    };
    return axiosRequest;
  };
  const request = {
    operationId,
    parameters: query,
    requestInterceptor,
    securities: { authorized: { api_key: { value: token } } },
  };

  if (query?.body) request.requestBody = query.body;

  try {
    const { body } = await api.execute(request);
    return body;
  } catch (err) {
    if (!axios.isCancel(err)) {
      console.error('Error: ', operationId, { ...err });
      return Promise.reject(new ApiError(err));
    }
  }
};

export default apiGenerator;
